import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { ImageStateEnum } from '../../../hover-select-directive/image-state.enum';
import { InfoHoverDataModel } from '../../../info/info-hover/info-hover-data.model';

@Component({
	selector: 'app-special-ad-category-card',
	templateUrl: './special-ad-category-card.component.html',
	styleUrls: ['./special-ad-category-card.component.scss']
})
export class SpecialAdCategoryCardComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public control: AbstractControl;
	@Input() public isSpecialAdCategoryToggled: boolean;
	@Input() public specialAdCategories: any[];
	@Input() public selectedSpecialAdCategory: boolean;

	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public onSpecialAdSelectPopUp = new EventEmitter<void>();
	@Output() public onToggleOff = new EventEmitter<boolean>();

	public state = ImageStateEnum;
	public specialAdCategoryInfoHover: InfoHoverDataModel;

	constructor() {}

	public ngOnInit(): void {
		this.initComponent();
	}

	public initComponent(): void {
		this.specialAdCategoryInfoHover = {
			message: 'Ads related to credit, employment or housing.'
		};
	}

	public onSpecialAdCategoryToggle(): void {
		this.isSpecialAdCategoryToggled = !this.isSpecialAdCategoryToggled;
		if (this.isSpecialAdCategoryToggled) {
			this.specialAdCategories.forEach(category => (category.imageState = ImageStateEnum.Default));
			this.control.setValidators(Validators.required);
		} else {
			this.control.clearValidators();
			this.selectedSpecialAdCategory = false;
			this.onToggleOff.emit(this.selectedSpecialAdCategory);
		}
		this.control.setValue(null);
		this.control.updateValueAndValidity();
	}

	public onSpecialCategoryClick(category: any): void {
		this.control.setValue(category.name);
		if (!this.selectedSpecialAdCategory) {
			this.onSpecialAdSelectPopUp.emit();
		}
	}
}

export enum NotificationActionEnum {
	CREATOR_MESSAGE_RECEIVED = 'creator_message_received',
	CREATOR_EMAIL_RECEIVED = 'creator_email_received',
	CREATOR_JOB_RECEIVED = 'creator_job_received',
	CREATOR_HIRED = 'creator_hired',
	CREATOR_REJECTED = 'creator_rejected',
	CREATOR_CREATIVE_APPROVED = 'creator_creative_approved',
	CREATOR_CREATIVE_DECLINED = 'creator_creative_declined',
	CREATOR_POST_URL_ACCEPTED = 'creator_post_url_accepted',
	CREATOR_CREATIVE_DEADLINE = 'creator_creative_deadline',
	CREATOR_PAYMENT_COMPLETED = 'creator_payment_completed',
	CREATOR_STRIPE_CONNECTED = 'creator_stripe_connected',
	CREATOR_SOCIAL_MEDIA_CONNECTED = 'creator_social_media_connected',
	CREATOR_EMAIL_CONNECTED = 'creator_email_connected',

	BRAND_STRIPE_CONNECTED = 'brand_stripe_connected', // NO NEW
	BRAND_MESSAGE_RECEIVED = 'brand_message_received', // NO NEW
	BRAND_EMAIL_RECEIVED = 'brand_email_received', // NO NEW
	BRAND_JOB_APPLIED = 'brand_job_applied', // NO NEW
	BRAND_JOB_ACCEPTED = 'brand_job_accepted', // NO NEW
	BRAND_JOB_DECLINED = 'brand_job_declined', // NO NEW
	BRAND_CREATIVE_UPLOADED = 'brand_creative_uploaded', // NEW NEED
	BRAND_POST_UPLOADED = 'brand_post_uploaded', // NEW NEED
	BRAND_CRM_EMAIL_SENT = 'brand_crm_email_sent', // NO IDEA
	BRAND_PAYMENT_PENDING = 'brand_payment_pending', // NO IDEA
	BRAND_PAYMENT_SUCCESSFUL = 'brand_payment_success', // NEW NEED
	BRAND_PAYMENT_FAILED = 'brand_payment_failed', // NEW NEED
	BRAND_NOTIFY_PENDING_PAYMENT = 'brand_notify_pending_payment', // NO IDEA
	BRAND_EMAIL_PAYMENT_COMPLETED = 'brand_email_payment_completed', // NO IDEA
	BRAND_CAMPAIGN_LIMIT = 'brand_campaign_limit', // NO IDEA
	BRAND_JOB_LIMIT_PER_CAMPAIGN = 'brand_job_limit_per_campaign', // NO IDEA
	BRAND_INFLUENCER_LIMIT_PER_JOB = 'brand_influencer_limit_per_job', // NO IDEA
	BRAND_DISCOVERY_CREDIT_FINISH = 'brand_discovery_credit_finish', // NO IDEA
	BRAND_SOCIAL_MEDIA_DATA = 'brand_social_media_data', // NO IDEA
	BRAND_THIRD_PARTY_CONNECTION = 'brand_third_party_connection', // NO IDEA
	BRAND_EMAIL_CONNECTED = 'brand_email_connected', // NO NEW
	BRAND_SUBUSER_ACCEPTED_INVITE = 'brand_subuser_accepted_invite' // NO NEW
}

export interface NotificationResponse {
	notifications: NotificationModel[];
	unread_count: number;
	page_number: number;
	next_page?: number;
}

export interface NotificationModel {
	id: number;
	message: string;
	action_type: NotificationActionEnum;
	date: string;
	action_name: string;
	unread: boolean;
	user_id?: number;
	influencer_id?: number;
	job_id?: number;
	campaign_id?: number;
	creative_id?: number;
	muted?: boolean;
}

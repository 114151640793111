import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
import { Observable } from 'rxjs';
import { ConnectToGoogleRequest } from '../../authentication/_models/ConnectToGoogleRequest';

@Injectable()
export class GoogleService {
	private readonly baseURL = BaseApiUrl.GoogleAccounts;

	public constructor(private http: HttpClient) {}

	public connectToGoogleAccount(request: ConnectToGoogleRequest): Observable<void> {
		return this.http.post<void>(`${this.baseURL}managed-customer/connect-to-google-account`, request);
	}
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class BaseApiUrl {
	public static Users = environment.SharedServices.Users;
	public static GeneralSettings = environment.SharedServices.GeneralSettings;
	public static Subscription = environment.SharedServices.Subscription;
	public static UserViews = environment.SharedServices.GeneralSettings;

	public static FacebookAccount = environment.Facebook.Account;
	public static GoogleTuring = environment.Facebook.FacebookAccounts;
	public static Audience = environment.Facebook.Audience;
	public static FacebookMarketing = environment.Facebook.CampaignBuilder;
	public static FacebookPythonCampaignBuilder = environment.Facebook.CampaignBuilderPython;
	public static CampaignBuilderSmartCreate = environment.Facebook.CampaignBuilderSmartCreate;
	public static FacebookDataPipeline = environment.Facebook.DataPipeline;
	public static Pixel = environment.Facebook.Pixel;
	public static PixelPython = environment.Facebook.PixelPython;
	public static FacebookApps = environment.Facebook.Apps;
	public static Events = environment.Facebook.Pixel;

	public static Reporting = environment.SharedMarketing.Reporting;
	public static OptimizationApi = environment.SharedMarketing.Optimization;
	public static Assets = environment.SharedMarketing.Assets;

	public static GoogleAccounts = environment.Google.Accounts;
	public static GoogleInsights = environment.Google.CampaignManager;
	public static PythonGoogleAccounts = environment.Google.PythonAccounts;
	public static GoogleDataPipeline = environment.Google.DataPipeLine;

	public static HubSpot = environment.Misc.HubSpot;
	public static LogErrors = environment.Misc.LogErrors;

	public static CreativeBuilder = environment.CreativeBuilders.CreativeBuilder;
	public static FacebookAsset = environment.SharedMarketing.Assets;

	public static SocialInfluencerPython = environment.SocialInfluencerApi.SocialInfluencerPython;
	public static SocialInfluencerCS = environment.SocialInfluencerApi.SocialInfluencerListAPI;
	public static SocialMessengerPython = environment.SocialInfluencerApi.SocialMessengerPython;
	public static SocialMessengerSocket = environment.SocialInfluencerApi.SocialMessengerSocket;
}

import { Observable } from 'rxjs';
import { TableColumn } from '../../_models/TableModels/table-column';
import { TableView } from '../../_models/TableModels/table-view';
import { BaseApiUrl } from '../base-api-urls';
import { HttpClient } from '@angular/common/http';

export class PixelServiceMetadata {
	private readonly metadatasBaseUrl = `${BaseApiUrl.Pixel}metadatas`;

	constructor(protected http: HttpClient) {}

	public getTableColumns(): Observable<TableColumn[]> {
		return this.http.get<TableColumn[]>(`${this.metadatasBaseUrl}?tablename=facebookpixels`);
	}

	public createTableView(tableView: TableView): Observable<TableView> {
		return this.http.post<TableView>(`${this.metadatasBaseUrl}/views`, tableView);
	}
}

import { getCurrencySymbol } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UserPreferencesDetails } from '../../../_models/elements-models/user-preferences-details.interface';
import { ElementsService } from '../../../_services/elements.service';
import { AdAccountApiService } from '../../../_services/facebook-accounts/ad-account-api.service';
import { InsightsCategoryTypeEnum } from '../../campaign-insights/models/insights-category-type.enum';
import { SetSelectedElementsMetrics } from '../../state/shared.actions';
import {
	getCurrentAdsManagerTab,
	getGlobalDate,
	getGlobalElementsView,
	getSelectedAdAccount,
	getToggleElementsMetrics,
	globalElementsViewAds,
	SharedState
} from '../../state/shared.reducer';
import { ToastNotificationService } from '../../toast-notification/toast-notification.service';
import { ElementViewsMetrics } from './models/elements-metrics.interface';
import { ColumnType, ViewName, ViewsTrendEnum } from './models/metrics-view.enum';
import { ColumnViews } from './models/views-column.interface';
import { ViewsPostDetails } from './models/views-value.interface';

@Component({
	selector: 'app-elements-metrics',
	templateUrl: './elements-metrics.component.html',
	styleUrls: ['./elements-metrics.component.scss']
})
export class ElementsMetricsComponent implements OnInit, OnDestroy {
	@Input() public accounts: boolean;

	public trend: typeof ViewsTrendEnum = ViewsTrendEnum;
	public columnTypeEnum: typeof ColumnType = ColumnType;
	public colorList = ['#2585FE', '#00AEB3', '#EF7499', '#525BCE'];
	public viewsDetails: ElementViewsMetrics[];
	public selectedDetails: ElementViewsMetrics;
	public masterDetails: ElementViewsMetrics;
	public sendViewData: ViewsPostDetails;
	public timeRange: any;
	public facebookAccountId: string;
	public metricsLoaded: boolean;
	public currency: string;
	public selectedView: string;
	public showMetrics: boolean;
	public userPreferencesJson: UserPreferencesDetails;
	public changedOnce: boolean;
	public selectedCampaigns: string[];
	public selectedAdSets: string[];
	public selectedAds: string[];
	public activeCampaignFilter: boolean;
	public activeAdSetsFilter: boolean;
	public activeAdsFilter: boolean;
	public adsTabChanges: boolean;
	public currentAdsTab: string;

	private unsubscriber$ = new Subject<void>();

	constructor(
		private elementsService: ElementsService,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService,
		private sharedStore: Store<SharedState>,
		private accountService: AdAccountApiService,
		private adsManagerStore: Store<any>
	) {}

	public ngOnInit(): void {
		this.getCurrency();
		this.getMetricsToggle();
		this.changedAdAccount();
		this.checkDataSaved();
		this.getSelectedCampaigns();
		this.getCurrentAdsManagerTab();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public checkDataSaved(): void {
		this.elementsService
			.getUserPreferences()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.userPreferencesJson = result.details;
			});
	}

	public updateUserPreferences(data: UserPreferencesDetails): void {
		this.elementsService
			.updateUserPreferences(JSON.stringify(data))
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {},
				() => {
					this.toastNotificationService.sendErrorToast('We could not save your preferences, please try again');
				}
			);
	}

	public changedAdAccount(): void {
		this.sharedStore.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$)).subscribe(data => {
			if (this.facebookAccountId !== data.adAccount.id) {
				this.facebookAccountId = data.adAccount.id;
				this.getSelectedView();
			}
		});
	}

	public getMetricsToggle(): void {
		this.sharedStore.pipe(select(getToggleElementsMetrics), takeUntil(this.unsubscriber$)).subscribe(metrics => {
			this.showMetrics = metrics;
		});
	}

	public getSelectedView(): void {
		if (this.accounts) {
			this.sharedStore.pipe(select(getGlobalElementsView), takeUntil(this.unsubscriber$)).subscribe(view => {
				this.selectedView = view;
				this.getTimeInterval();
			});
		} else {
			this.sharedStore.pipe(select(globalElementsViewAds), takeUntil(this.unsubscriber$)).subscribe(view => {
				this.selectedView = view;
				this.getTimeInterval();
			});
		}
	}

	public getCurrency(): void {
		this.accountService.adAccountChanged$.pipe(takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.currency = getCurrencySymbol(resp.currency, 'narrow');
		});
	}

	public getViewsDetails(): void {
		this.elementsService
			.getElementViews(this.accounts)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				viewsDetails => {
					this.sharedStore.dispatch(new SetSelectedElementsMetrics({ isLoading: true, Columns: [] }));
					this.viewsDetails = viewsDetails;
					this.masterDetails = this.viewsDetails.find(details => details.name === ViewName.MasterView);
					this.selectedDetails = this.viewsDetails.find(details => details.name === this.selectedView);

					if (
						!this.changedOnce &&
						this.accounts &&
						this.userPreferencesJson &&
						this.userPreferencesJson.accountsElements &&
						this.userPreferencesJson.accountsElements.metrics
					) {
						this.selectedDetails.columns = this.userPreferencesJson.accountsElements.metrics;
						this.changedOnce = true;
					} else if (
						!this.changedOnce &&
						!this.accounts &&
						this.userPreferencesJson &&
						this.userPreferencesJson.adsManagerElements &&
						this.userPreferencesJson.adsManagerElements.metrics
					) {
						this.selectedDetails.columns = this.userPreferencesJson.adsManagerElements.metrics;
						this.changedOnce = true;
					}

					this.sharedStore.dispatch(new SetSelectedElementsMetrics({ isLoading: false, Columns: this.selectedDetails.columns }));

					this.selectedDetails.columns.forEach((item, index) => {
						this.selectedDetails.columns[index].loading = true;
						this.getNumbersData(index, item.field);
						this.selectedDetails.columns[index].color = this.colorList[index] ? this.colorList[index] : '#2585FE';
					});

					this.sharedStore.dispatch(new SetSelectedElementsMetrics({ isLoading: false, Columns: this.selectedDetails.columns }));
				},
				() => {
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}

	public getNumbersData(index: number, field: string): void {
		this.sendViewData = {
			filterModel: {},
			agColumns: field,
			timeRange: this.timeRange,
			facebookAccountId: this.facebookAccountId
		};

		if (this.selectedCampaigns && this.selectedCampaigns.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.Campaign) {
			this.sendViewData.filterModel = {
				campaign_id: {
					filter: this.selectedCampaigns,
					filterTo: null,
					filterType: 'campaign_id',
					type: 'inValues'
				}
			};

			this.activeCampaignFilter = true;
		}
		if (this.selectedAdSets && this.selectedAdSets.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.AdSet) {
			this.sendViewData.filterModel = {
				adset_id: {
					filter: this.selectedAdSets,
					filterTo: null,
					filterType: 'adset_id',
					type: 'inValues'
				}
			};

			this.activeAdSetsFilter = true;
		}
		if (this.selectedAdSets && this.selectedAdSets.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.Ad) {
			this.sendViewData.filterModel = {
				ad_id: {
					filter: this.selectedAds,
					filterTo: null,
					filterType: 'ad_id',
					type: 'inValues'
				}
			};

			this.activeAdsFilter = true;
		}

		this.elementsService
			.getViewsValue(this.sendViewData, this.accounts)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				viewsNumbers => {
					this.selectedDetails.columns[index].values = viewsNumbers;
					this.selectedDetails.columns[index].loading = false;
				},
				() => {},
				() => {}
			);

		this.metricsLoaded = true;
	}

	public changeColumn(indexId: number, newCol: ColumnViews, field: string): void {
		this.sharedStore.dispatch(new SetSelectedElementsMetrics({ isLoading: true, Columns: [] }));
		this.selectedDetails.columns[indexId].loading = true;
		this.selectedDetails.columns[indexId] = newCol;
		this.selectedDetails.columns[indexId].color = this.colorList[indexId];
		this.getNumbersData(indexId, field);
		this.sharedStore.dispatch(new SetSelectedElementsMetrics({ isLoading: false, Columns: this.selectedDetails.columns }));

		this.selectedDetails.columns[indexId].loading = true;

		if (this.accounts) {
			this.userPreferencesJson.accountsElements = this.userPreferencesJson.accountsElements || {};
			this.userPreferencesJson.accountsElements.metrics = this.selectedDetails.columns;
		} else {
			this.userPreferencesJson.adsManagerElements = this.userPreferencesJson.adsManagerElements || {};
			this.userPreferencesJson.adsManagerElements.metrics = this.selectedDetails.columns;
		}
		this.updateUserPreferences(this.userPreferencesJson);
	}

	public toggleMetricAxis(metric: string, state: boolean): void {
		this.sharedStore.dispatch(new SetSelectedElementsMetrics({ isLoading: true, Columns: [] }));
		const indexToRemove = this.selectedDetails.columns.findIndex(obj => obj.field === metric);
		this.selectedDetails.columns[indexToRemove].disabled = !state;
		this.sharedStore.dispatch(new SetSelectedElementsMetrics({ isLoading: false, Columns: this.selectedDetails.columns }));

		if (this.accounts) {
			this.userPreferencesJson.accountsElements = this.userPreferencesJson.accountsElements || {};
			this.userPreferencesJson.accountsElements.metrics = this.selectedDetails.columns;
		} else {
			this.userPreferencesJson.adsManagerElements = this.userPreferencesJson.adsManagerElements || {};
			this.userPreferencesJson.adsManagerElements.metrics = this.selectedDetails.columns;
		}
		this.updateUserPreferences(this.userPreferencesJson);
	}

	public getTimeInterval(): void {
		this.sharedStore.pipe(select(getGlobalDate), takeUntil(this.unsubscriber$)).subscribe(date => {
			this.timeRange = {
				since: date.date.startDate.format('YYYY-MM-DD'),
				until: date.date.endDate.format('YYYY-MM-DD')
			};

			this.getViewsDetails();
		});
	}

	public findMetrics(metric: string): boolean {
		return this.selectedDetails.columns.findIndex(obj => obj.field === metric) !== -1;
	}

	public getSelectedCampaigns(): void {
		if (!this.accounts) {
			this.adsManagerStore.pipe(debounceTime(1000), takeUntil(this.unsubscriber$)).subscribe((selectedCampaigns: any[]) => {
				this.selectedCampaigns = [];

				if (selectedCampaigns && selectedCampaigns.length > 0) {
					selectedCampaigns?.forEach(item => {
						this.selectedCampaigns.push(item.campaign_id);
					});

					this.selectedDetails.columns.forEach((item, index) => {
						this.selectedDetails.columns[index].loading = true;
						this.getNumbersData(index, item.field);
					});
				}

				if ((this.activeCampaignFilter && !selectedCampaigns) || (this.activeCampaignFilter && selectedCampaigns.length === 0)) {
					this.selectedDetails.columns.forEach((item, index) => {
						this.selectedDetails.columns[index].loading = true;
						this.getNumbersData(index, item.field);
					});
				}
			});
		}
	}

	public getSelectedAdSets(): void {
		if (!this.accounts) {
			this.adsManagerStore.pipe(debounceTime(1000), takeUntil(this.unsubscriber$)).subscribe((selectedAdSets: any[]) => {
				this.selectedAdSets = [];

				if (selectedAdSets && selectedAdSets.length > 0) {
					selectedAdSets?.forEach(item => {
						this.selectedAdSets.push(item.adset_id);
					});

					this.selectedDetails.columns.forEach((item, index) => {
						this.selectedDetails.columns[index].loading = true;
						this.getNumbersData(index, item.field);
					});
				}

				if ((this.activeAdSetsFilter && !selectedAdSets) || (this.activeAdSetsFilter && selectedAdSets.length === 0)) {
					this.selectedDetails.columns.forEach((item, index) => {
						this.selectedDetails.columns[index].loading = true;
						this.getNumbersData(index, item.field);
					});
				}
			});
		}
	}

	public getSelectedAds(): void {
		if (!this.accounts) {
			this.adsManagerStore.pipe(debounceTime(1000), takeUntil(this.unsubscriber$)).subscribe((selectedAds: any[]) => {
				this.selectedAds = [];

				if (selectedAds && selectedAds.length > 0) {
					selectedAds?.forEach(item => {
						this.selectedAds.push(item.ad_id);
					});

					this.selectedDetails.columns.forEach((item, index) => {
						this.selectedDetails.columns[index].loading = true;
						this.getNumbersData(index, item.field);
					});
				}

				if ((this.activeAdsFilter && !selectedAds) || (this.activeAdsFilter && selectedAds.length === 0)) {
					this.selectedDetails.columns.forEach((item, index) => {
						this.selectedDetails.columns[index].loading = true;
						this.getNumbersData(index, item.field);
					});
				}
			});
		}
	}

	public getCurrentAdsManagerTab(): void {
		if (!this.accounts) {
			this.sharedStore.pipe(select(getCurrentAdsManagerTab), takeUntil(this.unsubscriber$)).subscribe(currentTab => {
				this.currentAdsTab = currentTab;
				if (this.adsTabChanges && this.currentAdsTab === InsightsCategoryTypeEnum.Campaign) {
					this.getSelectedCampaigns();
				} else if (this.currentAdsTab === InsightsCategoryTypeEnum.AdSet) {
					this.getSelectedAdSets();
				} else if (this.currentAdsTab === InsightsCategoryTypeEnum.Ad) {
					this.getSelectedAds();
				}
				this.adsTabChanges = true;
			});
		}
	}
}

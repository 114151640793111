import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { buildGeneratedValues } from 'build-generated-values';
import { Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { UserTokenData } from 'src/app/creators-module/modules/authentication/models/auth.model';
import { AuthenticationService } from 'src/app/creators-module/modules/authentication/services/authentication.service';
import { KnowledgeBaseComponent } from 'src/app/creators-module/modules/knowledge-base/components/knowledge-base/knowledge-base.component';
import { UserProfile } from 'src/app/creators-module/modules/settings/models/settings.model';
import { SettingsService } from 'src/app/creators-module/modules/settings/services/settings.service';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { ButtonClassEnum } from 'src/app/shared/button-component/button-class.enum';
import { ButtonTypeEnum } from 'src/app/shared/button-component/button-type.enum';
import { AccountsPermissions } from 'src/app/shared/permisions/enums/accounts-permissions';
import { AdsManagerPermissions } from 'src/app/shared/permisions/enums/ads-manager-permissions';
import { AudiencePermissions } from 'src/app/shared/permisions/enums/audience-permissions';
import { BackofficePermissions } from 'src/app/shared/permisions/enums/backoffice-permissions';
import { CampaignBuilderPermissions } from 'src/app/shared/permisions/enums/campaign-builder-permissions';
import { CreativeBuilderPermissions } from 'src/app/shared/permisions/enums/creative-builder-permissions';
import { InfluencerPermissions } from 'src/app/shared/permisions/enums/influencer-permissions';
import { Modules } from 'src/app/shared/permisions/enums/modules';
import { OptimizePermissions } from 'src/app/shared/permisions/enums/optimize-permissions';
import { PixelPermissions } from 'src/app/shared/permisions/enums/pixel-permissions';
import { ProductCatalogPermissions } from 'src/app/shared/permisions/enums/product-catalog-permissions';
import { ReportsPermissions } from 'src/app/shared/permisions/enums/reports-permissions';
import { PermissionsDirectiveInterface } from 'src/app/shared/permisions/models/permissions-directive.interface';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ShowNavigationSpinner } from 'src/app/shared/state/shared.actions';
import { getnavigationSpinnerStatus, SharedState } from 'src/app/shared/state/shared.reducer';
import { SetUserDetails } from 'src/app/shared/state/user/user.actions';
import { getAccountState, getUserDetails, hasBackOfficePermission, isUserImpersonated, UserState } from 'src/app/shared/state/user/user.reducer';
import { getActiveUserManagementPage, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { UserDetails } from 'src/app/_models/identity-models/identity-detail';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { ScrollService } from 'src/app/_services/scroll.service';
import { UserRejectedReason } from 'src/app/_services/user/user-rejected-reason.enum';
import { UserStateEnum } from 'src/app/_services/user/user-state.enum';
import { environment } from 'src/environments/environment';
import { IMainMenuGroup, IProductMenuGroup, IProductSubMenuGroup } from '../_models/nav-group.interface';
import { ChannelButton } from './sidenav-button-channel.model';
import { NotificationActionEnum, NotificationModel } from '../_models/notification.model';

@Component({
	selector: 'app-sidenav-group',
	templateUrl: './sidenav-group.component.html',
	styleUrls: ['./sidenav-group.component.scss'],
	providers: [ScrollService]
})
export class SidenavGroupComponent implements OnInit {
	@ViewChild('sidenav') public sidenav: MatSidenav;
	@ViewChild('firstTimeLogin') public firstTimeLogin: TemplateRef<any>;
	@ViewChild('menuTrigger') public trigger: MatMenuTrigger;

	@ViewChild('settingsPopup') settingsPopup: ElementRef;
	@ViewChild('settingsIcon') settingsIcon: ElementRef;
	@ViewChild('mainSection') mainSection: ElementRef;
	@ViewChild('headerLogo') headerLogo: ElementRef;

	public mainMenuGroup = new Map<number, IMainMenuGroup>();

	public productMenuGroup: Map<number, IProductMenuGroup>;

	public productSubMenuGroup = new Map<number, IProductSubMenuGroup>();

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public backOfficePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.BackOffice,
		permissions: [BackofficePermissions.CanAccessBackOffice]
	};
	public accountsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Accounts,
		permissions: [AccountsPermissions.CanAccessAccounts]
	};
	public adsManagerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.AdsManager,
		permissions: [AdsManagerPermissions.CanAccessAdsManager]
	};
	public campaignBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CampaignBuilder,
		permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
	};
	public creativeBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CreativeBuilder,
		permissions: [CreativeBuilderPermissions.CanAccessCreativeBuilder]
	};
	public optimizePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Optimize,
		permissions: [OptimizePermissions.CanAccessOptimize]
	};
	public pixelPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Pixel,
		permissions: [PixelPermissions.CanAccessPixels]
	};
	public audiencePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Audience,
		permissions: [AudiencePermissions.CanAccessAudience]
	};
	public reportsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Reports,
		permissions: [ReportsPermissions.CanAccessReports]
	};
	public productCatalogPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.ProductCatalog,
		permissions: [ProductCatalogPermissions.CanAccessProductCatalog]
	};
	public influencerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Influencer,
		permissions: [InfluencerPermissions.FullAccess]
	};
	public showSpinner = false;
	public isAdmin = false;
	public isImpersonated = false;
	public unpaidInvoice = false;
	public showProgressBar = false;
	public invoicesList: InvoiceHistory[];
	public timestamp: string;
	public userDetails: UserDetails;
	public profileData: UserProfile;
	public userTokenData: UserTokenData;
	public showUserManagement: boolean;
	public dropdownIsVisible: boolean;
	public selectedButton: ChannelButton;
	public accountState: number;
	public currentUrl: string;
	public currentRoute: string;
	public progressMin = 0;
	public progressMax: number;
	public progressStatus: number;
	public isLoading: boolean;
	public isSocialMediaInfluencer: boolean;
	public isInfluencerPlatform: boolean;
	public progressBarLabel: LabelSettings = {
		position: 'end',
		visible: true,
		format: () => `${this.progressStatus + ' of ' + this.progressMax}`
	};
	public userStateEnum = UserStateEnum;
	public pcHover = false;
	public pcChildHover = false;
	public userStatus: UserRejectedReason;
	public isSelectedNoBO: string;
	public isBO = false;
	productCatalogMenuSelected: boolean;
	selectedRoute: string;
	public newPointPosition: string = '0px';
	expandMenu: boolean;
	mouseInside: boolean;
	filteredMegaMenuList: IMainMenuGroup[] = [];
	filteredMenuList: IProductMenuGroup[] = [];
	filteredSubMenuList: IProductSubMenuGroup[];
	openSubMenu: boolean;
	megaMenuOpen: any;
	megaMenuSelected: string = 'sansa';
	profileOpen: any;
	dexterList: IProductMenuGroup[] = [];
	sansaList: IProductMenuGroup[] = [];
	shakespeareList: IProductMenuGroup[] = [];
	activeSearchMapList: IProductMenuGroup[];
	activeRoute: string;
	userFName: string;
	userLName: string;
	userEmail: string;
	urlOrigin: string;
	activeParentIndex: number;
	allSubMenuItems: IProductSubMenuGroup[] = [];
	pageLoadingRoute: boolean;
	sidenavMobile: boolean;

	public get navHover(): boolean {
		return this.pcHover || this.pcChildHover;
	}

	public hideSideBar: number = 0;

	private expandAccountMenuSubscription = new Subscription();
	private unsubscriber$ = new Subject<void>();
	private asyncLoadCount: number;
	public hasSMI: boolean;
	public smiHover = false;
	public smiChildHover = false;
	public get smiNavHover(): boolean {
		return this.smiHover || this.smiChildHover;
	}
	public sidenavHeaderShow: boolean = true;
	public notifications: NotificationModel[] = [];
	public unreadCount: number = 0;

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (!this.settingsIcon?.nativeElement.contains(event.target)) {
			this.profileOpen = false;
		}
	}
	public navLinks: IProductMenuGroup[] = [
		// {
		// 	parentName: 'sansa',
		// 	name: 'dashboard',
		// 	title: 'Home',
		// 	icon: 'assets/icons/creators/sidenav/home.svg',
		// 	isSelected: false,
		// 	hasChildren: false,
		// 	hoverOver: false,
		// 	childOpen: false,
		// 	route: '/',
		// 	childIndex: 0
		// },
		{
			parentName: 'sansa',
			name: 'my-offers',
			title: 'My Offers',
			icon: 'assets/icons/creators/sidenav/my-offers.svg',
			isSelected: false,
			hasChildren: false,
			hoverOver: false,
			childOpen: false,
			route: '/my-offers',
			childIndex: 0
		},
		{
			parentName: 'sansa',
			name: 'my-jobs',
			title: 'My Jobs',
			icon: 'assets/icons/creators/sidenav/my-jobs.svg',
			isSelected: false,
			hasChildren: false,
			hoverOver: false,
			childOpen: false,
			route: '/my-jobs',
			childIndex: 0
		},
		// {
		// 	parentName: 'sansa',
		// 	name: 'messages',
		// 	title: 'Chat',
		// 	icon: 'assets/icons/creators/sidenav/messages.svg',
		// 	isSelected: false,
		// 	hasChildren: false,
		// 	hoverOver: false,
		// 	childOpen: false,
		// 	route: '/messaging',
		// 	childIndex: 0
		// },
		// {
		// 	parentName: 'sansa',
		// 	name: 'creatives',
		// 	title: 'Creatives',
		// 	icon: 'assets/icons/creators/sidenav/creatives.svg',
		// 	isSelected: false,
		// 	hasChildren: false,
		// 	hoverOver: false,
		// 	childOpen: false,
		// 	route: '/creatives',
		// 	childIndex: 0
		// },
		{
			parentName: 'sansa',
			name: 'my-profile',
			title: 'My Profile',
			icon: 'assets/icons/creators/sidenav/my-profile.svg',
			isSelected: false,
			hasChildren: false,
			hoverOver: false,
			childOpen: false,
			route: '/settings',
			childIndex: 0,
			class: 'fit-width'
		}
	];

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private accountTypeService: AccountTypeService,
		private config: NgSelectConfig,
		private store: Store<UserManagementState>,
		private dialog: MatDialog,
		public translate: TranslateService,
		private userStore: Store<UserState>,
		private backOfficeService: BackOfficeService,
		// private smartCreateService: CampaignSmartCreateService,
		private scrollService: ScrollService,
		private sharedService: SharedService,
		private settingsService: SettingsService,
		public cdRef: ChangeDetectorRef,
		private sharedStore: Store<SharedState>,
		private ngZone: NgZone,
		private breakpointObserver: BreakpointObserver
	) {
		this.pageLoadingRoute = true;
		this.mainMenuGroup = new Map();
		// const megaMenus = this.authenticationService.allowedMegaRoutes$.value;
		this.mainMenuGroup.set(1, {
			name: 'sansa',
			title: 'Sansa',
			icon: 'assets/icons/nav-menu/smi.svg',
			isSelected: true,
			hoverOver: false
		});

		breakpointObserver
			.observe(['(max-width: 820px)'])
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				if (result.breakpoints['(max-width: 820px)']) {
					this.sidenavMobile = true;
				} else this.sidenavMobile = false;
			});

		// try {
		// 	megaMenus.forEach((item, index) => {
		// 		this.mainMenuGroup.set(index + 1, item);
		// 	});
		// } catch (e) {
		// 	this.router.navigate(['']);
		// }

		this.productMenuGroup = new Map([
			[
				1,
				{
					parentName: 'sansa',
					name: 'dashboard',
					title: 'Dashboard',
					icon: 'assets/icons/creators/sidenav/home.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/',
					childIndex: 0
				}
			],
			[
				2,
				{
					parentName: 'sansa',
					name: 'find-jobs',
					title: 'Find Jobs',
					icon: 'assets/icons/creators/sidenav/find-jobs.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/find-jobs',
					childIndex: 0
				}
			],
			[
				3,
				{
					parentName: 'sansa',
					name: 'my-jobs',
					title: 'My Jobs',
					icon: 'assets/icons/creators/sidenav/my-jobs.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/my-jobs',
					childIndex: 0
				}
			],
			[
				4,
				{
					parentName: 'sansa',
					name: 'creatives',
					title: 'Creatives',
					icon: 'assets/icons/creators/sidenav/creatives.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/creatives',
					childIndex: 0
				}
			],
			[
				5,
				{
					parentName: 'sansa',
					name: 'messages',
					title: 'Messages',
					icon: 'assets/icons/creators/sidenav/messages.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/messaging',
					childIndex: 0
				}
			],
			[
				6,
				{
					parentName: 'sansa',
					name: 'earnings',
					title: 'Earnings',
					icon: 'assets/icons/creators/sidenav/earnings.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/earnings',
					childIndex: 0
				}
			]
		]);

		this.sharedService.userDetailsSubject$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
			this.userTokenData = res;
		});

		this.productSubMenuGroup = new Map([
			[1, { parentName: 'reporting', name: 'campaign-dashboard', title: 'Campaign Dashboard', icon: '', isSelected: false, hoverOver: false }]
		]);

		this.settingsService
			.profileDataListener()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(profileData => {
				if (profileData) {
					this.profileData = profileData;
				}
			});

		if (environment.Flags.hasTimestamp) {
			this.timestamp = buildGeneratedValues.timeStamp;
		}

		this.config.appendTo = 'body';
		this.config.bindValue = 'value';
	}

	public ngOnInit(): void {
		this.checkRouteMap();
		this.isUserAdmin();
		this.initializeSpinner();

		// this.hideSideBar = +localStorage.getItem('hideBar') || 0;

		if (this.selectedButton) {
			this.accountTypeService.setAccountType(this.selectedButton.sourceChannel);
		}
		this.impersonationHandle();
		this.checkUserManagementState();
		this.isUnpaidInvoice();
		if (!this.isAdmin) {
			setTimeout(() => {
				// this.filterMegaMenutList();
				this.filterMenutList('sansa');
			}, 200);
		}
		this.sharedService
			.getSidenavHeaderState()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(state => {
				this.sidenavHeaderShow = state;
			});
		// this.getAllNotifications();
	}

	public updateUnreadCountEvent(count: number): void {
		this.unreadCount = count;
	}

	public generateFakeEvent(notification: NotificationModel): void {
		this.unreadCount++;
		this.notifications.push(notification);
	}

	public postNotif(): void {
		this.sharedService
			.postMockNotification()
			.pipe(take(1))
			.subscribe(res => {
				console.log('posted ');
			});
	}

	public getAllNotifications(): void {
		this.sharedService
			.getAllNotifications(10)
			.pipe(take(1))
			.subscribe(res => {
				this.notifications = res.notifications;
				this.unreadCount = res.unread_count;
				this.initNotificationListener();
			});
	}

	public initNotificationListener(): void {
		this.sharedService
			.observeNotificationEvents()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					try {
						console.log('socket res aya 1 -\n', res);
						console.log('socket res aya 2 -\n', typeof res);
						console.log('socket res aya 3 -\n', JSON.parse(res));
					} catch (e) {
						console.log('parse error', e);
					}
				},
				err => {
					console.log('socket err aya -\n', err);
				}
			);
	}

	public ngAfterViewChecked(): void {
		this.cdRef.detectChanges();
	}

	public checkRouteMap(): void {
		this.navLinks.forEach(v => {
			v.isSelected = false;
			if (location.pathname === v.route) v.isSelected = true;
			else if (location.pathname.includes(v.route) && v.route !== '/') v.isSelected = true;
		});
		this.checkUrlIsParent();
		this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((e: RouterEvent) => {
			this.navLinks.forEach(v => {
				if (e.url === v.route) v.isSelected = true;
				else if (e.url.includes(v.route) && v.route !== '/') v.isSelected = true;
				else v.isSelected = false;
			});
			this.checkUrlIsParent();
		});
	}

	public checkUrlIsParent(): void {
		const isParentUrl = this.navLinks.some(nav => {
			return this.router.url === nav.route;
		});
		this.sharedService.showFooter = isParentUrl;
	}

	public navLinkTrigger(index: number): void {
		this.navLinks.forEach(v => {
			v.isSelected = false;
		});
		this.navLinks[index].isSelected = true;
		this.router.navigate([this.navLinks[index].route]);
	}

	toggleProfileAction(): void {
		this.profileOpen ? (this.profileOpen = false) : (this.profileOpen = true);
	}

	public toggleExpandNavMenu(): void {
		if (this.expandMenu) {
			this.expandMenu = false;
			// this.authenticationService.expandMenu$.next(this.expandMenu);
		} else {
			this.expandMenu = true;
			// this.authenticationService.expandMenu$.next(this.expandMenu);
		}
	}

	public filterMegaMenutList(): void {
		let filtered: IMainMenuGroup[] = [];
		// const activeMegaRouteIndex = this.authenticationService.setMegaRouteIndex$.value;

		for (let item of this.mainMenuGroup.values()) {
			filtered.push(item);
			this.filterMenutList(item.name);
		}
		this.filteredMegaMenuList = filtered;

		// this.megaMenuItemActive(activeMegaRouteIndex);
	}

	public filterMenutList(megaMenuName: string): void {
		for (let item of this.productMenuGroup.values()) {
			if (item.parentName === megaMenuName && megaMenuName === 'dexter') {
				this.dexterList.push(item);
			}
			if (item.parentName === megaMenuName && megaMenuName === 'sansa') {
				this.sansaList.push(item);
			}
			if (item.parentName === megaMenuName && megaMenuName === 'shakespeare') {
				this.shakespeareList.push(item);
			}
		}

		if (this.megaMenuSelected === 'dexter') {
			this.filteredMenuList = this.dexterList;
		}
		if (this.megaMenuSelected === 'sansa') {
			this.filteredMenuList = this.sansaList;
			// this.router.navigate(['/']);
		}
		if (this.megaMenuSelected === 'shakespeare') {
			this.filteredMenuList = this.shakespeareList;
		}
		this.openSubMenu = true;
	}

	menuItemTrigger(index: number): void {
		this.menuItemActive(index);
	}

	menuItemSubTrigger(index: number): void {
		this.menuItemSubActive(index);
	}

	menuItemActive(index: number, pushRoute = true): void {
		let indexactive = this.filteredMenuList.findIndex(x => x.isSelected == true);
		if (index != indexactive) {
			this.sharedStore.dispatch(new ShowNavigationSpinner());
		}
		this.filteredMenuList.forEach(item => {
			item.isSelected = false;
		});
		for (let i = 0; i < this.filteredMenuList.length; i++) {
			if (i === index) {
				this.filteredMenuList[i].isSelected = true;
				if (this.filteredMenuList[i].hasChildren) {
					this.expandMenu = true;
					// this.authenticationService.expandMenu$.next(this.expandMenu);
				}
				this.menuChildrenList(this.filteredMenuList[i], i, pushRoute);

				break;
			}
		}
		this.activeParentIndex = index;
		if (this.filteredMenuList[index].name === 'audience-creator') {
			// this.authenticationService.triggerNavRoute$.next('audience');
		}
		if (this.filteredMenuList[index].name === 'copywriter') {
			// this.authenticationService.triggerNavRoute$.next('content');
		}
		if (this.filteredMenuList[index].name === 'campaign-builder') {
			// this.authenticationService.triggerNavRoute$.next('google');
		}
		if (!this.filteredMenuList[index].hasChildren) {
			this.router.navigate([this.filteredMenuList[index].route]);
		}
		if (!this.filteredMenuList[index].hasChildren) {
			// this.expandMenu = false;
			this.filteredMenuList.forEach(item => {
				item.childOpen = false;
			});
		} else {
			this.expandMenu = true;
			// this.authenticationService.expandMenu$.next(this.expandMenu);
			this.filteredMenuList[index].childOpen = true;
		}
	}

	megaMenuItemActive(index: number, change = false): void {
		this.filteredMegaMenuList.forEach(item => {
			item.isSelected = false;
		});
		let routesplit = this.activeRoute.split('/');
		for (let i = 0; i < this.filteredMegaMenuList.length; i++) {
			if (i === index) {
				this.filteredMegaMenuList[i].isSelected = true;
				this.megaMenuSelected = this.filteredMegaMenuList[i].name;
				break;
			}
		}
		if (this.megaMenuSelected === 'dexter') {
			this.filteredMenuList = this.dexterList;
			this.router.navigate(['/accounts']);
		}
		if (this.megaMenuSelected === 'sansa') {
			this.filteredMenuList = this.sansaList;

			if (this.activeRoute !== '/terms-and-conditions' && !(this.activeRoute.indexOf('outreach') >= 0 && this.activeRoute.indexOf('customize') >= 0)) {
				if (routesplit[3] == 'influencer-profile') {
					this.router.navigate([this.activeRoute]);
				} else {
					this.router.navigate(['/']);
				}
			}
		}
		if (this.megaMenuSelected === 'shakespeare') {
			this.filteredMenuList = this.shakespeareList;
			this.router.navigate(['/shakespeare']);
		}
		this.megaMenuOpen = false;
		// this.expandMenu = false;
		this.filteredSubMenuList = [];
		this.filteredMenuList.forEach(item => {
			item.isSelected = false;
			item.childOpen = false;
		});
		this.activeParentIndex = 0;
		if (this.megaMenuSelected === 'sansa') {
			if (routesplit[3] == 'influencer-profile') {
				this.filteredMenuList.length > 0 ? (this.filteredMenuList[1].isSelected = true) : null;
			} else {
				this.filteredMenuList.length > 0 ? (this.filteredMenuList[0].isSelected = true) : null;
			}
		} else {
			this.filteredMenuList.length > 0 ? (this.filteredMenuList[0].isSelected = true) : null;
		}

		if (this.pageLoadingRoute) {
			setTimeout(() => {
				this.pageLoadingRoute = false;
			}, 1000);
		}
	}

	menuItemSubActive(index: number, pushRoute = true): void {
		this.filteredSubMenuList.forEach(item => {
			item.isSelected = false;
		});
		for (let i = 0; i < this.filteredSubMenuList.length; i++) {
			if (i === index) {
				// this.authenticationService.navMenuRoute$.next(true);
				const findParent = this.filteredMenuList.findIndex(item => {
					return item.name === this.filteredSubMenuList[i].parentName;
				});
				this.activeParentIndex = findParent;
				if (this.filteredSubMenuList[i].parentName === 'reporting') {
					if (pushRoute) {
						this.router.navigate([this.filteredMenuList[findParent].route]).then(() => {
							// this.authenticationService.setSubNavIndex$.next(i);
						});
					}
				}
				if (this.filteredSubMenuList[i].parentName === 'audience-creator') {
					// this.authenticationService.triggerNavRoute$.next('audience');
					if (pushRoute) {
						this.router.navigate([this.filteredMenuList[findParent].route]);
					}
				}
				if (this.filteredSubMenuList[i].parentName === 'copywriter') {
					// this.authenticationService.triggerNavRoute$.next('content');
					if (pushRoute) {
						this.router.navigate([this.filteredMenuList[findParent].route]);
					}
				}
				if (this.filteredSubMenuList[i].parentName === 'templates') {
					this.router.navigate([this.filteredMenuList[findParent].route]);
				}
				if (this.filteredSubMenuList[i].parentName === 'campaign-builder') {
					if (this.filteredSubMenuList[i].name === 'google') {
						// this.authenticationService.triggerNavRoute$.next('google');
					} else if (this.filteredSubMenuList[i].name === 'facebook') {
						// this.authenticationService.triggerNavRoute$.next('facebook');
					}
					if (pushRoute) {
						this.router.navigate([this.filteredMenuList[findParent].route]);
					}
				}
				if (
					this.filteredSubMenuList[i].parentName !== 'reporting' &&
					this.filteredSubMenuList[i].parentName !== 'copywriter' &&
					this.filteredSubMenuList[i].parentName !== 'audience-creator' &&
					this.filteredSubMenuList[i].parentName !== 'campaign-builder'
				) {
					setTimeout(() => {
						if (pushRoute) {
							this.router.navigate([this.filteredSubMenuList[i].route]);
						}
					}, 200);
				}
				if (this.filteredSubMenuList[i].parentName === 'reporting') {
					switch (i) {
						case 1:
							this.filteredSubMenuList[0].isSelected = true;
							this.filteredMenuList[findParent].childIndex = 0;
							break;
						case 3:
							this.filteredSubMenuList[2].isSelected = true;
							this.filteredMenuList[findParent].childIndex = 2;
							break;
						default:
							this.filteredSubMenuList[i].isSelected = true;
							this.filteredMenuList[findParent].childIndex = i;
							break;
					}
				} else {
					this.filteredSubMenuList[i].isSelected = true;
					this.filteredMenuList[findParent].childIndex = i;
				}

				break;
			}
		}
	}

	public menuChildrenList(list: IProductMenuGroup, parentIndex, pushRoute = true): void {
		let filteredSubMenu: IProductSubMenuGroup[] = [];
		for (let item of this.productSubMenuGroup.values()) {
			if (item.parentName === list.name) {
				filteredSubMenu.push(item);
			}
		}
		this.filteredSubMenuList = filteredSubMenu;
		if (this.filteredSubMenuList.length > 0) {
			if (this.filteredMenuList[parentIndex].childIndex === 0) {
				this.filteredSubMenuList[0].isSelected = true;
				this.menuItemSubActive(0, pushRoute);
			} else {
				this.filteredSubMenuList[this.filteredMenuList[parentIndex].childIndex].isSelected = true;
				this.menuItemSubActive(this.filteredMenuList[parentIndex].childIndex, pushRoute);
			}
			this.openSubMenu = true;
		} else {
			this.menuItemSubActive(this.filteredMenuList[parentIndex].childIndex, pushRoute);
		}
		// this.store.dispatch(new HideGlobalSpinner());
	}

	public collapseMenu(): void {
		this.openSubMenu ? (this.openSubMenu = false) : (this.openSubMenu = true);
	}

	public mouseEnterMegaMenu(index: number): void {
		this.filteredMegaMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredMegaMenuList.length; i++) {
			if (i === index) {
				this.filteredMegaMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveMegaMenu(index): void {
		this.filteredMegaMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterMenu(index: number): void {
		this.filteredMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredMenuList.length; i++) {
			if (i === index) {
				this.filteredMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveMenu(index): void {
		this.filteredMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterSubMenu(index: number): void {
		this.filteredSubMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredSubMenuList.length; i++) {
			if (i === index) {
				this.filteredSubMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveSubMenu(index): void {
		this.filteredSubMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterMenuEffect(): Object {
		let styles = {
			background: !this.megaMenuOpen ? '#DADCE0' : '#fafafa'
		};
		return styles;
	}

	public menuItemSelectedEffect(): Object {
		let styles = {
			background: '#DADCE0',
			'border-left': !this.megaMenuOpen ? '5px solid #5b5fc7' : '0px'
		};
		return styles;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();

		this.removeSubscriptions();
	}

	public onScroll(event: any): void {
		this.scrollService.changeScrollY(event.target.scrollTop);
	}

	public exitImpersonation(): void {
		// const oldToken = localStorage.getItem(StorageKey.oldToken);
		// const oldDecodedToken = localStorage.getItem(StorageKey.oldDecodedJwtIo);

		this.userStore.pipe(select(getUserDetails), take(1)).subscribe(userDetails => {
			this.userDetails = userDetails;
		});
		this.router.navigate(['/back-office']);

		// localStorage.setItem(StorageKey.token, oldToken);
		// localStorage.setItem(StorageKey.decodedJwtIo, JSON.stringify(oldDecodedToken));

		this.userDetails.IsImpersonated = false;
		this.userStore.dispatch(new SetUserDetails(this.userDetails));

		this.resetPermissions();
	}

	public impersonationHandle(): void {
		this.userStore.pipe(select(getUserDetails), takeUntil(this.unsubscriber$)).subscribe(details => {
			this.isBO = details?.FacebookBusinessOwnerId !== '' && details?.FacebookBusinessOwnerId ? true : false;
			this.userStatus = details?.RejectedReason;
			this.resetPermissions();
		});
		this.userStore.pipe(select(isUserImpersonated), takeUntil(this.unsubscriber$)).subscribe(isImpersonated => {
			this.isImpersonated = isImpersonated;
			this.dropdownIsVisible = true;
			this.resetPermissions();
		});
		this.userStore.pipe(select(hasBackOfficePermission), take(1)).subscribe(isAdmin => {
			this.isAdmin = isAdmin;
			this.dropdownIsVisible = !isAdmin;
		});
	}

	public checkUserManagementState(): void {
		this.store.pipe(select(getActiveUserManagementPage), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.showUserManagement = resp;
		});
	}

	public openKnowledgeBase(): void {
		this.dialog.open(KnowledgeBaseComponent, {
			width: '600px',
			height: '100%',
			position: {
				right: '0'
			},
			panelClass: ['matdialog-no-padding', 'slide', 'slideInRight']
		});
	}

	public goToUserPage(): void {
		this.profileOpen = false;
		this.router.navigate(['/user-management/my-profile']);
	}

	public logout(): void {
		this.authenticationService.signOut();
	}

	public isUserAdmin(): void {
		this.userStore.pipe(select(hasBackOfficePermission), takeUntil(this.unsubscriber$)).subscribe(isAdmin => {
			this.isAdmin = isAdmin;
		});
	}

	private removeSubscriptions(): void {
		this.expandAccountMenuSubscription.unsubscribe();
	}

	private initializeSpinner(): void {
		this.sharedStore
			.select(getnavigationSpinnerStatus)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(status => (this.showSpinner = status));
	}

	private isUnpaidInvoice(): void {
		this.userStore.pipe(select(getAccountState), takeUntil(this.unsubscriber$)).subscribe(accountState => {
			this.accountState = accountState;

			if (this.accountState === 7 && !this.isAdmin) {
				this.unpaidInvoice = true;
			}
		});
	}

	private resetPermissions(): void {
		this.backOfficePermission = {
			moduleName: Modules.BackOffice,
			permissions: [BackofficePermissions.CanAccessBackOffice]
		};
		this.accountsPermission = {
			moduleName: Modules.Accounts,
			permissions: [AccountsPermissions.CanAccessAccounts]
		};
		this.adsManagerPermission = {
			moduleName: Modules.AdsManager,
			permissions: [AdsManagerPermissions.CanAccessAdsManager]
		};
		this.campaignBuilderPermission = {
			moduleName: Modules.CampaignBuilder,
			permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
		};
		this.optimizePermission = {
			moduleName: Modules.Optimize,
			permissions: [OptimizePermissions.CanAccessOptimize]
		};
		this.pixelPermission = {
			moduleName: Modules.Pixel,
			permissions: [PixelPermissions.CanAccessPixels]
		};
		this.audiencePermission = {
			moduleName: Modules.Audience,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.reportsPermission = {
			moduleName: Modules.Reports,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.influencerPermission = {
			moduleName: Modules.Influencer,
			permissions: [InfluencerPermissions.FullAccess]
		};
	}

	public goToSettings(): void {
		this.profileOpen = false;
		this.ngZone.run(() => {
			this.router.navigate(['/settings']);
		});
	}
}

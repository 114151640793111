import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-lolly-spinning-loader',
	templateUrl: './lolly-spinning-loader.component.html',
	styleUrls: ['./lolly-spinning-loader.component.scss']
})
export class LollySpinningLoaderComponent implements OnInit {
	@Input() public rotating: boolean;
	@Input() public background: string;

	constructor() {}

	ngOnInit(): void {}
}

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { BankAccountData } from '../models/bank-account-data.model';
import { InvoiceData } from '../models/invoice-data.model';
import { UserAddress, UserProfile, UserProfileSpecific, UserProject } from '../models/settings.model';
import { map, mapTo, mergeMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {
	public userProfile: UserProfile;
	private userProfile$ = new BehaviorSubject<UserProfile>(null);

	constructor(private http: HttpClient, private toast: ToastNotificationService, private authService: AuthenticationService) {}

	private get_url_extension(url): string {
		return url.split(/[#?]/)[0].split('.').pop().trim();
	}

	public getFileType(url): string {
		const extension = this.get_url_extension(url);

		switch (extension) {
			case 'mp4':
				return 'video';
			default:
				return 'image';
		}
	}

	public getProfileData(): void {
		this.http
			.get<UserProfile>(`${BaseApiUrl.SocialMessengerPython}my-profile`)
			.toPromise()
			.then(res => {
				this.userProfile = res;
				if (!this.userProfile?.bio) this.userProfile.bio = '';
				if (!this.userProfile?.instagram_url) this.userProfile.instagram_url = '';
				if (!this.userProfile?.tiktok_url) this.userProfile.tiktok_url = '';
				if (!this.userProfile?.youtube_url) this.userProfile.youtube_url = '';
				if (!this.userProfile?.address) this.userProfile.address = {} as UserAddress;
				if (!this.userProfile?.address?.address) this.userProfile.address.address = '';
				if (!this.userProfile?.address?.city) this.userProfile.address.city = '';
				if (!this.userProfile?.address?.country) this.userProfile.address.country = '';
				if (!this.userProfile?.address?.pin_code) this.userProfile.address.pin_code = '';
				if (!this.userProfile?.address?.state) this.userProfile.address.state = '';
				if (!this.userProfile?.interests) this.userProfile.interests = [];
				if (!this.userProfile?.projects) this.userProfile.interests = [];
				if (this.userProfile?.gender) this.userProfile.gender = this.userProfile.gender[0].toUpperCase() + this.userProfile.gender.slice(1);
				this.userProfile$.next(this.userProfile);
			});
	}

	public profileDataListener(): Observable<UserProfile> {
		return this.userProfile$.asObservable().pipe(
			map(user => {
				if (user && !user.profile_picture_url) {
					user.profile_picture_url =
						user?.gender === 'female' ? 'assets/creators/general/user-default-female.png' : 'assets/creators/general/user-default-male.png';
				}
				return user;
			})
		);
	}

	public uploadVideo(formData: FormData, fileName: string): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}upload`;
		const url = new URL(urlString);

		return this.http.post<any>(url.href, formData);
	}

	public editProfileData(profileData: UserProfileSpecific): void {
		this.http
			.post<any>(`${BaseApiUrl.SocialMessengerPython}my-profile`, profileData)
			.toPromise()
			.then(res => {
				if (res) {
					this.userProfile.name = profileData?.first_name + ' ' + profileData?.last_name;
					this.userProfile.country = profileData?.country;
					this.userProfile.gender = profileData?.gender ? profileData.gender[0].toUpperCase() + profileData.gender.slice(1) : '';
					this.userProfile.phone_number = profileData?.phone_number;
					this.userProfile.instagram_url = profileData?.instagram_url;
					this.userProfile.tiktok_url = profileData?.tiktok_url;
					this.userProfile.youtube_url = profileData?.youtube_url;

					this.userProfile$.next(this.userProfile);
					this.authService.refreshToken();
					return;
				}
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			})
			.catch(err => {
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			});
	}

	public editBio(bioText: string): void {
		this.http
			.post<any>(`${BaseApiUrl.SocialMessengerPython}my-profile/bio`, { data: bioText })
			.toPromise()
			.then(res => {
				if (res) {
					this.userProfile.bio = bioText;
					this.userProfile$.next(this.userProfile);
					return;
				}
				this.toast.sendSuccessToast('Bio updated successfully.');
			})
			.catch(err => {
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			});
	}

	public editInterests(interests: string[], showToast = true): void {
		this.http
			.post<any>(`${BaseApiUrl.SocialInfluencerPython}myProfile/interest`, { interests: interests })
			.toPromise()
			.then(res => {
				if (res) {
					this.userProfile.interests = interests;
					this.userProfile$.next(this.userProfile);
					return;
				}
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			})
			.catch(err => {
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			});
	}

	public editProject(projects: UserProject[], cb?: Function): void {
		this.http
			.post<any>(`${BaseApiUrl.SocialInfluencerPython}myProfile/project`, projects)
			.toPromise()
			.then(res => {
				if (res) {
					this.userProfile.projects = projects;
					this.userProfile$.next(this.userProfile);
				} else this.toast.sendErrorToast('Something went wrong, Please try again later');

				if (cb) cb();
			})
			.catch(err => {
				this.toast.sendErrorToast('Something went wrong, Please try again later');

				if (cb) cb();
			});
	}

	public validateAccountStripe(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialInfluencerPython}oauth/stripe/validate`);
	}

	public preInstallStripe(code): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialInfluencerPython}oauth/stripe/connect?country=${code}`);
	}

	public installStripe(code, state): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialInfluencerPython}oauth/stripe/install?code=${code}&state=${state}`);
	}

	public uninstallStripe(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialInfluencerPython}oauth/stripe/uninstall`);
	}

	public getBankAccountDetails(account_id: string): Observable<BankAccountData> {
		return this.http.get<BankAccountData>(`${BaseApiUrl.SocialInfluencerPython}myProfile/billings/account?account_id=${account_id}`);
	}

	public fetchInvoices(account_id: string): Observable<InvoiceData[]> {
		return this.http.get<InvoiceData[]>(`${BaseApiUrl.SocialInfluencerPython}myProfile/billings/summary?account_id=${account_id}`);
	}

	public verifyPassword(password: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialInfluencerPython}influencerUser/verifyPassword?password=${password}`);
	}

	public updatePassword(password: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialInfluencerPython}influencerUser/updatePassword?password=${password}`);
	}

	public saveAddress(payload: UserAddress): void {
		this.http
			.post<any>(`${BaseApiUrl.SocialInfluencerPython}myProfile/address`, payload)
			.toPromise()
			.then(res => {
				if (res) {
					this.userProfile.address = payload;
					this.userProfile$.next(this.userProfile);
					return;
				}
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			})
			.catch(err => {
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			});
	}

	public updateProfilePic(file: string): void {
		this.http
			.post<any>(`${BaseApiUrl.SocialInfluencerPython}myProfile/profile-picture`, file)
			.toPromise()
			.then(res => {
				if (res) {
					this.userProfile.profile_picture_url = file;
					this.userProfile$.next(this.userProfile);
					return;
				}
				this.toast.sendErrorToast('something went wrong, Please try again later');
			})
			.catch(err => {
				if (err) console.log(err);
				this.toast.sendErrorToast('something went wrong, Please try again later');
			});
	}

	// public updatePortfolioPic(file: string): void {
	// 	this.http
	// 		.put(`${BaseApiUrl.SocialInfluencerPython}myProfile/portfolio`, file)
	// 		.pipe(
	// 			mergeMap(res => {
	// 				if (typeof res === 'string') {
	// 					console.log(res);
	// 					this.userProfile.portfolio.push(res);
	// 					return this.http.post(`${BaseApiUrl.SocialInfluencerPython}myProfile/portfolio`, this.userProfile.portfolio);
	// 				}
	// 				return null;
	// 			})
	// 		)
	// 		.subscribe(res => {
	// 			if (!res) {
	// 				this.userProfile.portfolio.splice(this.userProfile.portfolio.length - 1, 1);
	// 				this.toast.sendErrorToast('Image was not added, Please try again later');
	// 			}
	// 			this.userProfile$.next(this.userProfile);
	// 		});
	// }

	public updatePortfolioPic(file, cb): void {
		this.http
			// .put(`${BaseApiUrl.SocialInfluencerPython}myProfile/portfolio`, file)
			.put(`${BaseApiUrl.SocialMessengerPython}upload/profile/portfolio`, file)
			.pipe(
				mergeMap(res => {
					if (typeof res === 'string') {
						this.userProfile.portfolio.push(res);
						cb(true);
						return this.http.post(`${BaseApiUrl.SocialInfluencerPython}myProfile/portfolio`, this.userProfile.portfolio);
					}
					return null;
				})
			)
			.subscribe(res => {
				if (!res) {
					this.userProfile.portfolio.splice(this.userProfile.portfolio.length - 1, 1);
					cb(false);
				}
				this.userProfile$.next(this.userProfile);
			});
	}

	public deletePortfolioPic(link: string, cb): void {
		this.http
			.delete(`${BaseApiUrl.SocialInfluencerPython}myProfile/portfolio`, { params: { key: link.split('portfolio/')[1] } })
			.pipe(
				mergeMap(res => {
					if (res) {
						let index = this.userProfile.portfolio.indexOf(link);
						this.userProfile.portfolio.splice(index, 1);
						cb(true);
						return this.http.post(`${BaseApiUrl.SocialInfluencerPython}myProfile/portfolio`, this.userProfile.portfolio);
					}
					return null;
				})
			)
			.subscribe(res => {
				if (!res) {
					this.userProfile.portfolio.push(link);
					this.toast.sendErrorToast('There was an error deleting file, please try again later!');
					cb(false);
				}
				this.userProfile$.next(this.userProfile);
			});
	}

	public getStripeUrl() {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}subscriptions/billing-portal`);
	}

	// ! NEW BILLING APIS
	public getStripeAccountData(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}payments/creator`);
	}

	public addStripeAccountData(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}payments/creator`, payload);
	}

	public updateStripeAccountData(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.SocialMessengerPython}payments/creator`, payload);
	}

	public removeStripeAccount(): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.SocialMessengerPython}payments/creator`);
	}
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import { NgbCalendar, NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../utils';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CalendarService } from './calendar.service';
import { DateRangeX, DateSelection, InternalDateModel, PublicDateModel } from './calendar.models';
import { SharedState } from '../state/shared.reducer';
import { Store } from '@ngrx/store';
import { ButtonClassEnum } from '../button-component/button-class.enum';
import { ButtonTypeEnum } from '../button-component/button-type.enum';

export enum CalendarDisplayMonths {
	One = 1,
	Two
}

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit {
	@Input() preselectDateModel?: PublicDateModel;
	@Input() useSimplifiedInterface?: boolean;
	@Input() emitInitialValue?: boolean;
	@Input() selectSingleDate?: boolean;
	@Input() todayBtn = true;
	@Input() useDefaultMaxDate = true;
	@Input() updateStore = true;

	@Input()
	public set minDate(minDate: moment.Moment) {
		if (!minDate) {
			this._minDate = null;
		} else {
			this._minDate = UtilsService.momentToNgb(minDate);
		}
	}

	// Used to programatically change the date
	@Input()
	public set dateUpdate(date: PublicDateModel) {
		if (
			date &&
			UtilsService.momentToNgb(date.date.startDate) !== this.dateModel.date.startDate &&
			UtilsService.momentToNgb(date.date.endDate) !== this.dateModel.date.endDate
		) {
			// IF there will be two components in the same page it will bug out. To find a different way if that issue occurs
			this.changeDate({ dateRangeToBeSelected: [date.date.startDate, date.date.endDate] }, null, false);
			this.selectDate(true);
		}
	}

	@Output() dateChanged: EventEmitter<PublicDateModel> = new EventEmitter();

	@ViewChild('defaultCalendar', { static: true }) public defaultCalendar: NgbDatepicker;
	@ViewChild('compareCalendar', { static: true }) public compareCalendar: NgbDatepicker;
	@ViewChild('menuTrigger') public menuTrigger: MatMenuTrigger;

	public hoveredDate: NgbDate;
	public hoveredCompareDate: NgbDate;
	public fromDate: NgbDate;
	public toDate: NgbDate;
	public compareToDate: NgbDate;
	public compareFromDate: NgbDate;
	public compareFlag: boolean;
	public readonly dateFormat: string = 'MMM Do YY';
	public activeIdx: number = null;
	public activeCompareIdx: number = null;
	public dateModel: InternalDateModel;
	public updatedDateModel: PublicDateModel;
	public backdropClass = 'back-drop__fileddatepicker';
	public delayedOpenedClass: boolean;
	public menuPrepare: boolean;
	public dateHasChanged = false;
	public defaultCalendarDisplayMonths = CalendarDisplayMonths.Two;
	public _minDate: NgbDateStruct;
	public triggerDisabled: boolean;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	public maxDate: NgbDateStruct;

	private readonly animationDelayCss = 350;
	private readonly oneDayInMs: number = 86400000;
	private readonly allTimeStartInUnix: number = 946677600000; // 1 Jan 2000
	private updateCalendarTimer: any;
	private newOverlay: Node;

	private predefinedLastYear: DateSelection = {
		label: 'Last year',
		dateRangeToBeSelected: [moment().subtract(1, 'year'), moment()]
	};

	public predefinedDateSelectionList: DateSelection[] = [
		{
			label: 'All time',
			dateRangeToBeSelected: [moment(this.allTimeStartInUnix), moment()]
		},
		{
			label: 'Today',
			dateRangeToBeSelected: [moment(), moment()]
		},
		{
			label: 'Yesterday',
			dateRangeToBeSelected: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
		},
		{
			type: 'dropdown',
			label: 'This week',
			dateRangeToBeSelected: [moment().startOf('week'), moment()],
			selectOptions: [
				{
					label: '(Sun - Today)',
					value: [moment().startOf('week'), moment()]
				},
				{
					label: '(Mon - Today)',
					value: [moment().startOf('isoWeek'), moment()]
				}
			],
			selected: {
				label: '(Sun - Today)',
				value: [moment().startOf('week'), moment()]
			}
		},
		{
			label: 'Last 7 days',
			dateRangeToBeSelected: [moment().subtract(6, 'days'), moment()]
		},
		{
			type: 'dropdown',
			label: 'Last week',
			dateRangeToBeSelected: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
			selectOptions: [
				{
					label: '(Sun - Sat)',
					value: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')]
				},
				{
					label: '(Mon - Sun)',
					value: [moment().subtract(1, 'weeks').startOf('isoWeek'), moment().subtract(1, 'weeks').endOf('isoWeek')]
				}
			],
			selected: {
				label: '(Sun - Sat)',
				value: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')]
			}
		},
		{
			label: 'Last 14 days',
			dateRangeToBeSelected: [moment().subtract(13, 'days'), moment()]
		},
		{
			label: 'This month',
			dateRangeToBeSelected: [moment().subtract(0, 'month').startOf('month'), moment().subtract(0, 'month').endOf('month')]
		},
		{
			label: 'Last 30 days',
			dateRangeToBeSelected: [moment().subtract(29, 'days'), moment()]
		},
		{
			label: 'Last month',
			dateRangeToBeSelected: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
		},
		{
			type: 'input',
			label: 'days up to today',
			dateRangeToBeSelected: null,
			dateRangeFn: function () {
				return [moment().subtract(this.hasParameterValue - 1, 'days'), moment()];
			},
			inputValidations: {
				minValue: 1
			},
			hasParameter: 30,
			hasParameterValue: 30
		},
		{
			type: 'input',
			label: 'days up to yesterday',
			dateRangeToBeSelected: null,
			dateRangeFn: function () {
				return [moment().subtract(this.hasParameterValue - 0, 'days'), moment().subtract(1, 'day')];
			},
			inputValidations: {
				minValue: 2
			},
			hasParameter: 30,
			hasParameterValue: 30
		}
	];

	constructor(public calendar: NgbCalendar, private calendarService: CalendarService, private store: Store<SharedState>) {
		this.dateModel = new InternalDateModel();
		this.updatedDateModel = new PublicDateModel();
	}

	ngOnInit() {
		if (this.useDefaultMaxDate) {
			this.maxDate = {
				year: moment().year(),
				month: moment().month() + 1,
				day: moment().date()
			};
		} else {
			this.maxDate = {
				year: 2050,
				month: 11,
				day: 31
			};
		}

		this.selectDefaultDate();
		this.selectDate(!this.emitInitialValue);
	}

	public clearAllStates(): void {
		this.selectDefaultDate();
		this.resetCompare();
		this.dateHasChanged = false;
	}

	public toggleCompareMode($event: MatSlideToggleChange): void {
		this.compareFlag = $event.checked;
		this.defaultCalendarDisplayMonths = $event.checked ? CalendarDisplayMonths.One : CalendarDisplayMonths.Two;

		if (this.compareFlag) {
			this.dateModel.compareDate.startDate = Object.assign({}, this.fromDate);
			this.dateModel.compareDate.endDate = Object.assign({}, this.toDate);
		} else {
			this.resetCompare();
		}
	}

	public previousPeriod(index: number, isUserAction = true) {
		this.activeCompareIdx = index;

		const toDateTime = UtilsService.ngbDateToTime(this.toDate);
		const fromDateTime = UtilsService.ngbDateToTime(this.fromDate);

		const differenceTime = UtilsService.ngbDateToTime(this.toDate) - fromDateTime + this.oneDayInMs;

		const startToTime = moment(fromDateTime - differenceTime);
		const endToTime = moment(toDateTime - differenceTime);

		this.dateModel.compareDate.startDate = UtilsService.momentToNgb(startToTime);
		this.dateModel.compareDate.endDate = UtilsService.momentToNgb(endToTime);

		isUserAction && (this.dateHasChanged = true);

		this.navigateToViewCompare(this.dateModel.compareDate);
	}

	public changeDate(option: DateSelection, idx?: number, isUserAction = true) {
		if (idx !== undefined) {
			this.activeIdx = idx;
		}

		if (option.type === 'input' && option.hasParameter && option.hasParameterValue) {
			option.dateRangeToBeSelected = option.dateRangeFn();
		}

		const from: moment.Moment = option.dateRangeToBeSelected[0];
		const to: moment.Moment = option.dateRangeToBeSelected[1];

		this.dateModel.date.startDate = UtilsService.momentToNgb(from);
		this.dateModel.date.endDate = UtilsService.momentToNgb(to);

		this.navigateToView(this.dateModel.date);

		// recalculate compare
		if (this.compareFlag) {
			if (this.activeCompareIdx === 0) {
				this.previousPeriod(this.activeCompareIdx, false);
			}
		} else if (isUserAction) {
			this.dateHasChanged = true;
		}
	}

	public selectDate(noUserAction?: boolean): void {
		// single day selection validations
		if (!this.toDate) {
			this.toDate = Object.assign({}, this.fromDate);
		}

		this.updatedDateModel.date.startDate = UtilsService.ngbDateToMoment(this.fromDate);
		this.updatedDateModel.date.endDate = UtilsService.ngbDateToMoment(this.toDate);

		if (!this.useSimplifiedInterface && this.compareFlag) {
			this.updatedDateModel.compareDate.startDate = UtilsService.ngbDateToMoment(this.compareFromDate);
			this.updatedDateModel.compareDate.endDate = UtilsService.ngbDateToMoment(this.compareToDate);
		}

		if (!noUserAction) {
			// TODO: DELETE AFTER ALL COMPONENTS ARE LISTENING TO THE STORE
			this.dateChanged.emit(this.updatedDateModel);
			if (this.updateStore) {
				this.calendarService.changeDate(this.updatedDateModel);
			}
			// this.menuTrigger.menuOpen && this.menuTrigger.closeMenu();
			this.smoothClose(this.newOverlay);
		}
	}

	private resetCompare = (): void => {
		this.dateModel.compareDate.startDate = null;
		this.dateModel.compareDate.endDate = null;

		this.updatedDateModel.compareDate.startDate = null;
		this.updatedDateModel.compareDate.endDate = null;

		this.activeCompareIdx = null;
		this.compareFlag = false;
	};

	private selectDefaultDate = (): void => {
		const initialDate: PublicDateModel = this.preselectDateModel || this.calendarService.getInitialDate();

		this.changeDate({ dateRangeToBeSelected: [initialDate.date.startDate, initialDate.date.endDate] }, null, false);
	};

	private updateDateModelWithPreselectedDateModel = (initialDate: PublicDateModel): void => {
		this.dateModel.date.startDate = UtilsService.momentToNgb(initialDate.date.startDate);
		this.dateModel.date.endDate = UtilsService.momentToNgb(initialDate.date.endDate);

		if (!this.useSimplifiedInterface) {
			this.dateModel.compareDate.startDate = UtilsService.momentToNgb(initialDate.compareDate.startDate);
			this.dateModel.compareDate.endDate = UtilsService.momentToNgb(initialDate.compareDate.endDate);
		}
	};

	public onDateSelection(date: NgbDate, isUserAction = true): void {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && (date.equals(this.fromDate) || date.after(this.fromDate))) {
			this.toDate = date;
		} else {
			if (this.selectSingleDate) {
				this.toDate = date;
				this.fromDate = date;
			} else {
				this.toDate = null;
				this.fromDate = date;
			}
		}

		this.dateHasChanged = true;
		this.activeIdx = null;

		if (this.compareFlag && this.fromDate && this.toDate) {
			if (this.activeCompareIdx === 0) {
				this.previousPeriod(0, isUserAction);
				return;
			}
		}
	}

	public compareOnDateSelection(date: NgbDate): void {
		if (!this.compareFromDate && !this.compareToDate) {
			this.compareFromDate = date;
		} else if (this.compareFromDate && !this.compareToDate && (date.equals(this.compareFromDate) || date.after(this.compareFromDate))) {
			this.compareToDate = date;
		} else {
			this.compareToDate = null;
			this.compareFromDate = date;
		}

		this.dateHasChanged = true;
		this.activeCompareIdx = null;
	}

	public isHovered(date: NgbDate, isCompare?: boolean): boolean {
		return isCompare
			? this.compareFromDate && !this.compareToDate && this.hoveredCompareDate && date.after(this.compareFromDate) && date.before(this.hoveredCompareDate)
			: this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	public lastHovered(date: NgbDate, isCompare?: boolean): boolean {
		return isCompare
			? this.compareFromDate && !this.compareToDate && this.hoveredCompareDate && date.after(this.compareFromDate) && date.equals(this.hoveredCompareDate)
			: this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.equals(this.hoveredDate);
	}

	public isInside(date: NgbDate, isCompare?: boolean): boolean {
		return isCompare ? date.after(this.compareFromDate) && date.before(this.compareToDate) : date.after(this.fromDate) && date.before(this.toDate);
	}

	public isRange(date: NgbDate, isCompare?: boolean): boolean {
		return isCompare
			? date.equals(this.compareFromDate) || date.equals(this.compareToDate) || this.isInside(date, isCompare) || this.isHovered(date, isCompare)
			: date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
	}

	public isStartDate(date: NgbDate, isCompare?: boolean): boolean {
		return isCompare ? date.equals(this.compareFromDate) : date.equals(this.fromDate);
	}

	public isEndDate(date: NgbDate, isCompare?: boolean): boolean {
		return isCompare ? date.equals(this.compareToDate) : date.equals(this.toDate);
	}

	public hasNoSiblings(date: NgbDate, isCompare?: boolean): boolean {
		return isCompare
			? this.hoveredCompareDate && this.compareFromDate && this.hoveredCompareDate.equals(this.compareFromDate)
			: this.fromDate && this.hoveredDate && this.hoveredDate.equals(this.fromDate);
	}

	public isFirstDayOfWeek(date: NgbDate): boolean {
		const weekday = UtilsService.ngbDateToMoment(date);
		const startOfWeek = weekday.startOf('isoWeek');
		return date.day === startOfWeek.date();
	}

	public isLastDayOfWeek(date: NgbDate): boolean {
		const weekday = UtilsService.ngbDateToMoment(date);
		const startOfWeek = weekday.endOf('isoWeek');
		return date.day === startOfWeek.date();
	}

	public setDelayedOpenedClass(): void {
		this.hijackEventListeners();

		// safe apply transition classes
		setTimeout(() => {
			this.menuPrepare = true;
		});
		setTimeout(() => {
			this.delayedOpenedClass = true;
		}, 100);
	}

	public updateCalendar(value: string, option: DateSelection, idx: number) {
		const userInput = Number(value);

		if (userInput < 1) {
			return;
		}

		clearTimeout(this.updateCalendarTimer);

		this.updateCalendarTimer = setTimeout(() => {
			option.hasParameterValue = userInput;
			this.changeDate(option, idx);
		}, 350);
	}

	public dropdownChanged(option: DateSelection, idx: number) {
		option.dateRangeToBeSelected = option.selected.value;
		this.changeDate(option, idx);
	}

	private navigateToView = (date: DateRangeX<NgbDate>): void => {
		this.fromDate = Object.assign({}, date.startDate);
		this.toDate = Object.assign({}, date.endDate);

		this.defaultCalendar.navigateTo(this.fromDate);
	};

	private navigateToViewCompare = (date: DateRangeX<NgbDate>): void => {
		this.compareFromDate = Object.assign({}, date.startDate);
		this.compareToDate = Object.assign({}, date.endDate);

		this.compareCalendar.navigateTo(this.compareFromDate);
	};

	private smoothClose = (overlay: any): void => {
		if (!overlay) {
			return;
		}
		this.triggerDisabled = true;

		this.delayedOpenedClass = false;

		setTimeout(() => {
			this.menuTrigger.closeMenu();
			overlay.style.display = 'none';
			setTimeout(() => {
				overlay.remove();
				this.menuPrepare = false;
				this.triggerDisabled = false;
			}, this.animationDelayCss);
		}, this.animationDelayCss);
	};

	private hijackEventListeners = (): void => {
		/* the matMenu does not have support for calling a function before emitting the closing event or handle a promise in this manner
		   therefor the animation ( slide left to right ) could not be handled properly given the fact that matMenu has different internal behaviour
		   therefor the click events on the cdk overlay will be removed and place custom ones
		*/
		const overlay: HTMLElement = document.querySelector(`.${this.backdropClass}`);
		const parent = overlay.offsetParent;
		this.newOverlay = overlay.cloneNode(true);

		this.newOverlay.addEventListener('click', () => {
			this.smoothClose(this.newOverlay);
		});

		parent.replaceChild(this.newOverlay, overlay);
	};

	public close(): void {
		this.menuTrigger.closeMenu();
	}
}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ErrorMessageService } from '../error-message.service';
import { ValidatorMessages } from '../validatorMessagesInputs';
import { CardTypeEnum } from '../card-type.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-input-card-number',
	templateUrl: './input-card-number.component.html',
	styleUrls: ['./input-card-number.component.scss', '../shared.scss']
})
export class InputCardNumberComponent implements OnInit, OnDestroy {
	constructor(public errorMessageService: ErrorMessageService) {}
	@ViewChild('ccNumber') public ccNumberField: ElementRef;
	@Input() control: UntypedFormControl | AbstractControl;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() label: string;
	@Input() placeholder: string;

	public isDestinationFocused: boolean;
	public errorMessage: string;
	public cardType: CardTypeEnum;
	public cardTypeEnum = CardTypeEnum;
	private unsubscriber$: Subject<void> = new Subject<void>();

	public ngOnInit(): void {
		this.control.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.cardType = this.getCardType(resp);
		});

		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public getCardType(number: string) {
		// visa
		let re = new RegExp('^4');
		if (number.match(re) != null) {
			return CardTypeEnum.Visa;
		}

		// Mastercard
		// Updated for Mastercard 2017 BINs expansion
		if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
			return CardTypeEnum.Mastercard;
		}

		// AMEX
		re = new RegExp('^3[47]');
		if (number.match(re) != null) {
			return CardTypeEnum.Amex;
		}

		// Discover
		re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
		if (number.match(re) != null) {
			return CardTypeEnum.Discover;
		}

		// JCB
		re = new RegExp('^35(2[89]|[3-8][0-9])');
		if (number.match(re) != null) {
			return CardTypeEnum.JCB;
		}

		// Visa Electron
		re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
		if (number.match(re) != null) {
			return CardTypeEnum.VisaElectron;
		}

		return null;
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public creditCardNumberSpacing(): void {
		const input = this.ccNumberField.nativeElement;
		const { selectionStart } = input;

		let trimmedCardNum = this.control.value?.replace(/\s+/g, '');

		if (this.control.value?.length > 16) {
			trimmedCardNum = trimmedCardNum.substr(0, 16);
		}

		/* Handle American Express 4-6-5 spacing */
		const partitions = trimmedCardNum?.startsWith('34') || trimmedCardNum?.startsWith('37') ? [4, 6, 5] : [4, 4, 4, 4];

		const numbers: Array<string> = [];
		let position = 0;
		partitions.forEach(partition => {
			const part = trimmedCardNum.substr(position, partition);
			if (part) {
				numbers.push(part);
			}
			position += partition;
		});

		this.control.setValue(numbers.join(' '));

		/* Handle caret position if user edits the number later */
		if (selectionStart < this.control.value.length - 1) {
			input.setSelectionRange(selectionStart, selectionStart, 'none');
		}
	}
}

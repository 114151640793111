<div class="platform-card {{ isConnected ? 'selected' : '' }}">
	<div class="details">
		<img src="assets/creators/social-channels/{{ platform }}-logo.svg" />
		<h5>{{ platform | socialPlatform }}</h5>
	</div>
	<!-- <button (click)="deleteAccount()" *ngIf="accountStatus === 'Connected' && !loading" class="connected">
		Disconnect
	</button> -->

	<ng-container *ngIf="loading">
		<app-ellipsis-loader></app-ellipsis-loader>
	</ng-container>

	<ng-container *ngIf="!loading">
		<ng-container *ngIf="isConnected">
			<button (click)="onDisconnectClick()" *ngIf="showDisconnectButton" class="transparent-btn blacked">
				Disconnect
			</button>
			<button *ngIf="!showDisconnectButton" class="transparent-btn blacked">
				<mat-icon>check</mat-icon>
				Connected
			</button>
		</ng-container>

		<ng-container *ngIf="!isConnected">
			<button (click)="onConnectClick()" class="transparent-btn blued" *ngIf="true">+ Connect</button>
		</ng-container>
	</ng-container>
</div>

import { initialSharedState, sharedReducer, SharedState } from '../shared/state/shared.reducer';

import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { initialUserManagementState, userManagementReducer, UserManagementState } from '../user-management/state/user-management.reducer';
import { AppAction, AppTypes } from './app.actions';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer');

export interface AppState {
	userManagement: UserManagementState;
	shared: SharedState;
	authentication: any;
	user: any;
}

export const initialAppState: AppState = {
	userManagement: initialUserManagementState,
	shared: initialSharedState,
	authentication: 'any',
	user: 'any'
};

export const reducers: ActionReducerMap<AppState> = {
	userManagement: userManagementReducer,
	shared: sharedReducer,
	authentication: sharedReducer,
	user: sharedReducer
};

export enum AppStateSlices {
	UserManagement = 'UserManagement',
	Shared = 'Shared',
	Authentication = 'Authentication',
	User = 'User',
	Accounts = 'Accounts'
}

export function clearStateMetaReducer(reducer: any) {
	return function (state: AppState, action: AppAction) {
		if (action.type === AppTypes.ResetAppState) {
			state = initialAppState;
		}

		return reducer(state, action);
	};
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorMessageService } from 'src/app/shared/form-input/error-message.service';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
	@Input() public control: FormControl | AbstractControl = new FormControl('');
	@Input() public inputId: string = '';
	@Input() public inputType: string = 'text';
	@Input() public inputPlaceholder: string = 'enter text';
	@Input() public styleClass: string;
	@Input() public validatorMessages: ValidatorMessages[];
	@Input() public errorMessage: string = null;
	@Input() public maxLength: number = 600;
	@Output() public keyInputEvent: EventEmitter<any> = new EventEmitter();
	private unsubscriber$ = new Subject<void>();

	constructor(public errorMessageService: ErrorMessageService) {}

	ngOnInit(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
		this.checkError();
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	private checkError(): void {
		this.control.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.control.hasError) {
				this.setErrorMessage();
			}
		});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}

import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { UserManagementModule } from '../user-management/user-management.module';
import { SharedModule } from '../shared/shared.module';

import { FiledLogoComponent } from '../shared/filed-logo/filed-logo.component';
import { LinkAccountDialogComponent } from './link-account-dialog/link-account-dialog.component';

import { SidenavGroupComponent } from './sidenav/sidenav-group.component';
import { MaterialModule } from '../material.module';
import { NotificationsDialogComponent } from './component-pieces/notifications-dialog/notifications-dialog.component';
import { NotificationsMainComponent } from './component-pieces/notifications-main/notifications-main.component';

@NgModule({
	declarations: [FiledLogoComponent, LinkAccountDialogComponent, SidenavGroupComponent, NotificationsDialogComponent, NotificationsMainComponent],
	imports: [SharedModule, TranslateModule, BrowserModule, MaterialModule, RouterModule, UserManagementModule],
	providers: []
})
export class SidenavModule {}

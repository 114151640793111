<div class="notifications-root">
	<div class="notifications-wrapper">
		<div class="notification-header">
			<h1 class="notification-title">Notifications</h1>
		</div>

		<div class="notification-body">
			<div class="notification-body">
				<ng-container *ngFor="let notification of notifications; let notificationSequence = index">
					<div
						*ngIf="notificationSequence < 7"
						(click)="onNotificationClick(notification)"
						class="menu-list-item {{ notification?.unread ? 'unread' : '' }}"
					>
						<img class="pp mr-m" src="assets/images/creators/creatives/mock-influencer.png" />
						<div class="d-flex flex-column w-100 notification-col">
							<div class="w-100 d-flex align-items-center justify-content-between">
								<div class="message">
									{{ notification?.message }}
								</div>
								<div class="date">
									{{ notification?.date }}
								</div>
							</div>
							<div class="d-flex align-items-center justify-content-between">
								<div class="action" (click)="onNotificationActionClick(notification); notificationsMenuTrigger.closeMenu()">
									{{ notification?.action_name }}
								</div>
								<div class="more-btn" [matMenuTriggerFor]="actionsMenu" #actionsMenuTrigger="matMenuTrigger">
									<img class="icon-btn" src="assets/creators/general/more-horizontal.svg" />
								</div>
								<mat-menu #actionsMenu="matMenu" class="edit-notification-menu">
									<div class="edit-body-wrapper">
										<div
											class="edit-menu-item"
											(click)="onEditNotificationClick(notification, 'delete'); $event.stopPropagation(); actionsMenuTrigger.closeMenu()"
										>
											<img class="edit-icon mr-s" src="assets/creators/general/trash.svg" />
											<div class="d-flex w-100 flex-column">
												<span class="txt-b">Delete</span>
												<span class="txt-n mt-xs">Delete this notification</span>
											</div>
										</div>

										<div
											class="edit-menu-item"
											(click)="onEditNotificationClick(notification, 'mute'); $event.stopPropagation(); actionsMenuTrigger.closeMenu()"
										>
											<ng-container *ngIf="notification?.muted">
												<img class="edit-icon mr-s" src="assets/creators/general/unmute-notification.svg" />
												<div class="d-flex w-100 flex-column">
													<span class="txt-b">Turn on</span>
													<span class="txt-n mt-xs">Start receiving notification like this</span>
												</div>
											</ng-container>
											<ng-container *ngIf="!notification?.muted">
												<img class="edit-icon mr-s" src="assets/creators/general/mute-notification.svg" />
												<div class="d-flex w-100 flex-column">
													<span class="txt-b">Turn off</span>
													<span class="txt-n mt-xs">Stop receiving notification like this</span>
												</div>
											</ng-container>
										</div>
									</div>
								</mat-menu>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

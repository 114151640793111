<div class="dialog-root h-100 d-flex flex-column align-items-center">
	<div class="font-l blk font-weight-bold ls-0">{{ title }}</div>

	<div class="font-m blk font-weight-medium ls-0 mt-m">{{ description }}</div>

	<div class="d-flex w-100 justify-content-between align-items-center mt-m">
		<div class="icon-btn-transparent d-flex align-items-center cursor-pointer" (click)="closeDialog(false)">
			<span class="blue-text mr-xs">SKIP</span>
			<img class="btn-icon" src="assets/images/creators/arrow-right-blue.svg" />
		</div>
		<div class="icon-btn d-flex align-items-center cursor-pointer" (click)="closeDialog(true)">
			<span class="white-text mr-xs">Next</span>
			<img class="btn-icon" src="assets/images/creators/arrow-right-white.svg" />
		</div>
	</div>
</div>

<div *ngIf="isToastVisible" class="toast-wrapper">
	<div class="toast-container-2">
		<div class="notification-content">
			<div class="information-wrapper">
				<div [ngSwitch]="toastModel.type" class="toast-icon">
					<img src="assets/creators/toast/info.svg" *ngSwitchCase="toastTypes.Info" />
					<img src="assets/creators/toast/success.svg" *ngSwitchCase="toastTypes.Success" />
					<img src="assets/creators/toast/warning.svg" *ngSwitchCase="toastTypes.Warning" />
					<img src="assets/creators/toast/error.svg" *ngSwitchDefault />
				</div>
				<div class="content-toast">
					<h6
						[ngClass]="{
							info: toastModel.type === toastTypes.Info,
							error: toastModel.type === toastTypes.Error,
							warning: toastModel.type === toastTypes.Warning,
							success: toastModel.type === toastTypes.Success
						}"
					>
						{{ toastModel.customTitle }}
					</h6>
					<span>{{ toastModel.message }}</span>
				</div>
			</div>
			<mat-icon class="close-btn" (click)="dismiss()">close</mat-icon>
		</div>
	</div>
</div>

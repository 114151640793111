export enum CampaignObjectives {
	BrandAwareness = 1,
	Reach = 2,
	Traffic = 3,
	VideoViews = 4,
	LeadGeneration = 5,
	Messages = 16,
	PostEngagement = 10,
	PageLikes = 11,
	EventResponses = 13,
	Conversions = 6,
	CatalogSales = 7,
	StoreVisits = 8,
	AppTraffic = 21,
	AppInstalls = 9,
	AppConversion = 22,
	AppCatalogSales = 23
}

export enum MarketingEnums {
	ScheduleType = 'scheduleTypes',
	BudgetAllocateType = 'budgetAllocateTypes',
	BudgetBillingEvents = 'budgetBillingEvents',
	OptimizationGoalBillingEvents = 'optimizationGoalBillingEventsGroupedByObjectives',
	OptimizationGoalConversionWindow = 'optimizationGoalConversionWindowsGroupedByObjectives',
	BidCapType = 'bidStrategyTypes',
	BidAmountType = 'bidAmountTypes',
	DeliveryType = 'deliveryTypes',
	TimeZoneType = 'timeZoneTypes',
	PlacementType = 'placementTypes',
	DevicePlatform = 'devicePlatforms',
	MobileDeviceCategory = 'mobileDeviceCategories',
	AudienceExcludedCategory = 'audienceExcludedCategories',
	AdFormat = 'adFormats',
	CampaignGoal = 'campaignGoals',
	OptimizationGoalType = 'optimizationGoalTypes',
	OptimizationsForAdDelivery = 'objectiveOptimizationGoals',
	OptimizationGoalBidStrategies = 'optimizationGoalBidStrategies',
	BudgetBillingEventsMinimumValues = 'budgetBillingEventsMinimumValues',
	Objectives = 'objectives',
	CallToActionItems = 'callToActionItems',
	BuyingFormats = 'buyingFormats',
	ObjectiveTypes = 'objectiveTypes',
	FullDevicePlacementPositions = 'fullDevicePlacementPositions',
	CampaignBidStrategies = 'campaignBidStrategies',
	ObjectiveCampaignBidStrategies = 'objectiveCampaignBidStrategies'
}

export enum MarketingConstants {
	BudgetConversionWindows = 'conversionWindowTypes',
	DevicePlacements = 'devicePlacementPositions',
	ObjectivePlacements = 'objective/placements',
	PlacementPositions = 'placementPositions',
	PlacementsGroupedByObjectives = 'placementsGroupedByObjectives',
	ObjectiveAdFormats = 'objectiveAdFormats'
}

export enum MobileDeviceCategories {
	ALL = 1,
	ANDROID = 2,
	IOS = 3,
	FEATURES = 4
}

// This enums should match whith adformat enum on backend
export enum AdFormats {
	SingleImage = 1,
	Carousel = 2,
	Collection = 3,
	Slideshow = 4,
	Video = 5
}

export enum AdVariation {
	Manual = 1,
	Automatic = 2
}

export enum CampaignGoals {
	CPC = 1,
	CPM = 2,
	CTR = 3,
	CPA = 4,
	ROAS = 5
}

export enum Authentication {
	Role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
}

export const UserReasonHeader = 'Reason';

export const jwtUserDetails = 'UserDetailsKey';

export enum UserReasonStatuses {
	noFiledDetails = 'UserWithoutProperFiledDetails',
	noBusinessOwner = 'FrontOfficeUserWithNoBusinessOwner',
	noCreditCardConfirmation = 'FrontOfficeUserWithNoConfirmedCreditCard',
	pendingCreditCard = 'FrontOfficeUserWithFirstBillPending'
}

export const CreateRuleLink = 'https://www.facebook.com/business/help/1852322521654609?id=295312871325091';
export const CreateEventLink = 'https://www.facebook.com/business/help/780705975381000?id=1205376682832142';

export enum Status {
	Paused = 'PAUSED',
	Activated = 'ACTIVATED'
}

<app-user-management-header></app-user-management-header>

<div class="page-container">
	<app-user-sub-header [displayMenu]="!billingPlan?.freeTrialEndDate" [pageTitle]="SettingsPageLabelsEnum.MySubscription"></app-user-sub-header>

	<form [formGroup]="subscriptionGroup" class="subscription-container">
		<div>
			<div class="filed-input-field">
				<app-text-input-with-label [control]="subscriptionGroup.get('tier')" [inputLabel]="'Subscription'"></app-text-input-with-label>
			</div>
			<div class="filed-input-field">
				<app-text-input-with-label [control]="subscriptionGroup.get('startDate')" [inputLabel]="'Start Date'"></app-text-input-with-label>
			</div>
			<div class="filed-input-field">
				<app-text-input-with-label [control]="subscriptionGroup.get('lastInvoiceDate')" [inputLabel]="'Last Invoice date'"></app-text-input-with-label>
			</div>
		</div>
		<div>
			<div class="filed-input-field">
				<app-text-input-with-label [control]="subscriptionGroup.get('amount')" [inputLabel]="'Amount'"></app-text-input-with-label>
			</div>
			<div class="filed-input-field">
				<app-text-input-with-label [control]="subscriptionGroup.get('endDate')" [inputLabel]="'End Date'"></app-text-input-with-label>
			</div>
			<div class="filed-input-field">
				<app-text-input-with-label [control]="subscriptionGroup.get('nextInvoiceDate')" [inputLabel]="'Next invoice date'"></app-text-input-with-label>
			</div>
		</div>
	</form>
</div>

<div class="button-wrapper">
	<app-button
		(buttonClicked)="goToContactUs()"
		[buttonClass]="buttonClassEnum.Primary"
		[buttonType]="buttonTypeEnum.Simple"
		buttonLabel="CONTACT US"
	></app-button>
</div>

import { Injectable } from '@angular/core';
import { FiledPermissions, ModulesPermissions, ModulesPermissionsTypes } from './enums/filed-permissions';
import { Modules } from './enums/modules';
import { AccountsPermissions } from './enums/accounts-permissions';
import { BackofficePermissions } from './enums/backoffice-permissions';
import { UserState } from '../state/user/user.reducer';
import { Store } from '@ngrx/store';
import { CreativeBuilderPermissions } from './enums/creative-builder-permissions';
import { AdsManagerPermissions } from './enums/ads-manager-permissions';
import { AudiencePermissions } from './enums/audience-permissions';
import { CampaignBuilderPermissions } from './enums/campaign-builder-permissions';
import { MiscellaneousPermissions } from './enums/miscellaneous-permissions';
import { OptimizePermissions } from './enums/optimize-permissions';
import { PixelPermissions } from './enums/pixel-permissions';
import { ProductCatalogPermissions } from './enums/product-catalog-permissions';
import { ReportsPermissions } from './enums/reports-permissions';
import { SettingsPermissions } from './enums/settings-permissions';

@Injectable()
export class PermissionsService {
	constructor(private userStore: Store<UserState>) {}

	public decodePermission(codedPermission: string): FiledPermissions<ModulesPermissions> {
		const split = codedPermission.split('^');
		const module = parseInt(split[0], 16);
		const codedPermissions = parseInt(split[1], 16);
		const permissions: ModulesPermissions[] = [];

		switch (module) {
			case Modules.Accounts:
				permissions.push(...this.getPermissionsFromEnum(AccountsPermissions, codedPermissions));
				break;
			case Modules.BackOffice:
				permissions.push(...this.getPermissionsFromEnum(BackofficePermissions, codedPermissions));
				break;
			case Modules.CreativeBuilder:
				permissions.push(...this.getPermissionsFromEnum(CreativeBuilderPermissions, codedPermissions));
				break;
			case Modules.AdsManager:
				permissions.push(...this.getPermissionsFromEnum(AdsManagerPermissions, codedPermissions));
				break;
			case Modules.Audience:
				permissions.push(...this.getPermissionsFromEnum(AudiencePermissions, codedPermissions));
				break;
			case Modules.CampaignBuilder:
				permissions.push(...this.getPermissionsFromEnum(CampaignBuilderPermissions, codedPermissions));
				break;
			case Modules.Miscellaneous:
				permissions.push(...this.getPermissionsFromEnum(MiscellaneousPermissions, codedPermissions));
				break;
			case Modules.Optimize:
				permissions.push(...this.getPermissionsFromEnum(OptimizePermissions, codedPermissions));
				break;
			case Modules.Pixel:
				permissions.push(...this.getPermissionsFromEnum(PixelPermissions, codedPermissions));
				break;
			case Modules.ProductCatalog:
				permissions.push(...this.getPermissionsFromEnum(ProductCatalogPermissions, codedPermissions));
				break;
			case Modules.Reports:
				permissions.push(...this.getPermissionsFromEnum(ReportsPermissions, codedPermissions));
				break;
			case Modules.Settings:
				permissions.push(...this.getPermissionsFromEnum(SettingsPermissions, codedPermissions));
				break;
		}

		return {
			module: module,
			permissions: permissions
		};
	}

	private getPermissionsFromEnum(value: ModulesPermissionsTypes, permissionAsHex: number): number[] {
		const result: number[] = [];
		for (const item in value) {
			if (value.hasOwnProperty(item) && isNaN(+item)) {
				const permission = parseInt((value as any)[item]);
				/*tslint:disable-next-line:no-bitwise*/
				if ((permissionAsHex & permission) === permission) {
					result.push((value as any)[item]);
				}
			}
		}

		return result;
	}

	public checkPermission(data: FiledPermissions<ModulesPermissions>[], selectedModule: Modules, acceptedPermissions: ModulesPermissions[]): boolean {
		const module = data.find((moduleModel: any) => moduleModel.module === selectedModule);

		if (!module?.permissions) {
			return;
		}

		const permission = module?.permissions.find((permission: ModulesPermissions) => {
			return acceptedPermissions.includes(permission);
		});

		return !!permission;
	}
}

export class EventsHelper {
	public static mapEvents(events: any[]): any[] {
		const mappedEvents: any[] = [];
		events.forEach(event => {
			let convertedStatusEvent = '';
			switch (event.statusId) {
				case 0: {
					convertedStatusEvent = 'Active';
					break;
				}

				case 1: {
					convertedStatusEvent = 'Deleted';
					break;
				}

				case 2: {
					convertedStatusEvent = 'Paused';
					break;
				}
			}
			mappedEvents.push({
				...event,
				status: event.statusId === 1,
				statusDescription: convertedStatusEvent,
				type: event.isStandardEvent ? 'Standard Event' : 'Custom Event'
			});
		});
		return mappedEvents;
	}
}

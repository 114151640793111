// Note: This service is a temporary "hack. It's functionality should be added to the generic table itself after refactoring
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BreakdownService {
	private deliveryBreakdownChangedSource = new Subject<number>();

	private actionBreakdownChangedSource = new Subject<number>();
	private timeBreakdownChangedSource = new Subject<any>();
	private lastDeliveryBreakdown = 0;
	private lastActionBreakdown = 0;
	private lastTimeBreakdown: any = {
		id: 0,
		name: 'None',
		groupColumn: 'None',
		value: '1',
		displayName: '',
		filterColumn: '',
		propertyName: '',
		breakdownTempUID: null,
		breakdownType: null,
		columnName: ''
	};

	public changeBreakdowns(deliveryBreakdownId: number, actionBreakdownId: number, timeBreakdown: any) {
		this.deliveryBreakdownChangedSource.next(deliveryBreakdownId);
		this.lastDeliveryBreakdown = deliveryBreakdownId;

		this.actionBreakdownChangedSource.next(actionBreakdownId);
		this.lastActionBreakdown = actionBreakdownId;

		this.timeBreakdownChangedSource.next(timeBreakdown);
		this.lastTimeBreakdown = timeBreakdown;
	}

	public getLastTimeBreakdown() {
		return this.lastTimeBreakdown;
	}

	public getLastDeliveryBreakdown() {
		return this.lastDeliveryBreakdown;
	}

	public getLastActionBreakdown() {
		return this.lastActionBreakdown;
	}
}

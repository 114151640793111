<kendo-grid
	#grid
	style="height: 100%;"
	[kendoGridBinding]="gridView"
	[sortable]="false"
	[selectable]="selectableSettings"
	[resizable]="false"
	[columnMenu]="{ columnChooser: false, filter: false }"
	(cellClick)="onCellClick($event)"
	(selectionChange)="selectRow($event)"
	kendoGridSelectBy="Id"
	[selectedKeys]="selectedKeys"
	[pageable]="pageable"
>
	<kendo-grid-checkbox-column
		*ngIf="checkbox"
		[width]="45"
		[headerClass]="{ 'text-center': true }"
		[class]="{ 'text-center': true }"
		[resizable]="false"
		[columnMenu]="false"
		[showSelectAll]="true"
	></kendo-grid-checkbox-column>

	<ng-container *ngFor="let column of gridColumns; let i = index">
		<kendo-grid-column
			field="{{ column.name }}"
			[width]="column.width"
			title="{{ column.value }}"
			[headerStyle]="styleHeader(i)"
			[sortable]="column.type !== 'action' && column.type !== 'analytics' && column.type !== 'upload' && column.type !== 'object'"
			[selectable]="column.type !== 'action' && column.type !== 'analytics' && column.type !== 'upload' && column.type !== 'object'"
		>
			<ng-template kendoGridHeaderTemplate let-dataItem>
				<div *ngIf="column.type !== 'action' && column.type !== 'analytics'">
					{{ column.value }}
					<div class="new-tooltip">
						<div class="tooltip-body-main">
							<div class="tooltip-body">
								<div class="tooltip-text">
									<span>{{ column.value }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="column.type === 'action'" class="action-button action-header">
					<button mat-icon-button class="pr-15px" [matMenuTriggerFor]="headermenu">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #headermenu="matMenu">
						<button mat-menu-item *ngFor="let action of headerAction" (click)="action.click()">
							<mat-icon>{{ action.icon }}</mat-icon>
							<span>{{ action.title }}</span>
						</button>
					</mat-menu>
				</div>
			</ng-template>
			<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" style="background-color: white !important;">
				<div
					*ngIf="
						!column.highlight &&
						column.type !== 'platform' &&
						column.type !== 'action' &&
						column.type !== 'analytics' &&
						column.type !== 'editableString' &&
						column.type !== 'upload' &&
						column.type !== 'link' &&
						column.type !== 'object'
					"
					[ngStyle]="styleRows(i)"
					class="normal-text"
				>
					{{ dataItem[column.name] }}
				</div>

				<div *ngIf="column.type === 'platform'" [ngStyle]="styleRows(i)">
					<ng-container *ngFor="let platform of dataItem[column.name]">
						<ng-container *ngIf="platform && platform.toLowerCase() == 'instagram'">
							<img class="platform" src="assets/images/creators/instagram.svg" />
						</ng-container>
						<ng-container *ngIf="platform && platform.toLowerCase() == 'tiktok'">
							<img class="platform" src="assets/images/creators/tiktok.svg" />
						</ng-container>
						<ng-container *ngIf="platform && platform.toLowerCase() == 'youtube'">
							<img class="platform" src="assets/images/creators/youtube.svg" />
						</ng-container>
					</ng-container>
				</div>
				<div *ngIf="column.type === 'action'" class="action-button action-data">
					<button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item *ngFor="let action of cellAction" (click)="action.click(dataItem)">
							<mat-icon>{{ action.icon }}</mat-icon>
							<span>{{ action.title }}</span>
						</button>
					</mat-menu>
				</div>
				<div *ngIf="column.type === 'analytics'" class="action-button action-data">
					<button mat-button class="analytics">
						View Analytics
					</button>
				</div>
				<div class="kendo-editible" *ngIf="column.type == 'editableString'" [ngStyle]="styleRows(i)">
					<div fxLayout="row" class="w-100 d-align-items-c">
						<span fxFlex="70" class="unbreak text-left">{{ dataItem[column.name] ? dataItem[column.name] : 'None' }}</span>
						<span fxFlex="30">
							<mat-icon
								class="ml-10px"
								#menuTrigger="matMenuTrigger"
								[matMenuTriggerFor]="editnamemenu"
								(menuOpened)="menuOpened()"
								(menuClosed)="menuClosed()"
								(click)="$event.stopPropagation(); edit(dataItem[column.name])"
							>
								edit
							</mat-icon>
						</span>
					</div>
					<mat-menu id="matMenuEdit" #editnamemenu="matMenu">
						<div class="editmenu" *ngIf="menuOpen">
							<filed-font [fontSize]="'14px'" [fontWeight]="'400'" (click)="$event.stopPropagation()">{{ column.value }}</filed-font>
							<mat-form-field (click)="$event.stopPropagation()" class="edit-input mt-10px" appearance="outline">
								<input
									matInput
									value="{{ dataItem[column.name] }}"
									(keyup)="onKeydown($event.target.value)"
									(keydown.enter)="savestring(column.name, rowIndex); menuTrigger.closeMenu()"
								/>
							</mat-form-field>
							<div class="d-flex justify-content-between">
								<app-primary-button [buttonType]="'Secondary'" [buttonLable]="'Cancel'" [isPeddingReduceSmall]="true"></app-primary-button>

								<app-primary-button
									(buttonClicked)="savestring(column.name, rowIndex)"
									[buttonType]="'Primary'"
									[buttonLable]="'Save'"
									[isPeddingReduceSmall]="true"
								></app-primary-button>
							</div>
						</div>
					</mat-menu>
				</div>

				<div
					*ngIf="column.type == 'object'"
					(click)="onColumnClick(column?.name, rowIndex); $event.stopPropagation()"
					[ngStyle]="styleObjectRows(i, rowIndex)"
				>
					{{ dataItem[column.name].title }}
				</div>

				<div *ngIf="column.type === 'upload'" class="text-center cursor-pointer" (click)="onFileChange(rowIndex); $event.stopPropagation()">
					<img class="upload-icon" src="assets/icons/creators/upload-blue.svg" />
				</div>

				<div *ngIf="column.type === 'link'" class="normal-text" [ngStyle]="styleRows(i)">
					<span
						class="cursor-pointer"
						(click)="onLinkClick(rowIndex); $event.stopPropagation()"
						*ngIf="column.highlightName == undefined || dataItem[column.name] == column.highlightName"
					>
						{{ column.highlightName == undefined ? (dataItem[column.name] | titlecase) : (column?.linkText | titlecase) }}
					</span>
					<span [ngStyle]="{ color: '#121212' }" *ngIf="column.highlightName != undefined && dataItem[column.name] != column.highlightName">
						{{ dataItem[column.name] | titlecase }}
					</span>
				</div>

				<div
					*ngIf="
						column.highlight && column.type !== 'platform' && column.type !== 'editableString' && column.type !== 'link' && dataItem[column.name]
					"
					[ngStyle]="styleRows(i)"
				>
					<span class="{{ column?.highlightName && dataItem[column.name] == column?.highlightName ? 'highlight' : '' }}">
						{{ dataItem[column.name] }}
					</span>
				</div>
			</ng-template>
		</kendo-grid-column>
	</ng-container>

	<ng-template kendoPagerTemplate>
		<div class="center-align pager">
			<select class="form-control drop-size" style="margin-right: 18px;" (change)="pageSizeChange($event)">
				<ng-container *ngFor="let size of pageSizeOptions">
					<option [value]="size" [selected]="size == pageSize">{{ size }} per page</option>
				</ng-container>
			</select>
			<ng-container *ngIf="!hidePaginationArrows">
				<mat-icon class="{{ pageNumber == 1 ? 'disabled-icon' : 'active-icon' }}" (click)="previousPage()">
					arrow_back_ios
				</mat-icon>
				<span style="font-size: 12px; margin-left: 18px; margin-right: 18px;">{{ pageNumber + '/' + totalPages }}</span>
				<mat-icon class="{{ pageNumber == totalPages ? 'disabled-icon' : 'active-icon' }}" (click)="nextPage()">
					arrow_forward_ios
				</mat-icon>
			</ng-container>
		</div>
	</ng-template>
</kendo-grid>

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { NewStorageKey } from 'src/app/_models/local-storage-key';
import { SettingsService } from '../../../settings/services/settings.service';
import { AuthenticationService } from '../../services/authentication.service';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
	public sendToMyJobs: string;
	public campaignId: string;
	loginRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.loginRequest),
			mergeMap(action => {
				if (action.gToken) {
					return this.authService.signInWithGoogle(action.gToken).pipe(
						map(token => {
							if (typeof token === 'string') {
								return AuthActions.loginSuccess({ token: token });
							} else {
								return AuthActions.loginFailure({ error: 'Invalid Credentials' });
							}
						}),
						catchError(err => of(AuthActions.loginFailure({ error: err })))
					);
				}
				return this.authService.signIn(action.email, action.password).pipe(
					map(token => {
						if (typeof token === 'string') {
							return AuthActions.loginSuccess({ token: token });
						} else {
							return AuthActions.loginFailure({ error: 'Invalid Credentials' });
						}
					}),
					catchError(err => of(AuthActions.loginFailure({ error: err })))
				);
			})
		)
	);

	loginSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.loginSuccess),
				tap(response => {
					let decodedJwt = this.authService.decodeToken(response.token);
					if (!decodedJwt) {
						this.authService.signOut();
						this.toastNotification.sendErrorToast('There was a problem Signing you in, Please try again');
						this.authService.authLoadingState.next(false);
						return;
					}
					localStorage.setItem(NewStorageKey.token, response.token);
					localStorage.setItem(NewStorageKey.decodedJwtIo, JSON.stringify(decodedJwt));
					localStorage.setItem(NewStorageKey.first_time_user, 'true');

					if (decodedJwt?.influencer_available_platforms?.trim() || !decodedJwt?.first_time_user) {
						if (this.sendToMyJobs) {
							this.router.navigate(['/'], { queryParams: { sendToMyJobs: '1', campaignId: this.campaignId } });
						} else {
							this.router.navigate(['/']);
						}
					} else {
						// ! FIRST TIME USER HERE

						// * PREVIOUS IMPLEMENTATION
						// if (this.sendToMyJobs) {
						// 	this.router.navigate(['/authentication/connect-accounts'], { queryParams: { sendToMyJobs: '1', campaignId: this.campaignId } });
						// } else {
						// 	this.router.navigate(['/authentication/connect-accounts']);
						// }

						// * NEW IMPLEMENTATION
						this.router.navigate(['/my-offers']);
					}
					this.settingsService.getProfileData();
					this.authService.authLoadingState.next(false);
				})
			),
		{ dispatch: false }
	);

	loginFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.loginFailure),
				tap(response => {
					this.authService.authLoadingState.next(false);
					if (typeof response.error === 'string') {
						this.toastNotification.sendErrorToast(response.error);
					} else if (response.error?.message) {
						this.toastNotification.sendErrorToast(response.error?.message);
					} else {
						this.toastNotification.sendErrorToast('Something went wrong, Please try again later');
					}
				})
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private authService: AuthenticationService,
		private router: Router,
		private toastNotification: ToastNotificationService,
		private settingsService: SettingsService
	) {
		const url: string = window.location.href;
		if (url.indexOf('sendToMyJobs') >= 0) {
			this.sendToMyJobs = '1';
			const index = url.indexOf('campaignId=');
			this.campaignId = url.substring(index + 11, url.length);
		}
	}
}

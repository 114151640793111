import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, skipWhile, take, takeUntil } from 'rxjs/operators';
import { ButtonClassEnum } from '../../../../button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../button-component/button-type.enum';
import { DropdownData } from '../../../../dropdown-search-select/dropdown-data.interface';
import { MapperService } from '../../../../services/mapper.service';
import { BuildUrlParameterDialogComponent } from '../build-url-parameter-dialog/build-url-parameter-dialog.component';

@Component({
	selector: 'app-ad-creation-input-form',
	templateUrl: './ad-creation-input-form.component.html',
	styleUrls: ['./ad-creation-input-form.component.scss']
})
export class AdCreationInputFormComponent implements OnInit {
	@Input() public formGroup: UntypedFormGroup;
	@Input() public callToActions: DropdownData<any>[] = [];
	@Input() public isPageLikesSelected: boolean;
	@Input() public isPostLikesSelected: boolean;
	@Input() public isWebsiteUrl: boolean;
	@Input() public adFormat: any;
	@Input() public adCreateFormGroup: UntypedFormGroup;

	public isCtaSelected: boolean;
	public adFormatEnum;
	public buttonType = ButtonTypeEnum;
	public buttonClass = ButtonClassEnum;
	private unsubscriber$ = new Subject<void>();

	constructor(private dialog: MatDialog, private mapperService: MapperService, private store: Store<any>) {}

	public ngOnInit(): void {
		this.getCallToAction();
		this.unwantedCallToActions();
		this.initAdFormGroupListener();
		this.checkInputForm();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public unwantedCallToActions(): void {
		this.callToActions = this.callToActions.filter(item => item.value !== 'CallToActionEnum.WhatsappMessage');
	}

	public initAdFormGroupListener(): void {
		const urlValidator = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
		this.adCreateFormGroup
			.get('callToAction')
			.valueChanges.pipe(takeUntil(this.unsubscriber$), debounceTime(500))
			.subscribe(value => {
				this.checkInputForm();
			});
		this.formGroup.get('websiteUrl').setValidators([Validators.required, Validators.pattern(urlValidator), Validators.maxLength(1024)]);
	}

	public checkInputForm(): void {
		const urlValidator = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
		if (this.isPostLikesSelected && this.formGroup.get('callToAction').value === 'CallToActionEnum.NoButton') {
			this.isCtaSelected = false;
			this.adCreateFormGroup.get('websiteUrl').patchValue(null);
			this.adCreateFormGroup.get('websiteUrl').clearValidators();
			this.adCreateFormGroup.get('websiteUrl').updateValueAndValidity();
		}
		if (this.isPostLikesSelected && this.adCreateFormGroup.get('callToAction').value !== 'CallToActionEnum.NoButton') {
			this.isCtaSelected = true;
			this.adCreateFormGroup.get('websiteUrl').setValidators([Validators.required, Validators.pattern(urlValidator)]);
			this.adCreateFormGroup.get('websiteUrl').updateValueAndValidity();
		}
	}

	public getCallToAction(): void {
		// if (!this.callToActions) {
		// 	this.store
		// 		.pipe(
		// 			select(),
		// 			skipWhile(result => !result),
		// 			take(1)
		// 		)
		// 		.subscribe(callToActions => {
		// 			this.callToActions = this.mapperService.convertToDropdownData(callToActions, 'name', 'displayNameAutogen');
		// 		});
		// }
	}

	public getKeysForNestedFormGroup(groupName: string): string[] {
		const result: string[] = [];
		const nestedGroup = this.formGroup.get(groupName) as UntypedFormGroup;
		for (const controlKey in nestedGroup.controls) {
			if (nestedGroup.get(controlKey).enabled) {
				result.push(controlKey);
			}
		}
		return result;
	}

	public onBuildParameterClick(): void {
		this.dialog
			.open(BuildUrlParameterDialogComponent, {
				panelClass: 'cif-build-url-parameter',
				data: this.formGroup.get('websiteUrl').value
			})
			.afterClosed()
			.subscribe(param => {
				if (param) {
					this.formGroup.get('websiteUrl').patchValue(param);
				}
			});
	}
}

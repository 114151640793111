import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../../state/user/user.reducer';
import { Modules } from '../enums/modules';
import { PixelPermissions } from '../enums/pixel-permissions';
import { AdsManagerPermissions } from '../enums/ads-manager-permissions';
import { AccountsPermissions } from '../enums/accounts-permissions';
import { MiscellaneousPermissions } from '../enums/miscellaneous-permissions';
import { AudiencePermissions } from '../enums/audience-permissions';
import { CampaignBuilderPermissions } from '../enums/campaign-builder-permissions';
import { OptimizePermissions } from '../enums/optimize-permissions';
import { CreativeBuilderPermissions } from '../enums/creative-builder-permissions';
import { ProductCatalogPermissions } from '../enums/product-catalog-permissions';
import { ReportsPermissions } from '../enums/reports-permissions';
import { SettingsPermissions } from '../enums/settings-permissions';
import { BackofficePermissions } from '../enums/backoffice-permissions';
import { Subject, Subscription } from 'rxjs';
import { SelectedPermission } from './selected-permission';
import { Bits } from '../enums/bits';
import { ButtonTypeEnum } from '../../button-component/button-type.enum';
import { ButtonClassEnum } from '../../button-component/button-class.enum';
import { PermissionsData } from './permissions-data.interface';

@Component({
	selector: 'app-table-permissions',
	templateUrl: './table-permissions.component.html',
	styleUrls: ['./table-permissions.component.scss']
})
export class TablePermissionsComponent implements OnInit, OnDestroy, OnChanges {
	@Input() public rolePermissions: PermissionsData[];
	@Input() public roleIsReadonly: boolean;

	public buttonTypeEnum = ButtonTypeEnum;
	public buttonClassEnum = ButtonClassEnum;
	public permissionsData: PermissionsData;
	public modulesEnum = Modules;
	public accountPermissionsEnum = AccountsPermissions;
	public adsManagerPermissionsEnum = AdsManagerPermissions;
	public audiencePermissionsEnum = AudiencePermissions;
	public backOfficePermissionsEnum = BackofficePermissions;
	public campaignBuilderPermissionsEnum = CampaignBuilderPermissions;
	public creativeBuilderPermissionsEnum = CreativeBuilderPermissions;
	public miscellaneousPermissionsEnum = MiscellaneousPermissions;
	public optimizePermissionsEnum = OptimizePermissions;
	public pixelsPermissionsEnum = PixelPermissions;
	public productCatalogPermissionsEnum = ProductCatalogPermissions;
	public reportsPermissionsEnum = ReportsPermissions;
	public settingsPermissionsEnum = SettingsPermissions;
	public bitsEnum = Bits;
	public subscription: Subscription;

	public allPermissions: SelectedPermission[];
	public allPermissionsForAllModules: Map<
		Modules,
		Map<
			| Bits
			| AccountsPermissions
			| ReportsPermissions
			| PixelPermissions
			| AdsManagerPermissions
			| AudiencePermissions
			| BackofficePermissions
			| CampaignBuilderPermissions
			| CreativeBuilderPermissions
			| MiscellaneousPermissions
			| OptimizePermissions
			| ProductCatalogPermissions
			| SettingsPermissions,
			boolean
		>
	>;
	private unsubscriber$: Subject<void> = new Subject<void>();

	public constructor(private store: Store<UserState>) {
		this.allPermissionsForAllModules = new Map<
			Modules,
			Map<
				| Bits
				| AccountsPermissions
				| ReportsPermissions
				| PixelPermissions
				| AdsManagerPermissions
				| AudiencePermissions
				| BackofficePermissions
				| CampaignBuilderPermissions
				| CreativeBuilderPermissions
				| MiscellaneousPermissions
				| OptimizePermissions
				| ProductCatalogPermissions
				| SettingsPermissions,
				boolean
			>
		>();
		this.resetAllPermissionForModule();

		this.roleIsReadonly = true;
	}

	public checkPermissions(): void {
		this.store.dispatch({
			type: '[Permissions] Permissions Check'
		});
	}

	public checkboxPermissions(moduleId: Modules, checkedPermissions: any): boolean {
		let found = false;
		this.rolePermissions.forEach((permission: any) => {
			if (moduleId === permission?.module?.id) {
				permission.permissions.forEach((permissionData: any) => {
					if (permissionData.id === checkedPermissions) {
						found = true;
					}
				});
			}
		});
		return found;
	}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.resetAllPermissionForModule();

		if (this.rolePermissions) {
			this.rolePermissions.forEach(permission => {
				permission.permissions.forEach(module => {
					this.allPermissionsForAllModules.get(permission.moduleRole.id).set(module.id, true);
				});
			});
		}
	}

	public checkBoxChange(module: Modules, permission: any) {
		const allPermissionsForGivenModule = this.allPermissionsForAllModules.get(module);
		const permissionStateToBeInverted = allPermissionsForGivenModule.get(permission);
		allPermissionsForGivenModule.set(permission, !permissionStateToBeInverted);
	}

	private resetAllPermissionForModule(): void {
		this.allPermissionsForAllModules.set(Modules.Pixel, this.getPermissionsForModule(PixelPermissions));
		this.allPermissionsForAllModules.set(Modules.Accounts, this.getPermissionsForModule(AccountsPermissions));
		this.allPermissionsForAllModules.set(Modules.Reports, this.getPermissionsForModule(ReportsPermissions));
		this.allPermissionsForAllModules.set(Modules.Miscellaneous, this.getPermissionsForModule(MiscellaneousPermissions));
		this.allPermissionsForAllModules.set(Modules.AdsManager, this.getPermissionsForModule(AdsManagerPermissions));
		this.allPermissionsForAllModules.set(Modules.Audience, this.getPermissionsForModule(AudiencePermissions));
		this.allPermissionsForAllModules.set(Modules.BackOffice, this.getPermissionsForModule(BackofficePermissions));
		this.allPermissionsForAllModules.set(Modules.CampaignBuilder, this.getPermissionsForModule(CampaignBuilderPermissions));
		this.allPermissionsForAllModules.set(Modules.CreativeBuilder, this.getPermissionsForModule(CreativeBuilderPermissions));
		this.allPermissionsForAllModules.set(Modules.Optimize, this.getPermissionsForModule(OptimizePermissions));
		this.allPermissionsForAllModules.set(Modules.ProductCatalog, this.getPermissionsForModule(ProductCatalogPermissions));
		this.allPermissionsForAllModules.set(Modules.Settings, this.getPermissionsForModule(SettingsPermissions));
	}

	// Turn enum into array
	private getPermissionsForModule(enumerationType: any): Map<Bits, boolean> {
		const result: Map<Bits, boolean> = new Map<Bits, boolean>();
		const enumerationKeys = Object.values(enumerationType).filter(this.stringIsNumber);
		enumerationKeys.forEach(key => result.set(key as Bits, false));
		return result;
	}

	// Helper
	private stringIsNumber = (value: any) => isNaN(Number(value)) === false;
}

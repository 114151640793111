import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateRange } from '../../shared/calendar/calendar.models';
import { PixelDataTable } from '../../_models/PixelModels/pixel-data-table';
import { PixelDetails, PixelDetailsForAudiences } from '../../_models/PixelModels/pixel-details';
import { PixelDto } from '../../_models/PixelModels/pixel-dto';
import { BaseApiUrl } from '../base-api-urls';
import { EventsHelper } from './events-helper';
import { PixelService } from './pixel.service';

@Injectable()
export class PixelServiceApi {
	private readonly baseURL = `${BaseApiUrl.Pixel}pixels`;
	private readonly basePythonURL = `${BaseApiUrl.PixelPython}`;

	constructor(protected http: HttpClient) {}

	public getPixelsByAdAccounts(adAccountId: string): Observable<PixelDto> {
		return this.http.get<PixelDto>(`${this.baseURL}?adAccFbIds=${adAccountId}`);
	}

	public getAppEvents(id: string): Observable<any[]> {
		return this.http.get<any[]>(`${BaseApiUrl.FacebookPythonCampaignBuilder}smart-create/account-advertisable-apps/${id}`);
	}

	public getEventsByAdAccountIds(adAccountId: string): Observable<any[]> {
		return this.http.get<any[]>(`${BaseApiUrl.Pixel}events?adAccId=${adAccountId}`).pipe(map(events => EventsHelper.mapEvents(events)));
	}

	public getEventsByPixelId(pixelId: string): Observable<any[]> {
		return this.http.get<any[]>(`${BaseApiUrl.Pixel}pixels/${pixelId}/events/`).pipe(map(events => EventsHelper.mapEvents(events)));
	}

	public getPixelsByAdAccountsWithTimeRange(adAccountId: string, selectedDateRange: DateRange): Observable<any[]> {
		return this.http.get<any[]>(`${this.baseURL}?adAccId=${adAccountId}`).pipe(map(pixels => PixelService.mapPixels(pixels)));
	}

	public getPixelEventsForAdAccount(id: number): Observable<any[]> {
		const endpoint = `${this.baseURL}?adAccId=act_${id}`;
		return this.http.get<any[]>(endpoint);
	}

	public getBreakdownsStandardEvents(): Observable<any[]> {
		return this.http.get<any[]>(`${this.basePythonURL}pixels/catalogs`);
	}

	public getBreakdownsCustomConversions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.basePythonURL}customconversions/catalogs`);
	}

	public createPixel(pixelDetails: PixelDetails): Observable<PixelDataTable> {
		return this.http.post<PixelDataTable>(this.baseURL, pixelDetails);
	}

	public updatePixel(pixelId: string, Attributes: [{ Name: string; Value: any }]): Observable<any> {
		return this.http.put(`${this.baseURL}/${pixelId}`, Attributes);
	}

	public getEventsPixelDetails(pixelId: string): Observable<PixelDetailsForAudiences> {
		return this.http.get<PixelDetailsForAudiences>(`${this.baseURL}/${pixelId}/pixel-with-events`);
	}

	public getPixelEventInsight(query: any): Observable<any[]> {
		return this.http.post<any[]>(`${this.basePythonURL}${query.level}/insights`, query);
	}

	public getConversionEvents(pixelId: string): Observable<any[]> {
		return this.http.get<any[]>(`${this.baseURL}/${pixelId}/events`);
	}
}

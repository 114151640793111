import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { getPermissions, UserState } from '../state/user/user.reducer';
import { switchMap, take } from 'rxjs/operators';
import { MiscellaneousPermissions } from '../permisions/enums/miscellaneous-permissions';
import { Modules } from '../permisions/enums/modules';
import { SettingsPermissions } from '../permisions/enums/settings-permissions';

@Injectable({
	providedIn: 'root'
})
export class UserRoleBusinessGuardService implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, private userStore: Store<UserState>) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if (this.authService.isLoggedIn()) {
		// 	return this.userStore.pipe(
		// 		select(getPermissions),
		// 		take(1),
		// 		switchMap(module => {
		// 			let IsBusinessOwner = false;
		// 			let IsClientEmployee = false;
		// 			module
		// 				.filter(module => module.module === Modules.Miscellaneous || Modules.Settings)
		// 				.forEach(allPermissions => {
		// 					allPermissions.permissions.forEach(permission => {
		// 						if (permission === MiscellaneousPermissions.IsBusinessOwner || permission === MiscellaneousPermissions.IsFreemium) {
		// 							IsBusinessOwner = true;
		// 						} else if (permission === SettingsPermissions.SettingsUserManagementView) {
		// 							IsClientEmployee = true;
		// 						}
		// 					});
		// 				});

		// 			if (IsBusinessOwner || IsClientEmployee) {
		// 				return of(true);
		// 			}
		// 			this.router.navigate(['/accounts']);
		// 			return of(false);
		// 		})
		// 	);
		// }

		return of(false);
	}
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import _ from 'lodash';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { PublicDateModel } from '../calendar/calendar.models';
import { InsightsCategoryTypeEnum } from '../campaign-insights/models/insights-category-type.enum';
import { InsightsReportModel } from '../campaign-insights/models/insights-report.model';
import { ViewName } from '../elements/elements-metrics/models/metrics-view.enum';
import { ColumnViews } from '../elements/elements-metrics/models/views-column.interface';
import { TableViewColumn2 } from '../generic-table2/models/table-structure/table-view-column.model';
import { BreakdownsResponseInterface } from '../models/breakdowns-response.interface';
import { InsightsLevelEnum } from '../services/general-settings/models/insights-level.enum';
import { LocalStorageService } from '../services/local-storage.service';
import { SharedActions, SharedActionTypes } from './shared.actions';
import { CachedAdAccountsModel, SelectedAdAccountModel } from './state-entities/cached-ad-accounts.model';
import { DataInterface, NullDataInterface } from './state-entities/data-interface.model';
import { FiledUsersApiCodesEnum } from './state-entities/errors/api-codes.enum';
import { FiledSubscriptionApiValidationCodesEnum, FiledUsersApiValidationCodesEnum } from './state-entities/errors/api-validation.enum';
import { FiledApiModels } from './state-entities/errors/errors.model';
import { GeneralSettingsSlice, initialState as generalSettingsInitialState } from './state-entities/general-settings/general-settings-slice.model';
import { initialState as insightsInitialState, SharedInsightsStructure } from './state-entities/insights/shared-insights-structure.model';
import { PublishStatusInterface } from './state-entities/publish/publish-status.interface';
import { ReducerFunctions } from './utils/reducer-functions.util';

export interface SharedState {
	globalDateSelection: PublicDateModel;
	cachedAdAccounts: DataInterface<CachedAdAccountsModel[]>;
	selectedAdAccount: SelectedAdAccountModel;
	generalSettings: GeneralSettingsSlice;
	insights: SharedInsightsStructure;
	globalSpinnerShown: boolean;
	navigationSpinnerShown: boolean;
	filedApiEnums: FiledApiModels;
	measureSettings: {
		reportTypes: DataInterface<any[]>;
		dimensions: DataInterface<any>;
		metrics: DataInterface<TableViewColumn2[]>;
		breakdowns: DataInterface<BreakdownsResponseInterface>;
	};
	globalElementsView: string;
	globalElementsViewAds: string;
	toggleElementsGraph: boolean;
	toggleElementsMetrics: boolean;
	toggleElementsRecommendations: boolean;
	publish: PublishStatusInterface;
	selectedMetrics: {
		isLoading: boolean;
		Columns: ColumnViews[];
	};
	selectedAdsManagerTab: string;
}

export const initialSharedState: SharedState = {
	globalDateSelection: new PublicDateModel(),
	cachedAdAccounts: {
		isLoaded: false,
		errorCode: null,
		data: []
	},
	selectedAdAccount: {
		adAccount: (LocalStorageService.tryGetItem<any[]>('selectedAdAccounts') || [])[0],
		sourceChannel: (+localStorage.getItem('source_channel') || null) as SourceChannel
	},
	generalSettings: generalSettingsInitialState,
	insights: insightsInitialState,
	globalSpinnerShown: false,
	navigationSpinnerShown: false,
	filedApiEnums: {
		filedApiUsersState: {
			filedUsersApiCodesState: [
				{
					code: FiledUsersApiCodesEnum.InitialPasswordWasAlreadySetup,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.Filed__Domain__NA__SetPassword__IsInvalidPasswordMismatch,
					message: 'Password mismatch'
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresDigit,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresLower,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresNonAlphanumeric,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresUniqueChars,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresUpper,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordTooShort,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityIsLockedOut,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityIsNotAllowed,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityRequiresTwoFactor,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_HasUserWithNoRolesDefined,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_IsDifferentInRouteAndJwt,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_IsNotClientEmployeeForCurrentUser,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ByBusinessOwnerFacebookId,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ByEmail,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ById,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWithSameEmail_AlreadyExists,
					message: ''
				}
			],
			filedUsersApiValidationCodesState: [
				{
					code: FiledUsersApiValidationCodesEnum.BusinessOwnerFacebookId_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.BusinessOwnerGoogleId_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeeFirstName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeeLastName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeePermissions_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeePhoneNumber_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.InputFile_HasNoContent,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.InputFile_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileContentType_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileContentType_IsNotImageOrVideo,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_CurrentPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_NewPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_ConfirmNewPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_NewPassword_DiffersFomConfirmNewPassword,
					message: ''
				}
			]
		},
		filedSubscriptionApi: {
			filedSubscriptionApiModel: [
				{
					code: FiledSubscriptionApiValidationCodesEnum.Filed__Domain__NA__PhoneNumber_ContactPhoneNumber__AreDifferent,
					message: 'The phone number entered does not match the phone number of the account'
				}
			]
		}
	},
	measureSettings: {
		reportTypes: _.cloneDeep(NullDataInterface),
		dimensions: _.cloneDeep(NullDataInterface),
		metrics: _.cloneDeep(NullDataInterface),
		breakdowns: _.cloneDeep(NullDataInterface)
	},
	globalElementsView: ViewName.Performance,
	globalElementsViewAds: ViewName.Performance,
	toggleElementsGraph: true,
	toggleElementsMetrics: true,
	publish: null,
	toggleElementsRecommendations: true,
	selectedMetrics: {
		isLoading: false,
		Columns: []
	},
	selectedAdsManagerTab: InsightsCategoryTypeEnum.Campaign
};

export const getSharedState = createFeatureSelector<SharedState>('shared');

export const getApiEnums = createSelector(getSharedState, state => state.filedApiEnums);

export const getGlobalDate = createSelector(getSharedState, state => state.globalDateSelection);
export const getCurrentAdsManagerTab = createSelector(getSharedState, state => state.selectedAdsManagerTab);
export const getGlobalElementsView = createSelector(getSharedState, state => state.globalElementsView);
export const globalElementsViewAds = createSelector(getSharedState, state => state.globalElementsViewAds);
export const getToggleElementsGraph = createSelector(getSharedState, state => state.toggleElementsGraph);
export const getToggleElementsMetrics = createSelector(getSharedState, state => state.toggleElementsMetrics);
export const getToggleElementsRecommendations = createSelector(getSharedState, state => state.toggleElementsRecommendations);
export const getSelectedElementsMetrics = createSelector(getSharedState, state => state.selectedMetrics);
export const getAllCachedAddAccounts = createSelector(getSharedState, state => state.cachedAdAccounts?.data);
export const getSelectedAdAccount = createSelector(getSharedState, state => state.selectedAdAccount);
export const getAdAccountAndGlobalDate = createSelector(
	getSelectedAdAccount,
	getGlobalDate,
	(selectedAdAccount: SelectedAdAccountModel, globalDate: PublicDateModel) => {
		return {
			selectedAdAccount: selectedAdAccount,
			globalDate: globalDate
		};
	}
);

export const facebookAdAccountsLoaded = createSelector(getSharedState, state =>
	state.cachedAdAccounts?.data?.find(cachedAdAccount => cachedAdAccount.sourceChannel === SourceChannel.Facebook)
);
export const googleAdAccountsLoaded = createSelector(getSharedState, state =>
	state.cachedAdAccounts?.data?.find(cachedAdAccount => cachedAdAccount.sourceChannel === SourceChannel.Google)
);
export const getCachedAddAccountsBySourceChannel = (sourceChannel: SourceChannel) =>
	createSelector(getSharedState, state => state.cachedAdAccounts?.data?.find(cachedAdAccount => cachedAdAccount.sourceChannel === sourceChannel));

export const getSharedGeneralSettingsState = createSelector(getSharedState, state => state.generalSettings.shared.data);
export const getAdsManagerGeneralSettingsState = createSelector(getSharedState, state => state.generalSettings.adsManager.data);

export const getDimensions = (level: string = null) =>
	createSelector(getSharedState, state => {
		return state.measureSettings.dimensions.data?.vCampaignInsights;
	});
export const getMetrics = createSelector(getSharedState, state => state.measureSettings.metrics.data);
export const getBreakdowns = createSelector(getSharedState, state => state.measureSettings.breakdowns.data);
export const getPublishStatus = createSelector(getSharedState, state => state.publish);

export const getReportTypes = createSelector(getSharedState, (state: SharedState, payload: any) => {
	const reportTypesData = state.measureSettings.reportTypes?.data;
	if (!reportTypesData?.length) {
		return null;
	}
	return reportTypesData?.filter(reportType => {
		return reportType.reportLevel === payload.reportLevel && reportType.sourceChannel === payload.sourceChannel;
	});
});

export const getInsightsFromAdAccount = (
	sourceChannel: SourceChannel,
	statusKeys: any[],
	adAccountId: any['id'],
	level: InsightsLevelEnum,
	parentIds: string[] = []
) =>
	createSelector(getSharedState, state => {
		const adAccount = ReducerFunctions.getAdAccountByIdFromState(state, adAccountId, sourceChannel);

		if (adAccount && level in adAccount) {
			const insights = adAccount[level];
			const selectedInsights = _.cloneDeep(ReducerFunctions.getSelectedInsights(insights, statusKeys));
			if (level === InsightsLevelEnum.Campaigns || !parentIds.length) {
				return selectedInsights;
			}
			if (level === InsightsLevelEnum.AdSets) {
				return ReducerFunctions.filterAdSetsByCampaignId(selectedInsights, parentIds);
			}
			if (level === InsightsLevelEnum.Ads) {
				return ReducerFunctions.filterAdsByAdSetId(selectedInsights, parentIds);
			}
		}

		return null;
	});

export const isInsightDataLoaded = (sourceChannel: SourceChannel, statusKeys: any[], adAccountId: any['id'], level: InsightsLevelEnum) =>
	createSelector(getSharedState, state => {
		const adAccountModel = ReducerFunctions.getAdAccountByIdFromState(state, adAccountId, sourceChannel);
		return ReducerFunctions.checkIfStructureDataIsPopulated(adAccountModel, level);
	});

export const isSharedGeneralSettingsLoaded = createSelector(getSharedState, state => state.generalSettings.shared.isLoaded);
export const isAdsManagerGeneralSettingsLoaded = createSelector(getSharedState, state => state.generalSettings.adsManager.isLoaded);
export const isCampaignBuilderGeneralSettingsLoaded = createSelector(getSharedState, state => state.generalSettings.campaignBuilder.isLoaded);
export const getGlobalSpinnerStatus = createSelector(getSharedState, state => state.globalSpinnerShown);
export const getnavigationSpinnerStatus = createSelector(getSharedState, state => state.navigationSpinnerShown);

export const getMetadata = createSelector(
	getSharedState,
	(state: SharedState, { channel, category }: { channel: SourceChannel; category: InsightsCategoryTypeEnum }) =>
		_.cloneDeep(ReducerFunctions.getAdsManagerMetadata(state, channel, category))
);

export const isMetadataLoaded = createSelector(
	getSharedState,
	(state: SharedState, { channel, category }: { channel: SourceChannel; category: InsightsCategoryTypeEnum }) =>
		ReducerFunctions.getAdsManagerMetadata(state, channel, category) != null
);

export const getAdsManagerUserViews = createSelector(
	getSharedState,
	(state: SharedState, { sourceChannel, category }: { sourceChannel: SourceChannel; category: InsightsCategoryTypeEnum }) => {
		const details = ReducerFunctions.getAdsManagerViewDetails(state.generalSettings.shared, sourceChannel, category);
		return _.cloneDeep((details || {}).userViews || []);
	}
);

export const getAdsManagerCurrentView = createSelector(
	getSharedState,
	(state: SharedState, { sourceChannel, category }: { sourceChannel: SourceChannel; category: InsightsCategoryTypeEnum }) => {
		const details = _.cloneDeep(ReducerFunctions.getAdsManagerViewDetails(state.generalSettings.shared, sourceChannel, category));
		return (details || {}).currentView;
	}
);

export const getAdsManagerDefaultViewName = createSelector(
	getSharedState,
	(state: SharedState, { sourceChannel, category }: { sourceChannel: SourceChannel; category: InsightsCategoryTypeEnum }) => {
		const details = ReducerFunctions.getAdsManagerViewDetails(state.generalSettings.shared, sourceChannel, category);
		return (details || {}).defaultViewName;
	}
);

export const getAdsManagerSelectedCampaigns = createSelector(getSharedState, state => _.cloneDeep(state.generalSettings.adsManager.data.selectedCampaigns));
export const getAdsManagerSelectedAdsets = createSelector(getSharedState, state => _.cloneDeep(state.generalSettings.adsManager.data.selectedAdsets));
export const getAdsManagerSelectedAds = createSelector(getSharedState, state => _.cloneDeep(state.generalSettings.adsManager.data.selectedAds));

export const getAdsManagerSelection = createSelector(
	getSharedState,
	(state: SharedState, { channel, category }: { channel: SourceChannel; category: InsightsCategoryTypeEnum }) =>
		_.cloneDeep(ReducerFunctions.getAdsManagerSelection(state, channel, category))
);

export const getAdsManagerFilteredStructures = createSelector(
	getSharedState,
	(state: SharedState, { channel, category }: { channel: SourceChannel; category: InsightsCategoryTypeEnum }) =>
		_.cloneDeep(ReducerFunctions.getAdsManagerFilteredStructures(state, channel, category))
);

export const getAdsManagerUserDashboards = createSelector(getSharedState, (state: SharedState) => {
	return _.cloneDeep(ReducerFunctions.getAdsManagerUserDashboard(state));
});

export function sharedReducer(state = initialSharedState, action: SharedActions): SharedState {
	switch (action.type) {
		case SharedActionTypes.LoadReportTypes: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					reportTypes: {
						...state.measureSettings.reportTypes,
						isLoading: true
					}
				}
			};
		}
		case SharedActionTypes.LoadReportTypesSuccess: {
			const reportTypesModel = (action.payload as unknown) as any;
			const campaignsReportTypes = reportTypesModel.campaign.map((campaign: any) => {
				return ReducerFunctions.mapReportType(campaign.displayName, campaign.key, SourceChannel.Facebook, InsightsCategoryTypeEnum.Campaign);
			});
			const adSetsReportTypes = reportTypesModel.adset.map((campaign: any) => {
				return ReducerFunctions.mapReportType(campaign.displayName, campaign.key, SourceChannel.Facebook, InsightsCategoryTypeEnum.AdSet);
			});
			const adsReportTypes = reportTypesModel.ad.map((campaign: any) => {
				return ReducerFunctions.mapReportType(campaign.displayName, campaign.key, SourceChannel.Facebook, InsightsCategoryTypeEnum.Ad);
			});
			const reportTypes = campaignsReportTypes.concat(adSetsReportTypes).concat(adsReportTypes);
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					reportTypes: {
						...state.measureSettings.reportTypes,
						data: reportTypes,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.LoadReportTypesFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					reportTypes: {
						...state.measureSettings.reportTypes,
						errorCode: action.payload,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}

		case SharedActionTypes.SetCurrentAdsManagerTab: {
			return {
				...state,
				selectedAdsManagerTab: action.payload
			};
		}

		case SharedActionTypes.SetGlobalElementsView: {
			return {
				...state,
				globalElementsView: action.payload
			};
		}

		case SharedActionTypes.SetGlobalElementsViewAds: {
			return {
				...state,
				globalElementsViewAds: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsGraph: {
			return {
				...state,
				toggleElementsGraph: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsMetrics: {
			return {
				...state,
				toggleElementsMetrics: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsRecommendations: {
			return {
				...state,
				toggleElementsRecommendations: action.payload
			};
		}

		case SharedActionTypes.SetSelectedElementsMetrics: {
			return {
				...state,
				selectedMetrics: action.payload
			};
		}

		// case SharedActionTypes.LoadCachedCampaigns:
		case SharedActionTypes.LoadCachedAdSets:
		case SharedActionTypes.LoadCachedAds: {
			const sourceChannel = action.payload.sourceChannel;
			const adAccountId = action.payload.adAccountId;
			const filterKeys = action.payload.filterKeys;
			const reportLevel = ReducerFunctions.insightCategoryToInsightLevel(action.payload.reportLevel);
			let newState = _.cloneDeep(state.cachedAdAccounts);
			newState?.data?.forEach(adAccount => {
				if (adAccount.sourceChannel === sourceChannel) {
					adAccount.adAccounts.forEach(adAccount => {
						if (adAccount.id === adAccountId) {
							if (!adAccount[reportLevel]) {
							}
							if (filterKeys) {
							}
						}
					});
				}
			});

			return {
				...state,
				cachedAdAccounts: {
					...state.cachedAdAccounts,
					data: newState.data
				}
			};
		}

		case SharedActionTypes.LoadCachedCampaignsSuccess:
		case SharedActionTypes.LoadCachedAdSetsSuccess:
		case SharedActionTypes.LoadCachedAdsSuccess: {
			const sourceChannel = action.payload.selector.sourceChannel;
			const adAccountId = action.payload.selector.adAccountId;
			const reportLevel = ReducerFunctions.insightCategoryToInsightLevel(action.payload.selector.reportLevel);

			let newState = _.cloneDeep(state.cachedAdAccounts);
			const insights = action.payload.insights.map(insight => {
				return {
					...insight,
					currentKey: ReducerFunctions.getCurrentStructureKeyFactory(insight, reportLevel)
				};
			});
			newState?.data?.forEach(adAccount => {
				if (adAccount.sourceChannel === sourceChannel) {
					adAccount.adAccounts.forEach(adAccount => {
						if (adAccount.id === adAccountId) {
							if (!adAccount[reportLevel]) {
							}

							if (!adAccount[reportLevel].active['data']) {
								adAccount[reportLevel].active['data'] = [];
							}

							insights.forEach((insight: any) => {});
						}
					});
				}
			});

			return {
				...state,
				cachedAdAccounts: {
					...state.cachedAdAccounts,
					data: newState.data
				}
			};
		}

		case SharedActionTypes.LoadMetrics: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					metrics: {
						...state.measureSettings.metrics,
						isLoading: true
					}
				}
			};
		}

		case SharedActionTypes.LoadMetricsSuccess: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					metrics: {
						...state.measureSettings.metrics,
						data: action.payload,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}

		case SharedActionTypes.LoadMetricsFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					metrics: {
						...state.measureSettings.metrics,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadDimensions: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					dimensions: {
						...state.measureSettings.dimensions,
						isLoading: true
					}
				}
			};
		}

		case SharedActionTypes.LoadDimensionsSuccess: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					dimensions: {
						...state.measureSettings.dimensions,
						data: action.payload,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}

		case SharedActionTypes.LoadDimensionsFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					dimensions: {
						...state.measureSettings.dimensions,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadBreakdowns: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						isLoading: true
					}
				}
			};
		}

		case SharedActionTypes.LoadBreakdownsSuccess: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						data: action.payload,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}

		case SharedActionTypes.LoadBreakdownsFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.ShowGlobalSpinner: {
			return {
				...state,
				globalSpinnerShown: true
			};
		}
		case SharedActionTypes.HideGlobalSpinner: {
			return {
				...state,
				globalSpinnerShown: false
			};
		}

		case SharedActionTypes.ShowNavigationSpinner: {
			return {
				...state,
				navigationSpinnerShown: true
			};
		}
		case SharedActionTypes.HideNavigationSpinner: {
			return {
				...state,
				navigationSpinnerShown: false
			};
		}

		case SharedActionTypes.SetGlobalDate: {
			return {
				...state,
				globalDateSelection: action.payload
			};
		}

		case SharedActionTypes.SetAdAccountSelection: {
			return {
				...state,
				selectedAdAccount: action.payload
			};
		}

		case SharedActionTypes.LoadFacebookAdAccounts:

		case SharedActionTypes.LoadGoogleAdAccounts: {
			return {
				...state,
				cachedAdAccounts: {
					...state.cachedAdAccounts,
					errorCode: null,
					data: [],
					isLoaded: true
				}
			};
		}
		case SharedActionTypes.LoadCachedAdAccountsSuccess: {
			let adAccounts = _.cloneDeep(state.cachedAdAccounts?.data) || [];
			const sourceChannel = action.payload?.sourceChannel;
			if ((state.cachedAdAccounts && sourceChannel) || sourceChannel === SourceChannel.Facebook) {
				const duplicateIndex = state.cachedAdAccounts?.data?.findIndex(cachedAdAccount => cachedAdAccount.sourceChannel === sourceChannel);
				if (duplicateIndex && duplicateIndex !== -1) {
					adAccounts[duplicateIndex] = action.payload;
				} else {
					adAccounts.push(action.payload);
				}
			}

			return {
				...state,
				cachedAdAccounts: {
					...state.cachedAdAccounts,
					data: adAccounts
				}
			};
		}
		case SharedActionTypes.LoadCachedAdAccountsFailure: {
			return {
				...state,
				cachedAdAccounts: {
					...state.cachedAdAccounts,
					errorCode: action.payload
				}
			};
		}

		case SharedActionTypes.LoadSharedSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						data: action.payload,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}

		case SharedActionTypes.LoadSharedSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadAdsManagerSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}
		case SharedActionTypes.LoadAdsManagerSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						data: action.payload || initialSharedState.generalSettings.adsManager.data
					}
				}
			};
		}
		case SharedActionTypes.LoadAdsManagerSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadCampaignBuilderSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}
		case SharedActionTypes.LoadCampaignBuilderSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						data: action.payload || initialSharedState.generalSettings.campaignBuilder.data
					}
				}
			};
		}
		case SharedActionTypes.LoadCampaignBuilderSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.UpdateSharedSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}
		case SharedActionTypes.UpdateSharedSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.UpdateSharedSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.UpdateAdsManagerSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}
		case SharedActionTypes.UpdateAdsManagerSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.UpdateAdsManagerSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.UpdateCampaignBuilderSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.UpdateCampaignBuilderSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.UpdateCampaignBuilderSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.SetSharedSettingsState: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		}

		case SharedActionTypes.SetAdsManagerState: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.SetCampaignBuilderState: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadFacebookCampaignsMetadata: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookCampaignsMetadata: {
						...state.insights.facebookCampaignsMetadata,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.LoadFacebookCampaignsMetadataSuccess: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookCampaignsMetadata: {
						...state.insights.facebookCampaignsMetadata,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.LoadFacebookCampaignsMetadataFailure: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookCampaignsMetadata: {
						...state.insights.facebookCampaignsMetadata,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadFacebookAdsetsMetadata: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookAdsetsMetadata: {
						...state.insights.facebookAdsetsMetadata,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.LoadFacebookAdsetsMetadataSuccess: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookAdsetsMetadata: {
						...state.insights.facebookAdsetsMetadata,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.LoadFacebookAdsetsMetadataFailure: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookAdsetsMetadata: {
						...state.insights.facebookAdsetsMetadata,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadFacebookAdsMetadata: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookAdsMetadata: {
						...state.insights.facebookAdsetsMetadata,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.LoadFacebookAdsMetadataSuccess: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookAdsMetadata: {
						...state.insights.facebookAdsetsMetadata,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.LoadFacebookAdsMetadataFailure: {
			return {
				...state,
				insights: {
					...state.insights,
					facebookAdsMetadata: {
						...state.insights.facebookAdsetsMetadata,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadGoogleCampaignsMetadata: {
			return {
				...state,
				insights: {
					...state.insights,
					googleCampaignsMetadata: {
						...state.insights.googleCampaignsMetadata,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.LoadGoogleCampaignsMetadataSuccess: {
			return {
				...state,
				insights: {
					...state.insights,
					googleCampaignsMetadata: {
						...state.insights.googleCampaignsMetadata,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.LoadGoogleCampaignsMetadataFailure: {
			return {
				...state,
				insights: {
					...state.insights,
					googleCampaignsMetadata: {
						...state.insights.googleCampaignsMetadata,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadGoogleAdgroupsMetadata: {
			return {
				...state,
				insights: {
					...state.insights,
					googleAdgroupsMetadata: {
						...state.insights.googleAdsMetadata,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.LoadGoogleAdgroupsMetadataSuccess: {
			return {
				...state,
				insights: {
					...state.insights,
					googleAdgroupsMetadata: {
						...state.insights.googleAdsMetadata,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.LoadGoogleAdgroupsMetadataFailure: {
			return {
				...state,
				insights: {
					...state.insights,
					googleAdgroupsMetadata: {
						...state.insights.googleAdsMetadata,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadGoogleAdsMetadata: {
			return {
				...state,
				insights: {
					...state.insights,
					googleAdsMetadata: {
						...state.insights.googleAdsMetadata,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.LoadGoogleAdsMetadataSuccess: {
			return {
				...state,
				insights: {
					...state.insights,
					googleAdsMetadata: {
						...state.insights.googleAdsMetadata,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.LoadGoogleAdsMetadataFailure: {
			return {
				...state,
				insights: {
					...state.insights,
					googleAdsMetadata: {
						...state.insights.googleAdsMetadata,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadGoogleKeywordsMetadata: {
			return {
				...state,
				insights: {
					...state.insights,
					googleKeywordsMetadata: {
						...state.insights.googleKeywordsMetadata,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.LoadGoogleKeywordsMetadataSuccess: {
			return {
				...state,
				insights: {
					...state.insights,
					googleKeywordsMetadata: {
						...state.insights.googleKeywordsMetadata,
						data: action.payload as InsightsReportModel[]
					}
				}
			};
		}
		case SharedActionTypes.LoadGoogleKeywordsMetadataFailure: {
			return {
				...state,
				insights: {
					...state.insights,
					googleKeywordsMetadata: {
						...state.insights.googleKeywordsMetadata,
						errorCode: action.payload as string
					}
				}
			};
		}

		case SharedActionTypes.SaveUserDashboards: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						data: {
							...state.generalSettings.shared.data,
							userDashboards: action.payload
						}
					}
				}
			};
		}

		case SharedActionTypes.UpdatePublishStatus: {
			return {
				...state,
				publish: action.payload
			};
		}

		default:
			return state;
	}
}

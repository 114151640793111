<div class="form-input">
	<kendo-formfield>
		<kendo-textbox
			class="transparent"
			(valueChange)="setErrorMessage()"
			placeholder="{{ placeHolderTextBox }}"
			[formControl]="control"
			[style.width]="'100%'"
			[disabled]="disabled"
		>
			<ng-template kendoTextBoxSuffixTemplate>
				<i class="icon fas {{ icon }}"></i>
			</ng-template>
		</kendo-textbox>
		<kendo-formerror>{{ errorMessage }}</kendo-formerror>
	</kendo-formfield>
</div>

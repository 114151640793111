import { Modules } from '../../shared/permisions/enums/modules';
import { MiscellaneousPermissions } from '../../shared/permisions/enums/miscellaneous-permissions';

export const navLinksList = [
	{
		label: 'USERS',
		link: '/back-office/users',
		index: 0,
		module: Modules.Miscellaneous,
		permissions: [
			MiscellaneousPermissions.IsAdmin,
			MiscellaneousPermissions.IsAccountManagerAdmin,
			MiscellaneousPermissions.IsSalesAdmin,
			MiscellaneousPermissions.IsAccountManager
		]
	},
	{
		label: 'ROLES',
		link: '/back-office/role',
		index: 1,
		module: Modules.Miscellaneous,
		permissions: [MiscellaneousPermissions.IsAdmin, MiscellaneousPermissions.IsSales, MiscellaneousPermissions.IsSalesAdmin]
	},
	{
		label: 'OFFERS',
		link: '/back-office/offers',
		index: 2,
		module: Modules.Miscellaneous,
		permissions: [MiscellaneousPermissions.IsAdmin, MiscellaneousPermissions.IsSalesAdmin]
	},
	{
		label: 'BILLING & PAYMENT',
		link: '/back-office/billing',
		index: 3,
		module: Modules.Miscellaneous,
		permissions: [MiscellaneousPermissions.IsAdmin, MiscellaneousPermissions.IsSalesAdmin]
	}
];

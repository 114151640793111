import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserProfile } from 'src/app/creators-module/modules/settings/models/settings.model';
import { SettingsService } from 'src/app/creators-module/modules/settings/services/settings.service';
import { AuthenticationService } from 'src/app/creators-module/modules/authentication/services/authentication.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { KnowledgeBaseComponent } from 'src/app/creators-module/modules/knowledge-base/components/knowledge-base/knowledge-base.component';
import { MessageChatService } from 'src/app/creators-module/modules/messaging/services/message-chat.service';
import { SharedService } from '../services/shared.service';

@Component({
	selector: 'app-mobile-sidenav-header',
	templateUrl: './mobile-sidenav-header.component.html',
	styleUrls: ['./mobile-sidenav-header.component.scss']
})
export class MobileSidenavHeaderComponent implements OnInit {
	@Input() public title: string = 'Lolly';
	@Input() public isIconVisible: boolean = true;
	@Input() public matIcon: string = null;
	@Input() public showChatScreen: boolean = false;
	@Input() public isProfile: boolean = true;
	@Input() public headerContentSpaceBetween: boolean = false;
	@Input() public showBackButton: boolean = false;
	@Input() public isImgIconVisible: boolean = false;
	@Input() public imgIconPath: string = '';
	@Input() public numberOfFilters: number = 0;
	@Input() public messageProfileImg: boolean = false;
	private unsubscriber$ = new Subject<void>();
	@Output() private endButtonClick = new EventEmitter<void>();
	public profileData: UserProfile = null;
	@Output() private clickOnBack = new EventEmitter<boolean>();
	constructor(
		private settingsService: SettingsService,
		private router: Router,
		private authenticationService: AuthenticationService,
		private location: Location,
		private dialog: MatDialog,
		public msgService: MessageChatService,
		private sharedService: SharedService
	) {}

	public navConfig: any[] = [
		{
			title: 'My Profile',
			icon: 'assets/icons/mobile-nav/My Profile.svg',
			action: () => this.router.navigate(['/settings'])
		},
		{
			title: 'Billing',
			icon: 'assets/icons/mobile-nav/Billing.svg',
			action: () => this.router.navigate(['/settings/billing'])
		},
		{
			title: 'Integrations',
			icon: 'assets/icons/mobile-nav/Group 36336 (1).svg',
			action: () => this.router.navigate(['/settings/integrations'])
		},
		{
			title: 'Help & Support',
			icon: 'assets/icons/mobile-nav/Icon ionic-ios-help-circle-outline.svg',
			action: () => this.openKnowledgeBase()
		},
		{
			title: 'Logout',
			icon: 'assets/icons/mobile-nav/Subtraction 69.svg',
			action: () => this.authenticationService.signOut()
		}
	];

	public openKnowledgeBase(): void {
		this.dialog.open(KnowledgeBaseComponent, {
			width: '600px',
			height: '100%',
			position: {
				right: '0'
			},
			panelClass: ['matdialog-no-padding', 'slide', 'slideInRight']
		});
	}

	ngOnInit(): void {
		this.settingsService
			.profileDataListener()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(profileData => {
				if (profileData) {
					this.profileData = profileData;
				}
			});
	}

	public endButtonClicked(): void {
		this.endButtonClick.emit();
	}
	public goBack(value: boolean): void {
		this.clickOnBack.emit(value);
	}

	public back(): void {
		this.sharedService.showCloseBtn = false;
		this.location.back();
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { SocialConnectionService } from '../services/social-connection.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastNotificationService } from '../../toast-notification/toast-notification.service';

@Component({
	selector: 'app-connect-socials',
	templateUrl: './connect-socials.component.html',
	styleUrls: ['./connect-socials.component.scss']
})
export class ConnectSocialsComponent implements OnInit {
	@Input() public platform: string = 'instagram';
	@Input() public showDisconnectButton: boolean = false;

	public isConnected: boolean = false;
	public loading: boolean = false;
	private unsubscriber$ = new Subject<void>();

	constructor(private socialConnectionService: SocialConnectionService, private toastNotificationService: ToastNotificationService) {}

	ngOnInit(): void {
		this.validateConnection();
	}

	public validateConnection(): void {
		this.loading = true;
		this.isConnected = false;
		this.socialConnectionService
			.validate(this.platform)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res?.data === true) {
						this.isConnected = true;
					}
					this.loading = false;
				},
				err => {
					this.loading = false;
				}
			);
	}

	public onConnectClick(): void {
		this.loading = true;
		this.socialConnectionService
			.preinstall(this.platform)
			.pipe(take(1))
			.subscribe(
				res => {
					if(res?.data?.message) {
						window.location.href = res.data.message;
					} else {
						this.toastNotificationService.sendErrorToast('Some error occurred. Try again later');
					}
					this.loading = false;
				},
				err => {
					this.toastNotificationService.sendErrorToast('Some error occurred. Try again later');
					this.loading = false;
				}
			);
	}

	public onDisconnectClick(): void {
		this.loading = true;
		this.socialConnectionService
			.uninstall(this.platform)
			.pipe(take(1))
			.subscribe(
				res => {
					this.toastNotificationService.sendSuccessToast('Account disconnected successfully!');
					this.validateConnection();
				},
				err => {
					this.toastNotificationService.sendErrorToast('Some error occurred. Try again later');
					this.loading = false;
				}
			);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}

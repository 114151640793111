<button class="header_icons" mat-button matTooltip="{{ 'SETTINGS' | translate }}" matTooltipClass="button-custom-tooltip">
	<mat-icon [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
	<mat-menu #settingsMenu="matMenu" (closed)="saveOnClose()">
		<div fxFlex="100" fxLayout="row wrap">
			<div *ngIf="userSetting" class="m-l-20 m-t-20 m-r-20 m-b-20" fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
				<mat-form-field (click)="$event.stopPropagation()">
					<mat-select [(value)]="userSetting.language" [disableOptionCentering]="true">
						<mat-option value="English">{{ 'ENGLISH' | translate }}</mat-option>
						<mat-option value="French">{{ 'FRENCH' | translate }}</mat-option>
						<mat-option value="German">{{ 'GERMAN' | translate }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field (click)="$event.stopPropagation()">
					<mat-select [(value)]="userSetting.notificationDelivery" [disableOptionCentering]="true">
						<mat-option value="1">{{ 'EMAIL' | translate }}</mat-option>
						<mat-option value="2">{{ 'PUSH' | translate }}</mat-option>
						<mat-option value="3">{{ 'PHONE' | translate }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field (click)="$event.stopPropagation()">
					<mat-select [(value)]="userSetting.notificationFrequency" [disableOptionCentering]="true">
						<mat-option value="1">{{ 'HOURLY' | translate }}</mat-option>
						<mat-option value="2">{{ 'DAILY' | translate }}</mat-option>
						<mat-option value="3">{{ 'WEEKLY' | translate }}</mat-option>
						<mat-option value="4">{{ 'NEVER' | translate }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-checkbox (click)="$event.stopPropagation()" [checked]="userSetting.dataSharing" class="font-medium">
					{{ 'DATA_SHARING' | translate }}:
				</mat-checkbox>
			</div>
		</div>
	</mat-menu>
</button>

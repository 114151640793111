<div class="dialog-root">
	<mat-icon class="close" mat-dialog-close>close</mat-icon>

	<h4 class="title">{{ data?.title }}</h4>

	<div fxLayout="row" class="w-100 mt-xl align-items-center" fxLayoutGap="30px">
		<input class="input-shadow" placeholder="{{ placeholder }}" (keydown.enter)="onSave()" [(ngModel)]="input" />
		<button class="outlined-btn" (click)="onSave()" *ngIf="!data.isMobileView">+ Add</button>
	</div>
	<button class="accept" *ngIf="data.isMobileView" (click)="onSave()">
		Update
	</button>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ValidatorMessages } from '../validatorMessagesInputs';
import { ErrorMessageService } from '../error-message.service';
import { InputTypeEnum } from '../input-type.enum';

@Component({
	selector: 'app-line-input-text',
	templateUrl: './line-input-text.component.html',
	styleUrls: ['./line-input-text.component.scss']
})
export class LineInputTextComponent implements OnInit {
	@Input() control: UntypedFormControl | AbstractControl;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() label: string;
	@Input() placeholder: string;
	@Input() disabledInput: boolean;
	@Input() inputType = InputTypeEnum.Text;
	@Input() currencyStr: string;
	@Input() decimals: number;

	@Output() inputFocusOut = new EventEmitter();

	public isDestinationFocused: boolean;
	public isInputTypeText: boolean;
	public isInputTypeCurrency: boolean;
	public errorMessage: string;

	constructor(public errorMessageService: ErrorMessageService) {}

	ngOnInit() {
		this.isInputTypeText = this.inputType === InputTypeEnum.Text;
		this.isInputTypeCurrency = this.inputType === InputTypeEnum.Currency;
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public onFocusOut(): void {
		this.setErrorMessage();
		this.inputFocusOut.emit();
	}
}

import { Action, createReducer, on } from '@ngrx/store';
import { loginFailure, loginSuccess } from './auth.actions';

export interface State {
	token: string;
	error?: string;
}

export const initialState: State = {
	token: null
};

const _authReducer = createReducer(
	initialState,
	on(loginSuccess, state => {
		return {
			...state,
			token: state.token,
			error: null
		};
	}),
	on(loginFailure, state => {
		return {
			...state,
			error: state.error,
			token: null
		};
	})
);

export function authReducer(state: State, action: Action) {
	return _authReducer(state, action);
}

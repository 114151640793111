<div class="master-table-wrapper" #wrapper>
	<div class="cancel-div">
		<img src="assets/icons/cancel-button.svg" (click)="onCancelExpandButton()" class="cancel-button" *ngIf="isFullScreenToggled" />
	</div>
	<div class="header-wrapper" *ngIf="isIconsBarVisible" [ngClass]="{ 'tabs-attached': areTabsAttached }">
		<div class="left-side-container">
			<ng-content select="[left-side-content]"></ng-content>
		</div>

		<div class="right-side-container">
			<ng-content select="[right-side-content]"></ng-content>

			<div class="header-button-option" (click)="onFilterButton()" *ngIf="buttonsConfiguration.isFilterButtonShown">
				<img src="assets/icons/filter-icon.svg" />
				<span>Filters</span>
			</div>

			<div class="header-button-option" (click)="onColumnButton()" *ngIf="buttonsConfiguration.isColumnButtonShown">
				<img src="assets/icons/columns-icon.svg" />
				<span>Columns</span>
			</div>

			<div class="header-button-option" (click)="onExpandButton()" *ngIf="buttonsConfiguration.isExpandButtonShown">
				<img src="assets/icons/expand-icon.svg" />
				<span>Expand</span>
			</div>

			<div class="header-button-option" (click)="onExportButton()" *ngIf="buttonsConfiguration.isExportButtonShown">
				<img src="assets/icons/ag-grid/export-black.svg" />
				<span>Export</span>
			</div>
		</div>
	</div>

	<div class="actions-bar" *ngIf="isActionsBarVisible">
		<ng-content select="[actions-content]"></ng-content>
	</div>
	<ag-grid-angular
		*ngIf="columnDefs"
		style="min-width: 31.25rem;"
		[ngStyle]="{ height: 'calc(100% - ' + heightNotOccupiedByAgGrid + 'px)' }"
		agGridDirective
		id="myGrid"
		class="ag-theme-alpine"
		[rowData]="rowData"
		[stopEditingWhenGridLosesFocus]="isEditingStoppedWhenGridLosesFocus"
		[columnDefs]="columnDefs"
		[rowModelType]="rowModelType"
		[defaultColDef]="defaultColDef"
		[suppressClickEdit]="isEditClickSuppressed"
		[suppressCellSelection]="isCellSelectionSuppressed"
		[rowBuffer]="rowBuffer"
		[cacheBlockSize]="numberOfRowsReturnedByServer"
		[paginationPageSize]="numberOfRowsReturnedByServer ? numberOfRowsReturnedByServer : paginationPageSize"
		[context]="context"
		[frameworkComponents]="frameworkComponents"
		[rowSelection]="rowSelection"
		[columnTypes]="columnTypes"
		[pinnedTopRowData]="topPinnedRowData"
		[pinnedBottomRowData]="getbottomPinnedRowData()"
		[treeData]="isTreeDataUsed"
		[enableCharts]="areChartsEnabled"
		[isRowSelectable]="isRowSelectable"
		[pagination]="true"
		[enableRangeSelection]="true"
		[suppressRowClickSelection]="true"
		[sideBar]="isSideBarShown"
		[getRowStyle]="getRowStyle"
		[getRowClass]="getRowClass"
		(gridReady)="onGridReady($event)"
		(rowSelected)="onSelectionChanged($event)"
		(filterChanged)="onFilterChanged($event)"
		(sortChanged)="onSortChanged($event)"
		(columnVisible)="onToggleColumn()"
		(paginationChanged)="onPaginationChanged($event)"
		(firstDataRendered)="onFirstDataRendered($event)"
		[headerHeight]="58"
	></ag-grid-angular>
	<div class="page-size-wrapper" *ngIf="canPageSizeBeChanged">
		<select (change)="onPageSizeChanged($event.target)" class="page-size-select">
			<option *ngFor="let pageSize of paginationOptions" [value]="pageSize" [selected]="paginationPageSize === pageSize">{{ pageSize }} per page</option>
		</select>
	</div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InfluencerAuthenticationService } from '../services/influencer-auth.service';

@Component({
	selector: 'app-validate-google-login',
	templateUrl: './validate-google-login.component.html',
	styleUrls: ['./validate-google-login.component.scss']
})
export class ValidateGoogleLoginComponent implements OnInit, OnDestroy {
	private unsubscriber$ = new Subject<void>();
	constructor(private route: ActivatedRoute, private authService: InfluencerAuthenticationService, private router: Router) {
		if (router.url.includes('tiktok')) {
			this.route.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
				const code = params['code'];
				const state = params['state'];
				parent.postMessage(JSON.stringify({ code: code, state: state }), 'https://localhost:4200/');
				this.authService
					.connectToTiktok(code, state)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(res => {
						window.close();
					});
			});
			return;
		}
		this.route.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			const code = params['code'];
			const state = params['state'];
			parent.postMessage(JSON.stringify({ code: code, state: state }), 'https://localhost:4200/');
			this.authService
				.connectToGoolge(code, state)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					res => {
						window.close();
					},
					err => {
						window.alert('Error connecting your account. Please close the window and try again.');
					}
				);
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}

import { InsightsMetadataBaseService } from '../services/insights-metadata-base.service';
import { InsightsReportModel } from './models/insights-report.model';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { map } from 'rxjs/operators';

export class GoogleInsightsMetadataService extends InsightsMetadataBaseService {
	constructor(http: HttpClient, tableName: string) {
		super(http, tableName);
	}

	public getInsightsMetadataModel(useCache: boolean = false): Observable<InsightsReportModel[]> {
		if (useCache && this.modelInstances) {
			return of(this.modelInstances);
		} else {
			return this.getInsightsMetadata().pipe(
				map((reports: InsightsReportModel[]) => {
					reports.forEach(report => {
						report.columns.forEach(item => (item.id = '__' + item.id));
					});
					return reports;
				})
			);
		}
	}

	private getInsightsMetadata(): Observable<InsightsReportModel[]> {
		return this.http.get<InsightsReportModel[]>(`${BaseApiUrl.GoogleInsights}views/${this.tableName}`);
	}
}

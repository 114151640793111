<mat-card class="u-h-100">
	<mat-card-header>
		<mat-card-title class="invoice-card-title">Invoice History</mat-card-title>
		<span *ngIf="dataSource?.length > 0" (click)="viewAllInvoice()" class="view-all">View All</span>
	</mat-card-header>

	<mat-card-content>
		<mat-table *ngIf="dataSource?.length > 0" [dataSource]="dataSource" class="mat-elevation-z8 table-view invoice-table">
			<!-- invoice id Column -->
			<ng-container matColumnDef="id">
				<div *matHeaderCellDef class="table-head id-head">Invoice ID</div>
				<div *matCellDef="let element" class="table-col-new id-col">
					<span (click)="viewInvoiceDetails(element.id)" class="blue-link">ID {{ element.id }}</span>
				</div>
			</ng-container>

			<!-- Date Column -->
			<ng-container matColumnDef="date">
				<div *matHeaderCellDef class="table-head">Date</div>
				<div *matCellDef="let element" class="table-col-new">{{ element.date | date: 'dd-MM-yyyy' }}</div>
			</ng-container>

			<!-- Weight Column -->
			<ng-container matColumnDef="total">
				<div *matHeaderCellDef class="table-head">Amount</div>
				<div *matCellDef="let element" class="table-col-new">{{ convertCurrency(element.currencyCode) }} {{ element.total }}</div>
			</ng-container>

			<!-- Symbol Column -->
			<ng-container matColumnDef="status">
				<div *matHeaderCellDef class="table-head">Status</div>
				<div *matCellDef="let element" class="status-view table-col-new">
					<span class="status-dot" [ngClass]="element.status === 'paid' ? 'status-paid' : 'status-unpaid'"></span>
					<span>{{ element.status === 'paid' ? 'Paid' : 'Unpaid' }}</span>
				</div>
			</ng-container>

			<!-- Actions Column -->
			<ng-container matColumnDef="actions">
				<div *matHeaderCellDef class="table-head actions-head">Actions</div>
				<div *matCellDef="let element" class="table-col-new actions-col">
					<div [matMenuTriggerFor]="actionList">
						<img class="dots" src="{{ imagesPathEnum.ActionDots }}" />
						<mat-menu #actionList="matMenu" class="custom_mat_menu">
							<button *ngIf="element.status !== 'paid'" (click)="payNow(element)" mat-menu-item>
								<img class="paynow" src="{{ imagesPathEnum.PaymentMethod }}" />
								Pay Now
							</button>
							<button (click)="download(element.id)" mat-menu-item>
								<img class="download" src="{{ imagesPathEnum.Download }}" />
								Download
							</button>
							<button (click)="shareInvoice(element)" mat-menu-item>
								<img class="share" src="{{ imagesPathEnum.Shared }}" />
								Share
							</button>
						</mat-menu>
					</div>
				</div>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns" class="invoices-header-row"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns" class="invoices-table-row"></mat-row>
		</mat-table>

		<div *ngIf="dataSource?.length === 0" class="invoice-table-no-data">
			<span class="table-head id-head">Invoice ID</span>
			<span class="table-head">Date</span>
			<span class="table-head">Amount</span>
			<span class="table-head">Status</span>
			<span class="table-head actions-head">Actions</span>
		</div>
		<div class="no-invoice-note" *ngIf="dataSource?.length === 0">You have no Invoices issued so far</div>
	</mat-card-content>
</mat-card>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EmailDataTable } from '../../back-office/models/email-data-table';
import { EmailsDetailsResponse } from '../../back-office/models/email-details.model';
import { Configuration } from '../../back-office/models/configuration.model';
import { UserSetting } from '../../back-office/models/user-setting.model';
import { Card } from '../../back-office/models/offers-table';
import { OfferModel } from '../../back-office/models/offer-model';
import { ClientModel } from '../../back-office/models/client-model';
import { TranslateService } from '@ngx-translate/core';
import { BaseApiUrl } from '../base-api-urls';
import { BaseOffersDto, Country } from './back-office.models';
import { FiledCreditCard, SourceCardToken } from '../../user-management/components/filed-cards/set-creditcard.models';
import { ContactStatusEnum } from 'src/app/back-office/models/contact-status.enum';
import { BackOfficeEmployees } from '../../back-office/models/back-office-employees-enum';
import { BillingAddress } from 'src/app/user-management/shared/billing-and-payment/billing-and-payment.models';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import { AccountManagersInterface } from '../../back-office/models/account-managers.interface';
import { AssignedBOsInterface } from '../../back-office/models/assigned-bos.interface';
import { BillingPlan } from 'src/app/user-management/models/billing-plan.interface';
import { PaymentMethod } from 'src/app/authentication/set-credit-card/interfaces/payment-no-pw.interface';
import { Subscribe } from '../../authentication/sign-up/subscribe.interface';
import { BillingsTableInterface } from '../../back-office/models/billings-table.interface';

@Injectable({
	providedIn: 'root'
})
export class BackOfficeService {
	private readonly baseURL = `${BaseApiUrl.Users}`;

	constructor(protected http: HttpClient) {}

	public getSettings(id: number): Observable<UserSetting> {
		return this.http.get<UserSetting>(`${this.baseURL}userSettings/get/${id}`);
	}

	public editSettings(request: UserSetting): Observable<void> {
		const body = {
			id: request.id,
			languageName: request.language,
			dataSharing: request.dataSharing,
			notificationDelivery: +request.notificationDelivery,
			notificationFrequency: +request.notificationFrequency
		};

		return this.http.put<void>(`${this.baseURL}userSettings/edit`, body as any);
	}

	public getAllBillings(): Observable<BillingsTableInterface[]> {
		return this.http.get<BillingsTableInterface[]>(`${BaseApiUrl.Subscription}billings`);
	}

	public getAllCards(): Observable<FiledCreditCard[]> {
		return this.http.get<FiledCreditCard[]>(`${BaseApiUrl.Subscription}paymentcards/my`);
	}

	public makeCardPrimary(cardId: string): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}paymentCards/${cardId}/default`, {});
	}

	public deletePaymentCard(cardId: string): Observable<void> {
		return this.http.delete<void>(`${BaseApiUrl.Subscription}paymentCards/${cardId}`, {});
	}

	public addNewPaymentCard(cardToken: string, makePrimary: boolean): Observable<boolean> {
		return this.http.post<boolean>(`${BaseApiUrl.Subscription}paymentCards`, { SourceCardToken: cardToken, MakePrimary: makePrimary });
	}

	public getBillingPlan(): Observable<BillingPlan> {
		return this.http.get<BillingPlan>(`${BaseApiUrl.Subscription}billings/my/billing-plan`);
	}

	public getAccountManagers(): Observable<AccountManagersInterface[]> {
		return this.http.get<AccountManagersInterface[]>(`${this.baseURL}backOfficeUsers/accountManagers`);
	}

	public getUnmanagedBOs(id: number): Observable<AccountManagersInterface[]> {
		return this.http.get<AccountManagersInterface[]>(`${this.baseURL}backOfficeUsers/accountManagers/${id}/unmanagedBOs`);
	}

	public getManagedBOs(id: number): Observable<AccountManagersInterface[]> {
		return this.http.get<AccountManagersInterface[]>(`${this.baseURL}backOfficeUsers/accountManagers/${id}/managedBOs`);
	}

	public updateAssignedBOs(id: number, BOs: AssignedBOsInterface): Observable<void> {
		return this.http.put<void>(`${this.baseURL}backOfficeUsers/accountManagers/${id}`, BOs);
	}

	public getContactId(contactId: number): Observable<ClientModel> {
		return this.http.get<ClientModel>(`${this.baseURL}contacts?contactId=${contactId}`);
	}

	public createOffer(attach: OfferModel): Observable<void> {
		return this.http.post<void>(`${this.baseURL}offers`, attach);
	}

	public addCardForCurrentAccount(cardToken: Card): Observable<void> {
		const body = {
			sourceCardToken: cardToken
		};

		return this.http.post<void>(`${this.baseURL}contacts/cardinfo`, body);
	}

	public addCard(cardToken: Card, id: number): Observable<void> {
		const body = {
			sourceCardToken: cardToken
		};

		return this.http.post<void>(`${this.baseURL}contacts/${id}/cardinfo`, body);
	}

	public attachOffer(offerId: number): Observable<void> {
		return this.http.put<void>(`${this.baseURL}offers/${offerId}`, {});
	}

	public activate(id: number): Observable<boolean> {
		return this.http.post<boolean>(`${this.baseURL}accounts`, { ContactId: id });
	}

	public deactivate(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}contacts/${id}/accounts`);
	}

	public deleteCard(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}contacts/card/${id}`);
	}

	public changePaymentMethodForContact(contactId: number, paymentMethodId: number): Observable<void> {
		return this.http.put<void>(`${this.baseURL}contacts/all/${contactId}/paymentMethod`, {
			clientPaymentMethod: paymentMethodId
		});
	}

	public updateBaseOfferLandingPage(offerId: number, status: boolean): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}baseOffers/${offerId}/landingPageSubscriptionAllowed`, {
			IsLandingPageSubscriptionAllowed: status
		});
	}

	public updateFreemiumOfferLandingPage(offerId: number, status: boolean): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}freemiumOffers/${offerId}/landingPageSubscriptionAllowed`, {
			IsLandingPageSubscriptionAllowed: status
		});
	}

	public editBaseOffer(baseOfferId: number, baseOffer: BaseOffersDto): Observable<boolean> {
		return this.http.put<boolean>(`${this.baseURL}baseOffers/${baseOfferId}`, baseOffer);
	}

	public addFirstTimeCard(firstTimeCardDto: PaymentMethod): Observable<boolean> {
		return this.http.post<boolean>(`${BaseApiUrl.Subscription}paymentCards/withPW`, firstTimeCardDto);
	}

	public addCardFreeExpired(firstTimeCardDto: PaymentMethod): Observable<boolean> {
		return this.http.post<boolean>(`${BaseApiUrl.Subscription}paymentCards/freeUseExpired`, firstTimeCardDto);
	}

	public addCardNoPw(firstTimeCardDto: PaymentMethod): Observable<boolean> {
		return this.http.post<boolean>(`${BaseApiUrl.Subscription}paymentCards/noPW`, firstTimeCardDto);
	}

	public getBusinessOwnerCards(): Observable<FiledCreditCard[]> {
		return this.http.get<FiledCreditCard[]>(`${this.baseURL}paymentcards/my`);
	}

	public getAllCountriesList(): Observable<Country[]> {
		return this.http.get<Country[]>(`${BaseApiUrl.Subscription}countries`);
	}

	public getBillingAccountDetails(): Observable<BillingAddress> {
		return this.http.get<BillingAddress>(`${BaseApiUrl.Subscription}accounts`);
	}

	public getInvoiceHistory(): Observable<InvoiceHistory[]> {
		return this.http.get<InvoiceHistory[]>(`${BaseApiUrl.Subscription}billings/my/invoices`);
	}

	public payInvoice(chargebeeInvoiceId: string): Observable<InvoiceHistory[]> {
		return this.http.put<InvoiceHistory[]>(`${BaseApiUrl.Subscription}billings/my/invoices/pay-invoice`, {
			ChargebeeInvoiceId: chargebeeInvoiceId
		});
	}

	public getAllCountries(): Observable<Country[]> {
		return this.http.get<Country[]>(`${BaseApiUrl.Subscription}countries`);
	}

	public downloadInvoiceHistoryPdf(chargebeeInvoiceId: string, inlineDisplay: string) {
		return this.http.get(`${BaseApiUrl.Subscription}billings/my/invoices/pdf`, {
			params: {
				chargebeeInvoiceId: chargebeeInvoiceId,
				inlineDisplay: inlineDisplay
			}
		});
	}

	public getUserState(userId: number): Observable<number> {
		return of(0);
		// return this.http.get<number>(`${BaseApiUrl.Users}users/${userId}/user-account-status`);
	}

	public subscribeToOfer(details: Subscribe): Observable<void> {
		return this.http.post<void>(`${BaseApiUrl.Subscription}subscriptions/subscribeToOffer`, details);
	}

	public resendActivationEmail(email: string): Observable<void> {
		return this.http.put<void>(`${this.baseURL}users/resend-first-password`, { userEmail: email });
	}
}

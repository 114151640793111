import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppCustomPreloader } from './app-routing-loader';
import { ValidateGoogleLoginComponent } from './creators-module/modules/authentication/validate-google-login/validate-google-login.component';
import { StripeLandingComponent } from './creators-module/modules/settings/components/stripe-landing/stripe-landing.component';
import { NoResourceComponent } from './shared/404/no-resource.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { SidenavGroupComponent } from './sidenav/sidenav/sidenav-group.component';
import { NotificationsMainComponent } from './sidenav/component-pieces/notifications-main/notifications-main.component';

const routes: Routes = [
	{
		path: 'authentication',
		loadChildren: () => import('./creators-module/modules/authentication/creators-authentication.module').then(m => m.CreatorsAuthenticationModule)
	},
	{
		path: '',
		canActivate: [AuthenticationGuard],
		component: SidenavGroupComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./creators-module/creators-module.module').then(m => m.CreatorsModuleModule)
			},
			{
				path: 'settings',
				loadChildren: () => import('./creators-module/modules/settings/settings.module').then(m => m.SettingsModule),
				data: { preload: true } // Custom property we will use preload this module instead of lazy load
			},
			{
				path: 'notifications',
				component: NotificationsMainComponent
			}
		]
	},
	{ path: 'validate-accounts/validate-google-login', component: ValidateGoogleLoginComponent },
	{ path: 'validate-accounts/validate-tiktok-login', component: ValidateGoogleLoginComponent },
	{ path: 'stripeLanding', component: StripeLandingComponent },
	{ path: '404', component: NoResourceComponent },
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: AppCustomPreloader })],
	exports: [RouterModule]
})
export class AppRoutingModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class SocialConnectionService {
	constructor(private http: HttpClient) {}

	public validate(platform: string): Observable<{ data: boolean }> {
		return this.http.get<{ data: boolean }>(`${BaseApiUrl.SocialMessengerPython}${platform}/validate`);
	}

	public preinstall(platform: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}${platform}/preinstall`);
	}

	public uninstall(platform: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}${platform}/uninstall`);
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SavedAudience } from '../../_models/saved-audience/saved-audience';
import { BaseApiUrl } from '../base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class AudienceService {
	private readonly baseURL = BaseApiUrl.Audience + 'audiences';

	constructor(private http: HttpClient) {}

	public getAll(selectedAccounts: any, types?: number[]): Observable<any[]> {
		let endpoint = this.baseURL + '?adAccountFacebookIds=' + selectedAccounts.id;
		if (types) {
			endpoint += '&types=' + types.join(',');
		}
		return this.http.get<any[]>(endpoint);
	}

	public createAudience(saveAudienceInterface: SavedAudience): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Audience}audiences`, saveAudienceInterface);
	}

	public deleteAudience(audienceId: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}/${audienceId}`);
	}

	public getSavedAudiencesByAdAccounts(adAccounts: any): Observable<SavedAudience[]> {
		if (!adAccounts) {
			console.error('No adAccounts provided for getSavedAudiencesByAdAccounts()');
			return;
		}
		return this.http.get<SavedAudience[]>(`${this.baseURL}?adAccountFacebookIds=${adAccounts.id}&types=2`);
	}

	public getAudienceSize(audience: any, id: string): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}audience-size/${id}`, audience);
	}

	public saveAudience(body: any): Observable<void> {
		return this.http.post<void>(`${BaseApiUrl.Audience}audiences/`, body);
	} // TODO: when endpoint will return the id eliminate update function of Saved Audiences

	public editAudience(body: any): Observable<void> {
		const id = body.details.savedAudienceId;
		return this.http.put<void>(`${BaseApiUrl.Audience}audiences/${id}`, body);
	}
}

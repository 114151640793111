<div class="search-main-wrapper" #mainContainer>
	<div>
		<mat-form-field>
			<input
				#searchInput
				(click)="showList()"
				(keydown)="filterItemList($event)"
				[placeholder]="searchPlaceholder"
				[title]="(singleSelection && selectedItemList[0] && selectedItemList[0][filterBy]) || ''"
				[value]="(singleSelection && selectedItemList[0] && selectedItemList[0][filterBy]) || ''"
				autocomplete="off"
				class="text-wrap"
				matInput
				[disabled]="disableInput"
			/>
			<!--      <div class="spinner" [hidden]="!showAudienceSpinner">-->
			<!--        <mat-spinner diameter="15"></mat-spinner>-->
			<!--      </div>-->
			<div *ngIf="!singleSelection && selectedItemList.length" class="selected-number" title="Selected items">
				(
				<ng-container *ngIf="selectedItemList.length < 10">{{ selectedItemList.length }}</ng-container>
				<ng-container *ngIf="selectedItemList.length > 9">9+</ng-container>
				)
			</div>
		</mat-form-field>
	</div>

	<div *ngIf="showMenu" [formGroup]="form" [ngClass]="{ 'no-filter-list': !groupingFilterList.length }" class="search-content mat-tab-campaign">
		<div class="displayed-items">
			<mat-tab-group>
				<ng-container *ngIf="!groupingFilterList.length">
					<mat-tab>
						<ng-template matTabContent>
							<mat-selection-list (selectionChange)="setSelectedItemList($event)" formControlName="selection">
								<mat-list-option *ngFor="let item of itemList | searchText: filterSearch:filterBy" [value]="item[uniqueKey]">
									<div class="first-col" title="Audience name">
										<span class="one_line_text">{{ item[filterBy] }}</span>
									</div>
								</mat-list-option>
							</mat-selection-list>
						</ng-template>
					</mat-tab>
				</ng-container>

				<ng-container *ngIf="groupingFilterList.length">
					<mat-tab *ngFor="let groupingFilterListItem of groupingFilterList" [label]="groupingFilterListItem.label">
						<ng-template matTabContent>
							<mat-selection-list (selectionChange)="setSelectedItemList($event)" formControlName="selection">
								<mat-list-option
									*ngFor="
										let item of itemList
											| searchText: groupingFilterListItem.filterValue:groupingFilterListItem.filterProperty
											| searchText: filterSearch:filterBy
									"
									[disabled]="checkIfDisabled(item)"
									[selected]="checkSelected(item, selectedItemList)"
									[value]="item[uniqueKey]"
								>
									<ng-container *ngIf="columnDetails.length">
										<div
											*ngFor="let col of columnDetails; index as i"
											[ngClass]="i === 0 ? 'first-col' : i < columnDetails.length - 1 ? 'first-col m-l-10' : 'second-col m-l-10'"
											class="one_line_text"
											title="{{ col.title }} : {{ item[col.displayValue] }}"
										>
											<span class="one_line_text">{{ item[col.displayValue] }}</span>
										</div>
									</ng-container>

									<ng-container *ngIf="!columnDetails.length">
										<div [title]="filterBy" class="one_line_text first-col">
											<span class="one_line_text">{{ item[filterBy] }}</span>
										</div>
									</ng-container>
								</mat-list-option>
							</mat-selection-list>
						</ng-template>
					</mat-tab>
				</ng-container>
			</mat-tab-group>
		</div>
		<div *ngIf="selectedItemList.length" class="selected-items">
			<button (click)="deselectAllSelectedItems()" mat-button>Deselect all</button>

			<div class="selected-item-inner">
				<div *ngFor="let selectedItem of selectedItemList; let index = index" class="inner-scroll">
					<div (click)="removeSelectedItem(index)" title="Remove item">
						<img src="assets/icons/close-icon.svg" alt="close-icon" />
					</div>

					<div class="text">{{ selectedItem[filterBy] }}</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="signin-con">
	<div class="inner-wrapper">
		<img class="logo" src="assets/creators/authentication/lolly-logo.svg" alt="" />

		<div class="d-flex flex-column header">
			<h4 *ngIf="action === true">Congratulations!</h4>
			<p class="title-text">We've notified {{ brandName }} of your {{ action === true ? 'acceptance' : 'rejection' }}.</p>
			<p class="title-text">Meanwhile, connect social accounts to get more offers from other brands.</p>
		</div>

		<div class="d-flex flex-column connects">
			<app-connect-socials [platform]="'instagram'"></app-connect-socials>
			<app-connect-socials [platform]="'tiktok'"></app-connect-socials>
			<app-connect-socials [platform]="'youtube'"></app-connect-socials>
		</div>

		<div class="footer">
			<button class="primary-btn" (click)="onContinue()" *ngIf="true">Continue</button>
		</div>
	</div>
</div>

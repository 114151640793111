import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, TemplateRef, ViewChildren } from '@angular/core';

@Component({
	selector: 'app-generic-tabs',
	templateUrl: './generic-tabs.component.html',
	styleUrls: ['./generic-tabs.component.scss']
})
export class GenericTabsComponent<T, S> implements OnInit, AfterViewInit {
	@Input() public templateReferences: TemplateRef<T>[];
	@Input() public templatesContexts: Array<S>;
	@Output() public changeTab = new EventEmitter<number>();
	@ViewChildren('tabs') public tabsReferences: QueryList<ElementRef<HTMLDivElement>>;

	public selectedTab = 0;

	constructor(private renderer: Renderer2) {}

	public ngOnInit(): void {}

	public ngAfterViewInit(): void {
		const tabs = this.tabsReferences.toArray();
		tabs.forEach(tab => {
			this.renderer.setStyle(
				tab.nativeElement,
				'width',
				this.templateReferences.length > 2 ? `calc((100% /${this.templateReferences.length}) - 10px)` : '33%'
			);
		});
	}

	public onClick(selectedTabIndex: number): void {
		this.selectedTab = selectedTabIndex;
		this.changeTab.emit(selectedTabIndex);
	}
}

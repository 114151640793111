import {
	AccountStateModel,
	BillingStateModel,
	MiscStateModel,
	PermissionStateModel,
	SubscriptionStateModel,
	UsersStateModel,
	UserStateModel
} from './state.model';
import { NullDataInterface } from '../../shared/state/state-entities/data-interface.model';
import { BillingImagesEnum, BillingLabelsEnum, BillingLinksEnum, BillingTabEnum } from '../shared/billing-and-payment/billing-tab.enum';
import { SettingsPageIndexEnum, SettingsPageLabelsEnum, SettingsPageUrlsEnum } from '../shared/enums/navigation.enum';
import _ from 'lodash';
import { MiscellaneousPermissions } from '../../shared/permisions/enums/miscellaneous-permissions';

export const PermissionsInitialState: PermissionStateModel = {
	keysPermissions: _.cloneDeep(NullDataInterface),
	allPermissions: [
		{
			id: 1,
			title: 'email',
			description: 'Grants Lolly app permission to access your primary email address.',
			faceBookCategoryName: 'General',
			filedPermisionCategoryId: 0,
			isChecked: false
		},
		{
			id: 2,
			title: 'public_profile',
			description: 'Grants Lolly app permission to access your public profile.',
			faceBookCategoryName: 'General',
			filedPermisionCategoryId: 0,
			isChecked: false
		},
		{
			id: 3,
			title: 'business_management',
			description: 'Grants Lolly app permission to read and write with the Business Manager API.',
			faceBookCategoryName: 'General',
			filedPermisionCategoryId: 0,
			isChecked: false
		},
		{
			id: 4,
			title: 'read_insights',
			description: 'Grants Lolly app permissions to read-only access of the Insights data for Pages, Apps, and web domains that you own.',
			faceBookCategoryName: 'General',
			filedPermisionCategoryId: 0,
			isChecked: false
		},
		{
			id: 5,
			title: 'ads_management',
			description:
				'Grants Lolly app the ability to both read and manage the ads for ad accounts Lolly owns or have been granted access to by the owner or owners of the ad accounts through this permission. By default, Lolly app may only access ad accounts that are owned by admins of the app when in developer mode.',
			faceBookCategoryName: 'AdsAndPages',
			filedPermisionCategoryId: 1,
			isChecked: false
		},
		{
			id: 6,
			title: 'ads_read',
			description:
				'Grants Lolly app access to the Ads Insights API to pull ads report information for ad accounts Lolly owns or have been granted access to by the owner or owners of other ad accounts through this permission.',
			faceBookCategoryName: 'AdsAndPages',
			filedPermisionCategoryId: 1,
			isChecked: false
		},
		{
			id: 7,
			title: 'manage_pages',
			description:
				'Grants Lolly app permission to retrieve Page Access Tokens for the Pages and Apps that the app user administers. Apps that allow users to publish as a Page must also have the publish_pages permission.',
			faceBookCategoryName: 'AdsAndPages',
			filedPermisionCategoryId: 1,
			isChecked: false
		},
		{
			id: 8,
			title: 'pages_show_list',
			description: 'Grants Lolly app access to show the list of the Pages that you manage.',
			faceBookCategoryName: 'AdsAndPages',
			filedPermisionCategoryId: 1,
			isChecked: false
		},
		{
			id: 9,
			title: 'instagram_basic',
			description: 'Grants Lolly app permission to read Instagram Accounts users of the app manage.',
			faceBookCategoryName: 'Instagram',
			filedPermisionCategoryId: 2,
			isChecked: false
		},
		{
			id: 10,
			title: 'instagram_manage_insights',
			description: 'Grants Lolly app permission to read insights of Instagram Accounts users of the app manage.',
			faceBookCategoryName: 'Instagram',
			filedPermisionCategoryId: 2,
			isChecked: false
		},
		{
			id: 11,
			title: 'pages_read_engagement',
			description: 'Grants Lolly app permission to read content (posts, photos, videos, events) posted by your Page.',
			faceBookCategoryName: 'Instagram',
			filedPermisionCategoryId: 0,
			isChecked: false
		},
		{
			id: 12,
			title: 'catalog_management',
			description: 'Grants Lolly app permission to create, read, update and delete business-owned product catalogs that you are an admin of.',
			faceBookCategoryName: 'Instagram',
			filedPermisionCategoryId: 0,
			isChecked: false
		}
	],
	generalPermissions: [],
	adsAndPagesPermissions: [],
	instagramPermissions: [],
	permissionsStatus: {
		touched: false
	}
};

export const MiscInitialState: MiscStateModel = {
	navigation: {
		userManagement: [
			{
				index: SettingsPageIndexEnum.MyAccount,
				label: SettingsPageLabelsEnum.MyAccount,
				link: SettingsPageUrlsEnum.MyAccount,
				guard: null
			},
			{
				index: SettingsPageIndexEnum.UserManagement,
				label: SettingsPageLabelsEnum.UserManagement,
				link: SettingsPageUrlsEnum.UserManagement,
				guard: [MiscellaneousPermissions.IsBusinessOwner]
			},
			{
				index: SettingsPageIndexEnum.MySubscription,
				label: SettingsPageLabelsEnum.MySubscription,
				link: SettingsPageUrlsEnum.MySubscription,
				guard: [MiscellaneousPermissions.IsBusinessOwner]
			},

			{
				index: SettingsPageIndexEnum.BillingAndPayment,
				label: SettingsPageLabelsEnum.BillingAndPayment,
				link: SettingsPageUrlsEnum.BillingAndPayment,
				guard: [MiscellaneousPermissions.IsBusinessOwner]
			},
			{
				index: SettingsPageIndexEnum.ChangePassword,
				label: SettingsPageLabelsEnum.ChangePassword,
				link: SettingsPageUrlsEnum.ChangePassword,
				guard: null
			}
		],
		billingAndPayment: [
			{
				index: BillingTabEnum.PaymentMethod,
				label: BillingLabelsEnum.PaymentMethod,
				link: BillingLinksEnum.PaymentMethod,
				image: BillingImagesEnum.PaymentMethod
			},
			{
				index: BillingTabEnum.AddNewCard,
				label: BillingLabelsEnum.AddNewCard,
				link: BillingLinksEnum.AddNewCard,
				image: BillingImagesEnum.AddNewCard
			},
			{
				index: BillingTabEnum.ViewInvoices,
				label: BillingLabelsEnum.ViewInvoices,
				link: BillingLinksEnum.ViewInvoices,
				image: BillingImagesEnum.ViewInvoices
			}
		],
		activeUserManagementPage: false,
		currentBillingPageIndex: -1,
		activeUserPage: false
	},
	businesses: []
};

export const UserInitialState: UserStateModel = {
	profile: _.cloneDeep(NullDataInterface),
	editImage: null
};

export const BillingInitialState: BillingStateModel = {
	cards: _.cloneDeep(NullDataInterface),
	editCardPageStatus: {
		reset: false,
		submit: false,
		valided: false,
		errors: false,
		touched: false,
		next: false,
		check: false
	},
	selectedCardId: null,
	billingAccountInfo: _.cloneDeep(NullDataInterface),
	invoiceHistory: _.cloneDeep(NullDataInterface)
};

export const SubscriptionInitialState: SubscriptionStateModel = {
	invoices: _.cloneDeep(NullDataInterface),
	contact: {
		reasons: [
			{
				data: '0',
				displayName: 'My account is not performing well',
				value: '0',
				selected: false
			},
			{
				data: '1',
				displayName: "I don't understand the Lolly platform",
				value: '1',
				selected: false
			},
			{
				data: '2',
				displayName: 'There is a mistake with my invoice',
				value: '2',
				selected: false
			},
			{
				data: '3',
				displayName: 'I have problems setting up my Facebook connection',
				value: '3',
				selected: false
			},
			{
				data: '4',
				displayName: 'I have problems setting up my Google connection',
				value: '4',
				selected: false
			},
			{
				data: '5',
				displayName: "I don't understand Dexter's recommendations",
				value: '5',
				selected: false
			}
		],
		answers: [
			{
				data: '0',
				displayName:
					'We are sorry to hear your account is not performing well, please describe in the comment bellow what is it that’s not performing well.',
				value: '0',
				selected: false
			},
			{
				data: '1',
				displayName:
					'We are sorry to hear you don’t understand our platform, please describe in the comment bellow what is it that you don’t understand from Filed’s platform.',
				value: '1',
				selected: false
			},
			{
				data: '2',
				displayName:
					'We are sorry to hear there’s a mistake in your invoice, please describe in the comment bellow what is it that’s wrong with your invoice and in which one.',
				value: '2',
				selected: false
			},
			{
				data: '3',
				displayName:
					'We are sorry to hear there’s problems setting up your Facebook connection, please describe in the comment bellow what is it that’s going wrong with connecting with Facebook.',
				value: '3',
				selected: false
			},
			{
				data: '4',
				displayName:
					'We are sorry to hear there’s problems setting up your Google connection, please describe in the comment bellow what is it that’s going wrong with connecting with Google.',
				value: '4',
				selected: false
			},
			{
				data: '5',
				displayName:
					'We are sorry to hear you are having troubles with Dexter’s recommendations, please describe in the comment bellow what it is exactly that you don’t understand.',
				value: '5',
				selected: false
			}
		]
	}
};

export const UsersInitialState: UsersStateModel = {
	usersData: _.cloneDeep(NullDataInterface),
	status: {
		refresh: false
	},
	facebookBusinessRoles: _.cloneDeep(NullDataInterface),
	facebookAdAccountRoles: _.cloneDeep(NullDataInterface),
	googleAdAccountRoles: _.cloneDeep(NullDataInterface)
};

export const AccountInitialState: AccountStateModel = {
	myAccount: {
		state: {
			edit: false,
			submit: false
		}
	},
	selectedUser: _.cloneDeep(NullDataInterface),
	addEditUser: {
		firstStepData: null,
		status: {
			next: false,
			submit: false
		},
		secondStepData: {
			adAccountPermissionArray: null,
			selectedBusinessIds: [],
			selectedAccessLevelIds: [],
			radioButtonValues: []
		}
	}
};

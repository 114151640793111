import { Component, OnInit } from '@angular/core';
import { NotificationActionEnum, NotificationModel } from '../../_models/notification.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-notifications-main',
	templateUrl: './notifications-main.component.html',
	styleUrls: ['./notifications-main.component.scss']
})
export class NotificationsMainComponent implements OnInit {
	public notifications: NotificationModel[] = [];
	private unsubscriber$ = new Subject<void>();

	constructor(public router: Router, public sharedService: SharedService) {}

	ngOnInit(): void {
		this.getAllNotifications();
		setTimeout(() => {
			const fakeNotification: NotificationModel = {
				id: 14,
				action_type: NotificationActionEnum.CREATOR_CREATIVE_APPROVED,
				message: 'A New CREATOR_CREATIVE_APPROVED',
				action_name: 'Reply',
				date: 'Apr 10',
				muted: false,
				unread: true,
				creative_id: 451
			};
			const fakeNotification2: NotificationModel = {
				id: 15,
				action_type: NotificationActionEnum.CREATOR_PAYMENT_COMPLETED,
				message: 'A New CREATOR_PAYMENT_COMPLETED',
				action_name: 'Reply',
				date: 'Apr 10',
				muted: false,
				unread: true,
				job_id: 21
			};
			const fakeNotification3: NotificationModel = {
				id: 16,
				action_type: NotificationActionEnum.CREATOR_EMAIL_RECEIVED,
				message: 'A New CREATOR_PAYMENT_COMPLETED',
				action_name: 'Reply',
				date: 'Apr 10',
				muted: false,
				unread: false,
				user_id: 231
			};
			this.generateFakeEvent(fakeNotification);
			this.generateFakeEvent(fakeNotification2);
			this.generateFakeEvent(fakeNotification3);
		}, 10000);
	}

	public generateFakeEvent(notification: NotificationModel): void {
		this.notifications.push(notification);
	}

	public getAllNotifications(): void {
		this.sharedService
			.getAllNotifications(10)
			.pipe(take(1))
			.subscribe(res => {
				this.notifications = res.notifications;
				this.initNotificationListener();
			});
	}

	public initNotificationListener(): void {
		this.sharedService
			.observeNotificationEvents()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					try {
						console.log('socket res aya 1 -\n', res);
						console.log('socket res aya 2 -\n', typeof res);
						console.log('socket res aya 3 -\n', JSON.parse(res));
					} catch (e) {
						console.log('parse error', e);
					}
				},
				err => {
					console.log('socket err aya -\n', err);
				}
			);
	}

	public onNotificationClick(notification: NotificationModel): void {
		// this.implementAction(notification);
	}

	public onNotificationActionClick(notification: NotificationModel): void {
		this.implementAction(notification);
	}

	public implementAction(notification: NotificationModel): void {
		switch (notification.action_type) {
			case NotificationActionEnum.CREATOR_MESSAGE_RECEIVED:
				this.router.navigate(['messaging'], { queryParams: { creatorId: notification.user_id } });
				break;
			case NotificationActionEnum.CREATOR_EMAIL_RECEIVED:
				this.router.navigate(['messaging'], { queryParams: { emailCreatorId: notification.user_id } });
				break;
			case NotificationActionEnum.CREATOR_JOB_RECEIVED:
			case NotificationActionEnum.CREATOR_HIRED:
			case NotificationActionEnum.CREATOR_REJECTED:
				location.href = `my-jobs;job_id=${notification.job_id}`;
				break;
			case NotificationActionEnum.CREATOR_CREATIVE_APPROVED:
			case NotificationActionEnum.CREATOR_CREATIVE_DECLINED:
			case NotificationActionEnum.CREATOR_POST_URL_ACCEPTED:
				this.router.navigate(['creatives'], { queryParams: { id: notification.creative_id } });
				break;
			case NotificationActionEnum.CREATOR_PAYMENT_COMPLETED:
				this.router.navigate(['earnings'], { queryParams: { id: 6 } });
				break;
			case NotificationActionEnum.CREATOR_CREATIVE_DEADLINE:
				this.router.navigate(['creatives']);
				break;
			case NotificationActionEnum.CREATOR_STRIPE_CONNECTED:
				this.router.navigate(['settings/billing']);
				break;
			case NotificationActionEnum.CREATOR_SOCIAL_MEDIA_CONNECTED:
				this.router.navigate(['settings']);
				break;
			case NotificationActionEnum.CREATOR_EMAIL_CONNECTED:
				this.router.navigate(['settings/integrations']);
				break;
		}
	}

	public onEditNotificationClick(notification: NotificationModel, editAction: string): void {
		if (editAction == 'delete') {
			this.deleteNotification(notification);
		} else if (editAction == 'mute') {
			this.toggleMuteNotification(notification);
		}
	}

	public deleteNotification(notification: NotificationModel): void {
		const index = this.notifications.findIndex(n => n.id == notification.id);
		this.notifications.splice(index, 1);
		this.sharedService
			.deleteNotification(notification.id)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public toggleMuteNotification(notification: NotificationModel): void {
		const payload = {
			preference_list: notification.action_type,
			type: notification?.muted ? 'unmute' : 'mute'
		};
		notification.muted = !notification.muted;
		this.sharedService
			.muteNotification(payload)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public routeToNotifications(): void {
		this.markAllAsRead();
		this.router.navigate(['notifications']);
	}

	public markAllAsRead(): void {
		this.sharedService
			.readAllNotifications()
			.pipe(take(1))
			.subscribe(
				res => {
					// All Notifications marked as read
				},
				err => {
					// Some error occurred
				}
			);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}

import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'socialPlatform'
})
export class SocialPlatformPipe implements PipeTransform {
	constructor(private titleCasePipe: TitleCasePipe) {}

	transform(value?: string) {
		if (!value) return '';
		if (value.toLowerCase() == 'tiktok') return 'TikTok';
		return this.titleCasePipe.transform(value);
	}
}

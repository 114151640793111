import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-walkthrough-generic-small-dialog',
	templateUrl: './walkthrough-generic-small-dialog.component.html',
	styleUrls: ['./walkthrough-generic-small-dialog.component.scss']
})
export class WalkthroughGenericSmallDialogComponent implements OnInit {
	public title: string = '';
	public elementId: string = '';
	public description: string = '';

	constructor(
		public dialogRef: MatDialogRef<WalkthroughGenericSmallDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { title: string; description: string; elementId: string }
	) {
		this.title = data.title;
		this.description = data.description;
		this.elementId = data.elementId;
	}

	ngOnInit(): void {}

	public closeDialog(flag: boolean): void {
		this.dialogRef.close(flag);
	}

	@HostListener('window:resize')
	public onWindowResize(): void {
		const activeElementRef = document.getElementById(this.elementId) as HTMLElement;
		const elementBounderies = activeElementRef.getBoundingClientRect();
		const dialogTop = elementBounderies.top;
		const dialogLeft = elementBounderies.left + elementBounderies.width + 20;
		this.dialogRef.updatePosition({
			top: `${dialogTop}px`,
			left: `${dialogLeft}px`
		});
	}
}

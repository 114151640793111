import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { ValidatorMessages } from '../../../form-input/validatorMessagesInputs';
import { CampaignBudgetChoices } from './campaign-budget-choices.enum';

@Component({
	selector: 'app-budget-card',
	templateUrl: './budget-card.component.html',
	styleUrls: ['./budget-card.component.scss']
})
export class BudgetCardComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public campaignBudgetOptimization: boolean;
	@Input() public currency: string;
	@Input() public validBudget: number;
	@Input() public adSetBudget: UntypedFormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter();

	public budgeChoiceEnum = CampaignBudgetChoices;
	public budgetType;
	public validationMessage: ValidatorMessages[];

	constructor() {}

	public ngOnInit(): void {
		this.initComponentListener();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.validationMessage = [
			{
				validationType: 'required',
				validationMessage: 'This field is mandatory'
			},
			{
				validationType: 'min',
				validationMessage: `${'Minimum budget is ' + this.validBudget + ' ' + this.currency}`
			}
		];
	}

	public onBudgetTypeClick(type: any): void {
		this.adSetBudget.get('budgetType').patchValue(type);
	}

	public initComponentListener(): void {
		if (!this.adSetBudget.get('budgetType').value) {
			this.adSetBudget.get('budgetType').patchValue(this.budgetType.LifeTime);
		}
		this.adSetBudget.get('amount').setValidators([Validators.required, Validators.max(1000000000), Validators.pattern('^[0-9]+$')]);
	}
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KnowledgeBasePopUpComponent } from './knowledge-base-pop-up/knowledge-base-pop-up.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChatIconSubjectService } from '../chat-icon-subject.service';
@Component({
	selector: 'app-knowledge-base',
	templateUrl: './knowledge-base.component.html',
	styleUrls: ['./knowledge-base.component.scss']
})
export class KnowledgeBaseComponent implements OnInit {
	public isClosed = true;
	public show = true;
	private unsubscriber$ = new Subject<void>();
	private matDialogConfig = {
		panelClass: ['dialog-without-padding', 'animate__animated', 'animate__fadeInRightBig'],
		width: '30%',
		height: '100%',
		disableClose: true,
		hasBackdrop: false,
		position: {
			top: '0',
			right: '0'
		}
	};

	constructor(private chatIconSubjectService: ChatIconSubjectService, private matDialog: MatDialog) {}

	public ngOnInit(): void {
		this.IconState();
	}

	public IconState(): void {
		this.chatIconSubjectService.castIconState.pipe(takeUntil(this.unsubscriber$)).subscribe(state => {
			this.show = state;
		});
	}

	public openMatDialog(): void {
		this.isClosed = false;
		this.matDialog
			.open(KnowledgeBasePopUpComponent, this.matDialogConfig)
			.afterClosed()
			.pipe()
			.subscribe(() => {
				this.isClosed = true;
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ImageStateEnum } from '../../../hover-select-directive/image-state.enum';

@Component({
	selector: 'app-objectives-card',
	templateUrl: './objective-card.component.html',
	styleUrls: ['./objective-card.component.scss']
})
export class ObjectiveCardComponent implements OnInit, OnDestroy {
	@Input() public objectivesGroup: AbstractControl;
	@Input() public objectives: any[];
	@Input() public isObjectivesPanelSelected: boolean;
	@Input() public isSubObjectivesPanelSelected: boolean;
	@Input() public isSubObjectivesOptionsPanelSelected: boolean;

	@Output() public onExpansionClick = new EventEmitter();

	public state = ImageStateEnum;
	public selectedObjective: any;
	public selectedSubObjective: any | any[];
	public campaignPanels;
	public contexts: string[];
	public objective;
	private unsubscriber$ = new Subject();

	constructor() {}

	public ngOnInit(): void {
		this.hideObjectivesAndSUbObjectives();
		this.onActivatedRoute();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onActivatedRoute(): void {
		if (this.objectivesGroup.get('objective').value && this.objectivesGroup.get('subObjective').value) {
			this.selectedObjective = this.objectives.find(objective => objective.name === this.objectivesGroup.get('objective').value);
			this.selectedObjective.imageState = ImageStateEnum.Pressed;
			this.selectedSubObjective = this.selectedObjective?.items?.find(subObjective =>
				subObjective.name.includes(this.objectivesGroup.get('subObjective').value)
			);
			this.updateSubObjectiveState(this.selectedSubObjective);
		}
	}

	public checkSelected(value: any): any {
		const objective = Object.values(value.items);
		const selectedObjective: any = objective.find((item: any) => item.kind === 'Objective');
		return selectedObjective.name;
	}

	public onObjectiveClick(objective: any): void {
		this.selectedObjective = objective;
		this.objectivesGroup.get('objective').setValue(objective.name);

		let newSubObjective: any;
		newSubObjective = objective.items ? objective.items[0] : null;
		this.updateSubObjectiveState(newSubObjective);
	}

	public updateSubObjectiveState(subObjective: any): void {
		this.selectedSubObjective = Object.values(subObjective.items);
		this.objectivesGroup.get('subObjectiveDisplayName').setValue(subObjective.display_name);
		for (let i = 0; i < this.selectedSubObjective.length; ++i) {
			if (this.selectedSubObjective[i].kind === 'Objective') {
				this.objectivesGroup.get('subObjective').setValue(this.selectedSubObjective[i].name);
			} else if (this.selectedSubObjective[i].kind === 'DestinationType') {
				this.objectivesGroup.get('destinationType').setValue(this.selectedSubObjective[i].name);
			}
		}
	}

	public isCardSelected(value: string, control: string): boolean {
		return this.objectivesGroup.get(control).value === value;
	}

	public hideObjectivesAndSUbObjectives(): void {
		this.objectives = this.objectives.filter(item => item.name !== this.objective.AppActivity);
		this.objectives.forEach(item => {});
	}
}

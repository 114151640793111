<app-user-management-header></app-user-management-header>

<div class="center-wrapper">
	<!--Header employees -->
	<div class="header-wrapper">
		<div class="employees-title">
			<h4>My Users</h4>
			<p>{{ rowDataLength }} employees</p>
		</div>

		<div class="right-options">
			<app-input-text [control]="searchFormControl.get('searchForm')" [icon]="'fa-search'" class="search-input" [label]="'Search'"></app-input-text>
			<div *appCheckPermissions="permissionModelUsers">
				<app-primary-button
					[buttonLable]="'+ Create New'"
					(buttonClicked)="openPopUp()"
					*ngIf="task.decline == false && task.accepted != false"
					[isPeddingReduceSmall]="true"
					[buttonType]="'Primary'"
				></app-primary-button>
			</div>
		</div>
	</div>
	<div class="master-table-container">
		<app-master-table
			(gridReady)="onGridReady($event)"
			[buttonsConfiguration]="iconsConfiguration"
			[canPageSizeBeChanged]="true"
			[columnDefs]="columnDefs"
			[defaultColDef]="defaultColDef"
			[frameworkComponents]="frameworkComponents"
			[isEditClickSuppressed]="true"
			[isEditingStoppedWhenGridLosesFocus]="true"
			[isIconsBarVisible]="true"
			[paginationOptions]="paginationOptions"
			[paginationPageSize]="defaultPageSize"
			[rowBuffer]="200"
			[rowData]="filteredRowData"
		></app-master-table>
	</div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-influencer-input-field',
	templateUrl: './influencer-input-field.component.html',
	styleUrls: ['./influencer-input-field.component.scss']
})
export class InfluencerInputFieldComponent implements OnInit {
	@Input('inputControl') public inputControl: UntypedFormControl = new UntypedFormControl();
	@Input('label') public label: string = '';
	@Input('errorMsg') public errorMsg: string = '';
	@Input('placeholder') public placeHolder: string = '';
	@Input('type') public type: string = 'text';
	@Input('autofocus') public autofocus: boolean = false;
	@Input('disabled') public disabled: boolean = false;

	@Output('enterKeyup') public enterKeyup = new EventEmitter<void>();

	public inpType = 'text';

	constructor() {}

	ngOnInit(): void {
		if (this.type === 'password') this.inpType = 'password';
		else if (this.type === 'number') this.inpType = 'number';
		else this.inpType = 'text';
	}

	public triggerEnter() {
		this.enterKeyup.emit();
	}
}

<app-expansion-panel-sc
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[expansionTitle]="'Ad Format'"
	[isExpandable]="false"
	[contentMargin]="false"
>
	<div expansion-content>
		<mat-radio-group class="afc-radio-group">
			<mat-radio-button
				class="afc-radio-button"
				[value]="AdFormatsEnum.SingleImage"
				[checked]="selectedFormat !== AdFormatsEnum.PagePosts"
				(click)="onRadioButtonClick(AdFormatsEnum.SingleImage)"
			>
				<span>Create Ad</span>
			</mat-radio-button>
			<mat-radio-button
				class="afc-radio-button"
				[value]="AdFormatsEnum.PagePosts"
				[checked]="selectedFormat === AdFormatsEnum.PagePosts"
				[disabled]="objectiveTree.subObjective === subObjectives.PageLikes"
				(click)="onRadioButtonClick(AdFormatsEnum.PagePosts)"
			>
				<span>Use existing post</span>
			</mat-radio-button>
		</mat-radio-group>
		<h6 *ngIf="!(selectedFormat === AdFormatsEnum.PagePosts || this.objectiveTree.subObjective === this.subObjectives.VideoViews)">
			Please choose an ad format
		</h6>
		<div class="afc-container" *ngIf="selectedFormat !== AdFormatsEnum.PagePosts">
			<app-card-box class="afc-images" *ngFor="let format of formats" (click)="onAdFormatClick(format)" [isSelected]="selectedFormat === format.key">
				<div card-content appHoverSelect class="afc-image-box" [statefulImage]="format" [statefulImages]="formats">
					<img src="assets/icons/ad-format/{{ format.imageUrl }}-{{ format.imageState }}.svg" alt="{{ format.name }}" />
					<span>{{ format.name }}</span>
				</div>
			</app-card-box>
		</div>
	</div>
</app-expansion-panel-sc>

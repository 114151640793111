<div class="action-button-2 cursor-pointer {{ disabled ? 'disabled' : '' }}">
	<mat-form-field appearance="outline" class="outline {{ disabled ? 'disabled-mat' : '' }}" [floatLabel]="'never'">
		<input type="text" class="font-change" [placeholder]="placeholder" matInput [formControl]="inputControl" [matAutocomplete]="auto" />
		<mat-icon matSuffix>keyboard_arrow_down</mat-icon>
		<mat-autocomplete [displayWith]="getOptionText" (optionSelected)="onOptionSelected($event)" class="auto-panel" #auto="matAutocomplete">
			<mat-option *ngFor="let item of filteredList | async" [value]="item">
				<div class="d-flex justify-content-between">
					<span class="auto-search-drp-name w-100">
						{{ item?.name }}
					</span>
					<span *ngIf="inputControl?.value == item" class="fas fa-check d-flex k-align-items-center" style="color: #4a4a4a;"></span>
				</div>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</div>

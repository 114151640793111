<div class="login-register">
	<img alt="laptop reporting" class="login-bk" src="/assets/icons/welcome-pages/laptop-reporting.svg" />
	<div class="login-register-box">
		<div class="wrapper-box">
			<mat-card>
				<mat-card-content class="p-b-0 fixed-width-box">
					<div class="text-center width-140">
						<img alt="homepage" src="/assets/logo/lolly-logo.svg" />
					</div>

					<h1 class="title">{{ state.title }}</h1>
					<h2 *ngIf="state.subtitle" class="subtitle">{{ state.subtitle }}</h2>
					<div class="d-404">404</div>
					<div class="btn-wrapper">
						<app-button
							(buttonClicked)="state.button.callback()"
							[buttonClass]="buttonClassEnum.Primary"
							[buttonType]="buttonTypeEnum.Simple"
							buttonLabel="{{ state.button.label | translate }}"
						></app-button>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ErrorMessageService } from '../error-message.service';

export interface DropdownInterface {
	name: string;
	id: number;
	data?: any;
	hasChildren?: boolean;
	children?: DropdownChildren[];
}
export interface DropdownChildren {
	name: string;
	id: number;
	data?: any;
}
export interface ValidatorMessages {
	validationType: string;
	validationMessage: string;
}

@Component({
	selector: 'lolly-drp13',
	templateUrl: './drp13.component.html',
	styleUrls: ['./drp13.component.scss']
})
export class LollyDrp13Component implements OnInit {
	@Input() public width = '100%';
	@Input() public height = '100%';
	@Input() public label: string;
	@Input() public value: DropdownInterface;
	@Input() public data: Array<DropdownInterface>;
	@Input() public filterdata: Array<DropdownInterface>;
	@Input() public placeholder: string;
	@Input() public disabled: boolean;
	@Input() public allowFilter: boolean;
	@Input() public validatorMessages: ValidatorMessages[];
	@Input() public control: FormControl | AbstractControl;
	@Output() public valueChanges: EventEmitter<DropdownInterface>;
	public errorMessage: string;

	constructor(public errorMessageService: ErrorMessageService) {
		this.valueChanges = new EventEmitter<DropdownInterface>();
	}

	public ngOnInit(): void {
		if (this.allowFilter) {
			this.data = this.filterdata;
		}
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public ngAfterViewInit(): void {
		if (this.allowFilter) {
			this.data = this.filterdata;
		}
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public changes($event: DropdownInterface): void {
		this.valueChanges.emit($event);
	}

	public handleFilter(value: string): void {
		this.data = this.filterdata.filter(s => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}
}

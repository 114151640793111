import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { ErrorsLoggingService } from '../errors-logging/errors-logging.service';
import { SharedState } from '../../shared/state/shared.reducer';
import { Store } from '@ngrx/store';
import { HideGlobalSpinner } from '../../shared/state/shared.actions';
import { BaseApiUrl } from '../base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<SharedState>,
		private errorsLoggingService: ErrorsLoggingService,
		private authenticationService: AuthenticationService,
		private toastNotificationService: ToastNotificationService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				this.store.dispatch(new HideGlobalSpinner());
				if (!error.status && !error.url.includes(`${BaseApiUrl.LogErrors}`)) {
					// this.errorsLoggingService.logServerDown(error);
					// this.toastNotificationService.sendErrorToast('There is a problem with internal server, please try again later');
				}

				if (error instanceof HttpErrorResponse) {
					const reason = (error as HttpErrorResponse).headers.get('reason');
					if (reason === 'FrontOfficeUserWithZeroAdAccounts') {
						this.authenticationService.logout();
						this.toastNotificationService.sendErrorToast(
							'There was an issue with syncing your data. Please wait a few moments before trying to login again. If this problem persists, contact your account manager'
						);
						return;
					}
				}
				return throwError(error);
			})
		);
	}
}

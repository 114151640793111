<div>
	<p *ngIf="label" class="label">{{ label }}</p>
	<div class="inp-con">
		<input
			class="influ-inp"
			[formControl]="inputControl"
			appCustomAutoFocus
			[focus]="autofocus"
			[style.borderColor]="!this.inputControl.valid && this.inputControl.dirty ? '#DE5241' : ''"
			[style.paddingInlineEnd]="type === 'password' ? '40px' : ''"
			type="{{ inpType }}"
			appInputRestriction="noSpecialChars"
			(keyup.enter)="triggerEnter()"
			[placeholder]="placeHolder"
			[attr.disabled]="disabled ? true : null"
		/>
		<div class="icon-con" *ngIf="type === 'password'">
			<mat-icon (click)="inpType = 'password'" *ngIf="inpType === 'text'">visibility</mat-icon>
			<mat-icon (click)="inpType = 'text'" *ngIf="inpType === 'password'">visibility_off</mat-icon>
		</div>
		<div class="icon-con" *ngIf="type === 'search'">
			<mat-icon class="search">search</mat-icon>
		</div>
	</div>
	<p class="err" *ngIf="errorMsg">{{ errorMsg }}</p>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { NewStorageKey } from 'src/app/_models/local-storage-key';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { SettingsService } from '../../settings/services/settings.service';
import { NewUserDetails, UserTokenData } from '../models/auth.model';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	public authLoadingState = new Subject<boolean>();

	private AuthSubject = new BehaviorSubject<string>(null);
	public isLoggedIn$: Observable<boolean> = this.AuthSubject.asObservable().pipe(
		map(user => {
			return !!user;
		})
	);

	constructor(private http: HttpClient, private router: Router, private injector: Injector) {}

	public changeAuthState(token: string): void {
		this.AuthSubject.next(token);
	}

	public decodeToken(token: string): UserTokenData {
		try {
			const jwtData = token.split('.')[1];
			const decodedJwtJsonData = window.atob(jwtData);
			const fullTokenAsJson = JSON.parse(decodedJwtJsonData);
			return fullTokenAsJson;
		} catch (err) {
			return null;
		}
	}

	public isTokenExpired(decodedJwt: UserTokenData): boolean {
		try {
			const expiry = decodedJwt.exp;
			return Math.floor(new Date().getTime() / 1000) >= expiry;
		} catch (err) {
			return false;
		}
	}

	public signIn(email: string, password: string): Observable<string> {
		const formData = new FormData();

		formData.append('username', email);
		formData.append('password', password);
		return this.http.post<string>(`${BaseApiUrl.SocialInfluencerPython}influencerUser/sign-in`, formData).pipe(
			tap(token => {
				if (typeof token === 'string') {
					this.AuthSubject.next(token);
				} else this.AuthSubject.next(null);
			})
			// shareReplay()
		);
	}

	public signInWithGoogle(gToken: string): Observable<string> {
		const formData = new FormData();

		formData.append('gtoken', gToken);
		return this.http.post<string>(`${BaseApiUrl.SocialInfluencerPython}influencerUser/sign-in`, formData).pipe(
			tap(token => {
				if (typeof token === 'string') {
					this.AuthSubject.next(token);
				} else this.AuthSubject.next(null);
			})
			// shareReplay()
		);
	}

	public signOut(): void {
		this.AuthSubject.next(null);
		localStorage.removeItem(NewStorageKey.token);
		localStorage.removeItem(NewStorageKey.decodedJwtIo);
		localStorage.removeItem(NewStorageKey.first_time_user);
		this.router.navigate(['/authentication']);
	}

	public campaignSignup(payload: { campaignId: number }): Observable<any> {
		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}influencerUser/campaignSignUp`, payload);
	}

	public signUp(payload: NewUserDetails): Observable<any> {
		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}influencerUser/sign-up`, payload);
	}

	public signUpWithGoogle(payload: { gToken: string; campaignId?: string }): Promise<any> {
		return this.http
			.post(`${BaseApiUrl.SocialInfluencerPython}influencerUser/sign-up`, { token: payload?.gToken, campaignId: payload?.campaignId })
			.toPromise();
	}

	public verifyEmail(token: string): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}influencerUser/verify?token=${token}`, {});
	}

	public sendForgottenEmail(email: string): Observable<any> {
		const formData = new FormData();
		formData.append('email', email);
		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}influencerUser/red-pit-stop`, formData);
	}

	public createNewPassword(newPassword: string, token: string): Observable<any> {
		let formData = new FormData();
		formData.append('new_password', newPassword);
		formData.append('token', token);
		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}influencerUser/green-pit-stop`, formData);
	}

	public refreshToken(): Observable<string> {
		return this.http.post<string>(`${BaseApiUrl.SocialInfluencerPython}influencerUser/refresh-token`, {}).pipe(
			tap(newToken => {
				localStorage.setItem(NewStorageKey.token, newToken);
				localStorage.setItem(NewStorageKey.decodedJwtIo, JSON.stringify(this.decodeToken(newToken)));
				localStorage.setItem(NewStorageKey.first_time_user, 'true');
			})
		);
	}

	public resendEmail(payload: { token?: string; campaignId?: number; sendToMyJobs: number }): Observable<any> {
		let url = `${BaseApiUrl.SocialInfluencerPython}influencerUser/resend-email?token=${payload?.token}`;
		if (payload?.campaignId) {
			url = url + `&campaignId=${payload?.campaignId}`;
		}
		if (payload?.sendToMyJobs) {
			url = url + `&sendToMyJobs=${1}`;
		}
		return this.http.post(url, {});
	}

	public authPreInstall(token: string, platform: string): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}oauth/${platform}/preinstall`, { headers: { Authorization: token } });
	}

	public authUnInstall(token: string, platform: string): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}oauth/${platform}/uninstall`, { headers: { Authorization: token } });
	}

	public checkIntegrationStatus(token: string): Observable<{ google: string; outlook: string }> {
		return this.http.get<{ google: string; outlook: string }>(`${BaseApiUrl.SocialInfluencerPython}users/connected-mail`, {
			headers: { Authorization: token }
		});
	}
}

/**

/influencerUser/enable-2fa

/influencerUser/verify-2fa
 */

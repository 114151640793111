import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonClassEnum } from '../../../../button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../button-component/button-type.enum';
import { DropdownData } from '../../../../dropdown-search-select/dropdown-data.interface';
import { AssetContractNameModel } from './asset-contract-name.model';
import { Asset } from './asset-model';
import { AssetPickerContract } from './asset-picker-contract';

@Component({
	selector: 'app-asset-picker-dialog',
	templateUrl: './asset-picker-dialog.component.html',
	styleUrls: ['./asset-picker-dialog.component.scss']
})
export class AssetPickerDialogComponent implements OnInit, OnDestroy {
	public file: File;
	public imageUrl: string;
	public searchControl = new UntypedFormControl();
	public assetsToDisplay: Asset[] = [];
	public selectedAssets: Asset[] = [];
	public selectedContract: AssetPickerContract;
	public contracts: AssetPickerContract[];
	public callToActions: DropdownData<any>[];

	public buttonType = ButtonTypeEnum;
	public buttonClass = ButtonClassEnum;
	public adFormat;
	public contractName = AssetContractNameModel;
	public searchLabel = 'Search by name';

	private uniqueId = 0;
	private unsubscriber$ = new Subject<void>();

	constructor(private sanitizer: DomSanitizer, private dialogRef: MatDialogRef<AssetPickerDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
		this.contracts = _.cloneDeep(data.contract);
		this.callToActions = data.callToActions;
	}

	public ngOnInit(): void {
		this.updatePosition();
		this.initListeners();
		this.searchListener();
	}
	public updatePosition(): void {
		this.dialogRef.updatePosition({ top: '20vh' });
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public initListeners(): void {
		this.selectedContract = this.contracts[0];
		this.assetsToDisplay = this.selectedContract.data ?? [];
		this.filerAssets();

		this.searchControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (!value) {
				this.assetsToDisplay = this.selectedContract.data;
			} else {
				this.assetsToDisplay = this.selectedContract.data.filter(asset => asset.title?.includes(value));
			}
			this.filerAssets();
		});
	}

	public filerAssets(): void {
		if (this.assetsToDisplay) {
			this.assetsToDisplay = this.assetsToDisplay.filter(item => item.url);
			this.assetsToDisplay.forEach(item => {
				item.contract = this.selectedContract.contractName;
				if (!item.title) {
					item.title = 'untitled';
				}
			});
		}
		if (this.selectedContract.contractFor === 'AdFormatsEnum.PagePosts') {
			this.assetsToDisplay = this.assetsToDisplay.filter(item => item.call_to_action && item.primary_text);
			this.assetsToDisplay.forEach(item => {
				if (item.title === 'untitled') {
					item.title = item.primary_text;
				}
			});
		}
	}

	public onFileUpload(event: any): void {
		this.fileChange(event.target.files);
	}

	public onFilesDragged(event: DragEvent): void {
		this.fileChange(event.dataTransfer.files);
	}

	public onSubmit(): void {
		this.dialogRef.close({ assets: this.selectedAssets, uploadFiles: this.selectedContract.uploadFiles });
	}

	public onCancel(): void {
		this.dialogRef.close();
	}

	public onMenuItemClick(contract: AssetPickerContract): void {
		this.selectedContract = contract;
		this.assetsToDisplay = this.selectedContract.data ?? [];
		this.searchControl.setValue('');
		setTimeout(() => {
			const scrollContainer = document.querySelector('.apd-autoflow');
			if (scrollContainer) {
				scrollContainer.scrollTop = 0;
			}
		});
	}

	public onAssetClick(asset: Asset): void {
		if (this.selectedContract.isSingleSelection) {
			this.selectedAssets = [];
		}
		const existingItemIndex = this.selectedAssets.findIndex(item => asset.id === item.id);
		if (existingItemIndex > -1) {
			this.selectedAssets = this.selectedAssets.filter((_, index) => index !== existingItemIndex);
		} else {
			this.selectedAssets.push(asset);
		}
	}

	public isContractSelected(contract: AssetPickerContract): boolean {
		return this.selectedContract.contractName === contract.contractName;
	}

	public isAssetSelected(value: Asset): boolean {
		return !!this.selectedAssets.find(asset => asset.id === value.id);
	}

	public searchListener(): void {
		this.searchControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(change => {
			if (change) {
				this.searchLabel = '';
			} else {
				this.searchLabel = 'Search by name';
			}
		});
	}

	private fileChange(files: FileList): void {
		let url: string;
		let uploade_asset: Asset;
		const contractType = this.selectedContract.acceptedMime.split('/')[0];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const fileType = file.type.split('/')[0];
			if (fileType !== contractType) {
				continue;
			}
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				url = reader.result as string;
				if (url) {
					uploade_asset = {
						url: url,
						id: this.uniqueId++,
						title: file.name,
						file: file
					};
					this.assetsToDisplay.push(uploade_asset);
					this.onAssetClick(uploade_asset);
					if (this.selectedContract.contractName === this.contractName.Upload) {
						this.selectedContract.data.push(uploade_asset);
					}
				}
			};
		}
	}
}

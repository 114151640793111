<div class="popup-section p-1">
	<!--header-->
	<!--delete btn-->
	<div class="clear-btn">
		<mat-icon (click)="actionEvent()">clear</mat-icon>
		<div></div>
	</div>

	<!--main section-->
	<div class="main-section">
		<img src="{{ image }}" />
		<p class="title">{{ header }}</p>
		<p>{{ message }}</p>
	</div>

	<!--footer-->
	<div class="bottom-buttons">
		<app-primary-button
			[buttonLable]="noActionAnswer"
			(buttonClicked)="actionEvent(false)"
			[buttonType]="'Secondary'"
			[isPeddingReduceSmall]="true"
		></app-primary-button>

		<app-primary-button
			[buttonLable]="actionAnswer"
			(buttonClicked)="actionEvent(true)"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
		></app-primary-button>
	</div>
</div>

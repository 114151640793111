import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-dropdown-wo-input',
	templateUrl: './dropdown-wo-input.component.html',
	styleUrls: ['./dropdown-wo-input.component.scss']
})
export class DropdownWoInputComponent implements OnInit {
	@Input('styleClass') public styleClass: string;
	@Input('cssStyle') public cssStyle: any;
	@Input('dropdownLabel') public dropdownLabel: string;
	@Input('inputPlaceholder') public inputPlaceholder: string;
	@Input('dropdownControl') public dropdownControl: FormControl;
	@Input('options') public options: any[];
	@Input('validatorMessages') public validatorMessages: string = '';

	@Output() public selectionEvent = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	public optionSelected(event: any) {
		this.selectionEvent.emit(event);
	}
}

<mat-card>
	<div class="header">
		<mat-card-header>
			<mat-card-title class="billing-info">My Billing Information</mat-card-title>
		</mat-card-header>
		<div *ngIf="this.billingInfo?.disabled" class="edit-button">
			<app-primary-button
				[buttonType]="'Primary'"
				[buttonLable]="'Edit'"
				[isPeddingReduceSmall]="true"
				(buttonClicked)="editEvent()"
			></app-primary-button>
		</div>
		<div *ngIf="!this.billingInfo?.disabled" class="action-buttons">
			<app-primary-button
				(buttonClicked)="cancelEvent()"
				[buttonType]="'Secondary'"
				[isPeddingReduceSmall]="true"
				[buttonLable]="'Cancel'"
			></app-primary-button>

			<app-primary-button
				[buttonType]="'Primary'"
				[isPeddingReduceSmall]="true"
				(buttonClicked)="saveEvent()"
				[buttonLable]="'Save'"
			></app-primary-button>
		</div>
	</div>
	<hr class="under-line" />
	<mat-card-content>
		<form [formGroup]="billingInfo">
			<div class="container billing-cards">
				<div class="billing-formelements">
					<app-text-input-with-label [control]="billingInfo.get('Company')" [inputLabel]="'Company *'"></app-text-input-with-label>
				</div>
				<div class="billing-formelements">
					<app-text-input-with-label [control]="billingInfo.get('FullName')" [inputLabel]="'Full Name *'"></app-text-input-with-label>
				</div>
				<div class="billing-formelements">
					<app-text-input-with-label [control]="billingInfo.get('BillingAddress')" [inputLabel]="'Billing Address *'"></app-text-input-with-label>
				</div>
			</div>

			<div class="container billing-cards">
				<div class="billing-formelements">
					<app-text-input-with-label [control]="billingInfo.get('City')" [inputLabel]="'City *'"></app-text-input-with-label>
				</div>
				<div class="billing-formelements">
					<app-text-input-with-label [control]="billingInfo.get('ZipCode')" [inputLabel]="'Zipcode *'"></app-text-input-with-label>
				</div>
				<div class="billing-formelements">
					<app-text-input-with-label
						*ngIf="isEditable === false"
						[control]="billingInfo.get('Country')"
						[inputLabel]="'Country *'"
					></app-text-input-with-label>
					<app-dropdown-wo-input
						*ngIf="isEditable"
						[dropdownControl]="billingInfo.get('CountryId')"
						[options]="dropdownCountries"
						[inputPlaceholder]="'Choose Role'"
						[cssStyle]="'width:18.49vw'"
					></app-dropdown-wo-input>
				</div>
			</div>
		</form>
		<span class="billing-note">
			{{ billingNote }}
		</span>
	</mat-card-content>
</mat-card>

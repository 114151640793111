import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ButtonClassEnum } from '../../../../button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../button-component/button-type.enum';
import { ValidatorMessages } from '../../../../form-input/validatorMessagesInputs';

import { DropdownData } from '../../../../dropdown-search-select/dropdown-data.interface';

@Component({
	selector: 'app-audience-name-pop-up',
	templateUrl: './audience-name-pop-up.component.html',
	styleUrls: ['./audience-name-pop-up.component.scss']
})
export class AudienceNamePopUpComponent implements OnInit, OnDestroy {
	constructor(@Inject(MAT_DIALOG_DATA) public audience: DropdownData<any>[], public matDialog: MatDialogRef<AudienceNamePopUpComponent>) {}

	public audienceControl = new UntypedFormControl();
	public audienceName: string;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public validationMessages: ValidatorMessages[] = [
		{
			validationType: 'used',
			validationMessage: 'Audience name already in use.'
		},
		{
			validationType: 'required',
			validationMessage: 'Audience name in required.'
		}
	];

	private unsubscriber$: Subject<void> = new Subject<void>();

	public ngOnInit(): void {
		this.initAudienceNameListener();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public initAudienceNameListener(): void {
		this.audienceControl.setValidators(Validators.required);
		this.audienceControl.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscriber$)).subscribe(name => {
			if (name) {
				for (let i = 0; i < this.audience.length; i++) {
					if (this.audience[i].displayName.toLocaleLowerCase() === `${'Filed / ' + name}`.toLowerCase()) {
						this.audienceControl.markAsTouched();
						this.audienceControl.setErrors({ used: true });
						break;
					}
				}
			}
		});
	}

	public confirm(confirm: boolean): void {
		if (confirm && this.audienceControl.valid) {
			const audienceName = `${'Filed / ' + this.audienceControl.value}`;
			this.matDialog.close({ confirm, audienceName });
		} else if (!confirm) {
			this.matDialog.close(!confirm);
		} else if (!this.audienceControl.value) {
			this.audienceControl.markAsTouched();
			this.audienceControl.setErrors({ required: true });
		}
	}
}

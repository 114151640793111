import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'echarts/theme/macarons.js';
import { LicenseManager } from 'ag-grid-enterprise';
import 'hammerjs';

LicenseManager.setLicenseKey(
	'CompanyName=Abstract Inc Limited,LicensedApplication=Filed.com,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-009936,ExpiryDate=20_August_2021_[v2]_MTYyOTQxNDAwMDAwMA==82ea40e1afde56232d8f30f68ab61d77'
);

namespace global {
	interface JQuery {
		dayScheduleSelector(): any;
	}
}

if (environment.Flags.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));

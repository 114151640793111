<section class="payment-details-container">
	<div class="right-section">
		<mat-card-header>
			<mat-card-title class="cards-list-title">Payment Details</mat-card-title>
		</mat-card-header>
		<div class="cards-outside">
			<div *ngFor="let card of this.cards; let i = index" class="card-user-item" [ngClass]="{ selected: card.isDefault }">
				<div class="payment-card-header" [ngClass]="{ 'action-menu-full': i > 1 }">
					<div class="payment-subtitle" *ngIf="card.isDefault">Primary Payment</div>
					<div class="payment-subtitle" *ngIf="!card.isDefault && i < 2">
						<span>Other Payments</span>
					</div>

					<div *ngIf="!card.isDefault" class="payment-action-menu" [ngClass]="{ 'action-menu-alone': i > 1 }" [matMenuTriggerFor]="actionList">
						<img class="dots" src="assets/icons/user-management/account/dots2.svg" />
						<mat-menu #actionList="matMenu" class="custom_mat_menu" xPosition="before">
							<button mat-menu-item (click)="makePrimaryCard(card.cardId)">
								<img class="make-primary" src="assets/icons/icon-primary.svg" />
								Make Primary
							</button>
							<button mat-menu-item (click)="confirmDelete(card.cardId)">
								<img class="delete" src="assets/icons/icon-delete-card.svg" />
								Delete
							</button>
						</mat-menu>
					</div>
				</div>
				<div class="credit-card-elements">
					<div class="image-card-left">
						<img [src]="card.isDefault ? 'assets/icons/primary-card.svg' : 'assets/icons/bill-credit-card.svg'" />
					</div>
					<div class="text-card-payment">
						<li>
							<span>Card ending in</span>
							<span>{{ card.last4Digits }}</span>
						</li>
						<li>
							<span>Exp. date</span>
							<span>{{ card.expirationMonth + '/' + card.expirationYear }}</span>
						</li>
					</div>
				</div>
			</div>
		</div>

		<div class="add-card-btn" *ngIf="this.cards?.length != 5" (click)="addPaymentPopup()">+ Add Payment</div>
		<div class="add-card-btn no-more-cards" *ngIf="this.cards && this.cards.length == 5">You cannot add more cards</div>
		<div class="addPaymentText" *ngIf="this.cards && this.cards.length < 2">
			* You can add more than one payment card and set a primary one according to your needs. Only the primary one will be billed.
		</div>
	</div>

	<div class="my-plan-section" [ngClass]="{ multiple: this.cards && this.cards.length > 1 }" *ngIf="this.billingPlan">
		<h3>My Plan</h3>
		<div class="subscription-box">
			<a [routerLink]="['/user-management/my-subscription']">Go to Plan</a>
			<div class="flex-subscription-inside">
				<img src="/assets/icons/subscription.svg" />
				<div class="subs-text-right">
					<h4>Subscription Plan</h4>
					<span>{{ this.billingPlan.subscriptionName }}</span>
					<p *ngIf="this.billingPlan.isFreeTrial && this.cards && this.cards.length < 1 && this.billingPlan.freeTrialEndDate">
						Your free trial ends on {{ this.billingPlan.freeTrialEndDate | date: 'MMMM d, yyyy' }}
					</p>
					<p *ngIf="this.billingPlan.isFreemium">You have a free account</p>
					<p *ngIf="!this.billingPlan.isFreeTrial && !this.billingPlan.isFreemium && this.billingPlan.nextInvoiceDate">
						Next payment due {{ this.billingPlan.nextInvoiceDate | date: 'MMMM d, yyyy' }}
					</p>
					<p *ngIf="this.billingPlan.isFreeTrial && this.cards && this.cards.length > 0 && this.billingPlan.freeTrialEndDate">
						Next payment due {{ this.billingPlan.freeTrialEndDate | date: 'MMMM d, yyyy' }}
					</p>
				</div>
			</div>
		</div>
	</div>
</section>

<app-expansion-panel-sc
	class="scs-expansion-panel"
	(click)="onExpansionClick.emit()"
	[isSelected]="isPanelSelected"
	[expansionTitle]="'Placements'"
	[isExpandable]="false"
>
	<div expansion-content class="content">
		<div class="scs-placements-cards-container">
			<app-card-box
				class="scs-card"
				*ngFor="let platform of platforms"
				(click)="onPlacementClick(platform)"
				[isSelected]="checkIfSelected(platform, selectedPlacements)"
				[isDisabled]="platform.imageState === state.Disabled"
				[isInvalid]="
					!this.placementsControl.valid &&
					this.placementsControl.touched &&
					platform.imageState !== state.Disabled &&
					platform.imageState !== state.Hover
				"
			>
				<div card-content appHoverSelect [statefulImage]="platform" [statefulImages]="platforms" class="scs-image-card-content">
					<img
						src="/assets/icons/campaign-smart-create/campaign-settings/{{ platform.imageNameAutogen }}-{{ platform.imageState }}.svg"
						alt="placement"
					/>
					<span [ngClass]="{ disabled: platform.imageState === state.Disabled }">{{ platform.displayNameAutogen }}</span>
				</div>
			</app-card-box>
		</div>
		<span *ngIf="!placementsControl.valid && placementsControl.touched" class="warning">Selecting a Placement is a mandatory action</span>
	</div>
</app-expansion-panel-sc>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ValidatorMessages } from '../validatorMessagesInputs';
import { CurrencyEnum } from '../currency.enum';
import { ErrorMessageService } from '../error-message.service';

@Component({
	selector: 'app-input-number',
	templateUrl: './input-number.component.html',
	styleUrls: ['./input-number.component.scss', '../shared.scss']
})
export class InputNumberComponent implements OnInit, OnChanges {
	@Input() showErrors = true;
	@Input() control: UntypedFormControl | AbstractControl;
	@Input() validatorNumMessages: ValidatorMessages[];
	@Input() label: string;
	@Input() placeholder: string;
	@Input() currency: CurrencyEnum;
	@Input() currencyStr: string;
	@Input() decimals: number;
	@Input() maxNumber: number;

	@Output() inputFocusOut = new EventEmitter();
	@Output() inputKeyUp = new EventEmitter();

	public isDestinationFocused: boolean;
	public errorMessage: string;

	constructor(public errorMessageService: ErrorMessageService) {}

	public ngOnInit(): void {
		if (!this.maxNumber) {
			this.maxNumber = null;
		}
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorNumMessages, this.control);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.setErrorMessage();
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorNumMessages, this.control);
	}

	public onFocusOut(): void {
		this.setErrorMessage();
		this.inputFocusOut.emit();
	}

	public onKeyUp(): void {
		this.setErrorMessage();
		this.inputKeyUp.emit();
	}
}

import { userPermission } from './permissions-user.enum';

export class NewUserModel {
	public id: number;
	public firstName: string;
	public lastName: string;
	public fullName?: string;
	public email: string;
	public phoneNumber: string;
	public position: string;
	public FiledPermissionsIds?: object;
	public FacebookBusinessPermissions?: any[];
	public ModuleAccessTypes?: userPermission;
	public accountState?: number = 1;

	constructor() {}
}

export interface FacebookAdAccount {
	facebookAdAccountId?: string;
	businessId: number;
	checked: boolean;
	name: string;
	id: string;
}

import { AssetType } from './asset-type.enum';
import { BaseAsset } from './base-asset.model';

export class AssetReference extends BaseAsset {
	public type: AssetType;
	public title: string;
	public name: string;
	public picture?: string;
	public primary_text?: string;
	public call_to_action: { value: string };
	public website_url?: string;
	public video_id?: number;
	public height?: number;
	public width?: number;
	public thumbnail?: string | any;
	public image?: string;
	public videoSize?: any;
}

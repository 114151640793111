<div class="main">
	<!-- nav -->
	<div *ngIf="page === 1" class="header w-100 d-flex justify-content-between">
		<div class="name">
			<h4>Hi {{ name }}</h4>
			<h6>Find your answers here</h6>
		</div>
		<mat-icon class="close" (click)="onNoClick()">close</mat-icon>
	</div>
	<div *ngIf="page !== 1" class="header w-100 d-flex justify-content-between">
		<button *ngIf="!isMobileView" class="back" (click)="goBack()">
			<mat-icon>chevron_left</mat-icon>
			Back
		</button>
		<mat-icon *ngIf="isMobileView" class="back" (click)="goBack()">chevron_left</mat-icon>
		<mat-icon class="close" (click)="onNoClick()">close</mat-icon>
	</div>
	<!-- main -->
	<div class="main-con">
		<div *ngIf="page !== 3 && page !== 5" class="main-head d-flex justify-content-evenly align-items-center">
			<h4 class="fw-bold">Knowledge Base</h4>
			<button [matMenuTriggerFor]="menu">Ticket Support</button>
			<mat-menu #menu="matMenu">
				<button (click)="page = 3" mat-menu-item>Submit a Ticket</button>
				<button (click)="openViewTicket()" mat-menu-item>View your Tickets</button>
			</mat-menu>
		</div>

		<!-- !submit ticket -->
		<div *ngIf="page === 3" class="main-head-2">
			<h3>We'll email you within one working day.</h3>

			<ng-container *ngIf="isMobileView">
				<div class="form-field">
					<label for="topic">Topic*</label>
					<input [(ngModel)]="topic" type="text" id="topic" class="input" />
				</div>
				<div class="form-field">
					<label for="des">Description*</label>
					<textarea [(ngModel)]="des" id="des" class="input"></textarea>
				</div>
				<div class="form-field">
					<label for="email">Email*</label>
					<input [(ngModel)]="email" type="text" id="email" class="input" readonly />
				</div>
			</ng-container>

			<ng-container *ngIf="!isMobileView">
				<div class="topic">
					<mat-form-field appearance="outline">
						<mat-label>Topic *</mat-label>
						<input [(ngModel)]="topic" matInput />
					</mat-form-field>
				</div>
				<div class="des">
					<mat-form-field appearance="outline">
						<mat-label>Description *</mat-label>
						<textarea [(ngModel)]="des" matInput></textarea>
					</mat-form-field>
				</div>
				<div class="email">
					<mat-form-field appearance="outline">
						<mat-label>Email *</mat-label>
						<input [value]="email" readonly matInput />
					</mat-form-field>
				</div>
			</ng-container>

			<div class="submit-btn">
				<button [disabled]="!topic || !des" (click)="submitTicket()">Submit</button>
			</div>
		</div>

		<!-- !loader -->
		<app-lolly-spinning-loader *ngIf="loading"></app-lolly-spinning-loader>

		<div class="main-cards" *ngIf="!loading">
			<!-- !category list -->
			<div class="page-content" *ngIf="page === 1">
				<mat-card *ngFor="let cat of categories" (click)="openCardPage(cat?.category_id, cat?.name)">
					<div class="mat-card d-flex align-items-center">
						<img class="card-img" src="assets/logo/lolly-logo-icon.svg" />
						<div class="card-content">
							<div class="card-title">
								{{ cat?.name }}
							</div>
							<div class="card-des">
								{{ cat?.description }}
							</div>
						</div>
					</div>
				</mat-card>
			</div>

			<!-- !category details -->
			<div class="page-content" *ngIf="page === 2">
				<p class="title">{{ selectedCategory }}</p>
				<mat-card *ngFor="let cat of categoryLinks" (click)="goTo(cat?.url)">
					<div class="mat-card d-flex align-items-center mat-card-small">
						<div class="card-content">
							<div class="card-title card-title-nodes">
								{{ cat.title }}
							</div>
						</div>
					</div>
				</mat-card>
			</div>

			<!-- !view ticket -->
			<div class="page-content" *ngIf="page === 4">
				<p class="title">Your Tickets</p>
				<mat-card *ngFor="let ticket of tickets; let i = index" (click)="viewTicketDetails(i)">
					<div class="mat-card d-flex align-items-center mat-card-small">
						<div class="card-content-2">
							<div class="card-title card-title-nodes">
								{{ ticket.subject }}
							</div>
							<div [class]="'card-des status ' + getStatusClass(ticket.status)">
								{{ ticket.status }}
							</div>
						</div>
					</div>
				</mat-card>
			</div>

			<!-- !view ticket details -->
			<div class="page-content" *ngIf="page === 5">
				<div class="view-ticket-details">
					<small class="text-muted">
						{{ selectedTicket.email }}
					</small>
					<div class="ticket-details mt-3">
						<div class="ticket-title d-flex mb-2 align-items-center justify-content-between">
							<h4 class="fw-bold">
								{{ selectedTicket.subject }}
							</h4>
							<div class="status-txt">
								{{ selectedTicket.status }}
							</div>
						</div>
						<p class="desc lh-base">
							{{ selectedTicket.description }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

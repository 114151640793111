import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ErrorMessageService } from '../error-message.service';
export interface ValidatorMessages {
	validationType: string;
	validationMessage: string;
}
@Component({
	selector: 'lolly-tb06',
	templateUrl: './tb06.component.html',
	styleUrls: ['./tb06.component.scss']
})
export class LollyTb06Component implements OnInit {
	@Input() public placeHolderTextBox: string;
	@Input() public icon: string;
	@Input() public disabled: boolean = false;
	@Input() public validatorMessages: ValidatorMessages[];
	@Input() public control: FormControl | AbstractControl;
	public errorMessage: string;

	constructor(public errorMessageService: ErrorMessageService) {}

	public ngOnInit(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { GridsterModule } from 'angular-gridster2';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MaterialModule } from '../material.module';
import { ManageAdAccountPermissionsComponent } from '../user-management/components/account/facebook-inputs/ad-account-inputs/manage-ad-account-permissions.component';
import { DragAndDropDirective } from '../user-management/directives/drag-and-drop.directive';
import { DragDropDirective } from '../_directives/drag-drop.directive';
import { BusinessOwnerService } from '../_services/facebook-accounts/business-owner.service';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { UserServiceApi } from '../_services/user/user.api.service';
import { NoResourceComponent } from './404/no-resource.component';
import { AdBlockerComponent } from './ad-blocker/ad-blocker.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { AdsManagerNameEditComponent } from './ag-grid-custom-cell-edit-renderers/ads-manager-name-edit/ads-manager-name-edit.component';
import { AdsManagerActionCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-action-cell/ads-manager-action-cell.component';
import { AdsManagerCompareCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-compare-cell/ads-manager-compare-cell.component';
import { AdsManagerDateCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-date-cell/ads-manager-date-cell.component';
import { AdsManagerEditCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-edit-cell/ads-manager-edit-cell.component';
import { AdsManagerImageCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-image-cell/ads-manager-image-cell.component';
import { AdsManagerLinkCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-link-cell/ads-manager-link-cell.component';
import { AdsManagerToggleCellComponent } from './ag-grid-custom-cell-renderers/ads-manager-toggle-cell/ads-manager-toggle-cell.component';
import { CustomDateComponent } from './ag-grid-custom-cell-renderers/custom-date-component/custom-date-component.component';
import { CustomFieldCellRendererComponent } from './ag-grid-custom-cell-renderers/custom-field-cell-renderer/custom-field-cell-renderer.component';
import { AssetsGalleryComponent } from './assets-gallery/assets-gallery.component';
import { GalleryMatTabComponent } from './assets-gallery/components/gallery-mat-tab/gallery-mat-tab.component';
import { UploadComponent } from './assets-gallery/components/upload/upload.component';
import { BackOfficeNavigationComponent } from './back-office-navigation/back-office-navigation.component';
import { ButtonComponent } from './button-component/button.component';
import { ButtonRowComponent } from './button-row/button-row.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NgbToMomentToString } from './calendar/calendar.pipe';
import { CalendarService } from './calendar/calendar.service';
import { ChartsComponent } from './charts/charts.component';
import { GenericChartsComponent } from './charts2/generic-charts.component';
import { ClickOutsideDirective } from './click-outside-directive/click-outside.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DropDownButtonComponent } from './drop-down-button/drop-down-button.component';
import { DropdownIconComponent } from './dropdown-icon/dropdown-icon.component';
import { DropdownSearchSelectComponent } from './dropdown-search-select/dropdown-search-select.component';
import { ChartComponent } from './elements/chart/chart.component';
import { ElementsMetricsComponent } from './elements/elements-metrics/elements-metrics.component';
import { ExpansionPanelScComponent } from './expansion-panel-sc/expansion-panel-sc.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { FilterTabListComponent } from './filter-tab-list/filter-tab-list.component';
import { ErrorMessageService } from './form-input/error-message.service';
import { GenericDropdownComponent } from './form-input/generic-dropdown/generic-dropdown.component';
import { InputCardNumberComponent } from './form-input/input-card-number/input-card-number.component';
import { InputCvcComponent } from './form-input/input-cvc/input-cvc.component';
import { InputBillingDateComponent } from './form-input/input-date/input-billing-date.component';
import { InputMultipleEmailComponent } from './form-input/input-multiple-email/input-multiple-email.component';
import { InputNumberCardDirective } from './form-input/input-number-card.directive';
import { InputNumberValueDirective } from './form-input/input-number-value.directive';
import { InputNumberComponent } from './form-input/input-number/input-number.component';
import { InputPasswordComponent } from './form-input/input-password/input-password.component';
import { InputTextComponent } from './form-input/input-text/input-text.component';
import { InputTextareaComponent } from './form-input/input-textarea/input-textarea.component';
import { LineInputTextComponent } from './form-input/line-input-text/line-input-text.component';
import { GenericSearchComponent } from './generic-search/generic-search.component';
import { GenericTabsComponent } from './generic-tabs/generic-tabs.component';
import { HourSelectorModule } from './hour-selector/hour-selector.module';
import { HoverSelectDirective } from './hover-select-directive/hover-select.directive';
import { InfoBoxComponent } from './info/info-box/info-box.component';
import { InfoHoverComponent } from './info/info-hover/info-hover.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { InlineSelectorComponent } from './inline-selector/inline-selector.component';
import { KnowledgeBasePopUpComponent } from './knowledge-base/knowledge-base-pop-up/knowledge-base-pop-up.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { AgGridDirective } from './master-table/directives/ag-grid.directive';
import { MasterTableComponent } from './master-table/master-table.component';
import { CheckPermissionsDirective } from './permisions/check-permissions.directive';
import { PermissionsService } from './permisions/permissions.service';
import { TablePermissionService } from './permisions/table-permissions/table-permission-service';
import { TablePermissionsComponent } from './permisions/table-permissions/table-permissions.component';
import { ContainsPipe } from './pipes/contains';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { FilterTextPipe } from './pipes/filterText';
import { FormatTextPipe } from './pipes/format-text-pipe';
import { CamelCaseToTextPipe } from './pipes/humanize';
import { LimitLengthPipe } from './pipes/limit-length.pipe';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html';
import { SnakeCaseToHuman } from './pipes/snake-case-to-human.pipe';
import { ArraySortPipe } from './pipes/sort';
import { GenericPopupComponent } from './pop-ups/generic-popup/generic-popup.component';
import { RecommendationTemplateComponent } from './recommendation-template/recommendation-template.component';
import { AdAccountResolver } from './resolvers/ad-account.resolver';
import { RoundedImageContainerComponent } from './rounded-image-container/rounded-image-container.component';
import { ScrollOutsideDirective } from './scroll-outside-directive/scroll-outside.directive';
import { GoogleInsightsMetadataService } from './services/google-insights-metadata.service';
import { SettingsComponent } from './settings/settings.component';
import { LiveSearchDropdownComponent } from './smart-create-edit-components/live-search-dropdown/live-search-dropdown.component';
import { BidStrategyCardComponent } from './smart-create-edit-components/step-one-components/bid-strategy-card/bid-strategy-card.component';
import { NameExpansionPanelComponent } from './smart-create-edit-components/step-one-components/name-expansion-pannel/name-expansion-panel.component';
import { ObjectiveCardComponent } from './smart-create-edit-components/step-one-components/objective-card/objective-card.component';
import { SpecialAdCategoryCardComponent } from './smart-create-edit-components/step-one-components/special-ad-category-card/special-ad-category-card.component';
import { AdCreationInputFormComponent } from './smart-create-edit-components/step-three-components/ad-format/ad-creation-input-form/ad-creation-input-form.component';
import { AdFormatSelectionCardComponent } from './smart-create-edit-components/step-three-components/ad-format/ad-format-selection-card.component';
import { AssetPickerDialogComponent } from './smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-picker-dialog.component';
import { BuildUrlParameterDialogComponent } from './smart-create-edit-components/step-three-components/ad-format/build-url-parameter-dialog/build-url-parameter-dialog.component';
import { DropDirective } from './smart-create-edit-components/step-three-components/ad-format/drop.directive';
import { PixelCardComponent } from './smart-create-edit-components/step-three-components/pixel-card/pixel-card.component';
import { BudgetCardComponent } from './smart-create-edit-components/step-two-components/budget-card/budget-card.component';
import { DateCardComponent } from './smart-create-edit-components/step-two-components/date-card/date-card.component';
import { PagesCardComponent } from './smart-create-edit-components/step-two-components/pages-card/pages-card.component';
import { PlacementsCardComponent } from './smart-create-edit-components/step-two-components/placements-card/placements-card.component';
import { AudienceNamePopUpComponent } from './smart-create-edit-components/step-two-components/targeting-card/pop-up/audience-name-pop-up.component';
import { SpinnerFiledComponent } from './spinner-filed/spinner-filed.component';
import { SpinnerComponent } from './spinner-filed/spinner.component';
import { GeneralSettingsEffects } from './state/effects/general-settings-effects.service';
import { InsightsEffects } from './state/effects/insights-effects.service';
import { sharedReducer } from './state/shared.reducer';
import { userReducer } from './state/user/user.reducer';
import { TableTabsItemsComponent } from './table-tabs-items/table-tabs-items.component';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { TreeChecklistComponent } from './tree-checklist/tree-checklist.component';
import { WarningAnimationMessageComponent } from './warning-animation-message/warning-animation-message.component';
import { WarningMessageNoDataComponent } from './warning-message-no-data/warning-message-no-data.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { WalkthroughGenericSmallDialogComponent } from './walkthrough-generic-small-dialog/walkthrough-generic-small-dialog.component';
import { InfluencerInputFieldComponent } from './influencer-input-field/influencer-input-field.component';
import { CustomAutoFocusDirective } from './directives/custom-auto-focus.directive';
import { KendoGridComponent } from './kendo-grid/kendo-grid.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { LollySaveAndCancelBtnComponent } from './lolly-save-and-cancel-btn/lolly-save-and-cancel-btn.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UserInputStringDialogComponent } from './user-input-string-dialog/user-input-string-dialog.component';
import { MobileSidenavHeaderComponent } from './mobile-sidenav-header/mobile-sidenav-header.component';
import { EllipsisLoaderComponent } from './ellipsis-loader/ellipsis-loader.component';
import { LollyLoadingDialogComponent } from './lolly-loading-dialog/lolly-loading-dialog.component';
import { PrimaryButtonComponent } from '../shared/primary-button/primary-button.component';
import { FiledFontComponent } from '../shared/filed-font/filed-font.component';
import { LollyBtn09Component } from './btn09/btn09.component';
import { DropdownWoInputComponent } from './dropdown-wo-input/dropdown-wo-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TextInputWithLabelComponent } from './text-input-with-label/text-input-with-label.component';
import { AppLollyOutlineTbComponent } from './outline-tb/outline-tb.component';
import { AllLollyPasswordoutlineTbComponent } from './passwordoutline-tb/passwordoutline-tb.component';
import { NewTooltipComponent } from './new-tooltip/new-tooltip.component';
import { LollyTb06Component } from './tb06/tb06.component';
import { LollyDrp13Component } from './drp13/drp13.component';
import { InputNoSpecialComponent } from './input-no-special/input-no-special.component';
import { NoSpecialCharsDirective } from './no-special-chars.directive';
import { LollySpinningLoaderComponent } from './lolly-spinning-loader/lolly-spinning-loader.component';
import { QuillModule } from 'ngx-quill';
import { VideoPopupComponent } from './pop-ups/video-popup/video-popup.component';
import { SelectDrpWoInputComponent } from './select-drp-wo-input/select-drp-wo-input.component';
import { SelectDrpWInputComponent } from './select-drp-w-input/select-drp-w-input.component';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { ConnectSocialsComponent } from './social-connection/connect-socials/connect-socials.component';
import { SocialConnectionMainComponent } from './social-connection/social-connection-main/social-connection-main.component';
import { SocialPlatformPipe } from './pipes/social-platform.pipe';
import { GenericSmDialogComponent } from './generic-dialogs/generic-sm-dialog/generic-sm-dialog.component';

@NgModule({
	imports: [
		MaterialModule,
		CommonModule,
		StoreModule.forFeature('shared', sharedReducer),
		StoreModule.forFeature('user', userReducer),
		EffectsModule.forFeature([GeneralSettingsEffects, InsightsEffects]),
		AgGridModule,
		MatButtonToggleModule,
		NgSelectModule,
		TranslateModule,
		NgbDatepickerModule,
		HourSelectorModule,
		GridsterModule,
		DragDropModule,
		MatMenuModule,
		NgxEchartsModule,
		FormsModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		NgxMatSelectSearchModule,
		MatDialogModule,
		MatSnackBarModule,
		ScrollingModule,
		RouterModule,
		Ng5SliderModule,
		SlickCarouselModule,
		GridModule,
		DropDownsModule,
		QuillModule.forRoot()
	],
	declarations: [
		CheckPermissionsDirective,
		PrimaryButtonComponent,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		ButtonComponent,
		InfoBoxComponent,
		WelcomePageComponent,
		NoResourceComponent,
		CalendarComponent,
		NgbToMomentToString,
		AddButtonComponent,
		DropDownButtonComponent,
		SpinnerFiledComponent,
		GenericSearchComponent,
		SpinnerComponent,
		ChartsComponent,
		ManageAdAccountPermissionsComponent,
		SettingsComponent,
		FilterTabListComponent,
		AssetsGalleryComponent,
		UploadComponent,
		GalleryMatTabComponent,
		TreeChecklistComponent,
		FilterTextPipe,
		ContainsPipe,
		ConfirmationDialogComponent,
		InformationDialogComponent,
		AdBlockerComponent,
		FiledFontComponent,
		RoundedImageContainerComponent,
		FormatTextPipe,
		WarningMessageNoDataComponent,
		GenericChartsComponent,
		CamelCaseToTextPipe,
		ToastNotificationComponent,
		InputTextComponent,
		ArraySortPipe,
		ButtonRowComponent,
		InfoHoverComponent,
		InlineSelectorComponent,
		InputNumberComponent,
		InputNumberValueDirective,
		LineInputTextComponent,
		InputTextareaComponent,
		InputMultipleEmailComponent,
		InputPasswordComponent,
		DropdownSearchSelectComponent,
		RecommendationTemplateComponent,
		DropdownIconComponent,
		InputCardNumberComponent,
		InputNumberCardDirective,
		InputCvcComponent,
		InputBillingDateComponent,
		DragDropDirective,
		DragAndDropDirective,
		ExpansionPanelComponent,
		ExpansionPanelScComponent,
		HoverSelectDirective,
		ClickOutsideDirective,
		ScrollOutsideDirective,
		FilterArrayPipe,
		WarningAnimationMessageComponent,
		AdsManagerActionCellComponent,
		AdsManagerToggleCellComponent,
		AdsManagerNameEditComponent,
		AdsManagerLinkCellComponent,
		AdsManagerCompareCellComponent,
		AdsManagerImageCellComponent,
		GenericPopupComponent,
		TablePermissionsComponent,
		BackOfficeNavigationComponent,
		TableTabsItemsComponent,
		NameExpansionPanelComponent,
		DateCardComponent,
		BudgetCardComponent,
		PlacementsCardComponent,
		AdFormatSelectionCardComponent,
		PagesCardComponent,
		ObjectiveCardComponent,
		BidStrategyCardComponent,
		SpecialAdCategoryCardComponent,
		PixelCardComponent,
		LiveSearchDropdownComponent,
		AdCreationInputFormComponent,
		AssetPickerDialogComponent,
		BuildUrlParameterDialogComponent,
		DropDirective,
		AdsManagerEditCellComponent,
		AdsManagerDateCellComponent,
		CustomDateComponent,
		MasterTableComponent,
		AgGridDirective,
		LimitLengthPipe,
		KnowledgeBaseComponent,
		KnowledgeBasePopUpComponent,
		GenericDropdownComponent,
		CustomFieldCellRendererComponent,
		AudienceNamePopUpComponent,
		GenericTabsComponent,
		ChartComponent,
		ElementsMetricsComponent,
		SafeURLPipe,
		WalkthroughGenericSmallDialogComponent,
		InfluencerInputFieldComponent,
		CustomAutoFocusDirective,
		KendoGridComponent,
		LollySaveAndCancelBtnComponent,
		UserInputStringDialogComponent,
		MobileSidenavHeaderComponent,
		EllipsisLoaderComponent,
		LollyLoadingDialogComponent,
		LollyBtn09Component,
		DropdownWoInputComponent,
		TextInputComponent,
		TextInputWithLabelComponent,
		AppLollyOutlineTbComponent,
		AllLollyPasswordoutlineTbComponent,
		NewTooltipComponent,
		LollyTb06Component,
		LollyDrp13Component,
		InputNoSpecialComponent,
		NoSpecialCharsDirective,
		LollySpinningLoaderComponent,
		VideoPopupComponent,
		SelectDrpWoInputComponent,
		SelectDrpWInputComponent,
		InfiniteScrollDirective,
		ConnectSocialsComponent,
		SocialConnectionMainComponent,
		SocialPlatformPipe,
		GenericSmDialogComponent
	],
	exports: [
		GenericSmDialogComponent,
		SocialPlatformPipe,
		SelectDrpWoInputComponent,
		LollySpinningLoaderComponent,
		NoSpecialCharsDirective,
		InputNoSpecialComponent,
		LollyDrp13Component,
		LollyTb06Component,
		AllLollyPasswordoutlineTbComponent,
		AppLollyOutlineTbComponent,
		TextInputWithLabelComponent,
		TextInputComponent,
		DropdownWoInputComponent,
		LollyBtn09Component,
		EllipsisLoaderComponent,
		PrimaryButtonComponent,
		MobileSidenavHeaderComponent,
		LollySaveAndCancelBtnComponent,
		CheckPermissionsDirective,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		FiledFontComponent,
		SpinnerFiledComponent,
		NoResourceComponent,
		TranslateModule,
		SpinnerComponent,
		NgbDatepickerModule,
		AddButtonComponent,
		DropDownButtonComponent,
		FilterTabListComponent,
		ManageAdAccountPermissionsComponent,
		GenericSearchComponent,
		WelcomePageComponent,
		MatButtonToggleModule,
		MatMenuModule,
		SettingsComponent,
		TreeChecklistComponent,
		AgGridModule,
		NgxEchartsModule,
		CalendarComponent,
		FilterTextPipe,
		ContainsPipe,
		ChartsComponent,
		CamelCaseToTextPipe,
		RoundedImageContainerComponent,
		FormatTextPipe,
		WarningMessageNoDataComponent,
		MaterialModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		NgxMatSelectSearchModule,
		MatDialogModule,
		MatSnackBarModule,
		ScrollingModule,
		ToastNotificationComponent,
		GenericChartsComponent,
		InputTextComponent,
		ArraySortPipe,
		GridsterModule,
		ButtonRowComponent,
		InfoBoxComponent,
		InfoHoverComponent,
		ButtonComponent,
		InlineSelectorComponent,
		InputNumberComponent,
		LineInputTextComponent,
		InputTextareaComponent,
		InputMultipleEmailComponent,
		InputPasswordComponent,
		RecommendationTemplateComponent,
		DropdownSearchSelectComponent,
		NgSelectModule,
		DropdownIconComponent,
		InputCardNumberComponent,
		InputCvcComponent,
		InputBillingDateComponent,
		DragDropDirective,
		DragAndDropDirective,
		ExpansionPanelComponent,
		ClickOutsideDirective,
		FilterArrayPipe,
		HoverSelectDirective,
		WarningAnimationMessageComponent,
		MasterTableComponent,
		NameExpansionPanelComponent,
		DateCardComponent,
		BudgetCardComponent,
		PlacementsCardComponent,
		AdFormatSelectionCardComponent,
		PagesCardComponent,
		ObjectiveCardComponent,
		BidStrategyCardComponent,
		SpecialAdCategoryCardComponent,
		PixelCardComponent,
		LiveSearchDropdownComponent,
		AdCreationInputFormComponent,
		AssetPickerDialogComponent,
		BuildUrlParameterDialogComponent,
		DropDirective,
		AudienceNamePopUpComponent,
		TableTabsItemsComponent,
		TablePermissionsComponent,
		BackOfficeNavigationComponent,
		LimitLengthPipe,
		GenericPopupComponent,
		KnowledgeBaseComponent,
		GenericDropdownComponent,
		GenericTabsComponent,
		ExpansionPanelScComponent,
		ChartComponent,
		ElementsMetricsComponent,
		SafeURLPipe,
		InfluencerInputFieldComponent,
		CustomAutoFocusDirective,
		KendoGridComponent,
		DropDownsModule,
		SelectDrpWInputComponent,
		InfiniteScrollDirective,
		ConnectSocialsComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [
		TablePermissionService,
		NoSpecialCharsDirective,
		PermissionsService,
		ErrorMessageService,
		TitleCasePipe,
		CalendarService,
		BusinessOwnerService,
		UserServiceApi,
		ConfirmationDialogComponent,
		GoogleInsightsMetadataService,
		AdAccountResolver,
		CheckPermissionsDirective,
		InterceptorsProvider
	]
})
export class SharedModule {}

import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-lolly-save-and-cancel-btn',
	template: '<button [disabled]="disabled" class="{{btnType}}-button"><ng-content></ng-content></button>',
	styleUrls: ['./lolly-save-and-cancel-btn.component.scss']
})
export class LollySaveAndCancelBtnComponent implements OnInit {
	@Input('type') public btnType;
	@Input('disabled') public disabled;

	constructor() {}

	ngOnInit(): void {}
}

<div class="employees-details">
	<div class="employees-header">
		<img alt="icon-employee" class="user-icon" src="./assets/icons/employee-icon.svg" />
		<p class="subtitle-main">Add Users Details</p>
		<p>Add a new user to you Lolly Account. Complete the details below</p>
	</div>

	<form [formGroup]="form">
		<!--Name input -->
		<div class="form-input">
			<app-input-text [control]="form.get('nameFormControl')" [validatorMessages]="validationNameMessages" label="First Name"></app-input-text>
		</div>
		<!--Surname input -->
		<div class="form-input">
			<app-input-text [control]="form.get('surnameFormControl')" [validatorMessages]="validationSurnameMessages" label="Last Name"></app-input-text>
		</div>
		<!--Email input -->
		<div class="form-input">
			<app-input-text
				[control]="form.get('emailFormControl')"
				[readonlyInput]="!!userData.email"
				[validatorMessages]="validationEmailMessages"
				label="Email"
			></app-input-text>
		</div>
		<!--Phone input -->
		<div class="form-input">
			<app-input-number [control]="form.get('phoneFormControl')" [validatorNumMessages]="validationPhoneMessages" label="Phone number"></app-input-number>
		</div>
		<!--Position input -->
		<div class="form-input">
			<app-input-text [control]="form.get('positionFormControl')" [validatorMessages]="validationPositionMessages" label="Position"></app-input-text>
		</div>
	</form>
</div>

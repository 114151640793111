import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorMessageService } from '../error-message.service';
import { Subject } from 'rxjs';
export interface ValidatorMessages {
	validationType: string;
	validationMessage: string;
}
@Component({
	selector: 'passwordoutline-tb',
	templateUrl: './passwordoutline-tb.component.html',
	styleUrls: ['./passwordoutline-tb.component.scss']
})
export class AllLollyPasswordoutlineTbComponent implements OnInit, OnDestroy {
	@Input() public control: FormControl | AbstractControl;
	@Input() public validatorMessages: ValidatorMessages[];
	@Input() public label = 'Enter Password';
	@Input() public placeholder: string;

	public isDestinationFocused: boolean;
	public errorMessage: string;
	public hidePassword = true;
	public myForm: FormGroup;
	public isConfirmHidden = true;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(public errorMessageService: ErrorMessageService) {}

	ngOnInit() {
		this.control.setValidators(Validators.required);

		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public toggleEvent(isConfirm: boolean = false): void {
		if (isConfirm) {
			this.isConfirmHidden = !this.isConfirmHidden;
		} else {
			this.hidePassword = !this.hidePassword;
		}
	}
}

<div class="header {{ headerContentSpaceBetween ? 'justify-content-between' : '' }}" *ngIf="!showChatScreen">
	<ng-container>
		<a *ngIf="showBackButton">
			<div class="btn-start btn-back d-flex align-items-center" (click)="goBack(false)">
				<mat-icon>arrow_back</mat-icon>
			</div>
		</a>
		<!-- <a [routerLink]="['/settings']" class="pofile-img" *ngIf="isProfile">
			<img [src]="profileData?.profile_picture_url" />
		</a> -->
		<a class="pofile-img" *ngIf="isProfile" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMobileNav" aria-controls="offcanvasMobileNav" #mybutton>
			<img [src]="profileData?.profile_picture_url" />
		</a>
		<div *ngIf="messageProfileImg" class="message-usr">
			<div class="user-pic">
				<img [src]="!msgService.selectedUser?.image ? 'assets/images/creators/chat/icon-default-user.svg' : msgService.selectedUser?.image" />
				<div [class]="msgService.selectedUser?.status ? 'online-status online' : 'online-status away'"></div>
			</div>
		</div>
	</ng-container>

	<p class="header-title" id="mobile-header-title">{{ title }}</p>

	<div class="btn-end" (click)="endButtonClicked()" style="margin-left: auto;" *ngIf="isIconVisible && !isImgIconVisible">
		<mat-icon *ngIf="matIcon && matIcon != 'filter_list'">{{ matIcon }}</mat-icon>
		<ng-container *ngIf="matIcon && matIcon == 'filter_list'">
			<mat-icon *ngIf="numberOfFilters == 0" class="filter-icon">{{ matIcon }}</mat-icon>
			<div *ngIf="numberOfFilters > 0" class="filter-icon-wrapper">
				<div class="outer-bubble">{{ numberOfFilters }}</div>
				<mat-icon class="f-icon-blue">{{ matIcon }}</mat-icon>
			</div>
		</ng-container>
	</div>

	<div class="btn-end" (click)="endButtonClicked()" style="margin-left: auto;" *ngIf="!isIconVisible && isImgIconVisible">
		<img class="img-icon" [src]="imgIconPath" />
	</div>

	<div class="btn-end-close" (click)="back()" *ngIf="router.url == '/settings' || sharedService.showCloseBtn">
		<img class="user-info-close-btn" src="assets\icons\mobile-nav\Icon ionic-ios-close.svg" (click)="onCloseChatInfo()" />
	</div>
</div>

<div class="header" *ngIf="showChatScreen">
	<div class="btn-start" (click)="goBack(true)">
		<mat-icon><span class="material-icons">arrow_back_ios_new</span></mat-icon>
	</div>
	<p class="header-title-chat">Chat</p>
</div>

<!-- !mobile nav -->
<div class="offcanvas offcanvas-start offcanvas-sm" tabindex="-1" id="offcanvasMobileNav" aria-labelledby="offcanvasMobileNavLabel">
	<div class="mobile-drawer-nav">
		<div class="mobile-drawer">
			<div class="nav-head d-flex align-items-center">
				<!-- <div class="profile-img">
				</div> -->
				<img class="profile-img" [src]="profileData?.profile_picture_url" />
				<div class="profile-info">
					<strong class="fw-semibold">{{ profileData?.name }}</strong>
					<small class="fw-medium">{{ profileData?.email }}</small>
				</div>
			</div>

			<div class="main-nav-wrapper">
				<ul class="main-nav p-0">
					<li class="d-flex align-items-center" *ngFor="let nav of navConfig; let i = index">
						<img class="logo" [src]="nav.icon" alt="" />
						<strong
							class="fw-semibold"
							(click)="nav.action()"
							data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasMobileNav"
							aria-controls="offcanvasMobileNav"
						>
							{{ nav.title }}
						</strong>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

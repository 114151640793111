import { Component, OnInit } from '@angular/core';
import { ButtonTypeEnum } from '../../button-component/button-type.enum';
import { ButtonClassEnum } from '../../button-component/button-class.enum';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';

@Component({
	selector: 'app-ads-manager-name-edit',
	templateUrl: './ads-manager-name-edit.component.html',
	styleUrls: ['./ads-manager-name-edit.component.scss']
})
export class AdsManagerNameEditComponent implements ICellEditorAngularComp {
	public params: ICellEditorParams;
	public buttonTypeEnum = ButtonTypeEnum;
	public buttonClassEnum = ButtonClassEnum;
	public nameFormControl = new UntypedFormControl(null, Validators.required);
	private gridApi: GridApi;

	public onNoClick(): void {
		this.gridApi.stopEditing(true);
	}

	public updateProperty(): void {
		// handle save and on success close
		// on error try again:D
		this.gridApi.stopEditing();
	}

	public isPopup(): boolean {
		return true;
	}

	public agInit(params: ICellEditorParams): void {
		this.params = params;
		this.gridApi = params.context.componentParent.gridApi;
		this.nameFormControl.setValue(params.value);
	}

	public getValue(): any {
		return this.nameFormControl.value;
	}
}

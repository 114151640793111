import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ColumnDetail } from './filter-tab-list.models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface GroupingFilterListItem {
	label: string;
	filterProperty: string;
	filterValue: string;
}

@Component({
	selector: 'app-filter-tab-list',
	templateUrl: './filter-tab-list.component.html',
	styleUrls: ['./filter-tab-list.scss']
})
export class FilterTabListComponent implements OnInit, OnDestroy {
	@Input() public singleSelection = false;
	@Input() public uniqueKey: string;

	@Input() public set itemList(itemList: any[]) {
		if (!itemList || !itemList.length) {
			return;
		}

		this._itemList = itemList || [];
		this.selectedItemList = [];
	}

	@Input() public groupingFilterList?: GroupingFilterListItem[] = [];
	@Input() public debounceDelay?: number;
	@Input() public filterBy?: string;
	@Input() public formControlName: any;
	@Input() public searchPlaceholder?: string;
	@Input() public showMenu = false;
	@Input() public disableInput = false;
	@Input() public columnDetails?: ColumnDetail[] = [];

	@Input() set selectedItems(value: any[]) {
		if (!value || !value.length) {
			return;
		}

		this.setSelectedItemList(null, value, true);
	}

	@Output() selectionChanged: EventEmitter<any[]> = new EventEmitter();

	@ViewChild('selectionModel') selectionModel: MatSelectionListChange;
	@ViewChild('mainContainer') container: ElementRef;

	public disableFieldsSubject: Subject<any[]> = new Subject<any[]>();
	public selectedItemList: any[] = [];
	public timeout: any;
	public filterSearch: string;

	public form: UntypedFormGroup = new UntypedFormGroup({
		selection: new UntypedFormControl('')
	});

	public get itemList(): any[] {
		return this._itemList;
	}

	private _itemList: any[] = [];
	private disabledEntries: any[] = [];
	private unsubscriber$: Subject<void> = new Subject<void>();

	@HostListener('document:click', ['$event.target'])
	public onClickEvent(element: HTMLElement): void {
		if (!this.container || !this.container.nativeElement) {
			return;
		}

		const clickedInside = !!this.container.nativeElement.contains(element);
		if (!clickedInside) {
			this.hideList();
		}
	}

	constructor() {}

	ngOnDestroy() {
		this.removeSubscriptions();

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	ngOnInit() {
		this.init();
		this.addSubscriptions();
	}

	private addSubscriptions = (): void => {
		this.disableFieldsSubject.pipe(takeUntil(this.unsubscriber$)).subscribe((entries: any[]) => {
			this.disabledEntries = entries;
		});
	};

	private removeSubscriptions = (): void => {
		this.disableFieldsSubject.unsubscribe();
	};

	private init = (): void => {
		if (!this.debounceDelay) {
			this.debounceDelay = 500;
		}
		if (!this.filterBy) {
			this.filterBy = 'name';
		}
		if (!this.searchPlaceholder) {
			this.searchPlaceholder = 'Type to search';
		}
		if (!this.uniqueKey) {
			this.uniqueKey = 'id';
		}
	};

	public removeSelectedItem(index: number): void {
		this.selectedItemList.splice(index, 1);
		const idListToBeSelected = this.selectedItemList.map(item => item[this.uniqueKey]);
		this.form.controls.selection.setValue(idListToBeSelected);
		this.selectionChanged.emit(this.selectedItemList);
	}

	public deselectAllSelectedItems(): void {
		this.selectedItemList = [];
		this.form.controls.selection.setValue([]);

		this.selectionChanged.emit(this.selectedItemList);
	}

	public checkSelected(item: any, selectedList: any[]): boolean {
		return selectedList.some((selectedItem: any) => selectedItem[this.uniqueKey] === item[this.uniqueKey]);
	}

	public checkIfDisabled(item: any): boolean {
		if (!this.disabledEntries.length) {
			return false;
		}
		return this.disabledEntries.some((disabledEntry: any) => disabledEntry[this.uniqueKey] === item[this.uniqueKey]);
	}

	public hideList(): void {
		this.showMenu = false;
	}

	public showList(): void {
		this.showMenu = true;
	}

	public filterItemList($event: KeyboardEvent) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			// @ts-ignore
			this.filterSearch = $event.target.value;
		}, this.debounceDelay);
	}

	public setSelectedItemList(selectionModel?: MatSelectionListChange, selectedItemList?: any[], preventEmit?: boolean): void {
		if (this.singleSelection) {
			const selectedId = selectionModel ? selectionModel.option.value : selectedItemList[0][this.uniqueKey];
			this.selectedItemList = this._itemList.filter(item => selectedId === item[this.uniqueKey]);
			this.selectionChanged.emit(this.selectedItemList);
		} else {
			const selectedIds = selectionModel
				? selectionModel.source.selectedOptions.selected.map(selection => selection.value)
				: selectedItemList.map(selectedItem => selectedItem[this.uniqueKey]);
			this.selectedItemList = this._itemList.filter(item => selectedIds.some(selectedId => selectedId === item[this.uniqueKey]));
			if (!selectionModel && selectedItemList.length) {
				this.form.controls.selection.setValue(selectedIds);
			}

			!preventEmit && this.selectionChanged.emit(this.selectedItemList);
		}
	}
}

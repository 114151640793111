import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { hasBackOfficePermission, UserState } from '../state/user/user.reducer';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UserRoleAdminGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, private userStore: Store<UserState>) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if (this.authService.isLoggedIn()) {
		// 	return this.userStore.pipe(
		// 		select(hasBackOfficePermission),
		// 		take(1),
		// 		map(isAdmin => {
		// 			if (!isAdmin) {
		// 				this.router.navigate(['/accounts']);
		// 				return false;
		// 			}
		// 			return true;
		// 		})
		// 	);
		// }
		return of(false);
	}
}

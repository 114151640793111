import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { GoogleTimeIncrement } from '../query-builder/models/google-time-increment.enum';
import { QueryBuilderConditionBlock } from '../query-builder/models/query-builder-condition-block.model';
import { QueryBuilderCondition } from '../query-builder/models/query-builder-condition.model';
import { QueryBuilderLogicalOperator } from '../query-builder/models/query-builder-logical-operator.enum';
import { QueryBuilderWhereOperator } from '../query-builder/models/query-builder-where-operator.enum';
import { QueryModel } from '../query-builder/models/query.model';

@Injectable()
export class GoogleInsightsMetadataService {
	private readonly baseUrl = BaseApiUrl.GoogleDataPipeline;

	constructor(private http: HttpClient) {}

	public postGoogleInsightsQuery(
		tableName: string,
		dateStart: moment.Moment,
		dateStop: moment.Moment,
		columns: string[],
		dimensions: string[],
		level: any | any,
		adAccountId?: string,
		structureIds?: string[],
		timeIncrement?: GoogleTimeIncrement
	): Observable<any> {
		let queryModel: QueryModel = {
			TableName: tableName,
			Dimensions: dimensions.map(dimension => {
				return { GroupColumnName: dimension };
			})
		};

		queryModel.Columns = columns.map(column => {
			return { Name: column };
		});

		let where: QueryBuilderConditionBlock = new QueryBuilderConditionBlock(QueryBuilderLogicalOperator.And);

		const adAccountCondition = new QueryBuilderCondition('account_id', QueryBuilderWhereOperator.Equals, adAccountId);

		where.addConditions(adAccountCondition);

		if (structureIds && structureIds.length > 0) {
			const idColumnName = `${level.toLowerCase()}.id`;
			const idCondition = new QueryBuilderCondition(idColumnName, QueryBuilderWhereOperator.In, structureIds);
			where.addConditions(idCondition);
		}

		const dateStartCondition = new QueryBuilderCondition('date_start', QueryBuilderWhereOperator.Equals, dateStart.format('YYYY-MM-DD').toString());
		const dateStopCondition = new QueryBuilderCondition('date_stop', QueryBuilderWhereOperator.Equals, dateStop.format('YYYY-MM-DD').toString());
		where.addConditions(dateStartCondition, dateStopCondition);

		if (timeIncrement) {
			const timeIncrementCondition = new QueryBuilderCondition('time_increment', QueryBuilderWhereOperator.Equals, timeIncrement);
			where.addConditions(timeIncrementCondition);
		}
		queryModel.Where = where;

		return this.http.post(this.baseUrl + 'reports', { query: queryModel });
	}
}

export enum SettingsPageIndexEnum {
	MyAccount,
	UserManagement,
	MySubscription,
	ManagePermissions,
	BillingAndPayment,
	ChangePassword
}

export enum SettingsPageLabelsEnum {
	MyAccount = 'My Account',
	UserManagement = 'User Management',
	MySubscription = 'My Subscription',
	ManagePermissions = 'Manage Permissions',
	BillingAndPayment = 'Billing and Payment',
	ChangePassword = 'Change Password'
}

export enum SettingsPageUrlsEnum {
	MyAccount = '/user-management/my-account',
	UserManagement = '/user-management/users',
	MySubscription = '/user-management/my-subscription',
	ManagePermissions = '/user-management/manage-permissions',
	BillingAndPayment = '/user-management/billing-and-payment',
	ChangePassword = '/user-management/change-password'
}

import { Bits } from '../../shared/permisions/enums/bits';

export enum PermissionsUserEnum {
	View = 1,
	Edit,
	Full
}

export class userPermission {
	frontEndModule: number;
	accessType: number;
}

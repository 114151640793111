<div mat-dialog-content>
	<mat-form-field class="p-b-0">
		Insert column template name
		<input [(ngModel)]="data" matInput max="70" type="text" />
	</mat-form-field>
</div>
<div fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
	<button [mat-dialog-close]="false" mat-button>{{ 'CANCEL' | translate }}</button>
	<button [mat-dialog-close]="data" cdkFocusInitial mat-button>{{ 'SAVE' | translate }}</button>
</div>

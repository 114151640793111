import { Component, OnInit, Input } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-text-input-with-label',
	templateUrl: './text-input-with-label.component.html',
	styleUrls: ['./text-input-with-label.component.scss']
})
export class TextInputWithLabelComponent implements OnInit {
	@Input() public control: FormControl | AbstractControl = new FormControl();
	@Input() public inputLabel: string = '';
	@Input() public maxLength: number = 80;
	@Input() public placeholder: string = '';
	@Input() public helperText: string = '';
	@Input() public showCounter: boolean = false;
	constructor() {}

	ngOnInit(): void {}
}

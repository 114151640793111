import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SourceChannel } from '../sidenav/sidenav-channel-buttons.enum';
import { environment } from '../../../environments/environment';
import { ConnectToGoogleRequest } from '../../authentication/_models/ConnectToGoogleRequest';
import { AuthenticationService } from '../../_services/authentication.service';
import { GoogleService } from '../../_services/google/google.service';
import { ButtonClassEnum } from '../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../shared/button-component/button-type.enum';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from '../../shared/state/user/user.reducer';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

declare var gapi: any;

@Component({
	selector: 'app-link-account',
	templateUrl: './link-account-dialog.component.html',
	styleUrls: ['./link-account-dialog.component.scss']
})
export class LinkAccountDialogComponent implements OnInit, OnDestroy {
	public auth2: any;
	public missingAccount: string;
	public hasConnected = false;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private authenticationService: AuthenticationService,
		private userStore: Store<UserState>,
		private googleService: GoogleService,
		private dialogRef: MatDialogRef<LinkAccountDialogComponent>,
		private changedetectorRef: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public sourceChannel: SourceChannel
	) {}

	ngOnInit() {
		switch (this.sourceChannel) {
			case SourceChannel.Facebook:
				this.initFacebookButton();
				break;
			case SourceChannel.Google:
				this.googleInit();
				break;
		}
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private initFacebookButton() {
		this.missingAccount = 'Facebook';
	}

	private googleInit(): void {
		this.missingAccount = 'Google';
		gapi.load('auth2', () => {
			this.auth2 = gapi.auth2.init({
				client_id: environment.Google.ClientId,
				cookiepolicy: 'single_host_origin',
				scope: 'https://www.googleapis.com/auth/adwords'
			});
		});
	}

	private googleSignIn(): void {
		this.dialogRef.disableClose = true;
		let userSignedIn = false;
		this.auth2.grantOfflineAccess().then((resp: any) => {
			this.auth2.isSignedIn.listen(() => {
				if (!userSignedIn) {
					userSignedIn = true;
					this.createGoogleConnectionRequest(resp['code'])
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe(request => {
							this.googleService
								.connectToGoogleAccount(request)
								.pipe(takeUntil(this.unsubscriber$))
								.subscribe(
									() => {
										this.hasConnected = true;
										this.auth2.signOut();
										this.changedetectorRef.detectChanges();
									},
									error => {
										this.auth2.signOut();
									}
								);
						});
				}
			});
		});
	}

	private createGoogleConnectionRequest(code: string): Observable<ConnectToGoogleRequest> {
		const currentUserProfile = this.auth2.currentUser.get();
		return this.userStore.pipe(
			select(getFiledId),
			take(1),
			switchMap(filedId => {
				return of({
					code: code,
					email: currentUserProfile.getBasicProfile().getEmail(),
					userId: filedId
				});
			})
		);
	}

	public onClick(): void {
		switch (this.sourceChannel) {
			case SourceChannel.Facebook:
				this.facebookSignIn();
				break;
			case SourceChannel.Google:
				this.googleSignIn();
				break;
		}
	}

	private facebookSignIn(): void {}

	public onNoClick(): void {
		this.dialogRef.close(false);
	}

	public signOut(): void {
		this.dialogRef.close(true);
	}
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ToastNotificationService } from '../../../toast-notification/toast-notification.service';
import { Placements } from './placements.model';

@Component({
	selector: 'app-placements-card',
	templateUrl: './placements-card.component.html',
	styleUrls: ['./placements-card.component.scss']
})
export class PlacementsCardComponent implements OnInit, OnChanges {
	@Input() public isPanelSelected: boolean;
	@Input() public platformsPerDevice: any[];
	@Input() public selectedPlacements: any[];
	@Input() public placementsControl: AbstractControl;
	@Input() public platforms: any[];
	@Input() public objective: string;
	@Input() public isInstagramSelected: boolean;

	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public onUpdatePlacements = new EventEmitter<any[]>();

	public state;

	constructor(private toastNotificationService: ToastNotificationService) {}

	public ngOnInit(): void {
		this.onComponentInit();
	}

	public onComponentInit(): void {
		this.platforms = this.platforms.filter(platform => platform.name !== Placements.Messenger && platform.name !== Placements.WhatsApp);
		if (!this.selectedPlacements.length) {
			this.selectedPlacements = this.platforms.filter(item => item.name !== Placements.AppsSites);
		}
		this.setDisable();
		this.checkInstagramSelected();
		this.setState();
		this.onUpdatePlacements.emit(this.selectedPlacements);
		this.placementsControl.patchValue(this.selectedPlacements);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.isInstagramSelected) {
			this.checkInstagramSelected();
			this.setState();
			this.onUpdatePlacements.emit(this.selectedPlacements);
			this.placementsControl.patchValue(this.selectedPlacements);
		}
	}

	public checkInstagramSelected(): void {
		if (this.isInstagramSelected && !this.selectedPlacements.find(placement => placement.name === Placements.Instagram)) {
			this.selectedPlacements.push(this.platforms.filter(platform => platform.name === Placements.Instagram)[0]);
		} else {
			this.selectedPlacements = this.selectedPlacements.filter(placement => placement.name !== Placements.Instagram);
		}
	}

	public onPlacementClick(selected: any): void {
		if (!this.selectedPlacements.find(placement => placement.name === selected.name)) {
			if (selected.imageState !== 'Disabled') {
				this.selectedPlacements.push(selected);
			}
		} else {
			if (selected.name === Placements.Facebook) {
				this.selectedPlacements = this.selectedPlacements.filter(placement => placement.name !== Placements.AppsSites);
			}
			this.selectedPlacements = this.selectedPlacements.filter(placement => placement.name !== selected.name);
		}
		if (selected.name === Placements.AppsSites) {
			this.autSelectFacebook();
		}
		this.setDisable();
		this.setState(true);
		this.onUpdatePlacements.emit(this.selectedPlacements);
		this.placementsControl.patchValue(this.selectedPlacements);
	}

	public setDisable(): void {
		if (this.objective === 'SubObjectives.PostLikes' || this.objective === 'SubObjectives.PageLikes' || this.objective === 'SubObjectives.BrandAwareness') {
			this.platforms.forEach(item => {
				if (item.name === Placements.AppsSites) {
					item.imageState = 'Disabled';
				}
			});
		}
		if (this.objective === 'SubObjectives.PageLikes') {
			this.platforms.forEach(item => {
				if (item.name === Placements.Instagram) {
					item.imageState = 'Disabled';
				}
			});
		}
		this.selectedPlacements = this.selectedPlacements.filter(item => item.imageState !== 'Disabled');
	}

	public autSelectFacebook(): void {
		const isAppsSelected = this.selectedPlacements.filter(item => item.name === Placements.AppsSites);
		const isFacebookSelected = this.selectedPlacements.filter(item => item.name === Placements.Facebook);
		if (isAppsSelected.length && !isFacebookSelected.length) {
			const facebook = this.platforms.filter(item => item.name === Placements.Facebook);
			this.selectedPlacements.push(facebook[0]);
			this.toastNotificationService.sendInfoToast(
				'Audience Network does not work without Facebook placements. As a result, Facebook was automatically selected as well.',
				8000
			);
		}
	}

	public checkIfSelected(placement: any, placements: any[]): boolean {
		let found = false;
		placements.forEach(selectedPlacement => {
			if (selectedPlacement.name === placement.name) {
				found = true;
			}
		});
		return found;
	}

	public setState(isClicked?: boolean): void {
		this.platforms.forEach(item => {
			if (this.selectedPlacements.includes(item)) {
				item.imageState = this.state.Pressed;
			} else if (item.name === Placements.Instagram && !isClicked) {
				item.imageState = this.state.Disabled;
			} else if (item.imageState !== this.state.Disabled && isClicked) {
				item.imageState = this.state.Default;
			}
		});
	}
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { NewStorageKey } from 'src/app/_models/local-storage-key';
import { KnowledgeBaseService } from '../../services/knowledge-base.service';

@Component({
	selector: 'app-knowledge-base',
	templateUrl: './knowledge-base.component.html',
	styleUrls: ['./knowledge-base.component.scss']
})
export class KnowledgeBaseComponent implements OnInit {
	public name: string = '';
	public page: number = 1;
	public topic: string = '';
	public des: string = '';
	public email: string = '';
	public loading: boolean = false;
	public categories = [];
	public categoryLinks = [];
	public tickets = [];
	public selectedTicket: any;
	selectedCategory = '';
	categoriesLink: any;
	public isMobileView: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<KnowledgeBaseComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private knowledgeBaseService: KnowledgeBaseService,
		private toast: ToastNotificationService
	) {}

	ngOnInit(): void {
		if (screen.width < 820) {
			this.isMobileView = true;
		} else this.isMobileView = false;

		let token = JSON.parse(localStorage.getItem(NewStorageKey.decodedJwtIo));
		this.name = token.user_firstname;
		this.email = token.username;

		this.loading = true;
		this.knowledgeBaseService
			.getAllCategories()
			.then(res => {
				this.loading = false;
				this.categories = res.filter((i: any) => i.name == 'Influencers');
			})
			.catch(err => {
				this.onCatch(err);
			});
	}

	public onNoClick(): void {
		this.dialogRef.close();
	}

	public getStatusClass(status: string): string {
		status = status.toLowerCase();
		const map = {
			open: 'open',
			escalated: 'escalated',
			'on hold': 'hold',
			closed: 'close'
		};
		return map[status];
	}

	public openCardPage(id: string, name: string = ''): void {
		this.page = 2;
		this.loading = true;
		this.knowledgeBaseService.getAllCategoryById(id).then(res => {
			this.loading = false;
			this.categoryLinks = res;
			this.selectedCategory = name;
		});
	}

	private resetForm(): void {
		this.topic = '';
		this.des = '';
	}

	public submitTicket(): void {
		this.page = 3;
		this.loading = true;
		this.knowledgeBaseService
			.submitTicket(this.topic, this.des)
			.then(res => {
				this.loading = false;
				this.page = 1;
				this.resetForm();
				this.toast.sendSuccessToast('Request Submitted. Your ticket details have also been emailed to you');
			})
			.catch(err => {
				this.onCatch(err);
			});
	}

	public onCatch(err): void {
		this.loading = false;
		this.toast.sendErrorToast('Something went wrong, Please try again later');
	}

	public openViewTicket(): void {
		this.page = 4;
		this.loading = true;
		this.knowledgeBaseService
			.getAllTickets()
			.then(res => {
				this.loading = false;
				this.tickets = res;
				// console.log(res);
			})
			.catch(err => {
				this.onCatch(err);
			});
	}

	public viewTicketDetails(index: number): void {
		this.page = 5;
		this.loading = true;
		const ticket = this.tickets[index];

		this.knowledgeBaseService
			.getTicket(ticket.ticket_id)
			.then(res => {
				// console.log(res);
				this.loading = false;
				this.selectedTicket = res[0];
			})
			.catch(err => {
				this.goBack();
				this.onCatch(err);
			});
	}

	public goBack(): void {
		if (this.page !== 5) this.page = 1;
		else this.page = 4;
	}

	public goTo(link: string): void {
		window.open(link, '_blank');
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonClassEnum } from '../button-component/button-class.enum';
import { ButtonTypeEnum } from '../button-component/button-type.enum';

@Component({
	selector: 'app-recommendation-template',
	templateUrl: './recommendation-template.component.html',
	styleUrls: ['./recommendation-template.component.scss']
})
export class RecommendationTemplateComponent implements OnInit {
	@Input() public recommendation: any;
	@Input() public showButtons = true;
	@Output() recommendationActionEmitter: EventEmitter<any> = new EventEmitter();

	public levels;
	public imagePath: string;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor() {}

	ngOnInit() {}

	public viewMoreRecommendation(): void {
		const action: any = {
			recommendation: this.recommendation,
			action: 'RecommendationClickedAction.ViewMore'
		};
		this.recommendationActionEmitter.emit(action);
	}

	public getLabelClass(label: string) {
		switch (label) {
		}
	}

	public rejectRecommendation(): void {
		const action: any = {
			recommendation: this.recommendation,
			action: 'RecommendationClickedAction.Reject'
		};
		this.recommendationActionEmitter.emit(action);
	}

	public editStructure(): void {
		const action: any = {
			recommendation: this.recommendation,
			action: 'RecommendationClickedAction.Edit'
		};
		this.recommendationActionEmitter.emit(action);
	}

	// Unused method will be used when we auto-apply recommendations
	public applyRecommendation(): void {
		const action: any = {
			recommendation: this.recommendation,
			action: 'RecommendationClickedAction.Apply'
		};
		this.recommendationActionEmitter.emit(action);
	}
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CellClickEvent, DataBindingDirective, SelectableSettings } from '@progress/kendo-angular-grid';

@Component({
	selector: 'app-kendo-grid',
	templateUrl: './kendo-grid.component.html',
	styleUrls: ['./kendo-grid.component.scss']
})
export class KendoGridComponent implements OnInit {
	@ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
	@ViewChild('grid') public kendo: any;
	@ViewChild('matMenuEdit') private matMenuEdit: ElementRef;
	@Input() public gridColumns;
	@Input() public gridData;
	@Input() public gridView: any[];
	@Input() public checkbox: boolean;
	@Input() public selectedKeys: number[];
	@Input() public pageable;
	@Input() public hidePaginationArrows: boolean = false;
	@Input() public cellAction: any;
	@Input() public headerAction: any;
	@Input() public pageNumber: number;
	@Input() public pageSize: number;
	@Input() public totalPages: number;
	@Input() public pageSizeOptions: number[] = [10, 15, 20, 100];
	@Output() public pageNumberChange = new EventEmitter<any>();
	@Output() public changePageSize = new EventEmitter<any>();
	@Output() public cellClick = new EventEmitter<any>();
	@Output() public columnClick = new EventEmitter<any>();
	@Output() public selectedRows = new EventEmitter<any>();
	@Output() public linkClick = new EventEmitter<number>();
	@Output() public emitStringChanges = new EventEmitter<any>();
	@Output() public filesChanged = new EventEmitter<number>();

	public uploadedFiles: FileList;
	public editinput: string;
	public menuOpen: boolean = false;
	public mySelection: any = [];
	public search = new UntypedFormControl();
	public selectableSettings: SelectableSettings;

	constructor(private router: Router) {
		this.selectableSettings = {
			checkboxOnly: true,
			mode: 'multiple',
			drag: false
		};
	}

	public ngOnInit(): void {}

	public ngAfterViewInit(): void {}

	public styleHeader(i) {
		return {
			'text-align': this.gridColumns[i].alignment,
			'font-weight': '500',
			'padding-left': this.gridColumns[i].alignment === 'center' ? '2em' : '',
			'font-size': '12px',
			'font-family': 'Gilroy'
		};
	}

	public styleRows(i) {
		return {
			color: this.gridColumns[i].color,
			'font-weight': '500',
			'text-align': this.gridColumns[i].alignment,
			cursor: 'pointer',
			'font-size': '12px',
			'font-family': 'Gilroy',
			'white-space': 'nowrap',
			overflow: 'hidden',
			'text-overflow': 'ellipsis',
			width: '100%'
		};
	}

	public onColumnClick(columnName: string, rowIndex: number): void {
		this.columnClick.emit({ name: columnName, index: rowIndex });
	}

	public pageSizeChange(event): void {
		this.pageSize = event?.target.value;
		this.changePageSize.emit(this.pageSize);
	}

	public previousPage(): void {
		this.pageNumberChange.emit(this.pageNumber - 1);
	}

	public nextPage(): void {
		this.pageNumberChange.emit(this.pageNumber + 1);
	}

	public onCellClick(event: CellClickEvent): void {
		this.cellClick.emit(event.dataItem);
	}

	public onLinkClick(index: number): void {
		this.linkClick.emit(index);
	}

	public onKeydown(inputP: string): void {
		this.editinput = inputP;
	}

	public savestring(column: string, rowIndex: number): void {
		let emitData = {
			index: rowIndex,
			edit: this.editinput,
			column: column
		};
		this.emitStringChanges.emit(emitData);
	}

	public edit(value: any): void {
		this.editinput = value;
	}

	public selectRow(e): void {
		if (e.selectedRows.length > 0) {
			e.selectedRows.forEach(x => {
				this.mySelection.push(x.dataItem);
			});
			this.selectedRows.emit({ type: 'add', data: this.mySelection });
		} else {
			let data = [];
			e.deselectedRows.forEach(x => {
				data.push(x.dataItem);
			});
			this.selectedRows.emit({ type: 'remove', data: data });
		}
	}

	public menuClosed(): void {
		this.menuOpen = false;
	}

	public menuOpened(): void {
		this.menuOpen = true;
	}

	public styleObjectRows(i, rowIndex: number) {
		return {
			color: this.gridData[rowIndex]?.objectName?.color,
			'font-weight': '500',
			'text-align': this.gridColumns[i].alignment,
			cursor: 'pointer',
			'font-size': '12px',
			'white-space': 'nowrap',
			overflow: 'hidden',
			'text-overflow': 'ellipsis',
			width: '100%'
		};
	}

	public onFileChange(rowIndex: number) {
		this.filesChanged.emit(rowIndex);
	}
}

<app-expansion-panel-sc
	class="pc-panel"
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[expansionTitle]="'Tracking'"
	[isExpandable]="false"
	[contentMargin]="false"
>
	<div expansion-content class="pc-container">
		<!--	Facebook Pixel	-->
		<div class="pc-content">
			<mat-slide-toggle class="pc-slider" (change)="onPixelToggle()" [disabled]="isPixelMandatory" [checked]="pixelToggle">
				<span>Facebook Pixel</span>
			</mat-slide-toggle>

			<app-dropdown-search-select
				*ngIf="pixelToggle"
				[dropdownData]="pixels"
				[dropdownPlaceholder]="'Select Pixel'"
				[dropdownSelect]="selectType.Simple"
				[dropdownFormControl]="pixelControl"
				(selectionChange)="updateChanges()"
				[searchEnable]="true"
			></app-dropdown-search-select>
		</div>

		<!--	Conversion Events	-->
		<span *ngIf="subObjective === subObjectives.Conversion">Conversion Events</span>
		<div class="pc-content pc-extra" *ngIf="subObjective === subObjectives.Conversion">
			<app-live-search-dropdown
				*ngIf="pixelToggle"
				[dropdownData]="conversionEvents"
				[inputControl]="conversionControl"
				[selectedData]="selectedConversion"
				[dropdownWidth]="21.3"
				[showSelected]="false"
				[inputLabel]="label"
				[columns]="conversionColumns"
				[inputPlaceholder]="'Select an Conversion Event'"
				[singleSelection]="true"
				(selectionChange)="showSelected($event)"
			></app-live-search-dropdown>
		</div>

		<!--	App events	-->
		<div *ngIf="events?.length">
			<mat-slide-toggle class="pc-slider" (change)="onAppEventsToggle()" [checked]="eventsToggle"><span>App Events</span></mat-slide-toggle>
			<app-dropdown-search-select
				*ngIf="eventsToggle"
				[dropdownData]="events"
				[dropdownFormControl]="pixelEventControl"
				[dropdownPlaceholder]="'Select Event'"
				[dropdownSelect]="selectType.Simple"
				[searchEnable]="true"
			></app-dropdown-search-select>
		</div>
	</div>
</app-expansion-panel-sc>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdFormats } from './ad-formats.model';

@Component({
	selector: 'app-ad-format-selection-card',
	templateUrl: './ad-format-selection-card.component.html',
	styleUrls: ['./ad-format-selection-card.component.scss']
})
export class AdFormatSelectionCardComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public selectedFormat: string;
	@Input() public objectiveTree: any;
	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public onUpdateFormatType = new EventEmitter<any>();

	public AdFormatsEnum;
	public subObjectives;
	public state;
	public formats = AdFormats;

	constructor(private store: Store<any>) {}

	public ngOnInit(): void {
		this.filterFormats();
		this.checkDefaultSelection();
	}

	public checkDefaultSelection(): void {
		if (!this.selectedFormat) {
			this.selectedFormat = 'AdFormatsEnum.SingleImage';
			this.formats[0].imageState = this.state.Pressed;
			this.onUpdateFormatType.emit(this.selectedFormat as any);
		} else {
			this.formats.forEach(format => {
				if (format.key === this.selectedFormat) {
					format.imageState = this.state.Pressed;
				}
			});
		}
	}

	public filterFormats(): void {
		this.formats.forEach(format => (format.imageState = this.state.Default));
		if (this.objectiveTree.subObjective === 'SubObjectives.PageLikes' || this.objectiveTree.subObjective === 'SubObjectives.PostLikes') {
			this.formats = this.formats.filter(item => item.key !== 'AdFormatsEnum.Carousel');
			if (this.selectedFormat === 'AdFormatsEnum.Carousel') {
				this.selectedFormat = 'AdFormatsEnum.SingleImage';
			}
		}
		if (this.objectiveTree.subObjective === 'SubObjectives.VideoViews') {
			this.formats = this.formats.filter(item => item.key === 'AdFormatsEnum.Video');
			if (this.selectedFormat === 'AdFormatsEnum.Carousel' || this.selectedFormat === 'AdFormatsEnum.SingleImage') {
				this.selectedFormat = 'AdFormatsEnum.Video';
			}
		}
		if (this.objectiveTree.subObjective === 'SubObjectives.PageLikes' && this.selectedFormat === 'AdFormatsEnum.PagePosts') {
			this.selectedFormat = 'AdFormatsEnum.SingleImage';
		}
	}

	public onRadioButtonClick(selection: string): void {
		if (this.objectiveTree.subObjective !== 'SubObjectives.PageLikes') {
			this.selectedFormat = selection;
			this.onUpdateFormatType.emit(this.selectedFormat as any);
		}
	}

	public onAdFormatClick(selection: any): void {
		if (selection.key !== this.selectedFormat) {
		}
		this.selectedFormat = selection.key;
		this.onUpdateFormatType.emit(this.selectedFormat as any);
	}
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnChanges {
	@Input() spinnerFlag: boolean;
	@Input() delay?: number;

	public localFlag: boolean;
	public hide: boolean;

	private timer1: any;
	private timer2: any;

	private _delay?: number;

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		clearTimeout(this.timer1);

		if (changes.delay && changes.delay.currentValue) {
			this._delay = changes.delay.currentValue;
		}

		if (changes.spinnerFlag && changes.spinnerFlag.currentValue === false && changes.spinnerFlag.firstChange === true) {
			return;
		}

		if (changes.spinnerFlag && changes.spinnerFlag.currentValue && !changes.spinnerFlag.firstChange) {
			this.hide = false;
		}

		if (changes.spinnerFlag && changes.spinnerFlag.currentValue === false && changes.spinnerFlag.firstChange === false) {
			this.hide = false;
			if (this._delay >= 0) {
				await new Promise<void>((resolve, reject) => {
					this.timer1 = setTimeout(() => {
						this.localFlag = changes.spinnerFlag.currentValue;
						resolve();
					}, this._delay);
				});

				this.delayedHide();
			} else {
				this.localFlag = changes.spinnerFlag.currentValue;
				this.delayedHide();
			}
		} else {
			this.localFlag = changes.spinnerFlag.currentValue;
		}
	}

	private delayedHide = () => {
		clearTimeout(this.timer2);
		this.timer2 = setTimeout(() => {
			this.hide = true;
		}, 1000); // todo: 1000 > 850 ( scss )
	};
}

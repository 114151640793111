import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { InstagramService } from '../../../../_services/facebook-accounts/instagram.service';
import { DropdownData } from '../../../dropdown-search-select/dropdown-data.interface';
import { SelectType } from '../../../dropdown-search-select/dropdown-type.enum';
import { MapperService } from '../../../services/mapper.service';
import { InstagramPagesInterface } from './instagram-pages.interface';
@Component({
	selector: 'app-pages-card',
	templateUrl: './pages-card.component.html',
	styleUrls: ['./pages-card.component.scss']
})
export class PagesCardComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public facebookPages: DropdownData<any>[];
	@Input() public instagramPages: DropdownData<any>[];
	@Input() public pagesFormGroup: AbstractControl;
	@Input() private selectedObjective: string;

	@Output() public onExpansionClick = new EventEmitter();

	public selectType = SelectType;
	public isPageLikesSelected: boolean;
	public instaAccountPage: boolean;
	public sortedInstagram: DropdownData<InstagramPagesInterface>[];

	constructor(private instagramService: InstagramService, private mapperService: MapperService) {}

	public ngOnInit(): void {
		this.hideInstagramPages();
		this.getPages(false);
		this.autoSelect();
	}

	public hideInstagramPages(): void {
		if (this.selectedObjective === 'PageLikes') {
			this.isPageLikesSelected = true;
		}
	}

	public autoSelect(): void {
		if (!this.pagesFormGroup.get('facebookId').value && this.facebookPages.length === 1) {
			this.pagesFormGroup.get('facebookId').patchValue(this.facebookPages[0].value);
		}
	}

	public getPages(change: boolean): void {
		if (change) {
			this.pagesFormGroup.get('instagramId').patchValue(null);
		}
		const selectedFacebookPage = this.pagesFormGroup.get('facebookId').value;
		if (selectedFacebookPage) {
			this.instagramService.getInstagramPages(selectedFacebookPage).subscribe(instagramPages => {
				if (instagramPages.length) {
					this.sortedInstagram = this.mapperService.convertToDropdownData(instagramPages, 'id', 'username');
					this.preselectPage();
					this.instaAccountPage = true;
				} else {
					this.pagesFormGroup.get('instagramId').patchValue(null);
					this.sortedInstagram = [];
					this.instaAccountPage = false;
				}
			});
		}
	}

	public preselectPage(): void {
		if (!this.pagesFormGroup.get('instagramId').value) {
			this.pagesFormGroup.get('instagramId').patchValue(this.sortedInstagram[0].value);
			this.pagesFormGroup.get('instagramId').markAsTouched();
		}
	}
}

<kendo-formfield>
	<font08-4 *ngIf="label"><kendo-label text="{{ label }}"></kendo-label></font08-4>
	<kendo-dropdownlist
		[data]="data"
		[formControl]="control"
		(valueChange)="changes($event)"
		[value]="value"
		[textField]="'name'"
		[valueField]="'id'"
		[style.width]="width"
		class="wrapper filed-combobox"
		[filterable]="allowFilter"
		(filterChange)="handleFilter($event)"
	></kendo-dropdownlist>
	<kendo-formerror>{{ errorMessage }}</kendo-formerror>
</kendo-formfield>

import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NewStorageKey, StorageKey } from '../../_models/local-storage-key';

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable({
	providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
	private readonly AUTH_TYPE = 'Bearer';

	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.context.get(BYPASS_LOG) === true) return next.handle(request);

		const token: string = localStorage.getItem(NewStorageKey.token);

		if (token) {
			request = request.clone({ headers: request.headers.set('Authorization', `${this.AUTH_TYPE} ${token}`) });
		}

		return next.handle(request);
	}
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import { PixelService } from '../../../../_services/pixel/pixel.service';
import { Router } from '@angular/router';
import { SetCreditCardService } from '../set-creditcard.service';
import { TranslateService } from '@ngx-translate/core';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { select, Store } from '@ngrx/store';
import { getEditingCardUserManagementPageStatus, UserManagementState } from '../../../state/user-management.reducer';
import * as UserManagementActions from '../../../state/user-management.actions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HideGlobalSpinner } from '../../../../shared/state/shared.actions';
import { BillingTabEnum } from '../../../shared/billing-and-payment/billing-tab.enum';
import { SettingsPageLabelsEnum } from '../../../shared/enums/navigation.enum';

@Component({
	selector: 'app-add-new-card',
	templateUrl: './new-card.component.html',
	styleUrls: ['./new-card.component.scss']
})
export class NewCardComponent implements OnInit, OnDestroy {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	public touched: boolean;
	public SettingsPageLabelsEnum = SettingsPageLabelsEnum;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private pixelService: PixelService,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private setCreditCardService: SetCreditCardService,
		private translate: TranslateService,
		private backOfficeService: BackOfficeService,
		private store: Store<UserManagementState>
	) {}

	ngOnInit() {
		this.fetchStore();
	}

	ngOnDestroy() {
		this.store.dispatch(new UserManagementActions.SetActiveBillingPageUserManagement(null));

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public addNewCard(): void {
		this.store.dispatch(new UserManagementActions.SetSubmitStatusForEditPage(true));
	}

	private fetchStore(): void {
		this.store.dispatch(new HideGlobalSpinner());
		this.store.dispatch(new UserManagementActions.SetActiveBillingPageUserManagement(BillingTabEnum.AddNewCard));

		this.store.pipe(select(getEditingCardUserManagementPageStatus), takeUntil(this.unsubscriber$)).subscribe(status => {
			this.touched = status.touched;
		});
	}
}

<div class="input-wrapper" style="width: 100%;">
	<div class="example-form-icon">
		<mat-form-field
			[ngClass]="{
				'input-error-message': control.invalid && control.touched,
				'input-success-message': control.valid && control.touched,
				'input-disabled-message': control.disabled || readonlyInput
			}"
			appearance="outline"
		>
			<mat-label>
				{{ label }}
				<span [ngClass]="{ asterisk: asterisk }" *ngIf="asterisk">*</span>
			</mat-label>
			<input [formControl]="control" matInput placeholder="{{ placeholder }}" type="text" autocomplete="off" [readonly]="readonlyInput" />
			<button *ngIf="control.invalid && (control.dirty || control.touched)" class="error-button" kendoButton look="clear" icon="exception"></button>
			<button *ngIf="control.valid" kendoButton look="clear" icon="success" class="success-button"></button>
			<mat-error *ngIf="control.invalid && (control.dirty || control.touched)">{{ errorMessage }}</mat-error>
		</mat-form-field>
	</div>
</div>

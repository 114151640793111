import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserTokenData } from 'src/app/creators-module/modules/authentication/models/auth.model';
import { AuthenticationService } from 'src/app/creators-module/modules/authentication/services/authentication.service';
import { SettingsService } from 'src/app/creators-module/modules/settings/services/settings.service';
import { NewStorageKey } from 'src/app/_models/local-storage-key';
@Injectable()
export class AuthenticationGuard implements CanActivate {
	constructor(public router: Router, public ngZone: NgZone, private authService: AuthenticationService, private setService: SettingsService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const token = localStorage.getItem(NewStorageKey.token);
		let decodedJwt: UserTokenData = JSON.parse(localStorage.getItem(NewStorageKey.decodedJwtIo));
		if (token && decodedJwt) {
			if (!this.authService.isTokenExpired(decodedJwt)) {
				this.authService.changeAuthState(token);
				this.setService.getProfileData();
				return of(true);
			} else {
				this.authService.signOut();
				return of(false);
			}
		} else if (token) {
			decodedJwt = this.authService.decodeToken(token);
			if (decodedJwt && !this.authService.isTokenExpired(decodedJwt)) {
				this.authService.changeAuthState(token);
				localStorage.setItem(NewStorageKey.decodedJwtIo, JSON.stringify(decodedJwt));
				this.setService.getProfileData();
				localStorage.setItem(NewStorageKey.first_time_user, 'true');
				return of(true);
			} else {
				this.authService.signOut();
				return of(false);
			}
		} else {
			this.authService.signOut();
			return of(false);
		}
	}
}

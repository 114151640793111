import { Injectable } from '@angular/core';
import moment from 'moment';
import { DateRange } from '../../shared/calendar/calendar.models';
import { ChartSeriesModel } from '../../shared/charts2/chart-models/chart-series.model';

@Injectable()
export class PixelService {
	public static mapPixels(pixels: any[]): any[] {
		const mappedPixels: any[] = [];
		pixels.forEach(pixel => {
			mappedPixels.push({
				...pixel,
				status: pixel.statusId === 1
			});
		});
		return mappedPixels;
	}

	public getQueryForPixelDetails(facebookId: string, breakdown: string, selectedDateRange: DateRange, level: any, facebookName = ''): any {
		return {
			facebookId: facebookId,
			facebookName: facebookName,
			breakdown: breakdown,
			startTime: selectedDateRange.startDate.format('YYYY-MM-DD'),
			endTime: selectedDateRange.endDate.format('YYYY-MM-DD'),
			level: level
		};
	}

	public formatInsightsForChart(data: any[], dates: string[]): Map<string, ChartSeriesModel[]> {
		if (!data || data.length === 0) {
			return;
		}
		const finalSeries: ChartSeriesModel[] = [];

		const mappedByEvent: Map<string, any[]> = new Map<string, any[]>();

		for (const insight of data) {
			const formattedInsight = {
				...insight,
				timestamp: moment(insight.timestamp, 'YYYY/MM/DD').format('DD/MM/YY')
			};
			if (!mappedByEvent.has(formattedInsight.value)) {
				mappedByEvent.set(formattedInsight.value, []);
			}
			mappedByEvent.get(formattedInsight.value).push(formattedInsight);
		}
		// format the data by Parameters_Until so that we can get the sum of values of events by date
		for (const event of Array.from(mappedByEvent.keys())) {
			const series = new ChartSeriesModel();
			series.name = event;
			series.data = new Array(dates.length);

			const mappedByTimestamp: Map<string, any[]> = new Map<string, any[]>();
			mappedByEvent.get(event).forEach(insight => {
				if (!mappedByTimestamp.has(insight.timestamp)) {
					mappedByTimestamp.set(insight.timestamp, []);
				}
				mappedByTimestamp.get(insight.timestamp).push(insight);
			});

			mappedByTimestamp.forEach(dto => {
				let totalCount = 0;
				dto.forEach(x => (totalCount += x.count));
				const timestamp = dto[0].timestamp;
				const index = dates.indexOf(timestamp);
				if (index !== -1) {
					series.data[index] = totalCount;
				}
			});
			finalSeries.push(series);
		}
		return new Map().set(data[0].breakdown, finalSeries);
	}
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Column } from '../../../_models/TableModels/column';
import { UntypedFormControl } from '@angular/forms';
import { PixelServiceApi } from '../../../_services/pixel/pixel.api.service';
import { PixelServiceMetadata } from '../../../_services/pixel/pixels-metadata.service';
import { TableColumn } from '../../../_models/TableModels/table-column';
import { SaveTableColumnViewComponent } from '../save-table-column-view/save-table-column-view.component';
import { TableView, TableViewColumn } from '../../../_models/TableModels/table-view';
import { AudienceService } from '../../../_services/audience/audience.service';
import { AdAccountApiService } from '../../../_services/facebook-accounts/ad-account-api.service';
import { OptimizationService } from '../../../_services/optimization/optimization.service';
import { ToastNotificationService } from '../../toast-notification/toast-notification.service';
import { QueryBuilderAggregatorEnum } from '../../query-builder/models/query-builder.aggregator.enum';
import { AudienceMetadataService } from '../../../_services/audience/audience-metadata.service';
import { takeUntil } from 'rxjs/operators';

enum TableColumnTitleEnum {
	Pixel = 'pixel',
	CampaignInsight = 'campaign_insight',
	AdsetInsight = 'adset_insight',
	AdInsight = 'ad_insight',
	Audience = 'audience',
	AdAccounts = 'adaccounts',
	OptimizationCompaigns = 'optimization_campaigns',
	OptimizationAdSets = 'optimization_adsets',
	OptimizationAds = 'optimization_ads'
}

@Component({
	selector: 'app-table-columns-management',
	templateUrl: './table-columns-management.component.html',
	styleUrls: ['./table-columns-management.component.scss']
})
export class TableColumnsManagementComponent implements OnInit {
	public columnFilterCtrl: UntypedFormControl = new UntypedFormControl();
	public filteredColumns: ReplaySubject<TableColumn[]> = new ReplaySubject<TableColumn[]>(1);
	allColumns: TableColumn[];
	column: Column;
	displayedColumns: TableColumn[] = [];
	tableView: TableView;
	columnsView: TableViewColumn[] = [];

	private getColumnActions: { [key: string]: Observable<TableColumn[]> };
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<TableColumnsManagementComponent>,
		public dialogRefSave: MatDialogRef<SaveTableColumnViewComponent>,
		public dialog: MatDialog,
		public toastNotificationService: ToastNotificationService,
		private pixelServiceApi: PixelServiceApi,
		private pixelServiceMetadata: PixelServiceMetadata,
		private audienceService: AudienceService,
		private audienceMetadataService: AudienceMetadataService,
		private facebookAccountService: AdAccountApiService,
		private optimizationService: OptimizationService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
		this.getColumns();
		this.data.displayedColumns.forEach((item: any) => {
			const column: TableColumn = {
				tableName: this.data.title,
				columnName: item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase(),
				columnDisplayName: item.name.toLowerCase(),
				isDimension: false,
				aggregationType: QueryBuilderAggregatorEnum.None,
				type: item.type
			};
			if (column.columnName !== 'Master_checkbox' && column.columnName !== 'Actions') {
				this.displayedColumns.push(column);
			}
		});
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	hideColumn(parameter: any) {
		const index = this.displayedColumns.indexOf(parameter);
		this.displayedColumns.splice(index, 1);
		this.allColumns.push(parameter);
		this.filterColumns();
	}

	addColumn(parameter: any) {
		this.allColumns.forEach((result: any) => {
			if (
				result.columnDisplayName === parameter.columnDisplayName &&
				!this.displayedColumns.some(i => i.columnDisplayName === parameter.columnDisplayName.toLowerCase())
			) {
				this.displayedColumns.push(result);
				this.allColumns.splice(this.allColumns.indexOf(result), 1);
				this.filterColumns();
			}
		});
	}

	lockedColumn() {
		this.toastNotificationService.sendInfoToast('This column is locked to the table.');
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
	}

	doSomething(result: any) {
		this.allColumns = result;
		this.spliceColumnsArray(this.allColumns);
		this.filteredColumns.next(this.allColumns.slice());
		this.columnFilterCtrl.valueChanges
			.pipe()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.filterColumns();
			});
	}

	getColumns(): Subscription {
		let action: Observable<TableColumn[]> = null;
		let subscription: Subscription = null;

		switch (this.data.title) {
			case TableColumnTitleEnum.Pixel:
				action = this.pixelServiceMetadata.getTableColumns();
				break;
			case TableColumnTitleEnum.Audience:
				action = this.audienceMetadataService.getTableColumns();
				break;
			case TableColumnTitleEnum.AdAccounts:
				action = this.facebookAccountService.getTableColumns();
				break;
			case TableColumnTitleEnum.OptimizationCompaigns:
				action = this.optimizationService.getTableColumns();
				break;
			case TableColumnTitleEnum.OptimizationAdSets:
				action = this.optimizationService.getTableColumns();
				break;
			case TableColumnTitleEnum.OptimizationAds:
				action = this.optimizationService.getTableColumns();
				break;
			default:
				action = null;
		}

		if (action) {
			subscription = action.pipe(takeUntil(this.unsubscriber$)).subscribe(result => {
				this.doSomething(result);
			});
		}

		return subscription;
	}

	spliceColumnsArray(allColumns: any[]) {
		this.data.displayedColumns.forEach((item: any) => {
			const arrIndex = allColumns.find(i => i.columnDisplayName.toLowerCase() === item.name.toLowerCase());
			if (arrIndex) {
				allColumns.splice(allColumns.indexOf(arrIndex), 1);
			}
		});
	}

	filterColumns() {
		if (!this.allColumns) {
			return;
		}
		let search = this.columnFilterCtrl.value;
		if (!search) {
			this.filteredColumns.next(this.allColumns.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredColumns.next(this.allColumns.filter((item: any) => item.columnDisplayName.toLowerCase().indexOf(search) > -1));
	}

	saveColumnsView(parameter: any) {
		if (parameter) {
			this.dialogRefSave = this.dialog.open(SaveTableColumnViewComponent, {
				width: '400px',
				data: ''
			});
			this.dialogRefSave
				.afterClosed()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(result => {
					if (!result) {
						this.toastNotificationService.sendErrorToast('You must insert a name for the column set.');
					} else {
						this.displayedColumns.forEach(item => {
							const columnView: TableViewColumn = {
								name: item.columnDisplayName,
								primaryValue: item,
								secondaryValue: null,
								type: item.type,
								width: '100'
							};
							this.columnsView.push(columnView);
						});
						this.tableView = {
							name: result,
							default: false,
							columns: this.columnsView
						};
						this.columnsView = [];
					}

					let action: Observable<TableView | TableView[]>;

					if (this.data.title === TableColumnTitleEnum.Pixel) {
						action = this.pixelServiceMetadata.createTableView(this.tableView);
					} else if (this.data.title === TableColumnTitleEnum.Audience) {
						action = this.audienceMetadataService.getTableViews();
					} else if (this.data.title === TableColumnTitleEnum.AdAccounts) {
						action = this.facebookAccountService.createTableView(this.tableView);
					}

					if (action) {
						action.pipe(takeUntil(this.unsubscriber$)).subscribe();
					}
				});
		}
	}

	displaySelectedColumns() {
		this.data.allColumns = [];
		this.displayedColumns.forEach(item => {
			const column: Column = {
				name: item.columnName,
				primaryValue: item.columnDisplayName,
				secondaryValue: null,
				type: item.type,
				displayedByDefault: true,
				width: '100',
				sticky: false
			};
			this.data.allColumns.push(column);
		});
	}
}

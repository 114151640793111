<app-expansion-panel-sc
	*ngIf="!campaignBudgetOptimization"
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[isExpandable]="false"
	[expansionTitle]="'AdSet Budget'"
	[expansionSecondLine]="'Optimization'"
>
	<div expansion-content class="abo-budget-optimization-wrapper">
		<div class="abo-content">
			<mat-radio-group class="abo-radio-group" [formControl]="adSetBudget.get('budgetType')" name="budgetType">
				<mat-radio-button class="abo-radio-button" [value]="budgetType.LifeTime" (click)="onBudgetTypeClick(budgetType.LifeTime)" name="budgetType">
					<span>Lifetime</span>
				</mat-radio-button>
				<mat-radio-button class="abo-radio-button" [value]="budgetType.Daily" (click)="onBudgetTypeClick(budgetType.Daily)" name="budgetType">
					<span>Daily</span>
				</mat-radio-button>
			</mat-radio-group>
			<div class="abo-budget">
				<span>Budget</span>
				<div class="abo-input">
					<app-input-number
						[validatorNumMessages]="validationMessage"
						[control]="adSetBudget.get('amount')"
						placeholder="200"
						(keydown.space)="$event.preventDefault()"
						[currencyStr]="currency"
					></app-input-number>
				</div>
			</div>

			<div class="abo-radio-second-group">
				<span>AdSet bid strategy</span>
				<mat-radio-button class="abo-first-button" [disabled]="true" [checked]="true" color="disabled" name="bid-strategy">
					<span>Lowest Cost</span>
				</mat-radio-button>
				<span class="abo-extra">Delivery type</span>
				<mat-radio-button class="abo-second-button" [disabled]="true" [checked]="true" color="disabled" name="delivery-type">
					<span>Standard</span>
				</mat-radio-button>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>

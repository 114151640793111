import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
import { GenericError } from './error-object.model';

@Injectable()
export class ErrorsLoggingServiceApi {
	private readonly baseUrl = `${BaseApiUrl.LogErrors}`;
	constructor(private http: HttpClient) {}

	public sendError(error: GenericError<any>): Observable<GenericError<typeof error>> {
		return this.http.post<GenericError<typeof error>>(`${this.baseUrl}log`, error);
	}
}

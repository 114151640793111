<!-- notification input type number -->
<div class="input-wrapper">
	<div class="input-money-wrapper">
		<mat-form-field
			[ngClass]="{
				'error-color-message': control.invalid && control.touched,
				'success-color-message': control.valid && control.touched,
				'disabled-color-message': control.disabled
			}"
			appearance="outline"
		>
			<mat-label>{{ label }}</mat-label>

			<!--Icon Currency -->
			<i *ngIf="!currencyStr" class="{{ currency }}"></i>
			<div *ngIf="currencyStr">{{ currencyStr }}</div>

			<input
				(focus)="autocompleteFocus()"
				(focusout)="onFocusOut()"
				(keyup)="onKeyUp()"
				[formControl]="control"
				ngDefaultControl
				appInputNumberValue
				autocomplete="off"
				[decimals]="decimals"
				[attr.maxlength]="maxNumber ? maxNumber : null"
				matInput
				placeholder="{{ placeholder }}"
				type="text"
			/>

			<!--Error message -->
			<mat-error *ngIf="showErrors && control.invalid">{{ errorMessage }}</mat-error>
		</mat-form-field>
	</div>
</div>

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
@Component({
	selector: 'app-input-multiple-email',
	templateUrl: './input-multiple-email.component.html',
	styleUrls: ['./input-multiple-email.component.scss', '../shared.scss']
})
export class InputMultipleEmailComponent {
	public emails: any[] = [];
	public selectable = true;
	public removable = true;
	public addOnBlur = true;
	public separatorKeyCodes: number[] = [ENTER, COMMA];
	public isDestinationFocused: boolean;
	public notEmail: boolean;
	@Input() control: UntypedFormControl | AbstractControl;
	@Input() placeholder: string;
	constructor() {}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}
	public remove(email: any): void {
		const index = this.emails.indexOf(email);
		if (index >= 0) {
			this.emails.splice(index, 1);
		}
		this.updateFormControl();
	}
	public add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
		if ((value || '').trim()) {
			if (this.validateEmail(value)) {
				this.emails.push({ name: value.trim() });
			}
		}
		if (!this.validateEmail(value)) {
			this.notEmail = true;
		} else {
			this.notEmail = false;
		}
		if (input) {
			input.value = '';
		}
		this.updateFormControl();
	}
	public validateEmail(email: string): any {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	private updateFormControl(): void {
		this.control.patchValue(this.emails.map(email => email.name).join(';'));
	}
}

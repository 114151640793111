import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopUpInterface } from '../../models/generic-popup-interface';

@Component({
	selector: 'app-generic-popup',
	templateUrl: './generic-popup.component.html',
	styleUrls: ['./generic-popup.component.scss']
})
export class GenericPopupComponent implements OnInit {
	@Input() public panelClass: string;
	public message: string;
	public actionAnswer: string;
	public noActionAnswer: string;
	public header: string;
	public image: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: PopUpInterface, public dialogRef: MatDialogRef<GenericPopupComponent>) {
		this.image = data.image;
		this.header = data.header;
		this.message = data.message;
		this.actionAnswer = data.actionAnswer;
		this.noActionAnswer = data.noActionAnswer;
	}

	ngOnInit(): void {}

	public actionEvent(param: boolean = null): void {
		this.dialogRef.close(param);
	}
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { GenericTableComponent } from './generic-table-component/generic-table.component';
import { SingleSelectionActionsComponent } from './single-selection-actions/single-selection-actions.component';
import { MultipleSelectionActionsComponent } from './multiple-selection-actions/multiple-selection-actions.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableColumnsManagementComponent } from './table-columns-management/table-columns-management.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GenericTableComponentHelperService } from './generic-table-component-helper.Service';
import { SaveTableColumnViewComponent } from './save-table-column-view/save-table-column-view.component';
import { SpinnerFiledComponent } from '../spinner-filed/spinner-filed.component';
import { AudienceService } from '../../_services/audience/audience.service';
import { SharedModule } from '../shared.module';
import { CommonModule } from '@angular/common';
import { InterceptorsProvider } from 'src/app/_services/interceptors/intercepors.provider';

@NgModule({
	imports: [CommonModule, TranslateModule, DragDropModule, ScrollingModule, SharedModule],
	declarations: [
		GenericTableComponent,
		SingleSelectionActionsComponent,
		MultipleSelectionActionsComponent,
		TableColumnsManagementComponent,
		SaveTableColumnViewComponent
	],
	exports: [GenericTableComponent, SingleSelectionActionsComponent, MultipleSelectionActionsComponent, SpinnerFiledComponent],
	providers: [InterceptorsProvider, GenericTableComponentHelperService, AudienceService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class GenericTableModule {}

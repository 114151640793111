import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownData } from '../dropdown-search-select/dropdown-data.interface';
import { SelectType } from '../dropdown-search-select/dropdown-type.enum';
import { ValidatorMessages } from '../form-input/validatorMessagesInputs';
import { ErrorMessageService } from '../form-input/error-message.service';

@Component({
	selector: 'app-dropdown-icon',
	templateUrl: './dropdown-icon.component.html',
	styleUrls: ['./dropdown-icon.component.scss']
})
export class DropdownIconComponent {
	@Input() dropdownIconFormControl: UntypedFormControl;
	@Input() dropdownData: DropdownData[];
	@Input() dropdownIconSelect: SelectType;
	@Input() dropdownSelect: SelectType;
	@Input() noDataFoundLabel: string;
	@Input() searchEnable: boolean;
	@Input() dropdownPlaceholder: string;
	@Input() compareFunction: any;
	@Input() validatorMessages: ValidatorMessages[];

	public errorMessage: string;
	public isDestinationFocused: boolean;

	constructor(public errorMessageService: ErrorMessageService) {}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.dropdownIconFormControl);
	}
}

<perfect-scrollbar class="scrollable">
	<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
		<!-- This is the tree node template for leaf nodes -->
		<mat-tree-node
			(click)="doSomething(node.functionName, selectedRows)"
			*matTreeNodeDef="let node"
			class="p-l-20 p-r-20 d-flex"
			matTreeNodePadding
			matTreeNodePaddingIndent="26"
		>
			<!-- use a disabled button to provide padding for tree leaf -->
			<div [class.delete-action]="node.isDelete">
				<span class="d-flex d-j-c-left {{ node.value }}-label-sa">
					{{ appendKey(node.name) | translate }}
				</span>
				<div *ngIf="node.isDelete">
					<i aria-hidden="true" class="fa fa-trash"></i>
				</div>
			</div>
		</mat-tree-node>
		<!-- This is the tree node template for expandable nodes -->
		<mat-tree-node
			*matTreeNodeDef="let node; when: hasChild"
			[attr.aria-label]="'toggle-' + node.value"
			class="p-l-10 d-flex"
			matTreeNodePadding
			matTreeNodePaddingIndent="26"
			matTreeNodeToggle
			matTreeNodeToggleRecursive
		>
			<span class="d-flex d-j-c-left {{ node.value }}-label-ma">
				{{ appendKey(node.name) | translate }}
			</span>
			<mat-icon class="mat-icon-rtl-mirror d-flex d-j-c-left">
				{{ treeControl.isExpanded(node) ? 'expand_less' : 'expand_more' }}
			</mat-icon>
		</mat-tree-node>
	</mat-tree>
</perfect-scrollbar>

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ValidatorMessages } from '../../../form-input/validatorMessagesInputs';

@Component({
	selector: 'app-name-expansion-panel',
	templateUrl: './name-expansion-panel.component.html',
	styleUrls: ['./name-expansion-panel.component.scss']
})
export class NameExpansionPanelComponent implements OnInit {
	@Input() public isSelected: boolean;
	@Input() public expansionTitle: string;
	@Input() public control: UntypedFormControl | AbstractControl;
	@Input() public validationMessages: ValidatorMessages[];
	@Input() public label: string;
	@Input() public placeholder: string;
	@Input() public showContentMargin = true;

	constructor() {}

	public ngOnInit(): void {}
}

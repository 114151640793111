import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { DropdownData, FilterRule } from './dropdown-data.interface';
import { SelectType } from './dropdown-type.enum';
import { ValidatorMessages } from '../form-input/validatorMessagesInputs';
import { ErrorMessageService } from '../form-input/error-message.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
	selector: 'app-dropdown-search-select',
	templateUrl: './dropdown-search-select.component.html',
	styleUrls: ['./dropdown-search-select.component.scss']
})
export class DropdownSearchSelectComponent implements OnInit, OnChanges, OnDestroy {
	@Input() dropdownData: DropdownData[];
	@Input() dropdownPlaceholder: string;
	@Input() noDataFoundLabel: string;
	@Input() dropdownFormControl: UntypedFormControl | AbstractControl;
	@Input() compareFunction: any;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() dropdownSelect: SelectType = SelectType.Simple;
	@Input() bindProperty = 'value';
	@Input() labelProperty = 'displayName';
	@Input() hideClearButton = true;
	@Input() enableAllActions = true;
	@Input() searchEnable: true;
	@Input() filterRules: FilterRule[] = null;
	@Input() readOnly: boolean;

	@Output() selectionChange = new EventEmitter();
	@Output() onClose = new EventEmitter();
	@Output() filters = new EventEmitter();

	public dropdownDataBuffer: DropdownData[];
	public bufferSize = 500;
	public numberOfItemsFromEndBeforeFetchingMore = 10;
	public loading = false;

	public dropdownSelectType = SelectType;
	public errorMessage: string;
	public isDestinationFocused: boolean;
	public independentFiler: boolean;

	public selectAll: boolean;
	public clearAll: boolean;

	private unsubscriber$: Subject<void> = new Subject<void>();

	private selectedFilters: any[] = [];

	constructor(public errorMessageService: ErrorMessageService) {}

	ngOnInit() {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.dropdownFormControl);

		this.dropdownFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(values => {
			this.selectAll = values?.length === this.dropdownData?.length && this.dropdownData?.length > 0;
			this.clearAll = this.dropdownData?.length > 0 && !values?.length;
		});

		if (this.filterRules) {
			this.filterRules.forEach(filterRule => {
				if (filterRule.selected) {
					this.selectedFilters.push(filterRule.key);
				}
			});

			if (!this.selectedFilters?.length) {
				this.initFilters();
			}
			this.bufferSize = 50;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.dropdownData) {
			if (this.dropdownFormControl.value?.length) {
				this.bufferSize = this.dropdownFormControl.value.length;
			}
			this.dropdownDataBuffer = this.dropdownData.slice(0, this.bufferSize);
		} else {
			this.dropdownDataBuffer = [];
		}

		if (this.dropdownFormControl.value) {
			this.fillBuffer();
		}
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onSelectAll(): void {
		if (this.dropdownData?.length) {
			this.dropdownFormControl.setValue(this.dropdownData.map(data => data.value));
		}
		this.fillBuffer();
		this.goToTop();
		this.selectionChange.emit();
	}

	public onClearAll(): void {
		this.goToTop();
		this.resetBuffer();
		this.dropdownFormControl.setValue([]);
		this.selectionChange.emit();
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.dropdownFormControl);
	}

	public onChange(): void {
		this.selectionChange.emit();
	}

	public onDropdownClose(): void {
		this.onClose.emit();
	}

	public onScrollToEnd(): void {
		this.increaseBuffer();
	}

	public onScroll(index: { start: number; end: number }): void {
		if (this.loading || this.dropdownData.length <= this.dropdownDataBuffer.length) {
			return;
		}

		if (index.end + this.numberOfItemsFromEndBeforeFetchingMore >= this.dropdownData.length) {
			this.increaseBuffer();
		}
	}

	public onResize($event: any, select: NgSelectComponent): void {
		select.close();
	}

	public filterStatus(key: any, removeOthersFilters: boolean): void {
		if (!this.dropdownData) {
			this.dropdownDataBuffer = [];
		}
		if (this.selectedFilters.includes(key)) {
			this.selectedFilters = this.selectedFilters.filter(filterKey => filterKey !== key);
		} else {
			if (removeOthersFilters) {
				this.independentFiler = true;
				this.selectedFilters = [key];
			} else {
				if (this.independentFiler) {
					this.selectedFilters = [key];
					this.independentFiler = false;
				} else {
					this.selectedFilters.push(key);
				}
			}
		}

		this.resetSelectedFilters();

		if (!this.selectedFilters?.length) {
			this.initFilters();
		}

		this.filters.emit(this.selectedFilters);
	}

	private resetBuffer(): void {
		this.bufferSize = 50;
		if (this.dropdownData) {
			this.dropdownDataBuffer = this.dropdownData.slice(0, this.bufferSize);
		}
	}

	private fillBuffer(fill = false): void {
		if ((fill || this.dropdownFormControl.value?.length) && this.dropdownData) {
			this.bufferSize = this.dropdownData.length;
			this.dropdownDataBuffer = this.dropdownData.slice(0, this.bufferSize);
		}
	}

	private increaseBuffer(): void {
		const len = this.dropdownDataBuffer.length;
		const more = this.dropdownData.slice(len, this.bufferSize + len);

		this.loading = true;
		// simulate backend API delay
		setTimeout(() => {
			this.loading = false;
			this.dropdownDataBuffer = this.dropdownDataBuffer.concat(more);
		}, 50);
	}

	private goToTop(): void {
		setTimeout(() => {
			const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
			if (scrollContainer) {
				scrollContainer.scrollTop = 0;
			}
		}, 0);
	}

	private initFilters(): void {
		this.filterRules.forEach((filter: FilterRule) => {
			if (filter.default) {
				this.selectedFilters = [filter.key];
				filter.selected = true;
			} else {
				filter.selected = false;
			}
		});
	}

	private resetSelectedFilters(): void {
		this.filterRules.forEach((filter: FilterRule) => {
			filter.selected = this.selectedFilters.includes(filter.key);
		});
	}

	public onSearch(searchItem: { term: string; items: any[] }): void {
		this.fillBuffer(true);
		if (!searchItem.term) {
			this.resetBuffer();
		}
	}
}

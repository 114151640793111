import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Ng5SliderModule } from 'ng5-slider';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { from, Observable } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountTypeService } from './shared/account-type.service';
import { AuthenticationRedirectGuard } from './shared/guards/authentication-redirect.guard';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { UserRoleAdminGuard } from './shared/guards/user-role-admin-guard.service';
import { SharedModule } from './shared/shared.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { AuthenticationService } from './_services/authentication.service';
import { DashboardService } from './_services/dashboard/dashboard-service';
import { ExcelService } from './_services/excel.service';
// import { BusinessOwnerService } from './_services/facebook-accounts/business-owner.service';
// import { AdvertService } from './_services/facebook-marketing/advert.service';
import { GoogleService } from './_services/google/google.service';
import { InterceptorsProvider } from './_services/interceptors/intercepors.provider';
import { PopupDialogService } from './_services/popup-dialog.service';
import { SidenavService } from './_services/side-nav.service';
import { TableService } from './_services/table.service';
import { UserServiceSubscription } from './_services/user/user-subscription.service';
import { UserServiceApi } from './_services/user/user.api.service';

import { CaseInsensitiveUrlSerializer } from './shared/case-insensitive-url-serializer.service';
import { ComponentRegistryService } from './shared/component-registry.service';
import { MapperService } from './shared/services/mapper.service';
import { clearStateMetaReducer, initialAppState, reducers, REDUCER_TOKEN } from './state/app.state';
import { ErrorsLoggingService } from './_services/errors-logging/errors-logging.service';
// import { GoogleAdAccountService } from './_services/google-accounts/ad-account.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { NgxEchartsModule } from 'ngx-echarts';
import { ErrorsLoggingServiceApi } from './_services/errors-logging/errors-logging-api.service';
import { InstagramService } from './_services/facebook-accounts/instagram.service';
import { authReducer } from './creators-module/modules/authentication/state/auth-state/auth.reducer';
import { AuthEffects } from './creators-module/modules/authentication/state/auth-state/auth.effects';
import { AppCustomPreloader } from './app-routing-loader';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { QuillModule } from 'ngx-quill';
import { HammerModule } from '../../node_modules/@angular/platform-browser';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../assets/i18n/${lang}.json`));
	}
}

@NgModule({
	declarations: [AppComponent],
	exports: [],
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		StoreModule.forRoot(REDUCER_TOKEN, {
			metaReducers: [clearStateMetaReducer],
			initialState: initialAppState,
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false
			}
		}),
		StoreModule.forRoot({ auth: authReducer }),
		EffectsModule.forRoot([AuthEffects]),
		StoreDevtoolsModule.instrument({
			name: 'Filed',
			maxAge: 50
		}),
		AgGridModule.withComponents([]),
		BrowserModule,
		Ng5SliderModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		SharedModule,
		SidenavModule,
		SlickCarouselModule,
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
		InputsModule,
		LabelModule,
		DropDownsModule,
		NotificationModule,
		LayoutModule,
		TooltipModule,
		DialogsModule,
		EditorModule,
		GridModule,
		SocialLoginModule,
		QuillModule.forRoot(),
		HammerModule
	],
	providers: [
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.Google.ClientId)
					}
				],
				onError: err => {
					console.error(err);
				}
			} as SocialAuthServiceConfig
		},
		{
			provide: REDUCER_TOKEN,
			useValue: reducers
		},
		AppCustomPreloader,
		ErrorsLoggingService,
		ErrorsLoggingServiceApi,
		MapperService,
		AuthenticationService,
		UserServiceApi,
		UserServiceSubscription,
		SidenavService,
		TableService,
		InstagramService,
		ExcelService,
		AuthenticationGuard,
		DatePipe,
		CurrencyPipe,
		UserRoleAdminGuard,
		InterceptorsProvider,
		PopupDialogService,
		DashboardService,
		AuthenticationRedirectGuard,
		GoogleService,
		AccountTypeService,
		ComponentRegistryService,
		{
			provide: UrlSerializer,
			useClass: CaseInsensitiveUrlSerializer
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}

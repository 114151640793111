<div>
	<div *ngFor="let control of getKeysForNestedFormGroup('primaryText'); index as i; last as isLast" class="cif-input-width-button">
		<div class="cif-input-width">
			<app-text-input [control]="formGroup.get('primaryText').get(control)" [inputPlaceholder]="'Primary text'"></app-text-input>
		</div>
	</div>

	<div *ngFor="let control of getKeysForNestedFormGroup('headline')" class="cif-input-width-button">
		<div *ngIf="!isPageLikesSelected && !isPostLikesSelected && adFormat !== adFormatEnum.PagePosts" class="cif-input-width">
			<app-text-input [control]="formGroup.get('headline').get(control)" [inputPlaceholder]="'Headline'"></app-text-input>
		</div>
	</div>
	<div *ngFor="let control of getKeysForNestedFormGroup('description')" class="cif-input-width-button">
		<div *ngIf="!isPageLikesSelected && !isPostLikesSelected && adFormat !== adFormatEnum.PagePosts" class="cif-input-width">
			<app-text-input [control]="formGroup.get('description').get(control)" [inputPlaceholder]="'Description'"></app-text-input>
		</div>
	</div>
	<div
		class="cif-input-width"
		*ngIf="
			((!isPageLikesSelected && !isPostLikesSelected) || (isPostLikesSelected && isCtaSelected)) && (adFormat !== adFormatEnum.PagePosts || isWebsiteUrl)
		"
	>
		<app-text-input [control]="formGroup.get('websiteUrl')" [inputPlaceholder]="'Website Url'"></app-text-input>
	</div>

	<span class="cif-call-title" *ngIf="!isPageLikesSelected">Call to Action</span>
	<div *ngIf="!isPageLikesSelected" class="cif-dropdown-height">
		<app-dropdown-search-select
			class="cif-call-to-action"
			[readOnly]="adFormat === adFormatEnum.PagePosts"
			[dropdownData]="callToActions"
			[dropdownFormControl]="this.formGroup.get('callToAction')"
		></app-dropdown-search-select>
	</div>
</div>

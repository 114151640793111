<mat-tab-group animationDuration="0ms">
	<mat-tab *ngIf="showUpload" label="{{ 'UPLOAD' | translate | uppercase }}">
		<ng-template matTabContent>
			<app-upload (submit)="submit($event)"></app-upload>
		</ng-template>
	</mat-tab>
	<mat-tab *ngIf="showExistingAdsImages" label="{{ 'EXISTING_ADS_IMAGES' | translate | uppercase }}">
		<ng-template matTabContent>
			<app-gallery-mat-tab (submit)="submit($event)" [subject]="existingAdsImages"></app-gallery-mat-tab>
		</ng-template>
	</mat-tab>
	<mat-tab *ngIf="showExistingAdsVideos" label="{{ 'EXISTING_ADS_VIDEOS' | translate | uppercase }}">
		<ng-template matTabContent>
			<app-gallery-mat-tab (submit)="submit($event, true)" [subject]="existingAdsVideos"></app-gallery-mat-tab>
		</ng-template>
	</mat-tab>
	<mat-tab *ngIf="false" label="{{ 'TEMPLATES' | translate | uppercase }}">
		<ng-template matTabContent>
			<app-gallery-mat-tab (submit)="submit($event)" [subject]="templates"></app-gallery-mat-tab>
		</ng-template>
	</mat-tab>
	<mat-tab *ngIf="showImages" label="{{ 'IMAGES' | translate | uppercase }}">
		<ng-template matTabContent>
			<app-gallery-mat-tab (submit)="submit($event)" [subject]="images"></app-gallery-mat-tab>
		</ng-template>
	</mat-tab>
	<mat-tab *ngIf="showVideos" label="{{ 'VIDEOS' | translate | uppercase }}">
		<ng-template matTabContent>
			<app-gallery-mat-tab (submit)="submit($event)" [subject]="videos"></app-gallery-mat-tab>
		</ng-template>
	</mat-tab>
	<mat-tab *ngIf="showPosts" label="{{ 'POSTS' | translate | uppercase }}">
		<ng-template matTabContent></ng-template>
	</mat-tab>
	<mat-tab *ngIf="false" label="{{ 'STOCK' | translate | uppercase }}">
		<ng-template matTabContent></ng-template>
	</mat-tab>
</mat-tab-group>

import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-social-connection-main',
	templateUrl: './social-connection-main.component.html',
	styleUrls: ['./social-connection-main.component.scss']
})
export class SocialConnectionMainComponent implements OnInit {
	public brandName: string;
	public action: boolean = false;
	public isMobileView: boolean = false;

	constructor(private router: Router, private sharedService: SharedService, private route: ActivatedRoute) {
		this.route.queryParams.subscribe(map => {
			this.brandName = decodeURIComponent(map['brand']);
			this.action = map['action'] === 'true';
		});
	}

	ngOnInit(): void {
		this.setNavHeaderState();
	}

	public setNavHeaderState(): void {
		if (screen.width <= 820) {
			this.sharedService.changeSidenavHeaderState(false);
		}

		if (screen.width < 820) {
			this.isMobileView = true;
		}
	}

	public onContinue(): void {
		this.router.navigate(['my-offers']);
	}
}

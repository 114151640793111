import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SourceChannel } from '../sidenav/sidenav/sidenav-channel-buttons.enum';
import { SourcePlatform } from '../sidenav/sidenav/sidenav-platform-menu';

@Injectable({
	providedIn: 'root'
})
export class AccountTypeService {
	private _accountType = new ReplaySubject<SourceChannel>(1);
	private _accountPlatform = new ReplaySubject<SourcePlatform>(1);

	public readonly accountType: Observable<SourceChannel> = this._accountType.asObservable();
	public readonly accountPlatform: Observable<SourcePlatform> = this._accountPlatform.asObservable();
	public readonly localStorageChannelKey = 'source_channel';
	public readonly localStoragePlatformKey = 'source_platform';

	public setAccountType(sourceChannel: SourceChannel): void {
		this._accountType.next(sourceChannel);
		localStorage.setItem(this.localStorageChannelKey, sourceChannel + '');
	}

	public getAccountTypeObservable(): Observable<SourceChannel> {
		return this.accountType;
	}

	public setAccountPlatform(sourcePlatform: SourcePlatform): void {
		this._accountPlatform.next(sourcePlatform);
		localStorage.setItem(this.localStoragePlatformKey, sourcePlatform + '');
	}

	public getAccountPlatformObservable(): Observable<SourcePlatform> {
		return this.accountPlatform;
	}
}

import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams, GridApi } from 'ag-grid-community';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ButtonTypeEnum } from '../../button-component/button-type.enum';
import { ButtonClassEnum } from '../../button-component/button-class.enum';

@Component({
	selector: 'app-ads-manager-edit-cell',
	templateUrl: './ads-manager-edit-cell.component.html',
	styleUrls: ['./ads-manager-edit-cell.component.scss']
})
export class AdsManagerEditCellComponent implements ICellEditorAngularComp {
	public params: ICellEditorParams;
	private gridApi: GridApi;
	public editFormControl = new UntypedFormControl(null, Validators.required);
	public buttonTypeEnum = ButtonTypeEnum;
	public buttonClassEnum = ButtonClassEnum;
	public placeholder: string;

	constructor() {}

	agInit(params: ICellEditorParams) {
		this.params = params;
		console.log('cell editor params:', this.params);
		this.gridApi = params.context.componentParent.gridApi;
		this.editFormControl.setValue(params.value);
		this.placeholder = 'Edit' + ' ' + this.params.colDef.headerName;
	}

	getValue() {
		return this.editFormControl.value;
	}

	isPopup(): boolean {
		return true;
	}

	getPopupPosition() {
		return 'under';
	}

	public onNoClick() {
		this.gridApi.stopEditing(true);
	}

	public updateProperty() {
		//todo: post request for save
		this.gridApi.stopEditing();
		console.log('coloana selectata: ', this.params.column.getColId());
		console.log('randul selectat: ', this.params.node.data['reach']);
	}
}

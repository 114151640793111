import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-select-drp-wo-input',
	templateUrl: './select-drp-wo-input.component.html',
	styleUrls: ['./select-drp-wo-input.component.scss']
})
export class SelectDrpWoInputComponent implements OnInit {
	@Input('styleClass') public styleClass: string;
	@Input('cssStyle') public cssStyle: any;
	@Input('dropdownLabel') public dropdownLabel: string;
	@Input('inputPlaceholder') public inputPlaceholder: string = 'Select';
	@Input('dropdownControl') public dropdownControl: UntypedFormControl;
	@Input('options') public options: any[];
	@Input('disabled') public disabled: boolean = false;
	@Input('titleKey') public titleKey: string = 'name';
	@Input('firstOptionName') public firstOptionName: string = 'Select';

	@Output() public selectionEvent = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	public optionSelected(event: any) {
		this.selectionEvent.emit(event);
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRangeX, PublicDateModel } from '../../../calendar/calendar.models';
import moment from 'moment';
import { CampaignDateChoices } from './campaign-date-choices.enum';
import { DateCardInterface } from './campaign-date-card.interface';

@Component({
	selector: 'app-date-card',
	templateUrl: './date-card.component.html',
	styleUrls: ['./date-card.component.scss']
})
export class DateCardComponent implements OnInit {
	@Input() public campaignDate = new DateRangeX<string>();
	@Input() public dateChoiceType: CampaignDateChoices;
	@Input() public preselectedDate: PublicDateModel;
	@Input() public isAdSetLifeTimeSelected: boolean;
	@Input() public isCampaignLifeTimeSelected: boolean;
	@Input() public isPanelSelected: boolean;
	@Output() public onExpansionClick = new EventEmitter();
	@Output() public defaultCampaign = new EventEmitter();
	@Output() public campaignDateStatus = new EventEmitter();

	constructor() {}

	public ngOnInit(): void {}

	public onDateChange(event: PublicDateModel): void {
		this.campaignDate.startDate = event.date.startDate.toISOString();
		this.campaignDate.endDate = event.date.endDate.toISOString(true);
		this.updateDate();
	}

	public changeDateChoice(event: CampaignDateChoices): void {
		this.dateChoiceType = event;
		this.getDefaultCampaignDateForChoice(event);
		this.updateDate();
	}

	private updateDate(): void {
		const status: DateCardInterface = {
			campaignDate: this.campaignDate,
			dateChoiceType: this.dateChoiceType
		};
		this.campaignDateStatus.emit(status);
	}

	private getDefaultCampaignDateForChoice(type: CampaignDateChoices): void {
		const result = new DateRangeX<string>();
		if (type === CampaignDateChoices.Default) {
			result.startDate = moment().toISOString(true);
			result.endDate = null;
		} else {
			result.startDate = moment().toISOString(true);
			result.endDate = moment().add(1, 'month').toISOString(true);
		}
		this.campaignDate = result;
	}
}

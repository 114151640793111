import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-user-input-string-dialog',
	templateUrl: './user-input-string-dialog.component.html',
	styleUrls: ['./user-input-string-dialog.component.scss']
})
export class UserInputStringDialogComponent implements OnInit {
	public input: string = '';
	public placeholder: string = 'Enter here';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { title: string; isMobileView: boolean; placeholder?: string },
		private dialogRef: MatDialogRef<UserInputStringDialogComponent>
	) {
		if (data?.placeholder) {
			this.placeholder = data.placeholder;
		}
	}

	ngOnInit(): void {}

	public onSave(): void {
		if (this.input.trim() == '') return;
		this.dialogRef.close({ editedString: this.input, hasChanged: true });
	}
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { BreakdownTemplate } from '../../../_models/TableModels/breakdown-template';
import { Column } from '../../../_models/TableModels/column';
import { Filter } from '../../../_models/TableModels/filter';
import { SelectionActions } from '../../../_models/TableModels/selection-actions';
import { TableView } from '../../../_models/TableModels/table-view';
import { ExcelService } from '../../../_services/excel.service';
import { TableService } from '../../../_services/table.service';
import { InsightsCategoryTypeEnum } from '../../campaign-insights/models/insights-category-type.enum';
import { InsightsReportModel } from '../../campaign-insights/models/insights-report.model';
import { InsightsMetadataAbstractFactory } from '../../campaign-insights/services/metadata/insights-metadata-abstract-factory.service';
import { GenericTableComponentHelperService } from '../generic-table-component-helper.Service';
import { TableColumnsManagementComponent } from '../table-columns-management/table-columns-management.component';
import { BreakdownService } from './breakdown-service';

@Component({
	selector: 'app-generic-table',
	templateUrl: './generic-table.component.html',
	styleUrls: ['./generic-table.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class GenericTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
	@Input() columnsView: TableView;
	@Input() tableData: any;
	@Input() isBackOffice: boolean;

	@Output() actionEvent: EventEmitter<any> = new EventEmitter();
	@Output() actionLinkEvent: EventEmitter<any> = new EventEmitter();
	@Output() breakdownEvent: EventEmitter<any> = new EventEmitter();
	@Output() columnsChangedEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild(MatSort, { static: true }) public sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatTable, { read: ElementRef, static: true }) matTableRef: ElementRef;

	public displayedColumns: Column[] = [];
	public isInFullScreen: boolean;
	public allColumns: Column[] = [];
	public filterActions: Filter[] = [];
	public singleSelectionActions: SelectionActions[] = [];
	public multipleSelectionActions: SelectionActions[] = [];
	public columnDefinitions: string[] = [];
	public timeOptions: any[] = []; // = ['None', 'Day', 'Week', '2 weeks', 'Month'];
	public deliveryOptions: any[] = [];
	public actionOptions: any[] = [];
	public breakdownCombinations: any[] = [];
	public form: UntypedFormGroup;

	public deliveryValue: any;
	public actionValue: any;
	public timeValue: any;
	// timeValue = '';

	public dataSource: MatTableDataSource<any>;
	public selection = new SelectionModel<any>(true, []);
	public breakdown: BreakdownTemplate;
	public columnsThatKeepsWidthFromJson: string[] = ['toggle', 'actions', 'masterCheckbox'];
	public isResizingRight: boolean;
	public currentResizeIndex: number;
	public pressed = false;
	public startX: number;
	public startWidth: number;
	public resizableMousemove: () => void;
	public resizableMouseup: () => void;
	public isLoading: boolean;
	public isLoaded = false;
	public tableWidthCopy: number;
	public fullScreenEventCount = 0;
	// the minimum column width the table will be rendered with
	public minimumColumnWidth = 40;
	public sortDelay = 500;
	public reloadColumnsDelay = 800;
	public paginationDelay = 800;
	public fullScreenPageSizeOptions = [40];
	public normalScreenPageSizeOptions = [40, 60, 80];

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private tableService: TableService,
		private excelService: ExcelService,
		private dialog: MatDialog,
		private renderer: Renderer2,
		private helperService: GenericTableComponentHelperService,
		private elementRef: ElementRef,
		private breakdownService: BreakdownService,
		private http: HttpClient
	) {}

	ngOnDestroy() {
		if (this.resizableMousemove) {
			this.resizableMousemove();
		}
		if (this.resizableMouseup) {
			this.resizableMouseup();
		}

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.setTableResize(this.matTableRef.nativeElement.clientWidth);
	}

	@HostListener('document:fullscreenchange', ['$event'])
	onEscapeDownEventHandler(event: KeyboardEvent) {
		this.fullScreenEventCount++;
		if (this.fullScreenEventCount % 2 === 0) {
			this.isInFullScreen = !this.isInFullScreen;
			this.paginator.pageSizeOptions = this.normalScreenPageSizeOptions;
			this.paginator.page.emit();
		} else {
			if (this.paginator.pageSize < Math.max.apply(Math, this.paginator.pageSizeOptions)) {
				this.paginator.pageIndex = 0;
			}
			this.paginator.pageSizeOptions = this.fullScreenPageSizeOptions;
			this.paginator.pageSize = this.fullScreenPageSizeOptions[0];
			this.paginator.page.emit();
		}
	}

	ngOnInit() {
		if (!this.isBackOffice) {
			this.manageBreakdowns();
		}
		this.getDefaultView();
		this.setTableWidthCopy();
		this.isLoading = true;
		this.reloadTable();
		this.setDisplayedColumns();
		this.sort.sortChange.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.setTableWidthCopy();
			this.isLoading = true;
			setTimeout(() => {
				this.setTableResize(this.matTableRef.nativeElement.clientWidth);
				this.isLoading = false;
			}, this.sortDelay);
		});
		this.paginator.page.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.setTableWidthCopy();
			this.isLoading = true;
			setTimeout(() => {
				this.setTableResize(this.matTableRef.nativeElement.clientWidth);
				this.isLoading = false;
			}, this.paginationDelay);
		});
		this.isLoading = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tableData) {
			this.dataSource = new MatTableDataSource(this.tableData.data);
			this.reloadTable();
		}
	}

	getDefaultView() {
		this.allColumns = this.tableService.current.tables[this.tableData.name].columns;
		this.displayedColumns = this.tableService.current.tables[this.tableData.name].columns.filter(t => t.displayedByDefault);
		this.displayedColumns = this.allColumns.filter(t => t.displayedByDefault);
		this.filterActions = this.tableService.current.tables[this.tableData.name].filters;
		this.singleSelectionActions = this.tableService.current.tables[this.tableData.name].singleSelectionActions;
		this.multipleSelectionActions = this.tableService.current.tables[this.tableData.name].multipleSelectionActions;
		this.columnDefinitions = this.displayedColumns.map(t => t.primaryValue);

		this.dataSource = new MatTableDataSource(this.tableData.data);
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
	}

	filterViewColumns() {
		this.allColumns.push(this.tableService.current.tables[this.tableData.name].columns[0]);
		let temp: any;
		for (let i = 0; i < this.columnsView.columns.length; i++) {
			switch (this.columnsView.columns[i].name) {
				case 'State': {
					temp = this.columnsView.columns[0];
					this.columnsView.columns[0] = this.columnsView.columns[i];
					this.columnsView.columns[i] = temp;
					break;
				}
				case 'Name': {
					temp = this.columnsView.columns[1];
					this.columnsView.columns[1] = this.columnsView.columns[i];
					this.columnsView.columns[i] = temp;
					break;
				}
			}
		}

		this.columnsView.columns.forEach(opt => {
			if (opt.name !== 'FacebookId') {
				const column: Column = {
					name: opt.name.toUpperCase(),
					primaryValue: opt.primaryValue.columnName,
					secondaryValue: !!opt.secondaryValue ? opt.secondaryValue.columnName : null,
					type: opt.type,
					displayedByDefault: opt.displayedByDefault || true,
					width: opt.width.toString(),
					sticky: opt.sticky || false
				};
				this.allColumns.push(column);
			}
		});
		this.tableService.current.tables[this.tableData.name].columns.forEach(opt => {
			if (opt.name === 'ACTIONS') {
				this.allColumns.push(opt);
			}
		});
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.dataSource.data.forEach(row => this.selection.select(row));
		}
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
		this.setTableResize(this.matTableRef.nativeElement.clientWidth, this.minimumColumnWidth, 7);
	}

	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
		this.setTableResize(this.matTableRef.nativeElement.clientWidth);
		// check if pagination flag is true
		if (this.tableService.current.tables[this.tableData.name].noPagination) {
			return;
		}
		this.dataSource.paginator = this.paginator;
	}

	doAction(event: any) {
		this.actionEvent.emit(event);
	}

	goToLink(row: any, column: string, event: any) {
		this.actionLinkEvent.emit({ row, column, event });
	}

	openTableColumnsManagement() {
		const dialogRef = this.dialog.open(TableColumnsManagementComponent, {
			data: {
				title: this.tableData.name.toLowerCase(),
				displayedColumns: this.displayedColumns,
				allColumns: this.allColumns
			}
		});
		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.isLoading = true;
				if (result) {
					this.displayedColumns = result.displayedColumns; // these 2 lines of code seem to have no effect.
					this.allColumns = result.allColumns;
					this.allColumns.unshift(this.tableService.current.tables[this.tableData.name].columns[0]);
					this.allColumns.push(this.tableService.current.tables[this.tableData.name].columns.find(i => i.name === 'ACTIONS'));
					this.reloadTable();
					this.columnsChangedEvent.emit(this.allColumns);
					this.setDisplayedColumns();
				}
				setTimeout(() => (this.isLoading = false), this.reloadColumnsDelay);
			});
	}

	reloadTable() {
		this.displayedColumns = this.allColumns.filter(t => t.displayedByDefault);
		this.filterActions = this.tableService.current.tables[this.tableData.name].filters;
		this.singleSelectionActions = this.tableService.current.tables[this.tableData.name].singleSelectionActions;
		this.multipleSelectionActions = this.tableService.current.tables[this.tableData.name].multipleSelectionActions;
		this.columnDefinitions = this.displayedColumns.map(t => t.primaryValue);
		this.dataSource = new MatTableDataSource(this.tableData.data);
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;

		setTimeout(() => {
			this.setTableResize(this.matTableRef.nativeElement.clientWidth, this.minimumColumnWidth, 7);
			// TODO: remove loading screen here
		});
	}

	exportDataToExcel(parameter: any[]) {
		if (parameter.length > 0) {
			this.excelService.exportAsExcelFile(parameter, this.tableData.name.toString(), 'data');
		} else {
			this.excelService.exportAsExcelFile(this.dataSource.data, this.tableData.name.toString(), 'data');
		}
	}

	displayBasicColumns() {
		this.displayedColumns.forEach(item => {
			if (item.locked === false) {
				item.displayedByDefault = false;
			}
			this.reloadTable();
		});
	}

	clearBreakdowns() {
		this.form.controls.selectedTime.setValue({ id: 0 });
		this.form.controls.selectedDelivery.setValue(0);
		this.form.controls.selectedAction.setValue(0);

		this.timeValue = this.timeOptions[0];
		this.deliveryValue = this.deliveryOptions[0];
		this.actionValue = this.actionOptions[0];
	}

	expand() {
		this.isInFullScreen = true;
		this.expandToFullScreen(this.elementRef.nativeElement);
	}

	onResizeColumn(event: any, index: number) {
		this.checkResizing(event, index);
		this.currentResizeIndex = index;
		this.pressed = true;
		this.startX = event.pageX;
		this.startWidth = event.target.clientWidth;
		event.preventDefault();
		this.mouseMove(index);
	}

	private checkResizing(event: any, index: any) {
		const cellData = this.getCellData(index);
		this.isResizingRight = index === 0 || Math.abs(event.pageX - cellData.right) < cellData.width / 2;
	}

	private getCellData(index: number) {
		const headerRow = this.matTableRef.nativeElement.children[0];
		const cell = headerRow.children[0].children[index];
		return cell.getBoundingClientRect();
	}

	mouseMove(index: number) {
		this.resizableMousemove = this.renderer.listen('document', 'mousemove', event => {
			if (this.pressed && event.buttons) {
				const dx = this.isResizingRight ? event.pageX - this.startX : -event.pageX + this.startX;
				const width = this.startWidth + dx;
				if (this.currentResizeIndex === index && width > 77) {
					this.setColumnWidthChanges(index, width);
				}
			}
		});
		this.resizableMouseup = this.renderer.listen('document', 'mouseup', event => {
			if (this.pressed) {
				this.pressed = false;
				this.currentResizeIndex = -1;
				this.resizableMousemove();
				this.resizableMouseup();
			}
		});
	}

	setColumnWidthChanges(index: number, width: number) {
		const orgWidth: number = Number(this.displayedColumns[index].width);
		const dx = width - orgWidth;
		if (dx !== 0) {
			const j = this.isResizingRight ? index + 1 : index - 1;
			const newWidth = Number(this.displayedColumns[j].width) - dx;
			if (newWidth > 50) {
				this.displayedColumns[index].width = String(width);
				this.setColumnWidth(this.displayedColumns[index]);
				this.displayedColumns[j].width = String(newWidth);
				this.setColumnWidth(this.displayedColumns[j]);
			}
		}
	}

	setColumnWidth(column: any) {
		const columnEls = Array.from(document.getElementsByClassName('mat-column-' + column.primaryValue));
		columnEls.forEach((el: HTMLDivElement) => {
			el.style.setProperty('width', String(column.width + 'px'), 'important');
		});
	}

	setColumnLeft(column: any) {
		const columnEls = Array.from(document.getElementsByClassName('mat-column-' + column.primaryValue));
		columnEls.forEach((el: HTMLDivElement) => {
			el.style.setProperty('left', '0px', 'important');
		});
	}

	// minimumWidthOfColumn - the min width the column will be re-rendered with
	// minimumNumberOfColumns - the table will only be rendered with the above rule if it has at least this number of columns
	setTableResize(tableWidth: number, minimumWidthOfColumn?: number, minimumNumberOfColumns?: number) {
		if (tableWidth === 0) {
			tableWidth = this.tableWidthCopy;
		}
		let totWidth = 0;
		this.displayedColumns.forEach(column => {
			if (column.type === 'toggle' || column.type === 'masterCheckbox' || column.type === 'actions') {
				return;
			}
			totWidth += Number(column.width);
		});
		this.columnsThatKeepsWidthFromJson.forEach(type => {
			if (this.displayedColumns.find(x => x.type === type) !== undefined) {
				tableWidth -= Number(this.displayedColumns.find(x => x.type === type).width);
			}
		});
		const scale = tableWidth / totWidth;
		this.displayedColumns.forEach(column => {
			if (!this.columnsThatKeepsWidthFromJson.includes(column.type)) {
				let aux: number = Number(column.width);
				aux *= scale;
				if (this.displayedColumns.length > minimumNumberOfColumns && aux < minimumWidthOfColumn) {
					aux = minimumWidthOfColumn;
				}
				column.width = String(aux);
				this.setColumnWidth(column);
				this.setColumnLeft(column);
			} else {
				this.setColumnWidth(column);
				this.setColumnLeft(column);
			}
		});
	}

	onNextPageKeepTableResized() {
		this.setTableResize(this.matTableRef.nativeElement.clientWidth);
	}

	setDisplayedColumns() {
		this.displayedColumns.forEach((column, index) => {
			column.index = index;
		});
	}

	expandToFullScreen(element: any) {
		this.helperService.openFullScreen(element as HTMLElement);
	}

	setTableWidthCopy() {
		this.tableWidthCopy = this.matTableRef.nativeElement.clientWidth;
	}

	exitFullScreen() {
		document.exitFullscreen();
		this.paginator.pageSizeOptions = this.normalScreenPageSizeOptions;
		this.paginator.page.emit();
	}

	FindActionCorelation(deliveryId: number) {
		for (let i = 0; i < this.breakdownCombinations.length; i++) {
			if (
				deliveryId === this.breakdownCombinations[i].deliveryBreakdownId &&
				this.form.controls.selectedAction.value == this.breakdownCombinations[i].actionBreakdownId
			) {
				return false;
			}
		}
		return true;
	}

	FindDeliveryCorelation(actionId: number) {
		for (let i = 0; i < this.breakdownCombinations.length; i++) {
			if (
				actionId === this.breakdownCombinations[i].actionBreakdownId &&
				this.form.controls.selectedDelivery.value === this.breakdownCombinations[i].deliveryBreakdownId
			) {
				return false;
			}
		}
		return true;
	}

	private manageBreakdowns() {
		this.form = this.formBuilder.group({
			selectedTime: new UntypedFormControl({
				id: 0,
				name: 'None',
				groupColumn: 'None',
				value: '1',
				groupType: ''
			}),
			selectedDelivery: new UntypedFormControl(0),
			selectedAction: new UntypedFormControl(0)
		});

		this.form.controls.selectedTime.setValue(this.breakdownService.getLastTimeBreakdown()); // DOES NOTHING > Bind none to something else.
		this.form.controls.selectedDelivery.setValue(this.breakdownService.getLastDeliveryBreakdown()); // DOES NOTHING > Bind none to something else.
		this.form.controls.selectedAction.setValue(this.breakdownService.getLastActionBreakdown());

		this.form.controls.selectedDelivery.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			this.breakdownEvent.emit({
				event: 'Clicks',
				deliveryValue: data,
				actionValue: this.form.controls.selectedAction.value,
				timeValue: this.form.controls.selectedTime.value
			});

			this.breakdownService.changeBreakdowns(data, this.form.controls.selectedAction.value, this.form.controls.selectedTime.value);
		});

		this.form.controls.selectedAction.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			this.breakdownEvent.emit({
				event: 'Clicks',
				deliveryValue: this.form.controls.selectedDelivery.value,
				actionValue: data,
				timeValue: this.form.controls.selectedTime.value
			});
			this.breakdownService.changeBreakdowns(this.form.controls.selectedDelivery.value, data, this.form.controls.selectedTime.value);
		});

		this.form.controls.selectedTime.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			this.breakdownEvent.emit({
				event: 'Clicks',
				deliveryValue: this.form.controls.selectedDelivery.value,
				actionValue: this.form.controls.selectedAction.value,
				timeValue: data
			});
			this.breakdownService.changeBreakdowns(this.form.controls.selectedDelivery.value, this.form.controls.selectedAction.value, data);
		});

		const serviceInstance = new InsightsMetadataAbstractFactory(this.http)
			.getInstance(SourceChannel.Facebook)
			.getInstance(InsightsCategoryTypeEnum.Campaign);

		serviceInstance
			.getInsightsMetadataModel(true)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((metadataModel: InsightsReportModel[]) => {
				const report = metadataModel[0]; // Facebook specific so far
				const breakdowns = report.breakdowns;

				this.deliveryOptions = breakdowns.delivery;
				this.actionOptions = breakdowns.action;
				this.timeOptions = breakdowns.time;
			});
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WelcomePageModel } from './welcome-page.model';
import { ButtonClassEnum } from '../button-component/button-class.enum';
import { ButtonTypeEnum } from '../button-component/button-type.enum';

@Component({
	selector: 'app-welcome-page',
	templateUrl: './welcome-page.component.html',
	styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {
	@Input() inputDetailModel: WelcomePageModel;
	@Output() leftButtonPressed = new EventEmitter<void>();
	@Output() rightButtonPressed = new EventEmitter<void>();
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor() {}

	ngOnInit() {}

	leftButtonPressedHandler() {
		this.leftButtonPressed.emit();
	}

	rightButtonPressedHandler() {
		this.rightButtonPressed.emit();
	}
}

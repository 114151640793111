<div class="welcome-page-wrapper">
	<div class="main-section" [ngClass]="{ 'single-card-width': !(inputDetailModel.showRightCard && inputDetailModel.showLeftCard) }">
		<!-- Title-->
		<div class="title-main-section">
			<span class="welcome-title">
				{{ inputDetailModel.title }}
			</span>
			<span class="welcome-subtitle">
				{{ inputDetailModel.subtitle }}
			</span>
		</div>

		<!--Welcome Cards-->
		<div class="box-cards-welcome">
			<div
				*ngIf="inputDetailModel.showLeftCard"
				class="left-welcome-section single-card-width"
				[ngClass]="!(inputDetailModel.showRightCard && inputDetailModel.showLeftCard) ? 'no-margin-left-card' : 'with-margin-left'"
			>
				<div class="center-wrapper">
					<div class="title-section">
						<img [src]="inputDetailModel.img.left" />
						<span>
							{{ inputDetailModel.text.left }}
							<a *ngIf="inputDetailModel.linkExtraInfo" href="{{ inputDetailModel.linkExtraInfo.href }}" target="_blank">
								{{ inputDetailModel.linkExtraInfo.text }}
							</a>
						</span>
					</div>
					<div class="buttons-section" *ngIf="inputDetailModel.showButtons">
						<app-primary-button
							[buttonType]="'Primary'"
							[isPeddingReduceSmall]="true"
							(buttonClicked)="leftButtonPressedHandler()"
							[buttonLable]="inputDetailModel.buttonText.left | uppercase"
						></app-primary-button>
					</div>
				</div>
			</div>

			<div *ngIf="inputDetailModel.showRightCard" class="right-welcome-section single-card-width">
				<div class="center-wrapper">
					<div class="title-section">
						<img [src]="inputDetailModel.img.right" />
						<span>
							{{ inputDetailModel.text.right }}
							<a *ngIf="inputDetailModel.linkExtraInfo" href="{{ inputDetailModel.linkExtraInfo.href }}" target="_blank">
								{{ inputDetailModel.linkExtraInfo.text }}
							</a>
						</span>
					</div>
					<div class="buttons-section" *ngIf="inputDetailModel.showButtons">
						<app-primary-button
							[buttonType]="'Primary'"
							[isPeddingReduceSmall]="true"
							(buttonClicked)="rightButtonPressedHandler()"
							class="welcome-btn-save"
							[buttonLable]="'inputDetailModel.buttonText.right | uppercase'"
						></app-primary-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

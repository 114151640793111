import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NewStorageKey, StorageKey } from 'src/app/_models/local-storage-key';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { TokenService } from 'src/app/_services/token.service';
import { UserTokenData } from '../models/auth.model';
import {
	InfluencerDetails,
	InfluencerDetailsInterface,
	InstagramAccountInterface,
	LoginDetails,
	NewAccountDetails,
	PlatformList,
	SetPasswordDetails
} from '../models/connect-accounts.model';

@Injectable({
	providedIn: 'root'
})
export class InfluencerAuthenticationService {
	private baseUrl = `${BaseApiUrl.SocialInfluencerCS}InfluencerAccount`;
	// private fbBaseUrl = `https://c4ee-2405-201-4022-a8a1-555e-55f2-59cc-5861.ngrok.io/api/v1/oauth/facebook/`;
	private fbBaseUrl = `${BaseApiUrl.SocialInfluencerPython}oauth/facebook/`;
	// private youtubeBaseUrl = `https://c4ee-2405-201-4022-a8a1-555e-55f2-59cc-5861.ngrok.io/api/v1/oauth/youtube/`;
	private youtubeBaseUrl = `${BaseApiUrl.SocialInfluencerPython}oauth/youtube/`;
	private tiktokBaseUrl = `${BaseApiUrl.SocialInfluencerPython}oauth/tiktok/`;

	public logoutSubject: Subject<void> = new Subject();
	public socialAccounts = new BehaviorSubject<InstagramAccountInterface[]>(null);
	public castSocialAccounts = this.socialAccounts.asObservable();
	public accountStatus = new BehaviorSubject<boolean>(false);
	public castAccountStatus = this.accountStatus.asObservable();
	private influencerDetails: InfluencerDetails;
	private token: string = localStorage.getItem(NewStorageKey.token);

	constructor(private http: HttpClient, private router: Router, private tokenService: TokenService) {
		// if(this.token){
		// 	const decodedToken: UserTokenData = this.decodeTokenTemp(this.token);
		// 	this.influencerDetails = this.getUserDetailsFromJwt(decodedToken);
		// }
	}

	public register(details: NewAccountDetails): Observable<number> {
		return this.http.post<number>(`${this.baseUrl}/signup`, details).pipe(catchError(this.handleError));
	}

	public sendAgain(email: string): Observable<Object> {
		const data = {
			userEmail: email
		};
		return this.http.put<Object>(`${this.baseUrl}/send-password-link`, data).pipe(catchError(this.handleError));
	}

	public login(details: LoginDetails): Observable<string> {
		return this.http.post<string>(`${this.baseUrl}/signin`, details).pipe(catchError(this.handleError));
	}

	public setFirstPassword(details: SetPasswordDetails): Observable<string> {
		return this.http.put<string>(`${this.baseUrl}/set-first-password`, details).pipe(catchError(this.handleError));
	}

	public isLoggedIn(): boolean {
		const tokenExists: boolean = !!localStorage.getItem(StorageKey.token);
		if (tokenExists) {
			const decodedJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
			const currentTime = moment().format();
			const timeFromJwt = moment(decodedJwt.exp * 1000).format();
			const decodedJwtIsValid: boolean = !moment(currentTime).isAfter(timeFromJwt);

			if (!this.influencerDetails) {
				const decodedToken: any = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
				this.influencerDetails = this.getUserDetailsFromJwt(decodedToken);
			}

			return tokenExists && decodedJwtIsValid;
		}
	}

	public getUserDetailsFromJwt(decodedJwt: UserTokenData): InfluencerDetails {
		return {
			FiledId: decodedJwt.user_filed_id,
			InfluencerAvailablePlatforms: decodedJwt?.influencer_available_platforms?.split(',')
		};
	}

	public initUserRolesLogic(): void {
		// this.tokenService.token = localStorage.getItem(StorageKey.token);
		// if (!this.tokenService.token) {
		// 	return;
		// }
		// try {
		// 	// this.influencerStore.dispatch(new SetInfluencerDetails(this.influencerDetails));
		// 	// this.authStore.dispatch(new RestoreAuthentication(this.tokenService.token));
		// } catch (e) {
		// 	this.logout();
		// }
	}

	public isInstagramConnected(): Observable<boolean> {
		if (!this.influencerDetails) {
			return of(false);
		}
		return of(this.influencerDetails.InfluencerAvailablePlatforms.includes(PlatformList.INSTAGRAM));
	}

	public isYoutubeLoggedIn(): Observable<boolean> {
		if (!this.influencerDetails) {
			return of(false);
		}
		return of(this.influencerDetails.InfluencerAvailablePlatforms.includes(PlatformList.YOUTUBE));
	}

	// Hack because we dont have refresh token
	public updateInfluencerPlatform(platform: string): void {
		if (!this.influencerDetails?.InfluencerAvailablePlatforms?.includes(platform)) {
			this.updateUserInfo(platform);
			this.influencerDetails?.InfluencerAvailablePlatforms?.push(platform);
		}
	}

	private updateUserInfo(platform: string): void {
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo)) as InfluencerDetailsInterface;
		let platforms = token.influencer_available_platforms;
		platforms = platform + ',' + platforms;
		token.influencer_available_platforms = platforms;
		// localStorage.setItem(StorageKey.decodedJwtIo, JSON.stringify(token));
	}

	public logout(params?: NavigationExtras): void {
		// TODO hack because we dont have refresh token
		const temporaryCredentials = localStorage.getItem('details');
		// localStorage.clear();
		if (temporaryCredentials) {
			localStorage.setItem('details', temporaryCredentials);
		}

		this.accountStatus.next(false);
		this.logoutSubject.next();

		// this.influencerStore.dispatch(new ResetAppState());
		// this.influencerStore.dispatch(new ClearInfluencerDetails());
		// this.authStore.dispatch(new LogOut());

		params ? this.router.navigate(['/authentication'], params) : this.router.navigate(['/authentication']);
	}

	public tempLogout(): void {
		//if comming from campaign signup
		const temporaryCredentials = localStorage.getItem('details');
		// localStorage.clear();
		if (temporaryCredentials) {
			localStorage.setItem('details', temporaryCredentials);
		}

		this.accountStatus.next(false);
		this.logoutSubject.next();

		// this.influencerStore.dispatch(new ResetAppState());
		// this.influencerStore.dispatch(new ClearInfluencerDetails());
		// this.authStore.dispatch(new LogOut());
	}

	public sendResetPasswordLink(email: string): Observable<Object> {
		const url = new URL(`${BaseApiUrl.SocialInfluencerCS}InfluencerAccount/send-password-link`);
		const body = {
			userEmail: email
		};
		return this.http.put(url.href, body);
	}

	public resetPassword(password: SetPasswordDetails, email: string): Observable<Object> {
		const url = new URL(`${BaseApiUrl.SocialInfluencerCS}InfluencerAccount/forgot-password`);
		const body = {
			confirmPassword: password.confirmPassword,
			emailAddress: email,
			newPassword: password.password,
			twoFactorToken: password.twoFactorToken
		};
		return this.http.put(url.href, body);
	}

	public validateUserAccounts(): Observable<any> {
		return this.http.get(`${this.fbBaseUrl}validate`, { headers: { Authorization: this.token }, observe: 'response' }).pipe(
			map(e => {
				if (e.status === 504) {
					return e.status;
				}
				return e.body;
			})
		);
	}

	public validateYoutubeAccounts(): Observable<any> {
		return this.http.get(`${this.youtubeBaseUrl}validate`, { headers: { Authorization: this.token } });
	}

	public validateTiktokAccounts(): Observable<any> {
		return this.http.get(`${this.tiktokBaseUrl}validate`, { headers: { Authorization: this.token } });
	}

	public connectToFacebook(token: string): Observable<any> {
		return this.http.get(`${this.fbBaseUrl}install?access_token=${token}`);
	}

	public addAddcount(id: string): Observable<any> {
		return this.http.get(`${this.fbBaseUrl}add?id=${id}`);
	}

	public connectToGoolge(code: string, state: string): Observable<string> {
		return this.http.get<string>(`${this.youtubeBaseUrl}install?code=${code}&state=${state}`);
	}

	public connectToTiktok(code: string, state: string): Observable<string> {
		return this.http.get<string>(`${this.tiktokBaseUrl}install?code=${code}&state=${state}`);
	}

	public getAccountStatus(): Observable<boolean> {
		return forkJoin();
	}

	private handleError(error: HttpErrorResponse): Observable<any> {
		let errorMsg: string = '';

		switch (error.error[0].code) {
			case 'Filed__Api__FirstPassword__Exists':
				errorMsg = 'This account has already been registered. Please log in.';
				break;
			case 'Filed__Domain__NA__Contact__ExistsWithSameCriteria' || 'Filed__Domain__NA__Contact_Hubspot__ExistsWithSameCriteria':
				errorMsg = 'A user already exists with this email';
				break;

			case 'Filed__Domain__NA__Credentials__AreInvalid':
				errorMsg = 'Invalid email or password';
				break;
			case 'Filed__Domain__NA__User__IsNotFoundByCriteria':
				errorMsg = 'Your email was not found in our database. Please create a new account.';
				break;
			case 'Filed__Api__Influencer__Email__ExistsWithSameCriteria':
				errorMsg = 'This email is linked with another Account.';
				break;
			case 'Filed__Api__Influencers_InfluencerId_IsNullOrEmpty':
				errorMsg = 'If you are seeing this error! Please report a bug!';
				break;

			case 'Filed__Api__SetPassword__IsInvalidTooShort':
				errorMsg = 'Password is too short';
				break;

			case 'Filed__Api__SetPassword__IsInvalidRequiresUpper':
				errorMsg = 'Password should contain an uppercase letter';
				break;

			case 'Filed__Api__SetPassword__IsInvalidRequiresUniqueChars':
				errorMsg = 'Password requires unique characters.';
				break;

			case 'Filed__Api__SetPassword__IsInvalidRequiresNonAlphanumeric':
				errorMsg = 'Password is missing a non alphanumeric character';
				break;

			case 'Filed__Api__SetPassword__IsInvalidRequiresLower':
				errorMsg = 'Password IsInvalidRequiresLower';
				break;

			case 'Filed__Api__SetPassword__IsInvalidRequiresDigit':
				errorMsg = 'Password IsInvalidRequiresDigit';
				break;

			case 'Filed__Api__SetPassword__IsInvalidPasswordMismatch':
				errorMsg = 'Password IsInvalidPasswordMismatch';
				break;

			case 'Filed__Api__User__Email__IsNotFoundByCriteria':
				errorMsg = "Email doesn't exists";
				break;

			default:
				errorMsg = 'There has been a problem with the server. Please contact support.';
		}

		return throwError(errorMsg);
	}

	public disconnectAccount(platform: string): Observable<string> {
		let str;
		switch (platform) {
			case 'youtube':
				str = 'oauth/youtube/uninstall';
				break;
			case 'facebook':
				str = 'oauth/facebook/uninstall';
				break;
			case 'tiktok':
				str = 'oauth/tiktok/uninstall';
				break;
			default:
				str = '';
		}
		const url = `${BaseApiUrl.SocialInfluencerPython}${str}`;
		return this.http.get<string>(url).pipe(catchError(this.handleError));
	}

	public authPreInstall(): Observable<{ message: string; status: boolean }> {
		return this.http.get<{ message: string; status: boolean }>(`${BaseApiUrl.SocialInfluencerPython}oauth/tiktok/preinstall`);
	}
}

<section #mainSection *ngIf="!megaMenuOpen && !sidenavMobile" [ngClass]="{ 'expanded-menu': expandMenu }" class="side-nav closed">
	<mat-sidenav #sidenav role="navigation">
		<div [ngClass]="{ 'no-menu-margin': !dropdownIsVisible }" class="menu-list" *ngIf="accountState !== userStateEnum.BillPendingRestrictedAccess">
			<!-- <mat-list class="list-top">
				<mat-list-item #headerLogo (click)="toggleExpandNavMenu()" class="mat-list-top-item-content-expanded"
					[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }">
					<img src="assets/icons/creators/sidenav/menu-icon.svg" />
				</mat-list-item>
			</mat-list> -->
			<mat-list *appCheckPermissions="backOfficePermission">
				<mat-list-item [routerLink]="'/back-office'" routerLinkActive="selectedItem">
					<mat-icon
						[ngClass]="{ 'nav-selected': currentUrl?.includes('back-office') && !currentUrl?.includes('creative-builder') }"
						class="nav-icons"
					>
						admin_panel_settings
					</mat-icon>
					<span
						[ngClass]="{ 'route-text-selected': currentUrl?.includes('back-office') && !currentUrl?.includes('creative-builder') }"
						class="route-text"
					>
						BACK OFFICE
					</span>
				</mat-list-item>
			</mat-list>
			<mat-list *appCheckPermissions="backOfficePermission">
				<mat-list-item [routerLink]="'/creative-builder-back-office'" routerLinkActive="selectedItem">
					<mat-icon [ngClass]="{ 'nav-selected': currentUrl?.includes('creative-builder') }" class="nav-icons">wallpaper</mat-icon>
					<span [ngClass]="{ 'route-text-selected': currentUrl?.includes('creative-builder') }" class="route-text">CB BACK OFFICE</span>
				</mat-list-item>
			</mat-list>
			<mat-list *ngFor="let mainItem of navLinks; let i = index">
				<mat-list-item
					(click)="navLinkTrigger(i)"
					[disabled]="showSpinner"
					[ngStyle]="(mainItem.isSelected && !mainItem.childOpen) || (mainItem.childOpen && !expandMenu) ? menuItemSelectedEffect() : {}"
					[ngStyle]="mainItem.hoverOver && !mainItem.isSelected ? mouseEnterMenuEffect() : {}"
					(mouseleave)="mouseLeaveMenu(i)"
					(mouseenter)="mouseEnterMenu(i)"
					[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
					[class]="mainItem.class ? 'menu-item ' + mainItem.class : 'menu-item'"
					id="{{ 'side-' + mainItem.name }}"
				>
					<img
						[ngClass]="{ 'filter-filed-purple': (mainItem.isSelected && !mainItem.childOpen) || (mainItem.childOpen && !expandMenu) }"
						class="filter-dark"
						[src]="mainItem.icon"
					/>
					<span
						[ngStyle]="(mainItem.isSelected && !mainItem.childOpen) || (mainItem.childOpen && !expandMenu) ? { color: '#5b5fc7' } : {}"
						class="route-text"
					>
						{{ mainItem.title }}
					</span>
					<img
						style="width: 10px; height: 6.25px; margin-right: 16px;"
						*ngIf="!mainItem.isSelected && mainItem.hasChildren && expandMenu"
						src="assets/icons/nav-menu/arrow-down.svg"
					/>
					<img
						style="width: 10px; height: 6.25px; margin-right: 16px;"
						*ngIf="mainItem.isSelected && mainItem.hasChildren && expandMenu"
						src="assets/icons/nav-menu/arrow-up.svg"
					/>
				</mat-list-item>
			</mat-list>
		</div>
	</mat-sidenav>
</section>

<section #mainSection *ngIf="megaMenuOpen && !sidenavMobile" class="side-nav closed mega-expanded-menu">
	<mat-sidenav #sidenav role="navigation">
		<div [ngClass]="{ 'no-menu-margin': !dropdownIsVisible }" class="menu-list" *ngIf="accountState !== userStateEnum.BillPendingRestrictedAccess">
			<mat-list *ngFor="let item of filteredMegaMenuList; let i = index">
				<mat-list-item
					[disabled]="showSpinner"
					(click)="megaMenuItemActive(i, true)"
					(mouseleave)="mouseLeaveMegaMenu(i)"
					(mouseenter)="mouseEnterMegaMenu(i)"
					[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
					[ngStyle]="item.isSelected ? menuItemSelectedEffect() : {}"
					[ngStyle]="item.hoverOver && !item.isSelected ? mouseEnterMenuEffect() : {}"
					class="menu-item"
				>
					<img class="filter-dark" style="width: 20px; height: 20px;" [src]="item.icon" />
					<span class="route-text">
						{{ item.title }}
					</span>
				</mat-list-item>
			</mat-list>
		</div>
	</mat-sidenav>
</section>

<section *ngIf="!sidenavMobile" [ngClass]="{ 'side-container-wide': expandMenu }" class="side-container closed">
	<div [ngClass]="{ 'header-wide': expandMenu }" class="header closed">
		<mat-toolbar class="header_toolbar">
			<div *ngIf="!megaMenuOpen" class="top-logo-img">
				<img src="assets/creators/logo-23/lolly-text-logo-white.svg" />
			</div>

			<span fxFlex class="d-flex k-align-items-center">
				<ng-container *ngIf="currentRoute == '/'">
					<hr class="vertical-line" />
					<div class="top-bar-nav-item full">
						<filed-font [fontSize]="'24px'">Lolly</filed-font>
					</div>
				</ng-container>
			</span>

			<div *ngIf="isImpersonated" class="impersonation-exit">
				<div class="impersonation-content">
					<p class="impersonation-text">You are in Impersonation Mode. Any changes you make will impact your client.</p>
					<app-primary-button
						(buttonClicked)="exitImpersonation()"
						[buttonType]="'Primary'"
						[buttonLable]="'EXIT'"
						[isPeddingReduceSmall]="true"
					></app-primary-button>
				</div>
			</div>

			<div class="header_icons">
				<div class="unpaid-invoice-btn" *ngIf="this.unpaidInvoice">
					<a [routerLink]="['/user-management/billing-and-payment']">Unpaid Invoice</a>
					<div class="unpaid-description">There is an issue with your card. We were unable to bill you. Please add a new payment.</div>
				</div>

				<!-- <app-notifications-dialog 
					*ngIf="router.url != '/notifications'"
					[unreadCount]="unreadCount" 
					[notifications]="notifications"
					(updateUnreadCount)="updateUnreadCountEvent($event)"
					>
				</app-notifications-dialog> -->

				<div class="help-icon" (click)="openKnowledgeBase()">
					<lolly-i-tooltip
						[title]="
							'Get it right the first time! Reach out to Lolly through Ticket Support or utilize our Knowledge base if you have any queries around the workflow over our Platform'
						"
					>
						<img src="assets/icons/creators/sidenav/help.svg" />
					</lolly-i-tooltip>
				</div>

				<div #settingsIcon (click)="toggleProfileAction()" class="profile-icon">
					<img [src]="profileData?.profile_picture_url ?? 'assets/icons/creators/messaging/icon-default-user.svg'" />
				</div>
			</div>
		</mat-toolbar>
	</div>

	<div class="main-container closed">
		<mat-sidenav-container>
			<mat-sidenav-content (scroll)="onScroll($event)" style="background: white;">
				<router-outlet></router-outlet>
				<app-lolly-spinning-loader [rotating]="true" class="loading-indicator" *ngIf="showSpinner"></app-lolly-spinning-loader>
			</mat-sidenav-content>
		</mat-sidenav-container>
	</div>
</section>

<div #settingsPopup class="profile-card" *ngIf="profileOpen && !sidenavMobile">
	<div fxLayout="row" class="avatar-personal w-100">
		<div class="avatar cursor-pointer" (click)="goToSettings()">
			<img [src]="profileData?.profile_picture_url" />
		</div>
		<div fxFlex="100" class="personal">
			<div class="top">{{ profileData?.name }}</div>
			<div class="bottom">{{ userTokenData?.email }}</div>
		</div>
	</div>
	<!-- <div (click)="goToBilling()" class="action"> -->
	<div (click)="goToSettings()" class="action">
		<img src="assets/icons/creators/sidenav/settings-filled.svg" />
		<div class="text">{{ 'Settings' }}</div>
	</div>
	<div (click)="logout()" class="action">
		<img src="assets/icons/creators/sidenav/signout.svg" />
		<div class="text">{{ 'Sign out' }}</div>
	</div>
</div>

<app-toast-notification class="toast-position"></app-toast-notification>

<!-- temporary -->
<ng-template #firstTimeLogin>
	<h2 mat-dialog-title>Thanks for using Lolly !</h2>
	<mat-dialog-content class="mat-typography restrict-users-dialog">
		<h3>
			The platform is undergoing development for our upcoming release and therefore new users don’t have access just yet. Please rest assured Dexter is
			still working in the background to analyse your campaign(s). Please get in touch with your Client Success Manager regarding any questions.
		</h3>
		<app-button (buttonClicked)="logout()" [buttonClass]="buttonClassEnum.Primary" [buttonType]="buttonTypeEnum.Simple" buttonLabel="Logout"></app-button>
	</mat-dialog-content>
</ng-template>

<div *ngIf="sidenavMobile" class="sidenav-mobile">
	<div class="content">
		<router-outlet></router-outlet>
	</div>
	<!-- <div class="footer" *ngIf="(router.url != '/settings' && router.url != '/my-offers') && sharedService.showFooter"> -->
	<div class="footer" *ngIf="sharedService.showFooter">
		<div class="nav-link" *ngFor="let menu of navLinks; let i = index" (click)="navLinkTrigger(i)">
			<img src="assets/creators/mobile-icons/{{ menu.isSelected ? menu.name + '-selected' : menu.name }}-nav.svg" alt="" />
			<p [ngStyle]="menu.isSelected ? { color: '#5B5FC7' } : {}">{{ menu.title }}</p>
		</div>
	</div>
</div>

<mat-card class="mat-card card-content">
	<mat-card-content class="chart-container">
		<div fxLayout="row">
			<mat-form-field class="formField">
				<mat-select *ngIf="pixelFunction" [disableOptionCentering]="true" [formControl]="allActorsCtrl" panelClass="charts" placeholder="Select Actors">
					<mat-option>
						<ngx-mat-select-search
							(selectionChange)="fetchWrapper()"
							[formControl]="actorsFilterCtrl"
							[noEntriesFoundLabel]="'NO_MATCHING_FOUND' | translate"
							[placeholderLabel]="'SEARCH' | translate"
						></ngx-mat-select-search>
					</mat-option>
					<mat-option *ngFor="let actor of filteredActors | async" [value]="actor">{{ actor.name }}</mat-option>
				</mat-select>

				<mat-select
					*ngIf="!pixelFunction"
					[disableOptionCentering]="true"
					[formControl]="allActorsCtrl"
					multiple
					panelClass="charts-multiple"
					placeholder="Select Actors"
				>
					<mat-option>
						<ngx-mat-select-search
							(selectionChange)="fetchWrapper()"
							[formControl]="actorsFilterCtrl"
							[noEntriesFoundLabel]="'NO_MATCHING_FOUND' | translate"
							[placeholderLabel]="'SEARCH' | translate"
						></ngx-mat-select-search>
					</mat-option>
					<mat-option *ngFor="let actor of filteredActors | async" [value]="actor">{{ actor.name }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="formField m-l-30">
				<mat-select
					(selectionChange)="drawCharts()"
					*ngIf="multipleMetricsAllowed"
					[disableOptionCentering]="true"
					[formControl]="chartWrapper.chartMultipleMetricCtrl"
					[multiple]="true"
					placeholder="Breakdown"
				>
					<mat-option *ngFor="let metric of metrics" [value]="metric">
						{{ metric.Name | camelCaseToText }}
					</mat-option>
				</mat-select>
				<mat-select
					(selectionChange)="drawCharts()"
					*ngIf="!multipleMetricsAllowed"
					[disableOptionCentering]="true"
					[formControl]="chartWrapper.chartSingleMetricCtrl"
					[multiple]="false"
					placeholder="Breakdown"
				>
					<mat-option *ngFor="let metric of metrics" [value]="metric">
						{{ metric.Name | camelCaseToText }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="formField m-l-30">
				<mat-select [disableOptionCentering]="true" [formControl]="chartTypeCtrl" class="font-medium" placeholder="Chart Type">
					<mat-option *ngFor="let chartType of chartTypes" [value]="chartType">{{ chartType }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div [hidden]="!showSpinner">
			<div class="spinner">
				<mat-spinner diameter="15"></mat-spinner>
			</div>
		</div>
		<div
			*ngIf="chartWrapper.show"
			[autoResize]="chartWrapper.autoResize"
			[merge]="chartWrapper.updateOptions"
			[options]="chartWrapper.chartOption"
			[theme]="'default'"
			echarts
		></div>
	</mat-card-content>
</mat-card>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from '../../services/settings.service';

@Component({
	selector: 'app-stripe-landing',
	templateUrl: './stripe-landing.component.html',
	styleUrls: ['./stripe-landing.component.scss']
})
export class StripeLandingComponent implements OnInit {
	public code: string;
	public state: string;
	public unsubscriber$ = new Subject<void>();

	constructor(public router: Router, private settingsService: SettingsService, public route: ActivatedRoute) {
		this.route.queryParams.subscribe(params => {
			this.code = params['code'];
			this.state = params['state'];
		});
	}

	ngOnInit(): void {
		this.settingsService
			.installStripe(this.code, this.state)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.router.navigate(['/settings/billing']);
			});
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}

import { Action } from '@ngrx/store';
import { LogInModel } from '../_models/login.model';

export enum AuthenticationActionType {
	LogIn = '[Auth] Log In',
	LogInSuccess = '[Auth] Log In Success',
	LogInFailure = '[Auth] Log In Failure',
	LogOut = '[Auth] Log Out',

	RestoreAuthentication = '[Auth] Reload token from storage',

	EnableFirstTimeCustomer = '[Auth Welcome Page] Enable First Time Customer',
	DisableFirstTimeCustomer = '[Auth Welcome Page] Disable First Time Customer'
}

export class LogIn implements Action {
	readonly type = AuthenticationActionType.LogIn;

	constructor(public payload: LogInModel) {}
}

export class LogInSuccess implements Action {
	readonly type = AuthenticationActionType.LogInSuccess;

	constructor(public payload: string) {}
}

export class LogInFailure implements Action {
	readonly type = AuthenticationActionType.LogInFailure;

	constructor(public payload: string) {}
}

export class LogOut implements Action {
	readonly type = AuthenticationActionType.LogOut;
}

export class RestoreAuthentication implements Action {
	readonly type = AuthenticationActionType.RestoreAuthentication;

	constructor(public payload: string) {}
}

export class EnableFirstTimeCustomer implements Action {
	readonly type = AuthenticationActionType.EnableFirstTimeCustomer;
}

export class DisableFirstTimeCustomer implements Action {
	readonly type = AuthenticationActionType.DisableFirstTimeCustomer;
}

export type AuthenticationActions = LogIn | LogInSuccess | LogInFailure | LogOut | RestoreAuthentication | EnableFirstTimeCustomer | DisableFirstTimeCustomer;

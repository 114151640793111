import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { GalleryItem } from 'src/app/_models/Gallery/GalleryItem';
import { AdAccountApiService } from '../../_services/facebook-accounts/ad-account-api.service';
import { AccountTypeService } from '../account-type.service';
import { AdFormats } from '../models/server-statics-enum-constants';
import { AssetService } from '../services/assets/asset.service';
import { AssetType } from '../services/assets/models/asset-type.enum';
import { TemplateModel } from '../services/assets/models/template.model';
import { getBusinessOwnerIdByChannel, UserState } from '../state/user/user.reducer';

@Component({
	selector: 'app-assets-gallery',
	templateUrl: './assets-gallery.component.html',
	styleUrls: ['./assets-gallery.component.scss']
})
export class AssetsGalleryComponent implements OnInit {
	public templates: BehaviorSubject<TemplateModel[]>;
	public videos: BehaviorSubject<any>;
	public images: BehaviorSubject<any>;
	public existingAdsImages: BehaviorSubject<any>;
	public existingAdsVideos: BehaviorSubject<any>;

	public showUpload = false;
	public showExistingAdsImages = false;
	public showExistingAdsVideos = false;
	public showImages = false;
	public showVideos = false;
	public showPosts = true;

	constructor(
		private dialogRef: MatDialogRef<AssetsGalleryComponent>,
		private assetService: AssetService,
		private accountTypeService: AccountTypeService,
		private adAccountService: AdAccountApiService,
		private store: Store<UserState>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	public async ngOnInit() {
		const adAccountFacebookId = this.adAccountService.getSelectedAdAccount().id;

		this.accountTypeService
			.getAccountTypeObservable()
			.pipe(switchMap((channel: SourceChannel) => this.store.pipe(select(getBusinessOwnerIdByChannel, { channel: channel }), take(1))))
			.subscribe((businessOwnerId: string) => {
				this.assetService.getAdVideos(adAccountFacebookId, businessOwnerId);
				this.assetService.getAdImages(adAccountFacebookId, businessOwnerId);

				this.updateUiOnAdFormat(this.data.adFormatId);

				this.assetService.getTemplates();
				this.assetService.getVideos();
				this.assetService.getImages();

				this.data.details.pageFacebookId && this.assetService.getPagePosts(this.data.details.pageFacebookId, businessOwnerId);

				this.templates = this.assetService.templates;
				this.videos = this.assetService.videos;
				this.images = this.assetService.images;
				this.existingAdsImages = this.assetService.existingAdsImages;
				this.existingAdsVideos = this.assetService.existingAdsVideos;
			});
	}

	public submit(data: GalleryItem[], withFacebookId = false): void {
		if (data.length === undefined) {
			return;
		}

		data.forEach((item: GalleryItem) => {
			if (item.asset.type !== AssetType.Video || !withFacebookId) {
				delete item.asset.id;
			}
		});

		this.dialogRef.close(data);
	}

	private updateUiOnAdFormat(format: number): void {
		switch (format) {
			case AdFormats.SingleImage:
				this.showUpload = this.showImages = this.showExistingAdsImages = true;
				break;
			case AdFormats.Video:
				this.showUpload = this.showVideos = this.showExistingAdsVideos = true;
				break;
			case AdFormats.Carousel:
				this.showUpload = this.showImages = this.showVideos = this.showExistingAdsImages = this.showExistingAdsVideos = true;
				break;
			case AdFormats.Slideshow:
				// TODO
				break;
			case AdFormats.Collection:
				// TODO
				break;
		}
	}
}

<app-expansion-panel-sc
	class="scs-expansion-panel"
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[expansionTitle]="'Select Page'"
	[isExpandable]="false"
>
	<div expansion-content>
		<span>Choose the Facebook Page you want to promote</span>
		<div class="pc-dropdown-wrapper pc-margin-bottom">
			<app-dropdown-search-select
				[dropdownFormControl]="pagesFormGroup.get('facebookId')"
				(selectionChange)="getPages(true)"
				[dropdownPlaceholder]="'Choose Facebook Page'"
				[dropdownData]="facebookPages"
				[dropdownSelect]="selectType.Simple"
				[searchEnable]="true"
			></app-dropdown-search-select>
		</div>

		<div *ngIf="!isPageLikesSelected && instaAccountPage">
			<span>Choose the Instagram Page you want to promote</span>
			<div class="pc-dropdown-wrapper">
				<app-dropdown-search-select
					[dropdownFormControl]="pagesFormGroup.get('instagramId')"
					[dropdownPlaceholder]="'Choose Instagram Page'"
					[dropdownData]="sortedInstagram"
					[dropdownSelect]="selectType.Simple"
					[searchEnable]="true"
				></app-dropdown-search-select>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './creators-module/modules/authentication/services/authentication.service';
import { SettingsService } from './creators-module/modules/settings/services/settings.service';
import { SidenavService } from './_services/side-nav.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	@ViewChild('sidenav')
	sidenav: MatSidenav;

	adAccounts: any[];

	asyncLoadCount: number;

	isShowingRouteLoadIndicator = false;

	constructor(private router: Router, private sidenavService: SidenavService, private translateService: TranslateService) {
		this.asyncLoadCount = 0;
		this.router.events.subscribe((event: RouterEvent) => {
			if (event instanceof RouteConfigLoadStart) {
				this.asyncLoadCount++;
			} else if (event instanceof RouteConfigLoadEnd) {
				this.asyncLoadCount--;
			}
			this.isShowingRouteLoadIndicator = !!this.asyncLoadCount;
		});
	}

	ngOnInit() {
		this.sidenavService.setSidenav(this.sidenav);
		this.translateService.addLangs(['en', 'fr']);
		this.translateService.setDefaultLang('en');
		const browserLanguage = this.translateService.getBrowserLang();
		this.translateService.use(browserLanguage.match(/en|fr/) ? browserLanguage : 'en');
		// this.registerThemes();
	}

	registerThemes() {
		// echarts.registerTheme('default', themesModel.default);
		// echarts.registerTheme('coconut', themesModel.coconut);
		// echarts.registerTheme('walden', themesModel.walden);
		// echarts.registerTheme('macarons', themesModel.macarons);
		// echarts.registerTheme('essos', themesModel.essos);
		// echarts.registerTheme('vintage', themesModel.vintage);
	}
}

import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { StorageKey } from '../_models/local-storage-key';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { TokenService } from './token.service';
import { UserDetails } from '../_models/identity-models/identity-detail';
import { UserStateEnum } from './user/user-state.enum';
import { select, Store } from '@ngrx/store';
import { LogOut, RestoreAuthentication } from '../authentication/state/authentication.action';
import { getAccountState, getUserDetails, hasBackOfficePermission, UserState } from '../shared/state/user/user.reducer';
import { SetUserDetails } from '../shared/state/user/user.actions';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthenticationState } from '../authentication/state/authentication.reducer';
import { ResetAppState } from '../state/app.actions';
import moment from 'moment';
import { PermissionsService } from '../shared/permisions/permissions.service';
import { UserDetailsInterface } from '../_models/identity-models/user-details.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	public logoutSubject: Subject<void> = new Subject();
	private userDetails: UserDetails;
	public setRoute$: BehaviorSubject<string> = new BehaviorSubject('');
	public allowedMegaRoutes$: BehaviorSubject<any[]> = new BehaviorSubject(null);
	public triggerNavRoute$: BehaviorSubject<string> = new BehaviorSubject('audience');
	public bigCommerceAction$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setSubNavIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
	public expandMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public navMenuRoute$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setMegaRouteIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
	public setParentRoute$: BehaviorSubject<string> = new BehaviorSubject('');
	constructor(
		private router: Router,
		private tokenService: TokenService,
		private permissionsService: PermissionsService,
		private userStore: Store<UserState>,
		private authStore: Store<AuthenticationState>
	) {}

	public isLoggedIn() {
		// const tokenExists: boolean = !!localStorage.getItem(StorageKey.token);
		// if (tokenExists) {
		// 	const decodedJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		// 	const currentTime = moment().format();
		// 	const timeFromJwt = moment(decodedJwt.exp * 1000).format();
		// 	const decodedJwtIsValid: boolean = !moment(currentTime).isAfter(timeFromJwt);
		// 	if (!this.userDetails) {
		// 		const decodedToken = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
		// 		this.userDetails = this.getUserDetailsFromJwt(decodedToken);
		// 	}
		// 	return tokenExists && decodedJwtIsValid;
		// }
	}

	public hasCreditCard(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState !== UserStateEnum.NoCreditCard))
		);
	}

	public hasBusinessOwner(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState !== UserStateEnum.NoBusinessOwner))
		);
	}

	public isAdmin(): Observable<boolean> {
		return this.userStore.pipe(select(hasBackOfficePermission), take(1));
	}

	public isFreemiumExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState === UserStateEnum.FreemiumExpiredNoCreditCard))
		);
	}

	public isFreeTrialExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState === UserStateEnum.FreeTrialExpiredNoCreditCard))
		);
	}

	public logout(params?: NavigationExtras): void {
		// TODO hack because we dont have refresh token
		const temporaryCredentials = localStorage.getItem('details');
		// localStorage.clear();
		if (temporaryCredentials) {
			localStorage.setItem('details', temporaryCredentials);
		}

		this.logoutSubject.next();

		this.userStore.dispatch(new ResetAppState());
		this.authStore.dispatch(new LogOut());

		params ? this.router.navigate(['/authentication'], params) : this.router.navigate(['/authentication']);
	}

	public initUserRolesLogic(): void {
		// this.tokenService.token = localStorage.getItem(StorageKey.token);
		// if (!this.tokenService.token) {
		// 	return;
		// }
		// try {
		// 	const decodedToken = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
		// 	this.userDetails = this.getUserDetailsFromJwt(decodedToken);
		// 	this.userStore.dispatch(new SetUserDetails(this.userDetails));
		// 	this.authStore.dispatch(new RestoreAuthentication(this.tokenService.token));
		// } catch (e) {
		// 	this.logout();
		// }
	}

	public getUserDetailsFromJwt(decodedJwt: UserDetailsInterface): UserDetails {
		const codedPermissions: string[] = decodedJwt.permissions_filed.split('|');
		const decodedPermissions = codedPermissions
			.map(permission => this.permissionsService.decodePermission(permission))
			.filter(permission => permission.permissions.length);
		return {
			FiledId: parseInt(decodedJwt.user_filed_id),
			OriginalFiledId: parseInt(decodedJwt.original_filed_id),
			AccountState: parseInt(decodedJwt.user_account_state),
			FacebookBusinessOwnerId: decodedJwt.user_facebook_businessowner_id,
			GoogleBusinessOwnerId: decodedJwt.user_google_businessowner_id,
			IsFrontOfficeUser: decodedJwt.user_is_frontoffice_user === 'True',
			RejectedReason: parseInt(decodedJwt.user_rejected_reason),
			IsImpersonated: false,
			Permissions: decodedPermissions
		};
	}
}

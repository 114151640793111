export enum PlatformList {
	FACEBOOK = 'Facebook',
	INSTAGRAM = 'Instagram',
	TIKTOK = 'Tiktok',
	YOUTUBE = 'YouTube'
}

export interface NewAccountDetails {
	influencerId: number;
	name: string;
	surname: string;
	phoneNumber: string;
	email: string;
	companyName?: string;
}

export interface LogInModel {
	email: string;
	password: string;
	idToken?: string;
}

export interface InfluencerDetails {
	OriginalFiledId?: number;
	InfluencerAvailablePlatforms: string[];
	IsFrontOfficeUser?: boolean;
	AccountState?: AccountStateEnum;
	FiledId: number;
	lastLogin?: string;
}

export interface InfluencerDetailsInterface {
	jti: string;
	sub: string;
	user_filed_id: string;
	user_firstname: string;
	user_lastname: string;
	influencer_available_platforms: string;
	exp: string;
	iss: string;
	aud: string;
	user_account_state: string;
	influencer_last_login?: string;
	influencer_profile_picture_url?: string;
}

export interface LoginDetails {
	emailAddress: string;
	password: string;
}

export interface SetPasswordDetails {
	confirmPassword: string;
	password: string;
	twoFactorToken: string;
	influencerUserId: number;
}

export interface InfluencerChangePassword {
	currentPassword: string;
	newPassword: string;
	confirmNewPassword: string;
}

export interface InfluencerDetailApi {
	email: string;
	id: number;
	instagramEngagement: number;
	instagramFollowers: number;
	lastLogin: string;
	name: string;
	phoneNumber: string;
	surname: string;
	tikTokEngagement: number;
}

export enum AccountStateEnum {
	Inactive = 0,
	Active = 1,
	Removed = 2,
	InTransition = 3,
	NoBusinessOwner = 4,
	NoCreditCard = 5,
	BillPendingUnrestrictedAccess = 7,
	BillPendingRestrictedAccess = 8,
	FreemiumExpiredNoCreditCard = 11,
	FreeTrialExpiredNoCreditCard = 12
}

export interface InstagramAccountInterface {
	name: string;
	profile_picture_url: string;
	id: string;
	username: string;
}

export enum SocialAccountStatus {
	NOT_CONNECTED = 'Not Connected',
	PENDING = 'Pending',
	CONNECTED = 'Connected'
}

export interface InstagramAccountInterface {
	name: string;
	profile_picture_url: string;
	id: string;
	username: string;
}

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../_services/authentication.service';

@Injectable()
export class AuthenticationRedirectGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// if (this.authService.isLoggedIn()) {
		// 	this.router.navigate(['/accounts']);
		// 	return false;
		// }

		return true;
	}
}

import { MarkPoint } from './mark-point.model';

export class ChartSeriesModel {
	public name: string;
	public data: any[];
	public stack: string;
	public connectNulls = false;
	public markPoint?: MarkPoint;
	public smooth?: boolean;
}

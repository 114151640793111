<div [hidden]="isLoading" [ngClass]="{ 'request-fullscreen': isInFullScreen }" class="generic-table">
	<div *ngIf="isInFullScreen" class="exit_button">
		<mat-icon (click)="exitFullScreen()" class="icon-greyed-color">cancel</mat-icon>
	</div>
	<!--  <div class="advertise-button" *ngIf="isInFullScreen">-->
	<!--    <app-drop-down-button></app-drop-down-button>-->
	<!--  </div>-->
	<div class="table-template" fxLayout="row wrap">
		<div fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
			<mat-card-title class="mat_card_title">
				<div *ngIf="selection.selected.length >= 2 && multipleSelectionActions && multipleSelectionActions.length > 0" class="roll-up">
					<div class="selected_checkbox">{{ selection.selected.length }} {{ 'SELECTED' | translate }}</div>
					<div class="bar">
						<div class="helper_bar"></div>
					</div>
					<div [matMenuTriggerFor]="menu" class="edit_table">
						<div class="edit_drop-down">{{ 'Actions' | translate }}</div>
						<mat-icon class="edit_arrow">arrow_drop_down</mat-icon>
						<mat-menu #menu="matMenu">
							<app-multiple-selection-actions
								(actionEvent)="doAction($event)"
								*ngIf="multipleSelectionActions && multipleSelectionActions.length > 0"
								[multipleSelectionActions]="multipleSelectionActions"
								[selectedRows]="selection.selected"
							></app-multiple-selection-actions>
						</mat-menu>
					</div>
				</div>
				<div
					*ngIf="!(selection.selected.length >= 2 && multipleSelectionActions && multipleSelectionActions.length > 0)"
					class="d-flex d-j-c-flex-end header_table_bar"
					layout="row wrap"
					layout-align="start center"
				>
					<mat-form-field class="width-200 search_table" fxFlex>
						<mat-icon matPrefix>search</mat-icon>
						<input
							(keyup)="applyFilter($event.target.value)"
							class="search-input"
							matInput
							placeholder="{{ 'SEARCH' | translate }}"
							type="search"
							value=""
						/>
					</mat-form-field>
					<div *ngIf="!isInFullScreen" class="filter_icon">
						<button (click)="expand()" class="icon-button expand-icon" mat-icon-button>
							<img src="/assets/icons/expand-icon.svg" />
							<div class="font-10">{{ 'EXPAND' | translate }}</div>
						</button>
					</div>
					<div *ngIf="filterActions | contains: 'filter'" class="filter_icon">
						<button class="icon-button expand-icon" mat-icon-button>
							<img src="/assets/icons/filter-icon.svg" />
							<div class="font-10">{{ 'FILTER' | translate }}</div>
						</button>
					</div>
					<div *ngIf="filterActions | contains: 'breakdown'" [formGroup]="form" class="filter_icon">
						<button [matMenuTriggerFor]="breakdownTypes" class="icon-button breakdown-icon" mat-icon-button>
							<img src="assets/icons/breakdown-icon.svg" />
							<div class="font-10">{{ 'BREAKDOWN' | translate }}</div>
						</button>
						<mat-menu #breakdownTypes="matMenu">
							<button
								(click)="clearBreakdowns()"
								*ngIf="form.value.selectedTime !== 0 || form.value.selectedDelivery !== 0 || form.value.selectedAction !== 0"
								class="breakdown_item"
								mat-menu-item
							>
								{{ 'CLEAR_ALL_BREAKDOWNS' | translate }}
							</button>
							<button [matMenuTriggerFor]="timeMenu" class="breakdown_item" mat-menu-item>{{ 'BY_TIME' | translate }}</button>
							<button [matMenuTriggerFor]="deliveryMenu" class="breakdown_item" mat-menu-item>{{ 'BY_DELIVERY' | translate }}</button>
							<button [matMenuTriggerFor]="actionMenu" class="breakdown_item" mat-menu-item>{{ 'BY_ACTION' | translate }}</button>
						</mat-menu>
						<mat-menu #timeMenu="matMenu">
							<mat-radio-group class="width-100-percentage m-b-15" formControlName="selectedTime">
								<mat-radio-button
									*ngFor="let time of timeOptions; let i = index"
									[checked]="form.controls['selectedTime'].value.id == time.id"
									[value]="time"
									class="width-150 example-radio-button"
								>
									{{ time.name }}
								</mat-radio-button>
							</mat-radio-group>
						</mat-menu>
						<mat-menu #deliveryMenu="matMenu">
							<mat-radio-group class="width-100-percentage m-b-15" formControlName="selectedDelivery">
								<mat-radio-button
									*ngFor="let delivery of deliveryOptions; let i = index"
									[checked]="form.controls['selectedDelivery'].value == delivery.id"
									class="width-150 example-radio-button"
									value="{{ delivery.id }}"
								>
									{{ delivery.name }}
								</mat-radio-button>
							</mat-radio-group>
						</mat-menu>
						<mat-menu #actionMenu="matMenu">
							<mat-radio-group class="width-100-percentage m-b-15" formControlName="selectedAction">
								<mat-radio-button
									*ngFor="let action of actionOptions; let i = index"
									[checked]="form.controls['selectedAction'].value == action.id"
									class="width-150 example-radio-button"
									value="{{ action.id }}"
								>
									{{ action.name }}
								</mat-radio-button>
							</mat-radio-group>
						</mat-menu>
					</div>
					<div *ngIf="filterActions | contains: 'group'" class="group_icon">
						<button class="icon-button group-icon" mat-icon-button>
							<img src="assets/icons/groups-icon.svg" />
							<div class="font-10">{{ 'GROUP' | translate }}</div>
						</button>
					</div>
					<div *ngIf="filterActions | contains: 'columns'" class="columns_icon">
						<button [matMenuTriggerFor]="showOptions" class="icon-button breakdown-icon" mat-icon-button>
							<img src="/assets/icons/columns-icon.svg" />
							<div class="font-10">{{ 'COLUMNS' | translate }}</div>
						</button>
						<mat-menu #showOptions>
							<mat-list role="list">
								<mat-list-item (click)="displayBasicColumns()" class="columns_options" role="listitem">
									{{ 'BASIC' | translate }}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item (click)="openTableColumnsManagement()" class="columns_options" role="listitem">
									{{ 'MODIFY_COLUMNS' | translate }}
								</mat-list-item>
								<mat-list-item class="columns_options" role="listitem">{{ 'SET_AS_DEFAULT' | translate }}</mat-list-item>
								<mat-list-item class="columns_options" role="listitem">{{ 'RESET_COLUMN_WIDTHS' | translate }}</mat-list-item>
								<mat-list-item (click)="openAddMetric()" class="columns_options" role="listitem">
									{{ 'ADD_NEW_METRIC' | translate }}
								</mat-list-item>
							</mat-list>
						</mat-menu>
						<!--<mat-menu #menu="matMenu">-->
						<!--<button mat-menu-item (click)="$event.stopPropagation()">-->
						<!--<mat-checkbox>CHECKBOX_TO_DO</mat-checkbox>-->
						<!--</button>-->
						<!--</mat-menu>-->
					</div>
					<div class="export_icon">
						<button (click)="exportDataToExcel(selection.selected)" class="icon-button export-icon" mat-icon-button>
							<img src="/assets/icons/export-icon.svg" />
							<div class="font-10">{{ 'EXPORT' | translate }}</div>
						</button>
					</div>
				</div>
			</mat-card-title>
			<div class="table-responsive" dir="ltr">
				<table #table [dataSource]="dataSource" mat-table matSort>
					<ng-container *ngFor="let col of displayedColumns; let i = index">
						<!-- MASTER CHECKBOX -->
						<ng-container *ngIf="col.type === 'masterCheckbox'" [matColumnDef]="col.primaryValue" [sticky]="col.sticky">
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-j-c-center d-flex right-border-transparent" mat-header-cell>
								<mat-checkbox
									(change)="$event ? masterToggle() : null"
									[checked]="selection.hasValue() && isAllSelected()"
									[indeterminate]="selection.hasValue() && !isAllSelected()"
								></mat-checkbox>
							</th>

							<td *matCellDef="let row" class="d-flex d-j-c-center right-border-transparent" mat-cell>
								<span class="header-label"></span>
								<mat-checkbox
									(change)="$event ? selection.toggle(row) : null"
									(click)="$event.stopPropagation()"
									[checked]="selection.isSelected(row)"
								></mat-checkbox>
							</td>
						</ng-container>

						<!-- TEXT -->
						<ng-container *ngIf="col.type === 'text'" [matColumnDef]="col.primaryValue" [sticky]="col.sticky">
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-flex" mat-header-cell>
								<span [class.sorted-header]="col.primaryValue === sort.active" class="header-text">
									{{ 'TABLE.COLUMN.' + col.name | translate }}
								</span>
								<div mat-sort-header></div>
							</th>
							<td
								*matCellDef="let row"
								[class.no-data]="row[col.primaryValue] == null && row[col.secondaryValue] == null"
								class="d-flex"
								mat-cell
							>
								<div class="width-100-percentage">
									<div class="width-90-percentage text-wrap">
										<div class="primary-value-text {{ col.primaryValue }} {{ col.name === 'NAME' ? 'name-column-value' : null }}">
											{{ row[col.primaryValue] }}
										</div>
									</div>
									<!--  <button *ngIf="col.name === 'NAME'"
                                (click)="doAction({functionName: 'editName', parameter: row})"
                                mat-icon-button class="edit-button">
                          <mat-icon>edit</mat-icon>
                        </button>-->
									<div *ngIf="col.secondaryValue" class="width-100-percentage">
										{{ row[col.secondaryValue] }}
									</div>
								</div>
							</td>
						</ng-container>

						<!-- NUMBER -->
						<ng-container *ngIf="col.type === 'number'" [matColumnDef]="col.primaryValue" [sticky]="col.sticky">
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-flex d-j-c-flex-start" mat-header-cell>
								<span [class.sorted-header]="col.primaryValue === sort.active" class="header-text">
									{{ 'TABLE.COLUMN.' + col.name | translate }}
								</span>
								<div mat-sort-header></div>
							</th>
							<td
								*matCellDef="let row"
								[class.no-data]="row[col.primaryValue] == null && row[col.secondaryValue] == null"
								class="d-flex align-right-table-numeric-cells"
								mat-cell
							>
								<div class="table-column">
									<div [class]="col.primaryValue">
										{{ row[col.primaryValue] | formatText }}
										<!--                        <app-row-value-interpreter [property]="row" [value]="col.primaryValue"></app-row-value-interpreter>-->
									</div>
									<div class="table-row"></div>
									<div *ngIf="col.secondaryValue" [class]="col.secondaryValue">
										{{ row[col.secondaryValue] | formatText }}
									</div>
								</div>
							</td>
						</ng-container>

						<!-- CURRENCY -->
						<ng-container *ngIf="col.type === 'currency'" [matColumnDef]="col.primaryValue" [sticky]="col.sticky">
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-flex d-j-c-flex-start" mat-header-cell>
								<span [class.sorted-header]="col.primaryValue === sort.active" class="header-text">
									{{ 'TABLE.COLUMN.' + col.name | translate }}
								</span>
								<div mat-sort-header></div>
							</th>
							<td *matCellDef="let row" class="d-flex d-j-c-flex-end" mat-cell>
								<div fxLayout="column" fxLayoutAlign="space-between start">
									<div *ngIf="row[col.primaryValue]" [class]="col.primaryValue">{{ row[col.primaryValue] }}</div>
								</div>
								<div *ngIf="col.secondaryValue" [class]="col.secondaryValue" fxLayout="row" fxLayoutAlign="space-between start">
									{{ row[col.secondaryValue] }}
								</div>
							</td>
						</ng-container>

						<!-- TOGGLE -->
						<ng-container *ngIf="col.type === 'toggle'" [matColumnDef]="col.primaryValue" [sticky]="col.sticky">
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-flex d-j-c-center" mat-header-cell>
								<span class="header-text">{{ 'TABLE.COLUMN.' + col.name | translate }}</span>
							</th>
							<td *matCellDef="let row" class="d-flex d-j-c-center" mat-cell>
								<div [class]="col.primaryValue" fxLayout="column" fxLayoutAlign="space-between start">
									<mat-slide-toggle
										(change)="
											doAction({
												functionName: 'toggleAction',
												parameter: { id: row['id'], name: col.primaryValue, value: row[col.primaryValue] }
											})
										"
										[checked]="row[col.primaryValue]"
									></mat-slide-toggle>
								</div>
							</td>
						</ng-container>

						<!-- CHECKBOX -->
						<ng-container *ngIf="col.type === 'checkbox'" [matColumnDef]="col.primaryValue" [sticky]="col.sticky">
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-flex" mat-header-cell>
								<span class="header-text">{{ 'TABLE.COLUMN.' + col.name | translate }}</span>
							</th>
							<td *matCellDef="let row" class="d-flex" mat-cell>
								<div [class]="col.primaryValue" fxLayout="column" fxLayoutAlign="space-between start">
									<span class="header-label"></span>
									<mat-checkbox [checked]="row[col.primaryValue]"></mat-checkbox>
								</div>
							</td>
						</ng-container>

						<!-- ICON -->
						<ng-container *ngIf="col.type === 'icon'" [matColumnDef]="col.primaryValue" [sticky]="col.sticky">
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-flex d-j-c-center" mat-header-cell>
								<span class="header-text">{{ 'TABLE.COLUMN.' + col.name | translate }}</span>
							</th>
							<td *matCellDef="let row" class="d-flex d-j-c-center" mat-cell>
								<div [class]="col.primaryValue" fxLayout="column" fxLayoutAlign="center center">
									<div fxLayout="row" fxLayoutAlign="center center">
										<img *ngIf="!col.secondaryValue" [src]="row[col.primaryValue]" alt="" height="24px" width="24px" />
										<mat-icon *ngIf="col.secondaryValue" class="table-cell-icon {{ row[col.secondaryValue] }}"></mat-icon>
									</div>
								</div>
							</td>
						</ng-container>

						<!-- LINK -->
						<ng-container *ngIf="col.type === 'link'" [matColumnDef]="col.primaryValue" [sticky]="col.sticky">
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-flex" mat-header-cell>
								<span [class.sorted-header]="col.primaryValue === sort.active" class="header-text">
									{{ 'TABLE.COLUMN.' + col.name | translate }}
								</span>
								<div mat-sort-header></div>
							</th>
							<td *matCellDef="let row" class="d-flex" mat-cell>
								<div class="width-100-percentage {{ col.primaryValue }}" fxLayout="column" fxLayoutAlign="flex-start">
									<div class="width-100-percentage {{ col.primaryValue }} text-wrap" fxLayout="row" fxLayoutAlign="space-between center">
										<a (click)="goToLink(row, col.primaryValue, $event)" class="link">
											{{ row[col.primaryValue] }}
											<!--                          <app-row-value-interpreter [property]="row" [value]="col.primaryValue"></app-row-value-interpreter>-->
										</a>
									</div>
									<div [class]="col.secondaryValue" fxLayout="row" fxLayoutAlign="space-between center" style="overflow: hidden;">
										{{ row[col.secondaryValue] }}
									</div>
								</div>
							</td>
						</ng-container>

						<!-- ACTIONS -->
						<ng-container *ngIf="col.type === 'actions'" [matColumnDef]="col.primaryValue" stickyEnd>
							<th (mousedown)="onResizeColumn($event, i)" *matHeaderCellDef class="d-flex d-j-c-center" mat-header-cell>
								<span class="header-text">{{ 'TABLE.COLUMN.' + col.name | translate }}</span>
							</th>
							<td *matCellDef="let row" class="d-flex d-j-c-center" mat-cell>
								<button *ngIf="col.displayForRowProperty == null || row[col.displayForRowProperty]" [matMenuTriggerFor]="menu" mat-icon-button>
									<mat-icon class="actionBtn">add</mat-icon>
								</button>
								<mat-menu #menu="matMenu">
									<app-single-selection-actions
										(actionEvent)="doAction($event)"
										[selectedRow]="row"
										[singleSelectionActions]="singleSelectionActions"
									></app-single-selection-actions>
								</mat-menu>
							</td>
						</ng-container>
					</ng-container>

					<!-- HEADER -->
					<mat-header-row *matHeaderRowDef="columnDefinitions"></mat-header-row>
					<mat-row *matRowDef="let row; columns: columnDefinitions"></mat-row>
				</table>
			</div>
			<!--*ngIf="dataSource.paginator"-->
			<mat-paginator #paginator [pageSizeOptions]="[40, 60, 80]" [pageSize]="40" class="mat_paginator"></mat-paginator>
		</div>
	</div>
</div>
<div [hidden]="!isLoading">
	<app-spinner-filed></app-spinner-filed>
</div>
<!--<button mat-button [matMenuTriggerFor]="menu"><mat-icon>edit</mat-icon></button>-->
<!--<mat-menu #menu="matMenu">-->
<!--<ng-container *ngFor="let action of actions">-->
<!--<ng-container *ngIf="action.type === 'button'">-->
<!--<button (click)="doSomething(action.function, row)" mat-menu-item>{{ "TABLE.SINGLE_SELECTION_ACTION." + action.name  | translate }}</button>-->
<!--</ng-container>-->
<!--</ng-container>-->
<!--</mat-menu>-->

import { Environment } from './models/environment.models';

export const environment: Environment = {
	Flags: {
		production: false,
		hasTimestamp: true
	},

	ShareUrl: 'https://dev3.filed.com/#/share-link/report/',

	SharedServices: {
		Users: 'https://qk4rq24ooj.execute-api.us-east-2.amazonaws.com/dev/api/v1/',
		Subscription: 'https://9ck3ekes9c.execute-api.us-east-2.amazonaws.com/dev/api/v1/',
		GeneralSettings: 'https://cs-general-settings-api.dev3.filed.com/api/v1/'
	},

	CreativeBuilders: {
		CreativeBuilder: 'https://cs-creative-builders-api.dev3.filed.com/api/v1/'
	},

	ProductCatalogs: {
		ProductCatalog: 'https://cs-product-catalogs-api.dev3.filed.com/api/v1/'
	},

	SharedMarketing: {
		Reporting: 'https://cs-reports-api.dev3.filed.com/api/v1/',
		Optimization: 'https://py-facebook-dexter-api.dev3.filed.com/api/v1/',
		Assets: 'https://cs-assets-api.dev3.filed.com/api/v1/'
	},

	Facebook: {
		DataPipeline: 'https://py-facebook-turing-api.dev3.filed.com/api/v1/',
		FacebookAccounts: 'https://py-facebook-accounts-api.dev3.filed.com/api/v1/',
		Account: 'https://cs-facebook-accounts-api.dev3.filed.com/api/v1/',
		CampaignBuilder: 'https://cs-facebook-campaigns-builder-api.dev3.filed.com/api/v1/',
		CampaignBuilderPython: 'https://py-facebook-campaigns-builder-api.dev3.filed.com/api/v1/',
		CampaignBuilderSmartCreate: 'https://cs-facebook-smart-create-api.dev3.filed.com/api/v1/',
		Apps: 'https://cs-facebook-apps-api.dev3.filed.com/api/v1/',
		Audience: 'https://cs-facebook-audiences-api.dev3.filed.com/api/v1/',
		Pixel: 'https://cs-facebook-pixels-api.dev3.filed.com/api/v1/',
		PixelPython: 'https://py-facebook-pixels-api.dev3.filed.com/api/v1/'
	},

	Google: {
		Accounts: 'https://cs-google-accounts-api.dev3.filed.com/api/v1/',
		PythonAccounts: 'https://py-google-turing-api.dev3.filed.com/api/v1/',
		CampaignManager: 'https://py-campaign-manager-api.dev3.filed.com/api/v1/',
		CampaignBuilder: 'https://cs-campaign-builder-api.dev3.filed.com/api/v1/',
		DataPipeLine: 'https://py-google-turing-api.dev3.filed.com/api/v1/',
		ClientId: '17118844476-fq28ato7jbgv4095u7cd6crlp9m4as55.apps.googleusercontent.com'
	},

	Misc: {
		HubSpot: 'https://api.hubapi.com/contentsearch/v2/',
		LogErrors: 'https://py-logging-api.dev3.filed.com/api/v1/'
	},

	SocialInfluencerApi: {
		SocialInfluencerPython: 'https://frwoecnvkf.execute-api.us-east-2.amazonaws.com/dev/',
		SocialInfluencerListAPI: 'https://rm9r7sfzx2.execute-api.us-east-2.amazonaws.com/dev/',
		SocialMessengerPython: 'https://py-filed-messenger-api.dev3.filed.com/api/v1/',
		SocialMessengerSocket: 'https://py-filed-messenger-api.dev3.filed.com/social'
	},
	InfluencerFacebookClientId: '174014546372191',
	InfluencerGoogleClientId: '17118844476-fq28ato7jbgv4095u7cd6crlp9m4as55.apps.googleusercontent.com',
	InfluencerGoogleSecretKey: 'aZnW5q0aJi03Kx2rAVDt1m3W',
	InfluencerTiktokClientKey: 'aw28pnc9g2tuwvfl'
};

export class StorageKey {
	public static token = 'token';
	public static oldToken = 'oldToken';
	public static oldDecodedJwtIo = 'oldDecodedJwtIo';
	public static decodedJwtIo = 'decodedJwtIo';
	public static selectedAdAccounts = 'selectedAdAccounts';
	public static hideAdBlockerNotification = 'hideAdBlockerNotification';
	public static date = 'selectedDateRange';
	public static loadConversationStatus = 'loadConversationStatus';
}

export class NewStorageKey {
	public static token = 'jwt';
	public static oldToken = 'oldToken';
	public static oldDecodedJwtIo = 'oldDecodedJwtIo';
	public static decodedJwtIo = 'decodedJwtIo';
	public static selectedAdAccounts = 'selectedAdAccounts';
	public static hideAdBlockerNotification = 'hideAdBlockerNotification';
	public static date = 'selectedDateRange';
	public static loadConversationStatus = 'loadConversationStatus';
	public static first_time_user = 'first_time_user';
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRange } from '../../shared/calendar/calendar.models';
import { QueryModel } from '../../shared/query-builder/models/query.model';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { BaseApiUrl } from '../base-api-urls';

export class UserDashboard {
	constructor(public dashboards: any[]) {}
}

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	constructor(protected http: HttpClient) {}

	public getWidgetDataWithQueryBuilder(model: QueryModel, date: DateRange, channel: SourceChannel): Observable<any[]> {
		switch (channel) {
			case SourceChannel.Facebook:
				return this.http.post<any>(`${BaseApiUrl.FacebookDataPipeline}ads-manager/reports`, model);
		}
	}

	public getStructures(adAccountId: string, level: string): Observable<any[]> {
		return this.http.get<any[]>(`${BaseApiUrl.FacebookDataPipeline}${level.toLowerCase()}/${adAccountId}`);
	}
}

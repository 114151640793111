import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FiledUsersComponent } from './components/filed-users/users/filed-users.component';
import { MyAccountComponent } from './components/account/my-account/my-account.component';
import { MySubscriptionComponent } from './components/subscription/my-subscription/my-subscription.component';
import { ContactUsComponent } from './components/subscription/contact-us/contact-us.component';
import { Module as StripeModule } from 'stripe-angular';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { PaymentMethodComponent } from './components/filed-cards/payment-method/payment-method.component';
import { NewCardComponent } from './components/filed-cards/new-card/new-card.component';
import { UserRoleClientGuard } from '../shared/guards/user-role-client-guard.service';
import { UserRoleBusinessGuardService } from '../shared/guards/user-role-business-guard.service';
import { FiledUsersResolver } from '../_services/user-management/resolvers/filed-users.resolver';
import { AccountResolver } from '../_services/user-management/resolvers/account.resolver';
import { BillingAddressResolver } from '../_services/user-management/resolvers/billing-address.resolver';

const routes: Routes = [
	{
		canActivate: [UserRoleClientGuard],
		path: '',
		component: MyAccountComponent,
		resolve: {
			store: AccountResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'users',
		component: FiledUsersComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},

	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'billing-and-payment',
		component: PaymentMethodComponent,
		resolve: {
			store: BillingAddressResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'billing-add-card',
		component: NewCardComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'billing-invoice',
		component: InvoicesComponent
	},
	{
		canActivate: [UserRoleClientGuard],
		path: 'my-account',
		component: MyAccountComponent,
		resolve: {
			store: AccountResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'my-subscription',
		component: MySubscriptionComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'subscription-question',
		component: ContactUsComponent
	},
	{
		canActivate: [UserRoleClientGuard],
		path: 'change-password',
		component: ChangePasswordComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes), StripeModule.forRoot()],
	exports: [RouterModule]
})
export class UserManagementRoutingModule {}

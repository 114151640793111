export enum Modules {
	Accounts = 1,
	AdsManager = 2,
	Audience = 3,
	BackOffice = 20,
	CampaignBuilder = 30,
	CreativeBuilder = 31,
	Miscellaneous = 130,
	Optimize = 150,
	Pixel = 160,
	ProductCatalog = 161,
	Reports = 180,
	Settings = 190,
	Influencer = 90
}

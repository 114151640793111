import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { GalleryItem } from 'src/app/_models/Gallery/GalleryItem';
import { Asset } from 'src/app/shared/services/assets/models/asset.model';
import { takeUntil } from 'rxjs/operators';
import { ButtonClassEnum } from '../../../button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../button-component/button-type.enum';

const TREE_DATA: any = {
	Facebook: ['Single image', 'Carousel', 'Slideshow', 'Videos', 'Collection', 'Instant experience'],
	Instagram: ['Stories', 'Carousel', 'Stories carousel', 'Single video', 'Single image']
};

@Component({
	selector: 'app-gallery-mat-tab',
	templateUrl: './gallery-mat-tab.component.html',
	styleUrls: ['./gallery-mat-tab.component.scss']
})
export class GalleryMatTabComponent implements OnInit, OnDestroy {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	@Input()
	subject: BehaviorSubject<any>;

	@Output()
	submit = new EventEmitter();

	filteredData: GalleryItem[];

	data: GalleryItem[] = [];

	form: UntypedFormGroup;

	showFilter = false;

	filters: any = TREE_DATA;

	get anySelected() {
		return this.data && this.data.some(r => r.isSelected);
	}
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private formBuilder: UntypedFormBuilder) {
		this.form = this.formBuilder.group({
			search: new UntypedFormControl('', [])
		});
	}

	ngOnInit() {
		this.subject.pipe(takeUntil(this.unsubscriber$)).subscribe((asset: Asset[]) => {
			this.filteredData = this.data = asset.map(a => ({ isSelected: false, asset: a } as GalleryItem));
		});

		this.form
			.get('search')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe((f: string) => {
				if (f && f.length > 0) {
					this.filteredData = this.data.filter(d => d.asset.title.indexOf(f) > -1);
				} else {
					this.filteredData = this.data;
				}
			});
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	selectItems = (): void => this.submit.emit(this.data.filter(r => r.isSelected));
}

<div class="spinning-loader">
	<svg class="circular" [class.rotating]="rotating" [style.background]="background ? background : 'transparent'" viewBox="25 25 50 50">
		<circle class="path" cx="50" cy="50" r="18" fill="none" stroke-width="3" stroke-miterlimit="10" />
	</svg>
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 239 279" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M77.756 23.9188C55.3528 37.0466 28.6942 52.6355 18.5124 58.5612L0 69.3358V139.299V209.263L16.1082 218.601C24.9694 223.739 33.1724 227.94 34.337 227.94C35.771 227.94 36.4678 205.703 36.486 159.238L36.5164 90.5349L75.4754 67.7602C96.9044 55.235 115.81 44.4583 117.487 43.8151C120.181 42.7789 134.764 50.6976 189.849 83.1069L202.471 90.5349L202.508 159.238C202.526 198.118 203.326 227.94 204.349 227.94C205.344 227.94 213.547 223.763 222.578 218.658L239 209.377V139.388V69.3989L220.265 58.4411C143.941 13.8017 120.021 -0.0446757 119.308 0.000108214C118.856 0.0265715 100.157 10.791 77.756 23.9188ZM58.1681 94.6978L50.5829 99.8785L51.1156 169.412L51.6483 238.943L73.928 251.965C86.1818 259.126 101.419 268.138 107.789 271.993L119.372 279L153.361 258.998L187.352 238.994L187.931 219.226L188.51 199.46L179.226 194.926L169.941 190.393L160.924 195.199C152.433 199.726 151.907 200.508 151.907 208.594C151.907 217.166 151.874 217.2 135.821 226.729L119.735 236.274L103.414 226.743L87.0932 217.212V158.527V99.8418L79.5343 94.6795C70.1019 88.2387 67.6228 88.2408 58.1681 94.6978Z"
			fill="black"
		/>
	</svg>
</div>

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Directive({
	selector: '[appCustomAutoFocus]'
})
export class CustomAutoFocusDirective implements OnInit {
	@Input() focus: boolean = true;
	@Input() focusNow: Observable<void> = null;
	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		if (this.focus) this.elementRef.nativeElement.focus();
		if (this.focusNow)
			this.focusNow.subscribe(() => {
				// this.elementRef.nativeElement.preventDefault()
				this.elementRef.nativeElement.focus();
			});
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { MessageChatUser } from '../models/conversation.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MessageChatService {
	public isEmailUserEmpty$ = new BehaviorSubject<boolean>(false);
	public isMessageUserEmpty$ = new BehaviorSubject<boolean>(false);
	public selectedMessageChatUser$ = new Subject<MessageChatUser>();
	private user: any;
	public newMessageReceived: boolean = false;
	private newMessageData = new BehaviorSubject<any>('');

	constructor(private http: HttpClient) {}

	set selectedUser(data: any) {
		this.user = data;
	}

	get selectedUser() {
		return this.user;
	}

	set newMessage(data: any) {
		this.newMessageData.next(data);
	}

	get newMessage() {
		return this.newMessageData;
	}

	public GetMessageChatUserList(): Observable<MessageChatUser[]> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}messages/chat-list`);
		return this.http.get<MessageChatUser[]>(url.href);
	}

	public GetMessageChatDetail(influencer_Id: number): Observable<Object> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}messages/chat?id=${influencer_Id}`);
		return this.http.get<Object>(url.href);
	}

	public GetMessageFileList(influencer_Id: number): Observable<Object> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}messages/attachment?id=${influencer_Id}`);
		return this.http.get<Object>(url.href);
	}

	public sendAttachment(payload: { send_id: number; file: File; message: string }): Observable<any> {
		const formData = new FormData();

		formData.append('attachment', payload.file);
		formData.append('message', payload.message);

		const url = new URL(`${BaseApiUrl.SocialMessengerPython}messages/attachment/${payload.send_id}`);
		return this.http.post<any>(url.href, formData);
	}

	public applyChatFilterAction(payload: any, user_id: number): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}messages/metadata/${user_id}`);
		return this.http.patch<any>(url.href, payload);
	}

	public applyEmailFilterAction(payload: any, user_id: number): Observable<any> {
		// const url = new URL(`${BaseApiUrl.SocialMessengerPython}email/metadata/${user_id}`);
		const url = new URL(`${BaseApiUrl.SocialInfluencerPython}sendmail/emailmetadata/${user_id}`);
		return this.http.patch<any>(url.href, payload);
	}
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationActions, AuthenticationActionType } from './authentication.action';
import { CreditCardStaticVariables } from '../set-credit-card/CreditCardStaticVariables';
import { ResetPasswordDialogConstants } from '../reset-password-email/ResetPasswordDialogConstants';
import { DataInterface } from '../../shared/state/state-entities/data-interface.model';

export interface AuthenticationState {
	isAuthenticated: boolean;
	jwtToken: DataInterface<string>;
	setCreditCardConstants: CreditCardStaticVariables;
	resetPasswordConstants: ResetPasswordDialogConstants;
	isFirstTimeCustomer: boolean;
	redirectUrl: string;
}

export const initialAuthState: AuthenticationState = {
	isAuthenticated: false,
	jwtToken: null,
	setCreditCardConstants: new CreditCardStaticVariables(),
	resetPasswordConstants: new ResetPasswordDialogConstants(),
	isFirstTimeCustomer: false,
	redirectUrl: '/redirect'
};

const getAuthenticationState = createFeatureSelector<AuthenticationState>('authentication');

export const getSetCreditCardConstants = createSelector(getAuthenticationState, state => state.setCreditCardConstants);
export const getResetPasswordDialogConstants = createSelector(getAuthenticationState, state => state.resetPasswordConstants.validationMessages);
export const getIsFirstTimeCustomer = createSelector(getAuthenticationState, state => state.isFirstTimeCustomer);
export const getRedirectUrl = createSelector(getAuthenticationState, state => state.redirectUrl);

export function authenticationReducer(state = initialAuthState, action: AuthenticationActions): AuthenticationState {
	switch (action.type) {
		case AuthenticationActionType.LogIn: {
			return {
				...state,
				isAuthenticated: true,
				jwtToken: {
					...state.jwtToken,
					isLoaded: true
				}
			};
		}
		case AuthenticationActionType.RestoreAuthentication:
		case AuthenticationActionType.LogInSuccess: {
			return {
				...state,
				isAuthenticated: true,
				jwtToken: {
					...state.jwtToken,
					data: action.payload
				}
			};
		}
		case AuthenticationActionType.LogInFailure: {
			return {
				...state,
				isAuthenticated: false,
				jwtToken: {
					...state.jwtToken,
					errorCode: action.payload
				}
			};
		}
		case AuthenticationActionType.EnableFirstTimeCustomer: {
			return {
				...state,
				isFirstTimeCustomer: true
			};
		}
		case AuthenticationActionType.DisableFirstTimeCustomer: {
			return {
				...state,
				isFirstTimeCustomer: false
			};
		}
		case AuthenticationActionType.LogOut: {
			return {
				...initialAuthState,
				isFirstTimeCustomer: state.isFirstTimeCustomer
			};
		}
		default:
			return state;
	}
}

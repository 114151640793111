import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ViewDto } from 'src/app/shared/campaign-insights/models/user-views.model';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { TableViewColumn2 } from '../generic-table2/models/table-structure/table-view-column.model';
import { TableViewType } from '../generic-table2/models/table-structure/table-view-type.enum';
import { FacebookActionIdentifier } from '../services/actions/models/identifiers/facebook-action-identifier.enum';
import { InsightsMetadataBaseService } from '../services/insights-metadata-base.service';
import { InsightsReportModel } from './models/insights-report.model';
import { ViewCollection } from './models/view-collection.model';

export class FacebookInsightsMetadataService extends InsightsMetadataBaseService {
	constructor(http: HttpClient, tableName: string) {
		super(http, tableName);
	}

	public getInsightsMetadataModel(useCache: boolean = false): Observable<InsightsReportModel[]> {
		if (useCache && this.modelInstances) {
			return of(this.modelInstances);
		} else {
			return forkJoin([this.populateBreakdowns(), this.populateColumns()]).pipe(
				map(([breakdowns, columns]: [any, TableViewColumn2[]]) => {
					const reportModel = new InsightsReportModel();
					reportModel.breakdowns = breakdowns;
					reportModel.columns = columns;

					this.modelInstances = [];
					this.modelInstances.push(reportModel);

					return this.modelInstances;
				})
			);
		}
	}

	private populateBreakdowns(): Observable<any> {
		return forkJoin([this.getAllBreakdowns(), this.getAllBreakdownCombinations()]).pipe(
			timeout(this.REQUEST_TIMEOUT_MS),
			catchError(e => {
				return of([this.getEmptyBreakdownWrapper(), [] as any[]]);
			}),
			map(([breakdowns, breakdownCombinations]: [any, any[]]) => {
				breakdowns.combinations = breakdownCombinations || [];
				return breakdowns;
			})
		);
	}

	private populateColumns(): Observable<TableViewColumn2[]> {
		return this.getUserViews(this.tableName).pipe(
			map((collection: ViewCollection) => {
				const views = collection.views;
				const masterView = views.find(view => view.type === TableViewType.Master);

				masterView.columns.forEach(column => (column.actions = this.getActionListForColumn(column)));
				return masterView.columns;
			})
		);
	}

	private getEmptyBreakdownWrapper(): any {
		return {
			segments: [],
			action: [],
			delivery: [],
			time: [],
			combinations: []
		};
	}

	private getAllBreakdowns(): Observable<any> {
		return this.http.get<any>(BaseApiUrl.FacebookDataPipeline + 'breakdowns');
	}

	private getAllBreakdownCombinations(): Observable<any[]> {
		return this.http.get<any[]>(BaseApiUrl.FacebookDataPipeline + 'breakdowns-combinations');
	}

	private getUserViews<T>(tableName: string): Observable<ViewDto<T>> {
		return this.http.get<ViewDto<T>>(`${BaseApiUrl.FacebookDataPipeline}views/${tableName}`);
	}

	// ============================== Inline actions ===============================
	// TODO: Remove this as it becomes obsolete when action identifiers come from BE
	private getActionListForColumn(col: TableViewColumn2): string[] {
		switch (col.id) {
			case 'e290ca6f46908d64b270de65471b8181e22e028f':
				return [FacebookActionIdentifier.InlineEditCampaign, FacebookActionIdentifier.RedirectAdsManagerParent];
			case 'ef1686cb31938ba825934d61ee6f95d337f61614':
				return [FacebookActionIdentifier.InlineEditAdset, FacebookActionIdentifier.RedirectAdsManagerParent];
			case 'df73ca5a2c8f1ebb7bb676a0ce0a9ecb8ecfbc29':
				return [FacebookActionIdentifier.InlineEditAd];
			case '81e4a5523e4e1843f200847f875f60d495df137f':
				return [FacebookActionIdentifier.InlineEditBudget];
			case '9343379cddc7060331f26ce61ff0d8d86fae898c':
				return [FacebookActionIdentifier.InlineEditSchedule];
			case '48a3661d846478fa991a825ebd10b78671444b5b':
				return [FacebookActionIdentifier.InlineStateToggle];
			default:
				return [];
		}
	}
}

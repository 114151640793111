import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import moment from 'moment';
import { DateRangeX, PublicDateModel } from './calendar.models';
import { SharedState } from '../state/shared.reducer';
import { Store } from '@ngrx/store';
import { SetGlobalDate } from '../state/shared.actions';
import _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class CalendarService {
	constructor(private store: Store<SharedState>) {}

	private localStorageFormat = 'YYYY-MM-DD';
	private localStorageKeyName = 'selectedDateRange';
	private lastWeekDate = {
		startDate: moment().subtract(6, 'days'),
		endDate: moment()
	};

	public dateChangedSource = new Subject<PublicDateModel>();
	dateChanged$ = this.dateChangedSource.asObservable();

	changeDate(dateRange: PublicDateModel) {
		this.dateChangedSource.next(dateRange);

		this.storeDateRange(dateRange);

		this.updateStoreDate(dateRange);
	}

	getInitialDate(): PublicDateModel {
		const selectedDateRangeBuffer = JSON.parse(localStorage.getItem(this.localStorageKeyName)) as PublicDateModel;
		let selectedDateRange: PublicDateModel;
		if (this.isValid(selectedDateRangeBuffer)) {
			selectedDateRange = this.deStringify(selectedDateRangeBuffer);
		} else {
			selectedDateRange = new PublicDateModel();
			selectedDateRange.date.startDate = this.lastWeekDate.startDate;
			selectedDateRange.date.endDate = this.lastWeekDate.endDate;
			this.storeDateRange(selectedDateRange);
		}
		this.updateStoreDate(selectedDateRange);
		return selectedDateRange;
	}

	private isValid(x: PublicDateModel) {
		return x && x.date && x.date.startDate && x.date.endDate;
	}

	private deStringify(dateRange: PublicDateModel) {
		const dateRangeBuffer = dateRange;
		if (!moment.isMoment(dateRangeBuffer.date.startDate)) {
			dateRangeBuffer.date.startDate = moment(dateRangeBuffer.date.startDate, this.localStorageFormat);
		}
		if (!moment.isMoment(dateRangeBuffer.date.endDate)) {
			dateRangeBuffer.date.endDate = moment(dateRangeBuffer.date.endDate, this.localStorageFormat);
		}
		if (dateRangeBuffer.compareDate && !moment.isMoment(dateRangeBuffer.compareDate.startDate)) {
			dateRangeBuffer.compareDate.startDate = moment(dateRangeBuffer.compareDate.startDate, this.localStorageFormat);
		}
		if (dateRangeBuffer.compareDate && !moment.isMoment(dateRangeBuffer.compareDate.endDate)) {
			dateRangeBuffer.compareDate.endDate = moment(dateRangeBuffer.compareDate.endDate, this.localStorageFormat);
		}
		return dateRangeBuffer;
	}

	private updateStoreDate(selectedDateRange: PublicDateModel) {
		this.store.dispatch(new SetGlobalDate(_.cloneDeep(selectedDateRange)));
	}

	private storeDateRange(selectedDateRange: PublicDateModel) {
		const stringifiedDateRange = {
			date: {
				startDate: selectedDateRange.date.startDate.format(this.localStorageFormat),
				endDate: selectedDateRange.date.endDate.format(this.localStorageFormat)
			},
			// @ts-ignore
			compareDate: null
		};

		if (selectedDateRange.compareDate.startDate) {
			stringifiedDateRange.compareDate = new DateRangeX<string>();
			stringifiedDateRange.compareDate.startDate = selectedDateRange.compareDate.startDate.format(this.localStorageFormat);
			stringifiedDateRange.compareDate.endDate = selectedDateRange.compareDate.endDate.format(this.localStorageFormat);
		}
	}
}

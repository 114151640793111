import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-new-tooltip',
	templateUrl: './new-tooltip.component.html',
	styleUrls: ['./new-tooltip.component.scss']
})
export class NewTooltipComponent implements OnInit {
	@Input() public text: string;
	@Input() public link: string;
	@Input() public linkText: string;
	@Input() public icon: string = 'help';
	constructor() {}

	ngOnInit(): void {}

	public goTo(): void {
		window.open(this.link, '_blank');
	}
}

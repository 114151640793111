import { Injectable } from '@angular/core';
import { DropdownData } from '../dropdown-search-select/dropdown-data.interface';

@Injectable()
export class MapperService {
	constructor() {}

	public convertToDropdownData<T = any>(data: T[], valueKey: string, displayNameKey: string): DropdownData<T>[] {
		return data.map(item => {
			return {
				displayName: (item as any)[displayNameKey],
				value: (item as any)[valueKey],
				data: item
			};
		});
	}

	public convertToDropdownDataOfType<T, U>(data: T[], valueKey: string, displayNameKey: string, callback: (item: T) => U): DropdownData<U>[] {
		return data.map(item => {
			return {
				displayName: (item as any)[displayNameKey],
				value: (item as any)[valueKey],
				data: callback(item)
			};
		});
	}

	public mapToString(data: any) {
		return JSON.stringify(data, (valueKey, value) => (value instanceof Map ? [...value] : value));
	}
}

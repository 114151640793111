import { HttpClient, HttpContext } from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { UserTokenData } from 'src/app/creators-module/modules/authentication/models/auth.model';
import { NewStorageKey } from 'src/app/_models/local-storage-key';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { NotificationActionEnum, NotificationModel, NotificationResponse } from 'src/app/sidenav/_models/notification.model';
import { map } from 'rxjs/operators';
import { BYPASS_LOG } from 'src/app/_services/interceptors/http-config.interceptor';

@Injectable({
	providedIn: 'root'
})
export class SharedService {
	public userDetailsSubject$ = new BehaviorSubject<UserTokenData>(null);
	public sidenavHeaderShow$ = new BehaviorSubject<boolean>(true);
	private unsubscriber$ = new Subject<void>();
	public showFooter = true;
	public showCloseBtn = false;
	public isMobileView = false;
	public isTabView = false;

	constructor(private http: HttpClient) {
		this.userDetailsSubject$.next(JSON.parse(localStorage.getItem(NewStorageKey.decodedJwtIo)));

		this.getScreenWidth();
		window.addEventListener('resize', this.getScreenWidth);
	}

	private getScreenWidth(): void {
		if (window.innerWidth < 820) this.isMobileView = true;
		else this.isMobileView = false;

		if (window.innerWidth >= 820 && window.innerWidth <= 1180) this.isTabView = true;
		else this.isTabView = false;
	}

	public disableFirstTimeUser(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.SocialInfluencerPython}influencerUser/tourFinish`, {});
	}

	public getSidenavHeaderState(): Observable<boolean> {
		return this.sidenavHeaderShow$.asObservable();
	}

	public changeSidenavHeaderState(state: boolean): void {
		this.sidenavHeaderShow$.next(state);
	}

	////// BELOW ENDPOINTS FOR NOTIFICATIONS

	public postMockNotification(): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialInfluencerPython}notifications/notifications`, {
			user_id: 123,
			message: 'Hi whats up the test  passed printing data to be sent to from'
		});
	}

	public observeNotificationEvents(): Observable<any> {
		const token = localStorage.getItem(NewStorageKey.token);
		const decodedToken: UserTokenData = JSON.parse(localStorage.getItem(NewStorageKey.decodedJwtIo));
		console.log(decodedToken);
		return new Observable<any>(obs => {
			const es = new EventSource(`${BaseApiUrl.SocialMessengerPython}notifications/?token=${token}`);
			es.addEventListener('open', evt => {
				obs.next(evt);
			});
			es.onmessage = event => {
				obs.next(event);
			};
			es.addEventListener(`new_message_influencer_${decodedToken.user_filed_id}`, evt => {
				obs.next(evt.data);
			});
			return () => es.close();
		});
	}

	public getAllNotifications(pageSize: number = 10, pageNumber: number = 1): Observable<NotificationResponse> {
		return this.http.get<NotificationResponse>(
			`${BaseApiUrl.SocialMessengerPython}notifications/notification-list?page_size=${pageSize}&page_number=${pageNumber}`
		);
	}

	public deleteNotification(notificationId: number): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.SocialMessengerPython}notifications/notification-delete?id=${notificationId}`);
	}

	public muteNotification(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}notifications/notification-preference`, payload);
	}

	public readAllNotifications(): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}notifications/update-unread`, {});
	}

	public uploadFile(file: File): Observable<any> {
		const body = new FormData();
		body.append('file', file);
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}upload/file`, body);
	}

	public downloadPDF(url: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}upload/view?url=${encodeURIComponent(url)}`);
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}

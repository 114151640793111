import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationActionEnum, NotificationModel } from '../../_models/notification.model';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
	selector: 'app-notifications-dialog',
	templateUrl: './notifications-dialog.component.html',
	styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit {
	@Input() notifications: NotificationModel[] = [];
	@Input() unreadCount: number = 0;
	@Output() updateUnreadCount = new EventEmitter<number>();
	private unsubscriber$ = new Subject<void>();

	constructor(public router: Router, public sharedService: SharedService) {}

	ngOnInit(): void {}

	public onNotificationClick(notification: NotificationModel): void {
		// this.implementAction(notification);
	}

	public onNotificationActionClick(notification: NotificationModel): void {
		this.implementAction(notification);
	}

	public implementAction(notification: NotificationModel): void {
		switch (notification.action_type) {
			case NotificationActionEnum.CREATOR_MESSAGE_RECEIVED:
				this.router.navigate(['messaging'], { queryParams: { creatorId: notification.user_id } });
				break;
			case NotificationActionEnum.CREATOR_EMAIL_RECEIVED:
				this.router.navigate(['messaging'], { queryParams: { emailCreatorId: notification.user_id } });
				break;
			case NotificationActionEnum.CREATOR_JOB_RECEIVED:
			case NotificationActionEnum.CREATOR_HIRED:
			case NotificationActionEnum.CREATOR_REJECTED:
				location.href = `my-jobs;job_id=${notification.job_id}`;
				break;
			case NotificationActionEnum.CREATOR_CREATIVE_APPROVED:
			case NotificationActionEnum.CREATOR_CREATIVE_DECLINED:
			case NotificationActionEnum.CREATOR_POST_URL_ACCEPTED:
				this.router.navigate(['creatives'], { queryParams: { id: notification.creative_id } });
				break;
			case NotificationActionEnum.CREATOR_PAYMENT_COMPLETED:
				this.router.navigate(['earnings'], { queryParams: { id: 6 } });
				break;
			case NotificationActionEnum.CREATOR_CREATIVE_DEADLINE:
				this.router.navigate(['creatives']);
				break;
			case NotificationActionEnum.CREATOR_STRIPE_CONNECTED:
				this.router.navigate(['settings/billing']);
				break;
			case NotificationActionEnum.CREATOR_SOCIAL_MEDIA_CONNECTED:
				this.router.navigate(['settings']);
				break;
			case NotificationActionEnum.CREATOR_EMAIL_CONNECTED:
				this.router.navigate(['settings/integrations']);
				break;
		}
	}

	public onEditNotificationClick(notification: NotificationModel, editAction: string): void {
		if (editAction == 'delete') {
			this.deleteNotification(notification);
		} else if (editAction == 'mute') {
			this.toggleMuteNotification(notification);
		}
	}

	public deleteNotification(notification: NotificationModel): void {
		const index = this.notifications.findIndex(n => n.id == notification.id);
		this.notifications.splice(index, 1);
		this.sharedService
			.deleteNotification(notification.id)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public toggleMuteNotification(notification: NotificationModel): void {
		const payload = {
			preference_list: notification.action_type,
			type: notification?.muted ? 'unmute' : 'mute'
		};
		notification.muted = !notification.muted;
		this.sharedService
			.muteNotification(payload)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public menuOpened(): void {}

	public menuClosed(): void {
		this.unreadCount > 7 ? (this.unreadCount -= 7) : (this.unreadCount = 0);
		// mark top 7 as read here
		for (let i = 0; i < 7; ++i) {
			if (this.notifications[i]) this.notifications[i].unread = false;
		}
		this.updateUnreadCount.emit(this.unreadCount);
	}

	public routeToNotifications(): void {
		this.markAllAsRead();
		this.router.navigate(['notifications']);
	}

	public markAllAsRead(): void {
		this.sharedService
			.readAllNotifications()
			.pipe(take(1))
			.subscribe(
				res => {
					// All Notifications marked as read
				},
				err => {
					// Some error occurred
				}
			);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
